
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { logo } from '@/utils/icons';
import { useStore } from 'vuex';
import { useField, useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import validationSchema from '@/validator/reset-password';

interface ErrorList {
  error?: string;
  email?: string;
  password?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'Reset',
  components: {
    VizButton
  },
  setup() {
    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const icons = markRaw({
      logo: logo
    });
    const { errors, handleSubmit, meta } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('resetPassword', { ...values })
        .then(() => {
          router.push({ name: 'ResetComplete' });
          state.isLoading = false;
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;

          err.errors.forEach(item => {
            if (item.error) {
              state.errorMessage = item.error;
              setTimeout(() => {
                state.errorMessage = '';
              }, 5000);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          });
        });
    });

    const { value: oldPassword } = useField('oldPassword');
    const { value: newPassword } = useField('newPassword');
    const { value: confirmNewPassword } = useField('confirmNewPassword');

    return {
      oldPassword,
      newPassword,
      confirmNewPassword,
      onSubmit,
      errors,
      state,
      meta,
      icons
    };
  }
});
