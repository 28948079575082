
import { defineComponent, markRaw } from 'vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import VizTable from '@/components/ui/VizTable.vue';
import VizModal from '@/components/ui/VizModal.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import VizButton from '@/components/ui/VizButton.vue';

import AnalysisReport from '@/components/contribution-analysis/AnalysisResultReport.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
import PatternAReport from '@/components/measure-simulation/PatternAReport.vue';
import PatternBReport from '@/components/measure-simulation/PatternBReport.vue';

import { AnalysisHistory } from '@/store/modules/history';
import learnmoreIcon from '@/assets/icons/more.svg';

import ImageViewer from '@/components/target-extraction/ImageViewer.vue';

interface AnalysisHeadingsMap {
  execution_date: string;
  type_of_analysis: string;
  analysis_status: string;
  name: string;
  username: string;
}

export default defineComponent({
  name: 'AnalysisHistory',
  components: {
    VizTable,
    VizModal,
    VizButton,
    VizPagination,
    AnalysisReport,
    ExtractionReport,
    PatternAReport,
    PatternBReport,
    ImageViewer
  },

  data() {
    const payload = {
      task_ids: [] as number[],
      features: [] as string[]
    };
    const icons = markRaw({
      learnmore: learnmoreIcon
    });
    return {
      isModalVisible: false,
      analysisHeadingsMap: {
        execution_date: 'pages.ih.tab2.th1',
        type_of_analysis: 'pages.ih.tab2.th2',
        analysis_status: '分析ステータス',
        username: 'pages.ih.tab2.th3',
        name: 'pages.ih.tab2.th4'
      } as AnalysisHeadingsMap,
      currentReport: {
        type: '',
        taskId: 0
      },
      errorMessage: '',
      icons,
      perPageCount: 10,
      currentPage: 1,
      isDeletionPending: false,
      selectedEntry: {
        taskId: 0,
        feature: ''
      },
      payload,
      isMultipleDelete: false
    };
  },
  methods: {
    ...mapActions([
      'getAnalysisHistory',
      'getImportHistory',
      'deleteAnalysisHistory',
      'deleteMultipleAnalysisHistory',
      'addToast',
      'getStorageUsage',
      'abortTask'
    ]),

    handlePageChange(page: number, perPage: number) {
      this.perPageCount = perPage;
      this.currentPage = page;
      this.getAnalysisHistory({ page, perPage })
        .then(() => {
          this.errorMessage = '';
        })
        .catch(err => {
          if (err?.errors && err.errors[0].error) {
            this.errorMessage = err.errors[0].error;
          }
        })
        .finally(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            // eslint-disable-next-line
            // @ts-ignore
            checkbox.checked = false;
          }

          this.payload.features = [];
          this.payload.task_ids = [];
          this.isMultipleDelete = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    closeSingleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteSingle as any;
      dismissRef?.click();
    },
    closeMultipleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteMultiple as any;
      dismissRef?.click();
    },
    setSelectedEntry(taskId: number, feature: string) {
      this.isMultipleDelete = false;
      this.selectedEntry = { taskId, feature };
    },
    handleSelect(e: any) {
      e.target.checked ? this.selectAll() : this.deSelectAll();
    },
    selectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        if (items[i].type == 'checkbox') {
          // eslint-disable-next-line
          // @ts-ignore
          items[i].checked = true;
          if (
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features[i] !== items[i].name &&
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids[i] !== items[i].value
          ) {
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features.push(items[i].name);
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids.push(Number(items[i].value));
          }
        }
      }
    },
    deSelectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        items[i].checked = false;
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.features, items[i].name);
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.task_ids, Number(items[i].value));
      }
    },
    setSelectedItems(taskId: any, feature: any, event: any) {
      const selectAllCheckboxId = document.getElementById(
        'selectAllCheckboxId'
      );
      if (selectAllCheckboxId) {
        // eslint-disable-next-line
        // @ts-ignore
        selectAllCheckboxId.checked = false;
      }
      this.isMultipleDelete = true;
      if (event.target.checked) {
        this.payload.features.push(feature);
        this.payload.task_ids.push(Number(taskId));
      } else {
        this.removeItem(this.payload.features, feature);
        this.removeItem(this.payload.task_ids, Number(taskId));
      }
    },
    removeItem(array: Array<any>, itemToRemove: any) {
      const index = array.indexOf(itemToRemove);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    handleMultipleDelete() {
      this.isDeletionPending = true;

      this.deleteMultipleAnalysisHistory(this.payload)
        .then(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            if (checkboxes !== null) {
              // eslint-disable-next-line
              // @ts-ignore
              checkbox.checked = false;
            }
          }
          if (
            this.perPageCount >= this.payload.features.length &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeMultipleDeleteModal();
          this.addToast({ message: 'データを削除しました。' });
        })
        .catch((err: any) => {
          const errs = err?.errors;

          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    handleSingleDelete() {
      const { taskId, feature } = this.selectedEntry;
      if (!taskId) {
        return;
      }
      this.isDeletionPending = true;
      this.deleteAnalysisHistory({ taskId, feature })
        .then(() => {
          if (
            this.perPageCount >= this.analysisHistory.totalEntries - 1 &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeSingleDeleteModal();
          this.addToast({ message: 'messages.analysis-history-deleted' });
          this.isDeletionPending = false;
        })
        .catch(err => {
          this.isDeletionPending = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    handleAbort() {
      const { taskId, feature } = this.selectedEntry;
      if (!taskId) {
        return;
      }
      this.isDeletionPending = true;
      this.abortTask({ taskId, feature })
        .then(() => {
          if (
            this.perPageCount >= this.analysisHistory.totalEntries - 1 &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeSingleDeleteModal();
          // this.addToast({ message: 'messages.analysis-history-deleted' });
          this.isDeletionPending = false;
        })
        .catch(err => {
          this.isDeletionPending = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    showModal(item: AnalysisHistory) {
      this.isModalVisible = true;
      this.currentReport.type = item.type_of_analysis;
      this.currentReport.taskId = item.task_id;
    },
    hideModal() {
      this.isModalVisible = false;
      this.currentReport = {
        type: '',
        taskId: 0
      };
    },
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss');
    }
  },
  computed: {
    ...mapGetters(['analysisHistory', 'userGroup']),

    headings(): string[] {
      return Object.keys(this.analysisHeadingsMap);
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.handlePageChange(1, 10);
      }
    }
  }
});
