
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VizTabs',
  emits: ['onTabChange'],

  props: ['tabs', 'currentTab'],
  setup(props, { emit }) {
    const handleChange = (tab: any) => {
      emit('onTabChange', tab);
    };
    return { handleChange };
  }
});
