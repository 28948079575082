
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';
// import VolumePrediction from '@/components/measure-simulation/VolumePrediction.vue';
import KpiForecast from '@/components/measure-simulation/KpiForecast.vue';
import { dropdownIcon } from '@/utils/icons';
import simulationAPI from '@/api/measure-simulation';
import { createNamespacedHelpers } from 'vuex';
import SalesBarGraph from '@/components/measure-simulation/SalesBarGraph.vue';
import { stringHelpers } from '@/utils/helpers';

const { mapActions } = createNamespacedHelpers('ms');

interface NumberKeyValInterface {
  [key: string]: string | number;
}

export default defineComponent({
  name: 'TargetSales',
  props: { steps: Array },

  beforeMount() {
    const { pattern, taskId } = this.$route.params;

    if (!pattern || !taskId) {
      this.$router.replace({ path: '/simulation' });
    }

    this.getMediaVolumeReport(Number(taskId));
    this.getTotalSales(Number(taskId));
  },
  computed: {
    targetKeys: function(): Array<string> {
      if (!this.state.targetValues) return [];
      return Object.keys(this.state.targetValues);
    }
  },
  methods: {
    ...mapActions(['runSimulateModel', 'exportSimulationReport']),
    addCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.addCommas(target.value);
    },
    removeCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.removeCommas(target.value);
    },
    handleExport: function() {
      this.state.isLoading = true;
      const { taskId } = this.$route.params;

      this.exportSimulationReport(taskId)
        .then(() => {
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    },
    handleTargetValuesChange(ev: Event) {
      const target = ev.target as HTMLInputElement;
      this.state.targetValues[target.name] = target.value;
    },

    handleResimulate() {
      const { taskId, modelName, pattern } = this.$route.params;

      this.state.isResimulateLoading = true;

      Object.keys(this.state.targetValues).forEach(el => {
        const tempNum = Number(
          stringHelpers.removeCommas(String(this.state.targetValues[el]))
        );

        if (!isNaN(tempNum)) {
          this.state.targetValues[el] = tempNum;
        }
      });

      this.runSimulateModel({
        targetValues: this.state.targetValues,
        taskId,
        modelName
      })
        .then(() => {
          this.state.isResimulateLoading = false;

          this.$router.push({
            path: '/simulate/observe',
            name: 'SimulationObserve',
            params: { taskId, modelName, pattern }
          });
        })
        .catch(err => {
          this.state.isResimulateLoading = false;

          const errors = err?.errors;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((item: any) => {
            if (item.error) {
              this.state.targetValuesError = item.error;
            }
            if (item.target_values) {
              this.state.targetValuesError = item.target_values;
            }
          });
        });
    },
    getTotalSales(taskId: number) {
      this.state.totalSales = 0;

      simulationAPI
        .getSimulateTotalSalesReport(taskId)
        .then(res => {
          const results = res?.data?.results;
          this.state.totalSales = results
            ? Number(Number(results[0]?.total_sales).toFixed(0))
            : 0;
          this.state.targetSalesError = '';
        })
        .catch(err => {
          this.state.totalSales = 0;
          this.state.targetSalesError = err?.errors ? err.errors[0]?.error : '';
        });
    },
    getMediaVolumeReport(taskId: number) {
      this.state.targetValues = {};
      simulationAPI
        .getSimulateMediaVolumeReport(taskId)
        .then(res => {
          const results = res.data?.results;
          let tempTarget = {};

          if (Array.isArray(results)) {
            results.forEach(item => {
              tempTarget = {
                ...tempTarget,
                [item.val]: stringHelpers.addCommas(
                  String(
                    isNaN(Number(item.budget))
                      ? item.budget
                      : Number(item.budget).toFixed(0)
                  )
                )
              };
            });
          }

          this.state.targetValues = tempTarget;
          this.state.targetValuesError = '';
        })
        .catch(err => {
          this.state.targetValuesError = err?.errors
            ? err.errors[0]?.error
            : '';
        });
    }
  },
  setup() {
    const icons = markRaw({
      dropdownIcon
    });
    const state = reactive({
      totalSales: 0,
      targetValues: {} as NumberKeyValInterface,
      errorMessage: '',
      targetSalesError: '',
      targetValuesError: '',
      isLoading: false,
      isResimulateLoading: false
    });
    return { currentStep: 2, icons, state, stringHelpers };
  },

  components: {
    StepsTrack,
    VizButton,
    // VolumePrediction,
    KpiForecast,
    SalesBarGraph
  }
});
