<template>
  <div>
    <div class="sub_title">
      <h4>
        <slot />
      </h4>
    </div>
    <div class="d-flex custom-radio">
      <div
        class="form-check me-3"
        v-for="option in options"
        :key="option.value"
      >
        <input
          class="form-check-input"
          type="radio"
          name="radioSelect"
          :value="option.value"
          :id="option.value"
          @change="handleChange"
          :checked="option.value == modelValue"
        />
        <label class="form-check-label" :for="option.value">
          {{ option.text }}
        </label>

        <viz-tooltip>
          {{ option.tooltip }}
        </viz-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';

export default defineComponent({
  components: {
    VizTooltip
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    modelValue: String // previously was `value: String`
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange(ev: Event) {
      const target = ev.target as HTMLInputElement;
      this.$emit('update:modelValue', target.value); // previously was `this.$emit('input', title)`
    }
  }
});
</script>

<style scoped>
.tooltip {
  background-color: red !important;
}
</style>
