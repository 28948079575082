<template>
  <div class="analysis_result mt-5">
    <!-- form section -->
    <div
      class="result-form d-flex justify-content-between align-items-center mb-5"
    >
      <div class="col-md-4 sub_title">
        <h4>{{ $t('pages.ca.analysisreport.title') }}</h4>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-center">
        <viz-button
          type="submit"
          class="btn btn-outline-primary has-dropdown"
          @click="handleExport"
          :loading="isLoading"
        >
          {{ $t('common.buttonlabels.export') }}
          <span class="d-inline-block ms-3 me-2">
            <figure>
              <img :src="icons.dropdownIcon" alt="" />
            </figure>
          </span>
        </viz-button>
      </div>
    </div>

    <!-- section correlation -->
    <kpi-corr-result :taskId="taskId"></kpi-corr-result>
    <!-- section contribution -->
    <kpi-coef-result :taskId="taskId"></kpi-coef-result>
    <!-- section consideration -->
    <kpi-effect-result :taskId="taskId"></kpi-effect-result>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { helpIcon, dropdownIcon } from '@/utils/icons';
import KpiCorrResult from '@/components/contribution-analysis/KpiCorrResult.vue';
import KpiCoefResult from '@/components/contribution-analysis/KpiCoefResult.vue';
import KpiEffectResult from '@/components/contribution-analysis/KpiEffectResult.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'AnalysisReport',
  components: {
    KpiCorrResult,
    KpiCoefResult,
    KpiEffectResult,
    VizButton,
  },

  data() {
    const icons = markRaw({
      helpIcon,
      dropdownIcon,
    });
    return {
      icons,
      isLoading: false,
    };
  },
  props: ['taskId'],
  methods: {
    ...mapActions(['exportAnalysisReport']),
    handleExport() {
      this.isLoading = true;

      this.exportAnalysisReport(this.taskId)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
