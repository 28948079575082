import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import AppLayout from '@/layout/AppLayout.vue';
import EmptyLayout from '@/layout/EmptyLayout.vue';
import appRoutes from './routes/app-routes';
import authRoutes from './routes/auth-routes';
import managerRoutes from './routes/manager-routes';

import { userRoles } from '@/constants/role';
import { UserGroup } from '@/store/modules/auth';
import { handleTokenRefresh } from '@/utils/session-handler';
import {
  deleteAccessToken,
  deleteExpiryTime,
  deleteRefreshToken
} from '@/utils/local-storage';

const routes = [
  {
    name: 'root',
    path: '/app',
    component: AppLayout,
    children: [...appRoutes]
  },
  //path separated for grouping based on layout
  {
    name: 'auth',
    path: '/auth',
    component: EmptyLayout,
    children: [...authRoutes]
  },
  {
    name: 'manage',
    path: '/manage',
    component: EmptyLayout,
    children: [...managerRoutes]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/analysis/enter'
  }
];
export const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  const currentUser = store.getters.user;

  const { authentication } = to.meta;
  if (!currentUser?.user_role && isAuthenticated && authentication) {
    handleTokenRefresh()
      .then(() => {
        store
          .dispatch('fetchUserGroups')
          .then(groups => {
            const { group: groupId } = to.query;

            if (groups?.length > 0) {
              const currentGroup = groups.filter(
                (item: UserGroup) => item.id == Number(groupId)
              )[0];

              store
                .dispatch(
                  'changeUserGroup',
                  currentGroup?.id ? currentGroup : groups[0]
                )
                .then(() => {
                  return next();
                });
            } else {
              store.dispatch('logout').then(() => {
                return next({ name: 'Login' });
              });
            }
          })
          .catch(err => {
            if (err.status == 401 || err.status == 403 || err.status == 500) {
              store.commit('logoutSuccess');
              deleteAccessToken();
              deleteRefreshToken();
              deleteExpiryTime();
              return next({ name: 'Login' });
            }
          });
      })
      .catch(() => {
        store.commit('logoutSuccess');
        deleteAccessToken();
        deleteRefreshToken();
        deleteExpiryTime();
        return next({ name: 'Login' });
      });
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { userFunction, authentication, enableAccessControl } = to.meta;
  const isAuthenticated = store.getters.isLoggedIn;
  const currentUser = store.getters.user;

  //check if auth is enabled on route
  if (authentication) {
    if (!isAuthenticated) {
      // not logged in so redirect to login page with the return url
      return next({ name: 'Login' });
    }
    //logic for handling group creation if manager did not create group during signup
    if (!currentUser && to.name == 'AddGroup') {
      next();
    }

    if (userFunction) {
      if (currentUser?.user_role == userRoles.MANAGER) {
        if (!enableAccessControl) return next();
        else {
          if (!currentUser?.user_functions.includes(userFunction)) {
            return next({ name: 'AccountSettings' });
          }
        }
      }

      if (
        currentUser?.user_role == userRoles.USER &&
        !currentUser?.user_functions.includes(userFunction)
      ) {
        return next({ name: 'AccountSettings' });
      }
    }
  }
  return next();
});

export default router;
