<template>
  <div class="main px-60">
    <main>
      <div class="account-setting">
        <form
          action="post"
          class="form-group_name"
          @submit.prevent="onSubmit"
          novalidate
        >
          <legend>{{ $t('pages.reset.title') }}</legend>

          <p class="text-danger mb-4" v-if="state.errorMessage">
            {{ state.errorMessage }}
          </p>

          <div class="input-groups">
            <input
              type="password"
              class="form-control"
              id="old-password"
              v-model="oldPassword"
              :placeholder="$t('labels.old-password')"
            />

            <label for="signup-password">{{ $t('labels.old-password') }}</label>
            <span class="text-danger">{{
              errors.oldPassword ? $t(errors.oldPassword) : ''
            }}</span>
          </div>

          <div class="input-groups">
            <input
              type="password"
              class="form-control"
              id="new-password"
              v-model="newPassword"
              :placeholder="$t('labels.new-password')"
            />

            <label for="signup-password">{{ $t('labels.new-password') }}</label>
            <span class="text-danger">{{
              errors.newPassword ? $t(errors.newPassword) : ''
            }}</span>
          </div>

          <div class="input-groups">
            <input
              type="password"
              class="form-control"
              id="confirm-password"
              v-model="confirmNewPassword"
              :placeholder="$t('labels.confirm-password')"
            />

            <label for="signup-confirm_password">{{
              $t('labels.confirm-password')
            }}</label>

            <span class="text-danger">{{
              errors.confirmNewPassword ? $t(errors.confirmNewPassword) : ''
            }}</span>
          </div>

          <div class="d-block mt-3 button">
            <viz-button
              type="submit"
              class="btn btn-primary text-white px-4"
              :loading="state.isLoading"
            >
              {{ $t('labels.save') }}
            </viz-button>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { logo } from '@/utils/icons';
import { useStore } from 'vuex';
import { useField, useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import validationSchema from '@/validator/reset-password';

interface ErrorList {
  error?: string;
  email?: string;
  password?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'Reset',
  components: {
    VizButton
  },
  setup() {
    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const icons = markRaw({
      logo: logo
    });
    const { errors, handleSubmit, meta } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('resetPassword', { ...values })
        .then(() => {
          router.push({ name: 'ResetComplete' });
          state.isLoading = false;
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;

          err.errors.forEach(item => {
            if (item.error) {
              state.errorMessage = item.error;
              setTimeout(() => {
                state.errorMessage = '';
              }, 5000);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          });
        });
    });

    const { value: oldPassword } = useField('oldPassword');
    const { value: newPassword } = useField('newPassword');
    const { value: confirmNewPassword } = useField('confirmNewPassword');

    return {
      oldPassword,
      newPassword,
      confirmNewPassword,
      onSubmit,
      errors,
      state,
      meta,
      icons
    };
  }
});
</script>

<style scoped></style>
