
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VizSelect',
  props: {
    options: Array,
    modelValue: null
  },

  emits: ['update:modelValue'],

  methods: {
    changeValue(value: string | null) {
      this.$emit('update:modelValue', value); // previously was `this.$emit('input', title)`
    },
    onChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const value = target?.value;
      this.changeValue(value);
    }
  }
});
