<template>
  <div>
    <div>
      <div class="mb-5">
        <div class="sub_title">
          <h4>{{ $t('pages.ca.measuredata.section1.h1') }}</h4>
        </div>
        <div class="table-responsive">
          <viz-table>
            <template #headings>
              <th
                scope="col"
                v-for="item in costIndicators.headers"
                :key="item"
              >
                {{ item }}
              </th>
            </template>

            <template #data>
              <tr v-for="item in costIndicators.data" :key="item.id">
                <td
                  v-for="(heading, index) in costIndicators.headers"
                  :key="item[index]"
                >
                  {{ item[index] }}
                </td>
              </tr>
            </template>
          </viz-table>
        </div>
        <!-- <div class="paragraph">
          <p class="text-muted">
            {{ $t('pages.ca.measuredata.section1.note') }}
          </p>
        </div> -->
      </div>
    </div>
    <div>
      <div class="mb-5 data-type">
        <form
          class="data-type__form row py-0"
          @submit.prevent="onSubmit"
          novalidate
        >
          <p id="error-container" class="text-danger pb-4">
            {{ state.errorMessage }}
          </p>

          <div class="col-md-8">
            <div class="sub_title">
              <h4>{{ $t('pages.ca.measuredata.section2.cmninput.title') }}</h4>
            </div>
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                required
                id="model-name"
                :placeholder="
                  $t('pages.ca.measuredata.section2.cmninput.placeholder')
                "
                v-model="modelName"
              />
              <label for="model-name">{{
                $t('pages.ca.measuredata.section2.cmninput.label')
              }}</label>
              <span class="text-danger">{{
                errors.modelName ? $t(errors.modelName) : ''
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="sub_title">
                <h4>{{ $t('pages.ca.measuredata.section2.target.title') }}</h4>
              </div>
              <viz-select
                :options="seasonEffectOptions"
                v-model="target"
                aria-label="target select "
              >
                {{ $t('pages.ca.measuredata.section2.target.label') }}
              </viz-select>
              <span class="text-danger">{{
                errors.target ? $t(errors.target) : ''
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <viz-select
                :options="dateOptions"
                v-model="date"
                aria-label="target select "
              >
                {{ $t('pages.ca.measuredata.section2.date.label') }}
              </viz-select>
              <span class="text-danger">{{
                errors.date ? $t(errors.date) : ''
              }}</span>
            </div>
          </div>
          <div class="col-md-8">
            <div class="sub_title">
              <h4>
                {{ $t('pages.ca.measuredata.section2.modalselection.title') }}
              </h4>
            </div>
            <div class="d-flex custom-radio">
              <div class="form-check me-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioSelect"
                  value="linear-regression"
                  id="linear-regression"
                  v-model="method"
                  checked
                />
                <label class="form-check-label" for="linear-regression">
                  {{ $t('pages.ca.measuredata.section2.modalselection.lrm') }}
                </label>
                <viz-tooltip>
                  {{
                    $t(
                      'pages.ca.measuredata.section2.modalselection.lrm-tooltip'
                    )
                  }}
                </viz-tooltip>
              </div>

              <!-- <div class="form-check me-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioSelect"
                  value="state-space"
                  id="state-space"
                  v-model="method"
                  :checked="method === 'state-space'"
                />
                <label class="form-check-label" for="state-space">
                  {{ $t('pages.ca.measuredata.section2.modalselection.state') }}
                </label>
              </div> -->
            </div>
            <span class="text-danger">{{
              errors.method ? $t(errors.method) : ''
            }}</span>
          </div>
          <div class="col-md-6">
            <div class="sub_title">
              <h4>
                {{ $t('pages.ca.measuredata.section2.optselection.title') }}
              </h4>
            </div>
            <!-- consider lag input -->
            <viz-switchbox
              fieldId="consider-lag"
              v-model="considerLag"
              @change="handleConsiderLagChange"
              :tooltip="
                $t('pages.ca.measuredata.section2.optselection.lag-tooltip')
              "
            >
              {{ $t('pages.ca.measuredata.section2.optselection.lag') }}
            </viz-switchbox>

            <span class="text-danger">{{
              errors.considerLag ? $t(errors.considerLag) : ''
            }}</span>

            <!-- lag list -->
            <div
              :class="
                `flex-wrap mb-3 align-items-center custom-checkbox ${
                  considerLag ? 'd-flex' : 'd-none'
                }`
              "
            >
              <div
                v-for="lagValue in seasonEffectOptions"
                :key="lagValue.text"
                class="form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="lagValue.value"
                  @click="handleLagListChange"
                  :checked="
                    Array.isArray(laglist) && laglist.includes(lagValue.value)
                  "
                />
                <label class="form-check-label">
                  {{ lagValue.text }}
                </label>
              </div>
            </div>
            <span class="text-danger">{{
              errors.laglist ? $t(errors.laglist) : ''
            }}</span>

            <!-- Season Effect -->
            <div>
              <viz-switchbox
                fieldId="season-effect"
                @change="() => handleSeasonEffectChange()"
                v-model="seasonEffects"
                :tooltip="
                  $t(
                    'pages.ca.measuredata.section2.optselection.effects-tooltip'
                  )
                "
              >
                {{ $t('pages.ca.measuredata.section2.optselection.effects') }}
              </viz-switchbox>
            </div>

            <span class="text-danger">{{
              errors.seasonEffects ? $t(errors.seasonEffects) : ''
            }}</span>

            <!-- Season effect input -->
            <div :className="`${seasonEffects ? 'd-block' : 'd-none'}`">
              <viz-select :options="seasonEffectOptions" v-model="mediaName">
                {{ $t('pages.ca.measuredata.section2.effectsinput.label') }}
              </viz-select>

              <span class="text-danger">{{
                errors.mediaName ? $t(errors.mediaName) : ''
              }}</span>
            </div>
          </div>

          <div class="d-block mt-3 button">
            <viz-button
              type="button"
              @click="handleGoBack"
              class=" btn btn-default text-muted mr-3"
            >
              {{ $t('common.buttonlabels.goback') }}
            </viz-button>
            <viz-button
              :loading="state.isLoading"
              :disabled="!meta.valid || state.isLoading"
              type="submit"
              class="btn btn-primary text-white fw-bold"
            >
              {{ $t('common.buttonlabels.nextstep') }}
            </viz-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import VizTable from '@/components/ui/VizTable.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';
import VizSwitchbox from '@/components/ui/VizSwitchbox.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import validationSchema from '@/validator/contribution-analysis/kpi-input';
import { useForm, useField } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
const { mapMutations, mapGetters } = createNamespacedHelpers('ca');

interface ErrorList {
  error?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'MeasureData',
  components: {
    VizTable,
    VizButton,
    VizSelect,
    VizSwitchbox,
    VizTooltip
  },
  beforeMount() {
    const { taskId } = this.$route.params;
    if (!taskId) {
      this.$router.replace({ path: '/analysis' });
    }
    this.setExitAllowed(false);
  },
  unmounted() {
    this.setExitAllowed(true);
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      errorMessage: '',
      isLoading: false,
      targetOptions: [{ text: '', value: '' }],
      lagListOptions: [{ text: '', value: '' }]
    });

    const initialFormValues = {
      modelName: '',
      target: '',
      date: '',
      method: 'linear-regression',
      considerLag: false,
      laglist: [],
      seasonEffects: false,
      mediaName: ''
    };

    const {
      errors,
      setFieldValue,
      handleSubmit,
      meta,
      values,
      setValues,
      setFieldError
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });

    const { value: modelName } = useField('modelName');
    const { value: target } = useField('target');
    const { value: date } = useField('date');
    const { value: method } = useField('method');
    const { value: considerLag } = useField('considerLag');
    const { value: laglist } = useField('laglist');
    const { value: seasonEffects } = useField('seasonEffects');
    const { value: mediaName } = useField('mediaName');

    const handleLagListChange = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      let tempLaglist = values.laglist ? values.laglist : [];

      if (target.value) {
        if (tempLaglist.includes(target.value)) {
          tempLaglist = tempLaglist.filter(item => item !== target.value);
        } else {
          tempLaglist.push(target.value);
        }
        setFieldValue('laglist', tempLaglist);
      }
    };
    const handleSeasonEffectChange = () => {
      if (!seasonEffects.value) {
        setFieldValue('mediaName', '');
      }
    };
    const handleConsiderLagChange = () => {
      if (!considerLag.value) {
        setFieldValue('laglist', []);
      }
    };
    const scrollToErrorView = () => {
      document.getElementById('error-container')?.scrollIntoView();
    };
    const onSubmit = handleSubmit(values => {
      if (
        values.considerLag &&
        Array.isArray(values.laglist) &&
        values.laglist.length < 1
      ) {
        setFieldError('laglist', 'errors.required-field');
        return;
      }

      state.isLoading = true;
      store
        .dispatch('ca/uploadKPIData', {
          ...values,
          taskId: Number(route.params.taskId)
        })
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';
          router.push({
            name: 'ContributionAnalysisObserve',
            params: { taskId: route.params.taskId }
          });
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;
          type FieldOptions = {
            [key: string]: string;
          };
          const fieldMaps: FieldOptions = {
            season_effect: 'seasonEffects',
            model_name: 'modelName',
            method: 'method',
            lag: 'lag',
            lag_list: 'laglist'
          };
          if (Array.isArray(err?.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.errors.forEach((error: any) => {
              Object.keys(fieldMaps).forEach((fieldname: string) => {
                if (error[fieldname]) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const errorField: any = fieldMaps[fieldname];
                  setFieldError(errorField, error[fieldname]);
                }
              });
            });

            scrollToErrorView();
          }
          if (err.errors[0]?.error) {
            state.errorMessage = err.errors[0].error;
          }
        });
    });

    return {
      modelName,
      target,
      date,
      method,
      considerLag,
      laglist,
      seasonEffects,
      mediaName,
      errors,
      meta,
      state,
      handleLagListChange,
      handleSeasonEffectChange,
      handleConsiderLagChange,
      onSubmit,
      setValues
    };
  },
  computed: {
    ...mapGetters(['costIndicators']),
    seasonEffectOptions: function(): Array<any> {
      const costIndicators = this.costIndicators as { headers: Array<string> };
      if (costIndicators) {
        return costIndicators?.headers.map((item: string) => {
          return { text: item, value: item };
        });
      }
      return [];
    },
    dateOptions: function(): Array<any> {
      return this.seasonEffectOptions;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    handleGoBack() {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.$router.push({
          name: 'ContributionEnterData'
        });
      }
    }
  }
});
</script>
