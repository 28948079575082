<template>
  <div v-bind="$attrs" class="data-type__req-select">
    <div class="icon">
      <figure>
        <img :src="icon" :alt="`${label} icon`" />
      </figure>
    </div>
    <div class="text">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["icon", "label"],
  setup() {
    return {};
  }
});
</script>

<style scoped></style>
