<template>
  <main class="my-4">
    <div class="sub_title">
      <h4>{{ $t('pages.ca.enterdata.section3.h1') }}</h4>
    </div>
    <p class="text-danger pb-4">{{ errorMessage }}</p>

    <div class="table-responsive">
      <viz-table>
        <template #headings>
          <th scope="col">{{ $t('pages.ca.enterdata.section3.th1') }}</th>
          <th scope="col">
            {{ $t('pages.ca.enterdata.section3.th2') }}
          </th>
          <th scope="col">
            {{ $t('pages.ca.enterdata.section3.th3') }}
          </th>
          <th scope="col">
            {{ $t('pages.ca.enterdata.section3.th4') }}
          </th>

          <th scope="col"></th>
        </template>
        <template v-if="taskList" #data>
          <tr v-for="(task, key) in taskList" :key="key">
            <td>{{ moment(task.execution_date) }}</td>
            <td>{{ $t(`labels.${task.type_of_analysis}`) }}</td>
            <td>{{ task.username }}</td>
            <td>{{ task.name }}</td>

            <td>
              <viz-button
                type="button"
                class="btn btn-outline-primary"
                @click="() => showModal(task)"
                data-bs-toggle="modal"
                data-bs-target="#resultModal"
              >
                {{ $t('common.buttonlabels.viewresult') }}
              </viz-button>
            </td>
          </tr>
        </template>
      </viz-table>
      <viz-modal :isModalVisible="true">
        <template #title>
          {{ $t('pages.ih.modal.title') }}
        </template>
        <template #body>
          <div class="modal-body">
            <analysis-report
              v-if="currentReport.type == 'Contribution Analysis'"
              :taskId="currentReport.taskId"
            />

            <extraction-report
              v-if="currentReport.type == 'Target Extraction'"
              :taskId="currentReport.taskId"
            />

            <pattern-a-report
              v-if="
                currentReport.type == 'Measure result simulation(Pattern A)'
              "
              :taskId="Number(currentReport.taskId)"
            />

            <pattern-b-report
              v-if="
                currentReport.type == 'Measure result simulation(Pattern B)'
              "
              :taskId="Number(currentReport.taskId)"
            />
          </div>
        </template>
      </viz-modal>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VizTable from '@/components/ui/VizTable.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizModal from '@/components/ui/VizModal.vue';

import AnalysisReport from '@/components/contribution-analysis/AnalysisResultReport.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
import PatternAReport from '@/components/measure-simulation/PatternAReport.vue';
import PatternBReport from '@/components/measure-simulation/PatternBReport.vue';

import { KPIOutput } from '@/store/modules/contribution-analysis';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { AnalysisHistory } from '@/store/modules/history';

export default defineComponent({
  name: 'ExecutionHistory',
  mounted() {
    this.getHistory();
  },
  data() {
    return {
      isModalVisible: false,
      taskList: [] as Array<KPIOutput>,
      errorMessage: '',
      currentReport: {
        type: '',
        taskId: 0
      }
    };
  },
  components: {
    VizTable,
    VizButton,
    VizModal,
    AnalysisReport,
    ExtractionReport,
    PatternAReport,
    PatternBReport
  },
  computed: {
    ...mapGetters(['userGroup'])
  },
  methods: {
    ...mapActions(['getAnalysisHistory']),
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss');
    },
    showModal(item: AnalysisHistory) {
      this.currentReport.type = item.type_of_analysis;
      this.currentReport.taskId = item.task_id;
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
      this.currentReport = {
        type: '',
        taskId: 0
      };
    },
    getHistory() {
      this.getAnalysisHistory({ page: 1, perPage: 2 })
        .then((histories: any) => {
          this.taskList = histories.data;
          this.errorMessage = '';
        })
        .catch(err => {
          this.errorMessage = err?.errors ? err.errors[0]?.error : '';
          this.taskList = [];
        });
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.getHistory();
      }
    }
  }
});
</script>

<style scoped></style>
