
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StepsTrack',
  props: {
    currentStep: Number,
    steps: Array,
    justifyStart: Boolean
  }
});
