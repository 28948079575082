
import { defineComponent, reactive, markRaw } from 'vue';
import VizTabs from '@/components/ui/VizTabs.vue';
import VizButton from '@/components/ui/VizButton.vue';

import Billing from './Invoice/index.vue';
import ContractAccount from './ContractManagement/index.vue';
import GroupAndMembers from './GroupAndMembers/index.vue';
import { useStore, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Settings',
  components: {
    VizButton,
    VizTabs,
    Billing,
    ContractAccount,
    GroupAndMembers
  },
  beforeMount() {
    const { tab } = this.$route.params;
    if (tab == 'billing') {
      this.state.tab = this.tabs[1];
    }
  },
  computed: {
    ...mapGetters(['groups'])
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const tabs = markRaw([
      {
        tabKey: 'contract',
        title: 'Import History',
        tsKey: 'pages.settings.contract.title'
      },
      {
        tabKey: 'billing',
        title: 'Billing',
        tsKey: 'pages.settings.billing.title'
      },
      {
        tabKey: 'groups',
        title: 'Group and Members',
        tsKey: 'pages.settings.groups.title'
      }
    ]);

    const state = reactive({
      tab: tabs[0],
      isLoading: false,
      groupName: '',
      errorMessage: ''
    });

    const openDeleteModal = () => {
      // state.groupName = store.state.auth.userGroup?.name;
      state.groupName = '';
    };

    const handleTabChange = (tab: any) => {
      state.tab = tab;
    };

    const handleCancel = () => {
      state.errorMessage = '';
    };

    const handleGroupDelete = () => {
      const group = store.state.auth.userGroup;
      if (state.groupName !== group?.name) {
        state.errorMessage = 'errors.group-not-match';
        return;
      }

      state.isLoading = true;

      store
        .dispatch('deleteGroup', group?.id)
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';

          document.getElementById('delete_group_modal')?.click();

          store
            .dispatch('fetchUserGroups')
            .then(groups => {
              store
                .dispatch(
                  'changeUserGroup',
                  groups.length > 0 ? groups[0] : null
                )
                .then(() => {
                  return router.push({ name: 'History' });
                });
            })
            .catch(() => {
              state.errorMessage = 'errors.automatic-reload-failed';
            });
        })
        .catch(() => {
          state.errorMessage = 'errors.group-deletion-failed';
          state.isLoading = false;
        });
    };
    return {
      tabs,
      handleTabChange,
      handleGroupDelete,
      handleCancel,
      state,
      openDeleteModal
    };
  }
});
