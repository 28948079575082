
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { useField, useForm } from 'vee-validate';
import validationSchema from '@/validator/create-group';
import { logo } from '@/utils/icons';
import managerAPI from '@/api/manager';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'InviteMembers') {
      if (window.confirm('戻ってもよろしいですか？')) {
        next();
      }
    } else {
      next();
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },
  beforeMount() {
    const { token, email } = this.$route.params;
    if (!token || !email) {
      this.$router.push('/login');
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = '\o/';
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  },
  setup() {
    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const route = useRoute();
    const router = useRouter();

    const { errors, handleSubmit, meta, setFieldError } = useForm({
      validationSchema
    });
    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      const token = route.params?.token as string;
      const email = route.params?.email as string;

      managerAPI
        .createGroup({ name: values.name as string, email, token })
        .then(res => {
          const { id } = res?.data;

          state.errorMessage = '';
          router.replace({
            name: 'InviteMembers',
            params: { groupId: String(id) }
          });
          state.isLoading = false;
        })
        .catch(err => {
          const errors = err.errors;
          if (errors) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errors.forEach((item: any) => {
              if (item.error) {
                state.errorMessage = item.error;
              }
              if (item.token) {
                state.errorMessage = state.errorMessage.concat(
                  ` ${item.token}`
                );
              }
              if (item.name) {
                setFieldError('name', item.name);
              }
            });
          }
          state.isLoading = false;
        });
    });

    const { value: name } = useField('name');
    return {
      name,
      onSubmit,
      meta,
      errors,
      state,
      logo
    };
  }
});
