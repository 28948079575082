<!-- layout wrapper-->
<template>
  <div style="margin:0;padding:0;">
    <div class="d-flex w-100">
      <app-sidebar :isSidebarVisible="isSidebarVisible"></app-sidebar>

      <section id="main" v-bind:class="`${isSidebarVisible ? '' : 'active'}`">
        <div class="position-relative">
          <app-header @toggleSidebar="this.sidebarToggler"></app-header>
          <router-view></router-view>
        </div>
        <viz-toast
          v-if="toast.message"
          :message="$t(toast.message, toast.data)"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppSidebarVue from '@/components/layout/AppSidebar.vue';
import AppHeaderVue from '@/components/layout/AppHeader.vue';
import VizToast from '@/components/ui/VizToast.vue';

import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppSidebar: AppSidebarVue,
    VizToast,

    AppHeader: AppHeaderVue
  },
  computed: {
    ...mapGetters(['toast'])
  },
  data() {
    return {
      isSidebarVisible: true,
      langs: ['en', 'ja']
    };
  },
  beforeMount() {
    this.loadUserAccountDetails();
  },
  methods: {
    ...mapActions([
      'fetchUserGroups',
      'fetchContractFunctions',
      'loadUserAccountDetails',
      'changeUserGroup'
    ]),

    sidebarToggler() {
      this.isSidebarVisible = !this.isSidebarVisible;
    }
  }
});
</script>

<style lang="scss" scoped></style>
