<!-- eslint-disable -->
<template>
  <div class="position-relative">
    <div class="btn-groups position-absolute top-60 end-0">
      <button
        class=" btn btn-outline-primary rounded-pill  py-2 px-3 ms-3"
        data-bs-toggle="modal"
        data-bs-target="#delete_multiple_import_history_modal"
        :disabled="payload.features.length < 1"
      >
        選択したデータを削除
      </button>
    </div>
    <div class="table-responsive">
      <p class="text-danger">{{ errorMessage }}</p>
      <viz-table>
        <template #headings>
          <th scope="col">
            <input
              class="form-check-input select-all"
              type="checkbox"
              @click="event => handleSelect(event)"
              id="selectAllCheckboxId"
            />
          </th>
          <th scope="col" v-for="heading in tableHeadings" :key="heading.key">
            {{ $t(heading.i18key) }}
          </th>
          <th scope="col"></th>
        </template>
        <template #data>
          <tr v-if="!importHistory.data || importHistory.data.length < 1">
            <td colspan="3" class="text-center text-muted">
              利用可能なエントリはありません
            </td>
          </tr>
          <tr v-for="item in importHistory.data" :key="item.date">
            <td>
              <input
                class="form-check-input checkbox"
                type="checkbox"
                :name="item.feature"
                :value="item.task_id"
                @click="
                  event => setSelectedItems(item.task_id, item.feature, event)
                "
              />
            </td>
            <td>
              {{ moment(item.file_upload_date) }}
            </td>
            <td>
              {{ item.filename }}
            </td>
            <td>
              {{ item.file_upload_status }}
            </td>
            <td>
              {{ item.username }}
            </td>
            <td>
              <button
                class="btn btn-default p-0 ms-1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <figure class="three_dots">
                  <img :src="icons.learnmore" alt="" />
                </figure>
              </button>
              <ul class="dropdown-menu p-0">
                <li>
                  <viz-button
                    class="dropdown-item py-3"
                    type="button"
                    data-bs-toggle="modal"
                    @click="() => setSelectedEntry(item.task_id, item.feature)"
                    data-bs-target="#delete_single_import_history_modal"
                  >
                    {{ $t('labels.delete') }}
                  </viz-button>
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </viz-table>

      <viz-pagination
        @onPageChange="handlePageChange"
        :totalEntries="importHistory.totalEntries"
        :perPageCount="perPageCount"
        :currentPage="currentPage"
      />
      <!-- delete history modal -->
      <div
        class="modal fade"
        id="delete_single_import_history_modal"
        tabindex="-1"
        aria-labelledby="deleteImportHistoryModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title">
                データの削除
              </h5>
              <viz-button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></viz-button>
            </div>
            <div class="modal-body">
              <p>
                {{ $t('pages.ih.tab2.delete-modal.subtitle') }}
              </p>
              <p class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="modal-footer justify-content-end border-top-0">
              <button
                type="button"
                ref="dismissModalDeleteSingle"
                class="btn btn-default text-muted"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <viz-button
                type="button"
                class="btn btn-secondary text-white rounded-pill"
                @click="handleSingleDelete"
                :loading="isDeletionPending"
              >
                {{ $t('labels.delete') }}
              </viz-button>
            </div>
          </div>
        </div>
      </div>

      <!-- delete multiple history modal -->
      <div
        class="modal fade"
        id="delete_multiple_import_history_modal"
        tabindex="-1"
        aria-labelledby="deleteImportHistoryModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title">
                データの削除
              </h5>
              <viz-button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></viz-button>
            </div>
            <div class="modal-body">
              <p>
                削除したデータは復元できません。{{
                  payload.features.length
                }}件のデータを削除しますか?
              </p>
              <p class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="modal-footer justify-content-end border-top-0">
              <button
                type="button"
                ref="dismissModalDeleteMultiple"
                class="btn btn-default text-muted"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <viz-button
                type="button"
                class="btn btn-secondary text-white rounded-pill"
                @click="handleMultipleDelete"
                :loading="isDeletionPending"
              >
                {{ $t('labels.delete') }}
              </viz-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- display toast when user delete table data successfully -->
    <!-- <viz-toast message="データを削除しました" /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import VizTable from '@/components/ui/VizTable.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { mapGetters, mapActions } from 'vuex';
import VizPagination from '@/components/ui/VizPagination.vue';
import moment from 'moment';
import learnmoreIcon from '@/assets/icons/more.svg';

export default defineComponent({
  name: 'ImportHistory',
  components: {
    VizTable,
    VizPagination,
    VizButton
  },
  data() {
    const payload = {
      task_ids: [] as number[],
      features: [] as string[]
    };
    const tableHeadings = markRaw([
      {
        i18key: 'pages.ih.tab1.th1',
        key: 'file_upload_date',
        title: 'インポート日時'
      },
      { i18key: 'pages.ih.tab1.th2', key: 'filename', title: 'データ名' },
      {
        i18key: 'pages.ih.tab1.th6',
        key: 'file_upload_status',
        title: 'アップロードステータス'
      },
      { i18key: 'pages.ih.tab1.th5', key: 'username', title: 'ユーザー' }
    ]);

    const icons = markRaw({
      learnmore: learnmoreIcon
    });

    return {
      tableHeadings,
      payload,
      errorMessage: '',
      icons,
      perPageCount: 10,
      currentPage: 1,
      selectedEntry: {
        taskId: 0,
        feature: ''
      },
      isDeletionPending: false,
      isMultipleDelete: false
    };
  },

  computed: {
    ...mapGetters(['importHistory', 'userGroup'])
  },
  methods: {
    ...mapActions([
      'getImportHistory',
      'deleteImportHistory',
      'deleteMultipleImportHistory',
      'addToast',
      'getStorageUsage'
    ]),
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss');
    },
    closeSingleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteSingle as any;
      dismissRef?.click();
    },
    closeMultipleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteMultiple as any;
      dismissRef?.click();
    },
    handlePageChange(page: number, perPage: number) {
      this.getImportHistory({ page, perPage })
        .then(() => {
          this.perPageCount = perPage;
          this.currentPage = page;
          this.errorMessage = '';
        })
        .catch(err => {
          if (err?.errors && err.errors[0].error) {
            this.errorMessage = err.errors[0].error;
          }
        })
        .finally(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            // eslint-disable-next-line
            // @ts-ignore
            checkbox.checked = false;
          }

          this.payload.features = [];
          this.payload.task_ids = [];
          this.isMultipleDelete = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    setSelectedEntry(taskId: number, feature: string) {
      this.isMultipleDelete = false;
      this.selectedEntry = { taskId, feature };
    },
    handleSelect(e: any) {
      e.target.checked ? this.selectAll() : this.deSelectAll();
    },
    selectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        if (items[i].type == 'checkbox') {
          // eslint-disable-next-line
          // @ts-ignore
          items[i].checked = true;
          if (
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features[i] !== items[i].name &&
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids[i] !== items[i].value
          ) {
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features.push(items[i].name);
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids.push(Number(items[i].value));
          }
        }
      }
    },
    deSelectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        items[i].checked = false;
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.features, items[i].name);
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.task_ids, Number(items[i].value));
      }
    },
    setSelectedItems(taskId: any, feature: any, event: any) {
      const selectAllCheckboxId = document.getElementById(
        'selectAllCheckboxId'
      );
      if (selectAllCheckboxId) {
        // eslint-disable-next-line
        // @ts-ignore
        selectAllCheckboxId.checked = false;
      }
      this.isMultipleDelete = true;
      if (event.target.checked) {
        this.payload.features.push(feature);
        this.payload.task_ids.push(Number(taskId));
      } else {
        this.removeItem(this.payload.features, feature);
        this.removeItem(this.payload.task_ids, Number(taskId));
      }
    },
    removeItem(array: Array<any>, itemToRemove: any) {
      const index = array.indexOf(itemToRemove);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    handleMultipleDelete() {
      this.isDeletionPending = true;

      this.deleteMultipleImportHistory(this.payload)
        .then(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            if (checkboxes !== null) {
              // eslint-disable-next-line
              // @ts-ignore
              checkbox.checked = false;
            }
          }
          if (
            this.perPageCount >= this.payload.features.length &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeMultipleDeleteModal();
          this.addToast({ message: 'データを削除しました。' });
        })
        .catch(err => {
          const errs = err?.errors;

          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    handleSingleDelete() {
      const { taskId, feature } = this.selectedEntry;
      if (!taskId) {
        return;
      }

      this.isDeletionPending = true;

      this.deleteImportHistory({ taskId, feature })
        .then(() => {
          this.isDeletionPending = false;
          if (
            this.perPageCount >= this.importHistory.totalEntries - 1 &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeSingleDeleteModal();
          this.addToast({ message: 'messages.import-history-deleted' });
        })
        .catch(err => {
          this.isDeletionPending = false;
          const errs = err?.errors;

          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.handlePageChange(1, 10);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.three_dots {
  width: 20px;
  img {
    transition: all 0.3s ease;
  }
  &:hover {
    img {
      filter: brightness(0);
      transform: scale(1.1);
    }
  }
}
</style>
