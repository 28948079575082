<template>
  <div
    id="app"
    v-title="
      $route.meta.pageKey ? $t(`pages.${$route.meta.pageKey}.title`) : ''
    "
  >
    <!-- <locale-changer /> -->
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
// import LocaleChanger from '@/components/ui/LocaleChanger.vue';
import { mapGetters, mapActions } from 'vuex';
import { defineComponent } from 'vue';
import { handleTokenRefresh } from '@/utils/session-handler';

export default defineComponent({
  name: 'App',
  beforeMount() {
    if (this.isLoggedIn) {
      this.createSessionPoll();
    }
  },
  beforeUnmount() {
    if (this.isLoggedIn) {
      this.closeSessionPoll();
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'tokens'])
  },
  components: {
    // LocaleChanger,
  },
  data() {
    return {
      sessionRefreshPoll: null as any
    };
  },
  methods: {
    ...mapActions(['executeRefreshToken']),
    createSessionPoll() {
      this.sessionRefreshPoll = setInterval(() => {
        handleTokenRefresh();
      }, 180000);
    },
    closeSessionPoll() {
      clearInterval(this.sessionRefreshPoll);
    }
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.createSessionPoll();
      } else {
        this.closeSessionPoll();
      }
    }
  }
});
</script>
<style scoped>
#app {
  margin: 0;
  padding: 0;
}
</style>
