<template>
  <main>
    <div>
      <steps-track v-bind:steps="steps" :currentStep="2"></steps-track>
      <extraction-report :taskId="taskId" />
    </div>
    <div class="button d-block">
      <viz-button
        type="button"
        class="btn btn-default text-muted"
        @click="handleGoBack"
      >
        {{ $t('common.buttonlabels.goback') }}
      </viz-button>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
const { mapGetters, mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'TargetAnalysisReport',
  props: {
    isAnalysed: Boolean,
    steps: Array,
    currentStep: Number
  },
  emits: ['goBack', 'saveAnalysis'],
  computed: {
    ...mapGetters(['taskId'])
  },
  components: {
    StepsTrack,
    ExtractionReport
  },
  methods: {
    ...mapActions(['resetTargetState']),
    handleGoBack() {
      this.resetTargetState();
      this.$emit('goBack');
    }
  }
});
</script>

<style scoped></style>
