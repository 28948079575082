import './registerServiceWorker';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import titleDirective from '@/directives/title-directive';

import 'bootstrap';
import '@popperjs/core/dist/umd/popper.min.js';

import './assets/sass/style.scss';
import './assets/sass/responsive.scss';

const app = createApp(App);

app.directive('title', titleDirective);

app
  .use(store)
  .use(router)
  .use(i18n);

app.mount('#app');

app.config.errorHandler = (err: any, vm: any, info: string) => {
  console.log(info, err);
};
