
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import { mapActions, mapGetters } from 'vuex';
import GroupNameEditForm from './GroupNameEditForm.vue';
import AddMembersForm from './AddMembersForm.vue';
import DeleteMembersForm from './DeleteMembersForm.vue';
import learnmoreIcon from '@/assets/icons/more.svg';
import { UserGroup } from '@/store/modules/auth';

export default defineComponent({
  components: {
    VizButton,
    GroupNameEditForm,
    AddMembersForm,
    DeleteMembersForm,
    VizPagination
  },

  beforeMount() {
    this.state.errorMessage = '';
    this.loadGroupData(this.state.currentPage, this.state.perPageCount);
  },
  methods: {
    ...mapActions(['loadGroupAndMembers', 'increaseGroupQuota']),
    loadGroupData(page: number, perPage: number) {
      const groupId = this.userGroup?.id;
      this.loadGroupAndMembers({ page, perPage, groupId })
        .then(data => {
          const { id, groupName } = data;
          this.state.id = id;
          this.state.groupName = groupName;
        })
        .catch(err => {
          this.state.errorMessage = err.errors ? err.errors[0].error : '';
          this.state.id = '';
          this.state.groupName = '';
        });
    },
    handleQuotaExpansion() {
      this.increaseGroupQuota(this.userGroup?.id)
        .then(() => {
          this.openMemberInputModal();
        })
        .catch(() => {
          const addMemberDismissRef = this.$refs.addMemberDismissRef as any;
          addMemberDismissRef?.click();
        });
    },
    handlePageChange(page: number, perPage: number) {
      this.state.currentPage = page;
      this.state.perPageCount = perPage;
      this.loadGroupAndMembers({ page, perPage, groupId: this.userGroup?.id });
    }
  },
  computed: {
    ...mapGetters(['userGroup', 'groupDetails']),
    totalMembers(): number {
      return Array.isArray(this.groupDetails.members)
        ? this.groupDetails.members.length
        : 0;
    }
  },
  setup() {
    const icons = markRaw({
      learnmore: learnmoreIcon
    });
    const state = reactive({
      id: '',
      groupName: '',
      errorMessage: '',
      isMemberInputModalOpen: false,
      isMemberModalOpen: false,
      selectedMember: '',
      currentPage: 1,
      perPageCount: 10
    });

    const openMemberInputModal = () => {
      state.isMemberInputModalOpen = true;
    };

    const closeMemberInputModal = () => {
      state.isMemberInputModalOpen = false;
    };
    const selectMemberToDelete = (member: string) => {
      if (!state.id) return;
      state.selectedMember = member;
    };

    return {
      state,
      openMemberInputModal,
      closeMemberInputModal,

      selectMemberToDelete,
      icons
    };
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal) {
        this.loadGroupData(1, 10);
      }
    }
  }
});
