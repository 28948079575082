<template>
  <div class="d-flex-column justify-content-center align-items-center">
    <p class="h5 text-white">
      {{
        $route.params && $route.params.errorMessage
          ? $route.params.errorMessage
          : ''
      }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  beforeMount() {
    if (!this.$route.params?.errorMessage) {
      this.$router.push({ name: 'Login' });
    }
  },
  setup() {
    return {};
  }
});
</script>

<style scoped></style>
