<template>
  <div class="row">
    <div class="sub_title">
      <h4>
        {{ $t('pages.te.ar.section3.title') }}
        <button
          class="btn btn-outline-primary fw-normal ms-3"
          data-bs-target="#tree-structure-modal"
          data-bs-toggle="modal"
          data-bs-dismiss="modal"
          role="button"
          @click="getDecisionTreeImageOnClick"
        >
          決定木を出力
        </button>
      </h4>
    </div>

    <p class="text-danger">{{ errorMessage }}</p>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr v-for="(item, key) in data" :key="key" class="align-middle">
            <td>
              <span style="line-height: 40px"> {{ item.feature }}</span>
            </td>
            <td>
              <div class="d-flex justify-content-end align-items-center">
                <span class="text-primary fw-bold px-2" style="width: 100px">
                  {{ Number(item.importance).toFixed(3) }}
                </span>
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-animate"
                    role="progressbar"
                    :style="`width: ${getBarPercentage(item.importance)}%`"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import targetAPI from '@/api/target-extraction';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'KpiEffectResult',
  props: ['taskId'],
  beforeMount() {
    this.getImportance(this.taskId)
      .then(data => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  data() {
    return { data: [], errorMessage: '' };
  },

  methods: {
    ...mapActions(['getImportance', 'getDecisionTreeImageURL']),
    getBarPercentage(val: number) {
      return (Number(val) / 1) * 100;
    },

    getDecisionTreeImageOnClick() {
      this.getDecisionTreeImageURL(this.taskId);
    }
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.getImportance(this.taskId)
          .then(data => {
            this.data = data;
          })
          .catch(() => (this.data = []));
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.progress {
  width: 100%;

  height: 10px !important;
  min-width: 400px;
  background-color: #fff;
  border-radius: 0;
  &-bar {
    border-radius: 0 2px 2px 0;
  }
}
table td {
  padding: 0 !important;
}
</style>
