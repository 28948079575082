<template>
  <div :class="`chips input-field ${hasData ? 'position-relative' : ''}`">
    <label for=""> メールアドレス</label>
    <div v-for="item in state.data" :key="item">
      <div class="chip">
        <img :src="icons.checkAlt" class="check" alt="Contact email" />
        {{ item }}
        <img
          :src="icons.closeLine"
          class="close"
          alt="Close"
          @click.prevent="() => handleDelete(item)"
        />
      </div>
    </div>

    <input
      :type="type"
      class="input"
      @keypress="handleKeyPress"
      v-model="state.currentInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import { closeLineIcon, checkAltIcon } from '@/utils/icons';
export const errors = {
  VALUE_NOT_PERMITTED: 'not-permitted',
  DUPLICATE_NOT_ALLOWED: 'duplicate-input',
  MAX_LIMIT_REACHED: 'max-limit'
};
export default defineComponent({
  props: ['value', 'type', 'excludables', 'maxItems'],
  emits: ['change', 'onError'],
  beforeMount() {
    this.state.data = this.value;
  },
  setup() {
    const state = reactive({
      data: [] as Array<string>,
      currentInput: ''
    });
    const icons = markRaw({
      closeLine: closeLineIcon,
      checkAlt: checkAltIcon
    });
    return { state, icons };
  },

  computed: {
    hasData: function(): boolean {
      return Array.isArray(this.state.data) && this.state.data.length >= 1;
    }
  },
  methods: {
    validateEmail(email: string) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    hasExistingItem(item: string): boolean {
      return Array.isArray(this.state.data) && this.state.data.includes(item);
    },
    hasItemInExcludables(item: string) {
      return Array.isArray(this.excludables) && this.excludables.includes(item);
    },
    handleKeyPress(e: KeyboardEvent) {
      if (e.keyCode == 32 || e.keyCode == 13) {
        this.handleError(null);

        const newEmail = this.state.currentInput.trim();
        if (this.type == 'email' && !this.validateEmail(newEmail)) {
          return;
        }
        if (
          this.hasExistingItem(newEmail) ||
          this.hasItemInExcludables(newEmail)
        ) {
          this.state.currentInput = '';
          this.handleError(errors.DUPLICATE_NOT_ALLOWED);
          return;
        }
        let data = this.state.data;
        if (Array.isArray(this.state.data)) {
          data.push(newEmail);
        } else {
          data = [newEmail];
        }
        this.state.currentInput = '';
        this.handleUpdate(data);
      }
    },
    handleError(error: any) {
      this.$emit('onError', error);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdate(data: any) {
      this.state.data = data;
      this.$emit('change', data);
    },
    handleDelete(item: string) {
      if (Array.isArray(this.state.data)) {
        const newdata = this.state.data.filter(val => val !== item);
        this.handleUpdate(newdata);
      }
    }
  },
  watch: {
    value(newVal) {
      this.state.data = newVal;
    }
  }
});
</script>

<style scoped>
.input {
  min-height: 100px;
}
</style>
