
import { defineComponent } from 'vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';

export default defineComponent({
  name: 'VizSwitchbox',
  emits: ['update:modelValue'],
  props: ['fieldId', 'modelValue', 'tooltip'],
  components: {
    VizTooltip
  },
  setup() {
    return {};
  },
  methods: {
    changeValue(value: any) {
      this.$emit('update:modelValue', value); // previously was `this.$emit('input', title)`
    },
    onChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const value = target?.checked;
      this.changeValue(value);
    }
  }
});
