import { object, string } from 'yup';

export const contractSchema = object({
  company: string().required(() => 'errors.required-field'),
  friganaCompany: string().nullable(),
  org: string().nullable(),
  person: string().required(() => 'errors.required-field'),
  friganaPerson: string().required(() => 'errors.required-field'),
  phone: string()
    .required(() => 'errors.required-field')
    .matches(/^[0-9|-]{1,15}$/, () => 'errors.invalid-phone'),
  address: string().required(() => 'errors.required-field')
});
export default contractSchema;
