
import { defineComponent, reactive } from 'vue';

import { useStore } from 'vuex';

import validationSchema from '@/validator/measure-simulation/pattern-a-schema';

import { useForm, useField } from 'vee-validate';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton,
    VizSelect
  },
  props: {
    modelOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const initialFormValues = {
      modelName: '',
      targetValues: []
    };

    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setFieldValue
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });

    const { value: modelName } = useField<string>('modelName');
    const { value: targetValues } = useField<Array<string>>('targetValues');

    const state = reactive({
      errorMessage: '',
      isLoading: false,
      targetMeasureOptions: [] as Array<{ text: string; value: string }>
    });

    const handleTargetMeasureChange = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      let tempLaglist = targetValues.value ? targetValues.value : [];

      if (target.value) {
        if (tempLaglist.includes(target.value)) {
          tempLaglist = tempLaglist.filter(item => item !== target.value);
        } else {
          tempLaglist.push(target.value);
        }
        setFieldValue('targetValues', tempLaglist);
      }
    };

    const handleModelNameChange = () => {
      if (modelName.value) {
        store
          .dispatch('ms/getMeasureOptions', modelName.value)
          .then((measureOptions: Array<string>) => {
            if (Array.isArray(measureOptions)) {
              const tempVals = [] as Array<string>;
              state.targetMeasureOptions = measureOptions.map(item => {
                tempVals.push(item);
                return {
                  text: item,
                  value: item
                };
              });
              setFieldValue('targetValues', tempVals);
            } else {
              state.targetMeasureOptions = [];
            }
          })
          .catch(() => {
            state.targetMeasureOptions = [];
          });
      }
    };

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('ms/runSimulateModel', values)
        .then((taskId: number) => {
          state.isLoading = false;

          router.push({
            path: '/simulation/observe',
            name: 'SimulationObserve',
            params: {
              pattern: 'patternA',
              taskId,
              modelName: String(values.modelName)
            }
          });
        })
        .catch(err => {
          state.isLoading = false;

          const errors = err?.errors;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((error: any) => {
            if (error?.error) {
              state.errorMessage = error.error;
            }

            if (error?.target_values) {
              setFieldError('targetValues', error.target_values);
            }
          });
        });
    });

    return {
      onSubmit,
      handleModelNameChange,
      handleTargetMeasureChange,
      state,
      modelName,
      targetValues,
      errors,
      meta
    };
  }
});
