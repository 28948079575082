
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { useForm, useField } from 'vee-validate';
import { mapActions, useStore } from 'vuex';
import { object, string } from 'yup';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton
  },
  beforeMount() {
    this.state.data = {};

    this.loadUserAccountDetails()
      .then(res => {
        this.state.errorMessage = '';
        const { email: email } = res?.data;
        this.state.data = { email };
        this.setValues({ email });
      })
      .catch(err => {
        this.state.data = {};
        this.state.errorMessage =
          err?.errors && err.errors[0].error ? err.errors[0].error : '';
      });
  },
  methods: {
    ...mapActions(['loadUserAccountDetails'])
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      isEditable: false,
      data: {},
      errorMessage: '',
      isLoading: false
    });
    const { errors, handleSubmit, setValues, setFieldError } = useForm({
      initialValues: { email: '' },
      validationSchema: object({
        email: string()
          .email(() => 'errors.invalid-email')
          .required(() => 'errors.required-field')
      })
    });
    const handleEdit = () => {
      state.isEditable = true;
    };
    const handleDiscard = () => {
      state.isEditable = false;
      setValues({ ...state.data });
    };
    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('updateAccountDetails', { ...values })
        .then(() => {
          state.isLoading = false;
          state.isEditable = false;
          store.dispatch('addToast', { message: 'messages.edits-saved' });
          store.commit('logoutSuccess');
          router.push({ name: 'Login' });
        })
        .catch(err => {
          state.isLoading = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errs.forEach((item: any) => {
              if (item.error) setFieldError('email', item.error);
              if (item.email_address)
                setFieldError('email', item.email_address);
            });
          }
        });
    });
    const { value: email } = useField('email');
    return {
      errors,
      state,
      email,
      onSubmit,
      handleEdit,
      handleDiscard,
      setValues
    };
  }
});
