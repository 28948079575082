import store from '@/store';
import moment from 'moment';

export const handleTokenRefresh = async () => {
  const currentTimeStamp = moment.now();

  const expiryTimeStamp = moment.unix(store.getters.tokens.expiresIn);
  const timeDiff = moment.duration(expiryTimeStamp.diff(currentTimeStamp));
  const diffHours = timeDiff.hours();
  const diffMinutes = timeDiff.minutes();

  if (diffHours <= 0 && diffMinutes < 6) {
    return store.dispatch('executeRefreshToken');
  }
};
