<template>
  <div class="col">
    <!--  -->
    <div class="sub_title">
      <h4>
        {{ $t('pages.te.ar.section1.title') }}
      </h4>
    </div>
    <p class="text-danger">{{ errorMessage }}</p>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('labels.user') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in data" :key="key" class="align-middle">
            <td>
              <span>{{ item.user_id }}</span>
            </td>
            <td>
              <div class="d-flex justify-content-center align-items-center">
                <span class="text-primary fw-bold px-1 text-right">
                  {{
                    `${Number(item.prediction).toFixed(3)} (${Number(
                      item.score
                    )})`
                  }}
                </span>
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-animate"
                    role="progressbar"
                    :style="`width: ${getBarPercentage(item.score)}%`"
                    :aria-valuenow="getBarPercentage(item.score)"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('te');
interface UserScore {
  score: number;
  user_id: string;
  prediction: number;
}
export default defineComponent({
  name: 'KpiEffectResult',
  props: ['taskId'],

  beforeMount() {
    this.getUserScore(this.taskId)
      .then(data => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },

  data() {
    return { data: [] as Array<UserScore>, errorMessage: '' };
  },
  computed: {
    maxBarValue: function(): number {
      let maxValue = 1;

      this.data.forEach((item: UserScore) => {
        const yaxisValue = Math.abs(Number(item.score));
        if (yaxisValue > maxValue) {
          maxValue = yaxisValue;
        }
      });
      return maxValue;
    }
  },
  methods: {
    ...mapActions(['getUserScore']),
    getBarPercentage(val: number) {
      return (Number(val) / 10) * 100;
    }
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.getUserScore(newtaskId)
          .then(data => {
            this.data = data;
          })
          .catch(() => (this.data = []));
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.progress {
  height: 10px !important;
  min-width: 200px;
  background-color: #fff;
  border-radius: 0;
  &-bar {
    border-radius: 0 2px 2px 0;
  }
}
table td {
  padding: 8px !important;
}
.text-primary.fw-bold.px-1 {
  min-width: 100px;
  white-space: nowrap;
}
</style>
