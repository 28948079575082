<template>
  <div class="target-sales mt-4">
    <div class="row">
      <div class="col-6">
        <div class="sub_title">
          <h4>
            {{ $t('pages.ms.report.patterna.title') }}
          </h4>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end">
          <viz-button
            type="button"
            class="btn btn-outline-primary has-dropdown"
            @click="handleExport"
            :loading="state.isLoading"
          >
            {{ $t('common.buttonlabels.export') }}
            <span class="d-inline-block ms-3 me-2">
              <figure>
                <img :src="icons.dropdownIcon" alt="" />
              </figure>
            </span>
          </viz-button>
        </div>
      </div>
    </div>
    <div class="estimated-sales">
      <div class="row">
        <div class="sub_title">
          <h4>
            {{ $t('pages.ms.report.patterna.pred-result') }}
          </h4>
        </div>
        <div class="col-md-3 col-6">
          <div class="border">
            <div class="fw-bold">
              {{
                stringHelpers.addCommas(
                  String(Number(state.totalSales).toFixed(0))
                )
              }}
            </div>
          </div>
          <p class="text-danger">{{ state.totalSalesError }}</p>
        </div>
      </div>
    </div>

    <div class="measure-volume">
      <div class="sub_title">
        <h4>
          {{ $t('pages.ms.report.patterna.adjust-field.title') }}
        </h4>
        <div class="text-muted">
          {{ $t('pages.ms.report.patterna.adjust-field.desc') }}
        </div>
      </div>
      <!-- <div class="text-primary mb-3">
        {{ $t('pages.ms.report.patterna.adjust-field.info') }}
      </div> -->
      <div class="row">
        <div
          v-for="label in targetKeys"
          :key="label"
          class="col-xl-3 col-lg-4 col-md-6"
        >
          <div class="border px-3">
            <!-- <div class="fw-bold">{{ state.targetValues[label] }}</div> -->
            <div class="text-field">
              <input
                type="text"
                class="text-center w-100 m-0"
                :value="stringHelpers.addCommas(state.targetValues[label])"
                placeholder="00"
                readonly
              />
            </div>
            <div class="text-muted">{{ label }}</div>
          </div>
        </div>
        <p class="text-danger">{{ state.targetValuesError }}</p>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-lg-6">
        <volume-prediction :taskId="Number(taskId)" pattern="patternA" />
      </div> -->
      <div class="col">
        <kpi-forecast :taskId="Number(taskId)" pattern="patternA" />
      </div>
    </div>
    <sales-bar-graph :taskId="Number(taskId)" />
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
// import VolumePrediction from '@/components/measure-simulation/VolumePrediction.vue';
import KpiForecast from '@/components/measure-simulation/KpiForecast.vue';
import { dropdownIcon } from '@/utils/icons';
import simulationAPI from '@/api/measure-simulation';
import SalesBarGraph from '@/components/measure-simulation/SalesBarGraph.vue';
import { createNamespacedHelpers } from 'vuex';
import { stringHelpers } from '@/utils/helpers';

const { mapActions } = createNamespacedHelpers('ms');

interface NumberKeyValInterface {
  [key: string]: string | number;
}

export default defineComponent({
  name: 'TargetSales',
  props: ['taskId'],

  beforeMount() {
    this.getMediaVolumeReport(Number(this.taskId));
    this.getTotalSales(Number(this.taskId));
  },
  computed: {
    targetKeys: function(): Array<string> {
      if (!this.state.targetValues) return [];
      return Object.keys(this.state.targetValues);
    }
  },
  methods: {
    ...mapActions(['exportSimulationReport']),
    handleExport: function() {
      this.state.isLoading = true;

      this.exportSimulationReport(this.taskId)
        .then(() => {
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    },
    getTotalSales(taskId: number) {
      simulationAPI
        .getSimulateTotalSalesReport(taskId)
        .then(res => {
          const results = res?.data?.results;
          this.state.totalSales = results ? Number(results[0]?.total_sales) : 0;
          this.state.targetSalesError = '';
        })
        .catch(err => {
          this.state.totalSales = 0;
          this.state.targetSalesError = err?.errors ? err.errors[0]?.error : '';
        });
    },
    getMediaVolumeReport(taskId: number) {
      simulationAPI
        .getSimulateMediaVolumeReport(taskId)
        .then(res => {
          const results = res.data?.results;
          let tempTarget = {};

          if (Array.isArray(results)) {
            results.forEach(item => {
              tempTarget = {
                ...tempTarget,
                [item.val]: isNaN(Number(item.budget))
                  ? item.budget
                  : Number(item.budget).toFixed(0)
              };
            });
          }

          this.state.targetValues = tempTarget;
          this.state.targetValuesError = '';
        })
        .catch(err => {
          this.state.targetValuesError = err?.errors
            ? err.errors[0]?.error
            : '';
        });
    }
  },
  setup() {
    const icons = markRaw({
      dropdownIcon
    });

    const state = reactive({
      totalSales: 0,
      targetValues: {} as NumberKeyValInterface,
      errorMessage: '',
      targetSalesError: '',
      targetValuesError: '',
      isLoading: false
    });
    return { icons, state, stringHelpers };
  },

  components: {
    VizButton,
    // VolumePrediction,
    KpiForecast,
    SalesBarGraph
  },
  watch: {
    taskId: function(newTaskId) {
      this.getMediaVolumeReport(Number(newTaskId));
      this.getTotalSales(Number(newTaskId));
    }
  }
});
</script>
