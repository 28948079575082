
import { defineComponent } from 'vue';
import RegisterData from './RegisterData.vue';
import TargetAnalysisLoader from '@/pages/TargetExtraction/TargetAnalysisLoader.vue';
import TargetAnalysisReport from '@/pages/TargetExtraction/TargetAnalysisReport.vue';
import { createNamespacedHelpers } from 'vuex';
import ExecutionHistory from '@/components/common/ExecutionHistory.vue';
import ImageViewer from '@/components/target-extraction/ImageViewer.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'TargetExtraction',

  mounted() {
    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.fileUploadStatus('aborted');
        this.resetTargetState();
        next();
      }
    } else {
      next();
    }
  },

  data() {
    return {
      currentStep: 1,
      isAnalysed: false,
      steps: [
        { title: 'pages.te.steps.1', number: 1 },
        { title: 'pages.te.steps.2', number: 2 }
      ]
    };
  },
  computed: {
    ...mapGetters(['isExitAllowed'])
  },
  methods: {
    ...mapActions(['fileUploadStatus', 'resetTargetState']),
    goBack() {
      this.currentStep = 1;
      this.isAnalysed = false;
    },
    loadAnalysis() {
      this.currentStep = 2;
      this.isAnalysed = false;
    },
    saveAnalysis() {
      this.isAnalysed = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';
      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    }
  },
  components: {
    RegisterData,
    TargetAnalysisLoader,
    TargetAnalysisReport,
    ExecutionHistory,
    ImageViewer
  }
});
