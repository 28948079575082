<template>
  <div class="contribution">
    <div class="sub_title">
      <h4>{{ $t('pages.ca.analysisreport.section2.h1') }}</h4>
    </div>
    <ul class="">
      <li>
        {{ $t('pages.ca.analysisreport.section2.h2') }}
      </li>
      <li>
        {{ $t('pages.ca.analysisreport.section2.h3') }}

        <!-- <span class="icon d-inline-block">
          <figure><img :src="helpIcon" alt=".." /></figure>
        </span> -->
      </li>
    </ul>
    <p class="text-danger">{{ errorMessage }}</p>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr v-for="(column, key) in data" :key="key">
            <td class="justify-content-center">{{ column.name }}</td>

            <td class="p-0 m-0">
              <horizontal-bar
                :data="[
                  column.no_option ? column.no_option.value : 0,
                  column.has_option ? column.has_option.value : 0,
                ]"
                :colors="
                  column.has_option
                    ? [column.no_option.color, column.has_option.color]
                    : [column.no_option.color, '']
                "
                :colorIntensities="[
                  column.no_option.color_intensity,
                  column.has_option
                    ? column.has_option.color_intensity
                    : 'light',
                ]"
                :labels="getLabels(column)"
                :yaxisMin="getBarValue(data, 'min')"
                :yaxisMax="getBarValue(data, 'max')"
              ></horizontal-bar>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="index d-flex justify-content-end">
        <div class="d-flex align-items-center me-3">
          <div
            class="color me-2"
            style="
              width: 8px;
              height: 8px;
              background: #f9a212;
              border-radius: 2px;
            "
          ></div>
          <div class="text">
            {{ $t('pages.ca.analysisreport.legends.no_option') }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="color me-2"
            style="
              width: 8px;
              height: 8px;
              background: #e1683d;
              border-radius: 2px;
            "
          ></div>
          <div class="text">
            {{ $t('pages.ca.analysisreport.legends.with_option') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { helpIcon } from '@/utils/icons';
import HorizontalBar from '@/components/contribution-analysis/ContributionHorizontalBar.vue';
import { KPIEsimateInterface } from '@/store/modules/contribution-analysis';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

interface DataColumnOption {
  value: number | null;
  color: string;
  color_intensity: string;
}
interface DataColumn {
  has_option: DataColumnOption | null;
  no_option: DataColumnOption;
}

export default defineComponent({
  name: 'KpiCoefResult',
  props: ['taskId'],
  data() {
    return {
      data: [] as Array<KPIEsimateInterface>,
      helpIcon,
      errorMessage: '',
    };
  },
  beforeMount() {
    this.getKPICoefEstimate(this.taskId)
      .then((data: Array<KPIEsimateInterface>) => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch((err) => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  components: {
    HorizontalBar,
  },

  methods: {
    ...mapActions(['getKPICoefEstimate']),
    getLabels(column: DataColumn) {
      return [
        column.no_option
          ? column.no_option.value === null
            ? ''
            : Number(column?.no_option?.value).toFixed(3)
          : '',
        column.has_option
          ? column.has_option.value === null
            ? ''
            : Number(column?.has_option?.value).toFixed(3)
          : '',
      ];
    },
    getBarValue: function (
      data: Array<KPIEsimateInterface>,
      type: string
    ): any {
      if (!data) return 1;

      const no_option: number[] = data.map((e) => Number(e.no_option?.value));
      const has_option: number[] = data.map((e) => Number(e.has_option?.value));
      const mergedArr: number[] = [...no_option, ...has_option].filter(Number);

      if (type === 'max') {
        return Math.max(...mergedArr);
      } else {
        return Math.min(...mergedArr);
      }
    },
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.data = [];

        this.getKPICoefEstimate(this.taskId)
          .then((data: Array<KPIEsimateInterface>) => {
            this.errorMessage = '';
            this.data = data;
          })
          .catch((err) => {
            this.data = [];
            this.errorMessage = err?.errors ? err.errors[0].error : '';
          });
      }
    },
  },
});
</script>

<style scoped></style>
