
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmptyLayout',

  data() {
    return {
      isSidebarVisible: true,
      langs: ['en', 'ja']
    };
  },
  methods: {
    // eslint-disable-next-line
  }
});
