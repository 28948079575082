
import { defineComponent } from 'vue';
import VizTable from '@/components/ui/VizTable.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizModal from '@/components/ui/VizModal.vue';

import AnalysisReport from '@/components/contribution-analysis/AnalysisResultReport.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
import PatternAReport from '@/components/measure-simulation/PatternAReport.vue';
import PatternBReport from '@/components/measure-simulation/PatternBReport.vue';

import { KPIOutput } from '@/store/modules/contribution-analysis';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { AnalysisHistory } from '@/store/modules/history';

export default defineComponent({
  name: 'ExecutionHistory',
  mounted() {
    this.getHistory();
  },
  data() {
    return {
      isModalVisible: false,
      taskList: [] as Array<KPIOutput>,
      errorMessage: '',
      currentReport: {
        type: '',
        taskId: 0
      }
    };
  },
  components: {
    VizTable,
    VizButton,
    VizModal,
    AnalysisReport,
    ExtractionReport,
    PatternAReport,
    PatternBReport
  },
  computed: {
    ...mapGetters(['userGroup'])
  },
  methods: {
    ...mapActions(['getAnalysisHistory']),
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss');
    },
    showModal(item: AnalysisHistory) {
      this.currentReport.type = item.type_of_analysis;
      this.currentReport.taskId = item.task_id;
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
      this.currentReport = {
        type: '',
        taskId: 0
      };
    },
    getHistory() {
      this.getAnalysisHistory({ page: 1, perPage: 2 })
        .then((histories: any) => {
          this.taskList = histories.data;
          this.errorMessage = '';
        })
        .catch(err => {
          this.errorMessage = err?.errors ? err.errors[0]?.error : '';
          this.taskList = [];
        });
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.getHistory();
      }
    }
  }
});
