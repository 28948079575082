<!-- layout wrapper-->
<template>
  <div
    class="bg-primary min-vh-100 d-flex align-items-center justify-content-center"
  >
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmptyLayout',

  data() {
    return {
      isSidebarVisible: true,
      langs: ['en', 'ja']
    };
  },
  methods: {
    // eslint-disable-next-line
  }
});
</script>

<style lang="scss" scoped></style>
