export const attachFileIcon = require('@/assets/icons/attach_file_24px.svg') as string;
export const awsIcon = require('@/assets/icons/aws.svg') as string;
export const azureIcon = require('@/assets/icons/azure.svg') as string;
export const caSmallIcon = require('@/assets/icons/ca_small.svg') as string;
export const checkCircleOutlineIcon = require('@/assets/icons/check_circle_outline_24px.svg') as string;
export const cloudUploadIcon = require('@/assets/icons/cloud_upload_24px.svg') as string;

export const dropdownIcon = require('@/assets/icons/dropdown.svg') as string;

export const googleCloudIcon = require('@/assets/icons/Google_Cloud.svg') as string;
export const layersIcon = require('@/assets/icons/layers_24px.svg') as string;
export const layersSmallIcon = require('@/assets/icons/layers_small.svg') as string;
export const linkIcon = require('@/assets/icons/link.svg') as string;
export const moreIcon = require('@/assets/icons/more.svg') as string;
export const peopleIcon = require('@/assets/icons/people_24px.svg') as string;
export const peopleLargeIcon = require('@/assets/icons/people_large.svg') as string;
export const refreshIcon = require('@/assets/icons/refresh_24px.svg') as string;
export const settingIcon = require('@/assets/icons/settings_24px.svg') as string;
export const storageIcon = require('@/assets/icons/storage_24px.svg') as string;
export const tuneIcon = require('@/assets/icons/tune_24px.svg') as string;
export const tuneLargeIcon = require('@/assets/icons/tune_large.svg') as string;
export const yesgreenIcon = require('@/assets/icons/yes-green.svg') as string;
export const logo = require('@/assets/icons/logo-2.1.svg') as string;
export const importHistoryIcon = require('@/assets/icons/import-history.svg') as string;
export const importHistoryLargeIcon = require('@/assets/icons/import-history@2x.svg') as string;

export const helpIcon = require('@/assets/icons/help.svg') as string;
export const closeLineIcon = require('@/assets/icons/close-line.svg') as string;
export const checkAltIcon = require('@/assets/icons/check-alt.svg') as string;
export const downIcon = require('@/assets/icons/down.svg') as string;
export const nextIcon = require('@/assets/icons/next.svg') as string;
export const prevIcon = require('@/assets/icons/prev.svg') as string;
