
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';
import { SimulationStateInterface } from '@/store/modules/measure-simulation';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('ms');

export default defineComponent({
  beforeMount() {
    const { pattern, taskId } = this.$route.params;
    if (!pattern || !taskId) {
      this.$router.replace({ path: '/simulation' });
    }
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
    window.addEventListener('beforeunload', this.handleClose);
  },
  props: {
    steps: Array
  },
  components: {
    StepsTrack,
    VizButton
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);

    this.setExitAllowed(true);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.activateProceed && !this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        next();
      }
    } else {
      next();
    }
  },
  beforeUnmount() {
    clearInterval(this.state.analysisPoll);
  },
  setup() {
    const state = reactive({
      currentStep: 2,
      analysisPoll: 0,
      errorMessage: '',
      simulationState: {} as SimulationStateInterface
    });

    const states = markRaw({
      LOADING: 'loading_data',
      PREPROCESSING: 'preprocessing',
      OPTIMIZING: 'optimizing',
      SIMULATING: 'simulating',
      POSTPROCESSING: 'postprocessing',
      FINISH_OPTIMIZATION: 'finish optimization process'
    });
    return {
      states,
      state,
      abortActive: true
    };
  },
  computed: {
    ...mapGetters(['isExitAllowed']),
    simulationError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.state.simulationState.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    currentPattern: function(): string {
      const pattern = this.$route.params?.pattern as string;
      if (pattern) return pattern;
      return '';
    },
    activateProceed: function() {
      if (
        Array.isArray(this.state.simulationState?.states) &&
        this.state.simulationState?.states.includes(
          this.states.FINISH_OPTIMIZATION
        )
      ) {
        return true;
      }
      return false;
    },

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.state.simulationState?.logs)) {
        return logs;
      }
      this.state.simulationState.logs.forEach((item: Array<string>) => {
        logs = [...item, ...logs];
      });
      return logs;
    },

    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.state.simulationState?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },

    analysisPercentage: function(): number {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions(['getSimulationState', 'termiateProcess']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasStateError(state: string) {
      return this.simulationError.failedSteps?.includes(state) ? true : false;
    },
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';

      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    handleGoBack() {
      this.$router.push('/simulation');
    },

    handleProceed() {
      const { pattern, taskId, modelName } = this.$route.params;
      if (pattern == 'patternA') {
        this.$router.push({
          path: '/simulation/patterna-report',
          name: 'EstimatedSales',
          params: {
            pattern,
            taskId,
            modelName
          }
        });
      }
      if (pattern == 'patternB') {
        this.$router.push({
          path: '/simulation/patternb-report',
          name: 'TargetSales',
          params: {
            pattern,
            taskId,
            modelName
          }
        });
      }
    },
    pullData() {
      const { taskId, pattern } = this.$route.params;

      this.state.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_OPTIMIZATION)) {
          this.abortActive = false;
          clearInterval(this.state.analysisPoll);
        }
        this.getSimulationState({ pattern, taskId })
          .then((data: SimulationStateInterface) => {
            this.state.errorMessage = '';
            this.state.simulationState = data;
            if (this.simulationError.status) {
              this.abortActive = false;
              this.state.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            if (err && Array.isArray(err.errors)) {
              this.abortActive = false;
              this.state.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },

    isStateComplete(state: string) {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },

    terminateProces() {
      this.termiateProcess();
      this.setExitAllowed(true);
      this.$router.push('/simulation');
    }
  },
  watch: {
    simulationError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.state.errorMessage = 'errors.analysis-failed';
        clearInterval(this.state.analysisPoll);
      }
    }
  }
});
