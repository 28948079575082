/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { http } from '@/utils/http';

const EXPORT_SIMULATION_REPORT_URL =
  '/api/v1/measure_simulation/simulate/output/download';
const EXPORT_OPTIMIZATION_REPORT_URL =
  '/api/v1/measure_simulation/optimize/output/download';
const FETCH_MODELS = '/api/v1/measure_simulation/fetch_group_models';
const FETCH_TARGET_VALUES = '/api/v1/measure_simulation/fetch_target_values';
const SIMULATE_STATUS = '/api/v1/measure_simulation/simulate_status';
const OPTIMIZE_STATUS = '/api/v1/measure_simulation/optimize_status';
const RUN_SIMULATE_MODEL = '/api/v1/measure_simulation/simulate';
const RUN_OPTIMIZE_MODEL = '/api/v1/measure_simulation/optimize';

//report APIS

//pattern a report
const TOTAL_SALES_SIMULATE =
  '/api/v1/measure_simulation/simulate/output/total_sales';
const MEDIA_VOLUME_SIMULATE =
  '/api/v1/measure_simulation/simulate/output/media_volume';
const BUDGET_SPEND_SIMULATE =
  '/api/v1/measure_simulation/simulate/output/budget_spend';
const SALES_PREDICTION_SIMULATE =
  '/api/v1/measure_simulation/simulate/output/sales_prediction';
const SALES_BAR_GRAPH_SIMULATE =
  '/api/v1/measure_simulation/simulate/output/sales_bar_graph';

//pattern b report
const TOTAL_SALES_OPTIMIZE =
  '/api/v1/measure_simulation/optimize/output/total_sales';
const MEDIA_VOLUME_OPTIMIZE =
  '/api/v1/measure_simulation/optimize/output/media_volume';
const BUDGET_SPEND_OPTIMIZE =
  '/api/v1/measure_simulation/optimize/output/budget_spend';
const SALES_PREDICTION_OPIMIZE =
  '/api/v1/measure_simulation/optimize/output/sales_prediction';

const TERMINATE_PROCESS = '/api/v1/measure_simulation/optimize/abort_analysis';

export interface PatternAInput {
  modelName: string;
}
export interface PatternBInput {
  modelName: string;
}
export interface NumberKeyValInterface {
  [key: string]: number | null;
}
export interface StringKeyValInterface {
  [key: string]: string;
}
export interface SimulateModelRequest {
  modelName: string;
  targetValues: NumberKeyValInterface | Array<string>;
  taskId?: number;
  groupId: number;
}
export interface OptimizeModelRequest {
  modelName: string;
  optimizeVariableList: Array<string>;
  taskId?: number;
  targetKpi: number;
  groupId: number;
}

const getModelOptions = (groupId: number) => {
  return http.post(FETCH_MODELS, JSON.stringify({ group_id: groupId }));
};
const getTargetMeasureOptions = ({
  model,
  groupId
}: {
  model: string;
  groupId: number;
}) => {
  return http.post(
    FETCH_TARGET_VALUES,
    JSON.stringify({ model, group_id: groupId })
  );
};

const runSimulateModel = ({
  modelName,
  taskId,
  targetValues,
  groupId
}: SimulateModelRequest) => {
  const data = {
    model_name: modelName,
    target_values: targetValues,
    task_id: taskId,
    group_id: groupId
  };

  if (Array.isArray(targetValues) && typeof targetValues[0] == 'string') {
    let newTargetValues: NumberKeyValInterface | null = null;

    targetValues.forEach((item: string) => {
      newTargetValues = { ...newTargetValues, [item]: null };
    });
    if (newTargetValues) {
      data.target_values = newTargetValues;
    }
  }

  if (!taskId) {
    delete data.task_id;
  }
  return http.post(RUN_SIMULATE_MODEL, JSON.stringify(data));
};

const runOptimizeModel = ({
  modelName,
  taskId,
  optimizeVariableList,
  targetKpi,
  groupId
}: OptimizeModelRequest) => {
  const data = {
    model_name: modelName,
    optimize_variable_list: optimizeVariableList,
    task_id: taskId,
    target_kpi: targetKpi,
    group_id: groupId
  };

  if (!taskId) {
    delete data.task_id;
  }

  return http.post(RUN_OPTIMIZE_MODEL, JSON.stringify(data));
};

const getSimulationState = (taskId: number) => {
  return http.post(SIMULATE_STATUS, JSON.stringify({ task_id: taskId }));
};
const getOptimizationState = (taskId: number) => {
  return http.post(OPTIMIZE_STATUS, JSON.stringify({ task_id: taskId }));
};

const exportSimulationReport = (taskId: number) => {
  return http.request(EXPORT_SIMULATION_REPORT_URL, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId })
  });
};

const exportOptimizationReport = (taskId: number) => {
  return http.request(EXPORT_OPTIMIZATION_REPORT_URL, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId })
  });
};

const getSimulateTotalSalesReport = (taskId: number) => {
  return http.post(TOTAL_SALES_SIMULATE, JSON.stringify({ task_id: taskId }));
};

const getSimulateMediaVolumeReport = (taskId: number) => {
  return http.post(MEDIA_VOLUME_SIMULATE, JSON.stringify({ task_id: taskId }));
};

const getSimulateBudgetSpendReport = (taskId: number) => {
  return http.post(BUDGET_SPEND_SIMULATE, JSON.stringify({ task_id: taskId }));
};

const getSimulateSalesPredictionReport = (taskId: number) => {
  return http.post(
    SALES_PREDICTION_SIMULATE,
    JSON.stringify({ task_id: taskId })
  );
};
const getSimulateSaleBarReport = (taskId: number) => {
  return http.post(
    SALES_BAR_GRAPH_SIMULATE,
    JSON.stringify({ task_id: taskId })
  );
};

const getOptimizeTotalSalesReport = (taskId: number) => {
  return http.post(TOTAL_SALES_OPTIMIZE, JSON.stringify({ task_id: taskId }));
};

const getOptimizeMediaVolumeReport = (taskId: number) => {
  return http.post(MEDIA_VOLUME_OPTIMIZE, JSON.stringify({ task_id: taskId }));
};

const getOptimizeBudgetSpendReport = (taskId: number) => {
  return http.post(BUDGET_SPEND_OPTIMIZE, JSON.stringify({ task_id: taskId }));
};

const getOptimizeSalesPredictionReport = (taskId: number) => {
  return http.post(
    SALES_PREDICTION_OPIMIZE,
    JSON.stringify({ task_id: taskId })
  );
};

const termiateProcess = (taskId: number) => {
  return http.post(
    TERMINATE_PROCESS,
    JSON.stringify({ task_id: taskId })
  );
};

const simulationAPI = {
  getModelOptions,
  getTargetMeasureOptions,
  runSimulateModel,
  runOptimizeModel,
  getSimulationState,
  getOptimizationState,
  exportSimulationReport,
  exportOptimizationReport,
  getSimulateTotalSalesReport,
  getSimulateMediaVolumeReport,
  getSimulateBudgetSpendReport,
  getSimulateSalesPredictionReport,
  getSimulateSaleBarReport,
  getOptimizeTotalSalesReport,
  getOptimizeMediaVolumeReport,
  getOptimizeBudgetSpendReport,
  getOptimizeSalesPredictionReport,
  termiateProcess
};

export default simulationAPI;
