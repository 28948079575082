import ContributionAnalysis from '@/pages/ContributionAnalysis/index.vue';
import ContributionEnterData from '@/pages/ContributionAnalysis/EnterData.vue';
import ContributionMeasureData from '@/pages/ContributionAnalysis/MeasureData.vue';
import ContributionAnalysisObserve from '@/pages/ContributionAnalysis/AnalysisResultObserve.vue';
import ContributionAnalysisResult from '@/pages/ContributionAnalysis/AnalysisResultReport.vue';

import TargetExtraction from '@/pages/TargetExtraction/index.vue';
import History from '@/pages/History/index.vue';
import MeasureSimulation from '@/pages/MeasureSimulation/index.vue';
import PatternSelection from '@/pages/MeasureSimulation/PatternSelection.vue';
import SimulationObserve from '@/pages/MeasureSimulation/SimulationObserve.vue';
import PatternBReport from '@/pages/MeasureSimulation/PatternBReport.vue';
import PatternAReport from '@/pages/MeasureSimulation/PatternAReport.vue';
import Settings from '@/pages/Settings/index.vue';
import UserProfile from '@/pages/UserProfile/index.vue';
import AccountSettings from '@/pages/AccountSettings/index.vue';
import Reset from '@/pages/Auth/ResetPassword/Reset.vue';

import * as LayersIcon from '@//assets/icons/layers.svg';
import * as TuneIcon from '@/assets/icons/tune_large.svg';
import * as PeopleIcon from '@/assets/icons/people_large.svg';
import * as SettingIcon from '@//assets/icons/settings@2x.svg';
import * as ImportHistoryIcon from '@//assets/icons/import-history@2x.svg';

import { contractFunctions } from '@/constants/role';

const contributionAnalysisMeta = {
  headerIcon: LayersIcon,
  pageKey: 'ca',
  userFunction: contractFunctions.CONTRIBUTION_ANALYSIS,
  authentication: true,
  enableAccessControl: true
};

const userProfileMeta = {
  headerIcon: LayersIcon,
  pageKey: 'profile',
  authentication: true
};

const accountSettingsMeta = {
  headerIcon: SettingIcon,
  pageKey: 'account',
  authentication: true
};

const measureSimulationMeta = {
  headerIcon: TuneIcon,
  pageKey: 'ms',
  userFunction: contractFunctions.MEASURE_SIMULATION,
  authentication: true,
  enableAccessControl: true
};

const targetExtractionMeta = {
  headerIcon: PeopleIcon,
  pageKey: 'te',
  userFunction: contractFunctions.TARGET_EXTRACTION,
  authentication: true,
  enableAccessControl: true
};

const importHistoryMeta = {
  headerIcon: ImportHistoryIcon,
  pageKey: 'ih',
  // userFunction: contractFunctions.HISTORY, history is accessible to both manager and user
  authentication: true
};

const settingMeta = {
  headerIcon: SettingIcon,
  pageKey: 'settings',
  userFunction: contractFunctions.SETTINGS,
  authentication: true
};
const resetMeta = {
  headerIcon: SettingIcon,
  pageKey: 'reset',
  authentication: true
};

const appRoutes = [
  {
    path: '/analysis',
    name: 'ContributionAnalysis',
    component: ContributionAnalysis,
    meta: contributionAnalysisMeta,

    children: [
      {
        name: 'ContributionEnter',
        path: '',
        redirect: '/analysis/enter'
      },
      {
        name: 'ContributionEnterData',
        path: '/analysis/enter',
        component: ContributionEnterData,
        props: true,
        meta: { ...contributionAnalysisMeta, step: 1 }
      },
      {
        name: 'ContributionMeasureData',
        path: '/analysis/measure',
        component: ContributionMeasureData,
        props: true,
        meta: { ...contributionAnalysisMeta, step: 2 }
      },
      {
        name: 'ContributionAnalysisObserve',
        path: '/analysis/observe',
        component: ContributionAnalysisObserve,
        props: true,
        meta: { ...contributionAnalysisMeta, step: 3 }
      },
      {
        name: 'ContributionAnalysisResult',
        path: '/analysis/result',
        component: ContributionAnalysisResult,
        props: true,
        meta: { ...contributionAnalysisMeta, step: 3 }
      }
    ]
  },

  {
    path: '/simulation',
    name: 'Simulation',
    component: MeasureSimulation,
    children: [
      {
        name: 'PatternSelection',
        path: '',
        component: PatternSelection
      },
      {
        name: 'SimulationObserve',
        path: '/simulation/observe',
        component: SimulationObserve,
        props: true
      },
      {
        name: 'EstimatedSales',
        path: '/simulation/patterna-report',
        component: PatternAReport,
        props: true
      },
      {
        name: 'TargetSales',
        path: '/simulation/patternb-report',
        component: PatternBReport,
        props: true
      }
    ],
    meta: measureSimulationMeta
  },
  {
    path: '/extraction',
    name: 'Extraction',
    component: TargetExtraction,
    meta: targetExtractionMeta
  },

  {
    path: '/history',
    name: 'History',
    component: History,
    meta: importHistoryMeta
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: settingMeta
  },
  {
    path: '/settings/:tab/:id',
    name: 'SettingsTab',
    component: Settings,
    meta: settingMeta,
    exact: false
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: userProfileMeta
  },
  {
    path: '/account',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: accountSettingsMeta
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: resetMeta
  },
  {
    path: '',
    redirect: '/account'
  }
];

export default appRoutes;
