
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizMultipleEmailInput from '@/components/ui/VizMultipleEmailInput.vue';
import { logo } from '@/utils/icons';
import managerAPI from '@/api/manager';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton,
    VizMultipleEmailInput
  },
  beforeMount() {
    const { groupId } = this.$route.params;
    if (!groupId) {
      this.$router.push('/login');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'InviteMembersComplete') {
      if (window.confirm('戻ってもよろしいですか？')) {
        next();
      }
    } else {
      next();
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = '\o/';
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      errorMessage: '',
      isLoading: false,
      errors: { emails: '' },
      emails: [] as Array<string>
    });

    const handleChange = (data: Array<string>) => {
      state.emails = data;
    };

    const onSubmit = () => {
      const { groupId } = route.params;
      state.errors.emails = '';

      if (!Array.isArray(state.emails) || state.emails.length < 1) {
        state.errors.emails = 'errors.required-field';
        return;
      }

      if (state.emails.length > 10) {
        state.errors.emails = 'errors.max-invitation-limit';
        return;
      }

      state.isLoading = true;
      managerAPI
        .inviteMembers({
          group_id: Number(groupId),
          receiver: state.emails
        })
        .then(() => {
          state.errorMessage = '';
          state.errors.emails = '';
          router.push({
            name: 'InviteMembersComplete',
            params: { receivers: state.emails.length }
          });
        })
        .catch(err => {
          const errors = err?.errors;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((item: any) => {
            if (item.error) state.errorMessage = item.error;
            if (item.receiver) state.errors.emails = item.receiver;
          });
        });
      state.isLoading = false;
    };

    return {
      onSubmit,
      handleChange,
      state,
      logo
    };
  }
});
