
import { defineComponent } from 'vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';

export default defineComponent({
  components: {
    VizTooltip
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    modelValue: String // previously was `value: String`
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange(ev: Event) {
      const target = ev.target as HTMLInputElement;
      this.$emit('update:modelValue', target.value); // previously was `this.$emit('input', title)`
    }
  }
});
