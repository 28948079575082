<template>
  <section id="sidebar" v-bind:class="isSidebarVisible ? '' : 'active'">
    <aside class="sidebar">
      <div class="logo d-flex align-items-center justify-content-center">
        <router-link :to="`/analysis?group=${userGroup ? userGroup.id : ''}`">
          <figure>
            <img
              :src="icons.logo"
              alt="viz"
              style="padding: 0 10px;height: 110px;"
            />
          </figure>
        </router-link>
      </div>
      <nav class="navbar">
        <ul class="navbar-nav me-auto w-100">
          <li
            class="nav-item"
            v-for="route in routes"
            :key="route.path"
            active-class="active"
          >
            <router-link
              v-if="isFunctionAvailable(route)"
              class="nav-link"
              active-class="active"
              aria-current="page"
              :to="route.path + `?group=${userGroup ? userGroup.id : ''}`"
            >
              <figure>
                <img :src="route.icon" alt="" />
              </figure>

              <span class="nav-link_text">
                {{ $t(route.title) }}
                <!--
                  *NOTE:  i18n transformation i.e. $t() needs to be rendered from template               
                -->
              </span>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="bottom">
        <div
          class="progress-data mb-2 d-flex justify-content-between align-items-center"
        >
          <div class="total">
            {{ $t('sidebar.pt') }}
          </div>
          <div class="remaining">
            {{ Number(storageUsage.used).toFixed(2) }}GB /
            {{ storageUsage.total }}GB
          </div>
        </div>
        <div class="position-relative">
          <div class="progress" style="height: 5px;">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              aria-valuenow="68"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="`width: ${usage}%`"
            ></div>
          </div>
          <div class="progress_percent">
            <span>{{ usage }}%</span>
          </div>
        </div>
      </div>
    </aside>
  </section>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import {
  logo,
  caSmallIcon,
  tuneIcon,
  peopleIcon,
  settingIcon,
  importHistoryIcon
} from '@/utils/icons';

import { contractFunctions, userRoles } from '@/constants/role';
import { UserGroup } from '@/store/modules/auth';

export default defineComponent({
  name: 'Sidebar',
  props: {
    isSidebarVisible: Boolean
  },
  beforeMount() {
    this.errorMessage = '';
    if (this.userGroup?.id) {
      this.getStorageUsage(this.userGroup?.id).catch(err => {
        this.errorMessage = Array.isArray(err?.errors)
          ? err.errors[0].error
          : '';
      });
    }
  },

  data() {
    const icons = markRaw({
      logo
    });
    return {
      errorMessage: '',
      activeLink: '/dashboard',
      routes: [
        {
          path: '/analysis',
          icon: caSmallIcon,
          title: 'navigations.ca',
          userFunction: contractFunctions.CONTRIBUTION_ANALYSIS,
          enableManagerAccessControl: true
        },
        {
          path: '/simulation',
          icon: tuneIcon,
          title: 'navigations.ms',
          userFunction: contractFunctions.MEASURE_SIMULATION,
          enableManagerAccessControl: true
        },
        {
          path: '/extraction',
          icon: peopleIcon,
          title: 'navigations.te',
          userFunction: contractFunctions.TARGET_EXTRACTION,
          enableManagerAccessControl: true
        },

        {
          path: '/history',
          icon: importHistoryIcon,
          title: 'navigations.ih'
          // userFunction: contractFunctions.HISTORY
        },
        {
          path: '/settings',
          icon: settingIcon,
          title: 'navigations.s',
          userFunction: contractFunctions.SETTINGS
        }
      ],
      icons
    };
  },
  computed: {
    ...mapGetters(['user', 'userGroup', 'storageUsage']),
    usage: function(): number | string {
      const percent =
        (this.storageUsage?.used / this.storageUsage?.total) * 100;
      return isNaN(percent) ? 0 : percent.toFixed(2);
    }
  },
  methods: {
    ...mapActions(['getStorageUsage']),
    isFunctionAvailable({
      userFunction,
      enableManagerAccessControl
    }: {
      userFunction: string;
      enableManagerAccessControl?: boolean;
    }) {
      if (!userFunction) {
        return true;
      }
      if (this.user?.user_role == userRoles.MANAGER) {
        if (!enableManagerAccessControl) {
          return true;
        } else {
          return this.user?.user_functions?.includes(userFunction);
        }
      } else {
        return this.user?.user_functions?.includes(userFunction);
      }
    },
    changeActiveStatus(url: string) {
      this.activeLink = url;
    }
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.errorMessage = '';
        this.getStorageUsage(newVal.id).catch(err => {
          this.errorMessage = Array.isArray(err?.errors)
            ? err.errors[0].error
            : '';
        });
      }
    }
  }
});
</script>
