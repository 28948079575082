import { object, string, array } from 'yup';

const patternASchema = object({
  modelName: string().required(() => 'errors.required-field'),
  targetValues: array().when('modelName', {
    is: String,
    then: array()
      .min(1, () => 'errors.required-field')
      .required(() => 'errors.required-field')
  })
});

export default patternASchema;
