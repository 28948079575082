<template>
  <table class="table ">
    <thead>
      <tr>
        <slot name="headings"></slot>
      </tr>
    </thead>
    <tbody>
      <slot name="data"></slot>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Table",
  props: ["tableData"]
});
</script>

<style scoped></style>
