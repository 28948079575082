
import { defineComponent, markRaw } from 'vue';
import { helpIcon, dropdownIcon } from '@/utils/icons';
import KpiCorrResult from '@/components/contribution-analysis/KpiCorrResult.vue';
import KpiCoefResult from '@/components/contribution-analysis/KpiCoefResult.vue';
import KpiEffectResult from '@/components/contribution-analysis/KpiEffectResult.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'AnalysisReport',
  components: {
    KpiCorrResult,
    KpiCoefResult,
    KpiEffectResult,
    VizButton,
  },

  data() {
    const icons = markRaw({
      helpIcon,
      dropdownIcon,
    });
    return {
      icons,
      isLoading: false,
    };
  },
  props: ['taskId'],
  methods: {
    ...mapActions(['exportAnalysisReport']),
    handleExport() {
      this.isLoading = true;

      this.exportAnalysisReport(this.taskId)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
});
