<template>
  <div
    class="toast d-flex align-items-center justify-content-center mx-auto bg-dark show"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-body text-white">
      {{ message }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['message'],
  setup() {
    return {};
  }
});
</script>

<style scoped>
.toast {
  position: absolute;
  left: 30%;
  bottom: 20%;
}
</style>
