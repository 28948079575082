<template>
  <div class="analyzing mt-5">
    <div class="row">
      <p class="text-danger pb-4">{{ $t(errorMessage) }}</p>

      <div class="col-md-4">
        <div class="sub_title">
          <h4>{{ $t('pages.ca.analysisobserve.analyzing') }}・・・</h4>
        </div>
        <ul class="process">
          <li class="mt-4">
            <div
              class="
                progress-data
                mb-2
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <!-- <div class="time text-primary">
              {{ $t('pages.ca.analysisobserve.secondsleft', { seconds: 32 }) }}
            </div> -->
            </div>
            <div class="position-relative">
              <div class="progress" style="height: 5px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :aria-valuenow="analysisPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="`width: ${analysisPercentage}%`"
                ></div>
              </div>
            </div>
          </li>
          <li
            :class="
              `mt-4 ${isStateComplete(states.LOADING) &&
                'sucess'} ${hasStateError(states.LOADING) && 'error'}`
            "
          >
            {{ $t('pages.ca.analysisobserve.dr') }}
          </li>
          <li
            :class="
              `mt-4 ${isStateComplete(states.PREPROCESSING) &&
                'sucess'} ${hasStateError(states.PREPROCESSING) && 'error'}`
            "
          >
            {{ $t('pages.ca.analysisobserve.dp') }}
          </li>
          <li
            :class="
              `mt-4 ${isStateComplete(states.ESTIMATING) &&
                'sucess'} ${hasStateError(states.ESTIMATING) && 'error'}`
            "
          >
            {{ $t('pages.ca.analysisobserve.ml') }}
          </li>
          <li
            :class="
              `mt-4 ${isStateComplete(states.POSTPREPROCESSING) &&
                'sucess'} ${hasStateError(states.POSTPREPROCESSING) && 'error'}`
            "
          >
            {{ $t('pages.ca.analysisobserve.ie') }}
          </li>
          <li
            :class="
              `mt-4 ${isStateComplete(states.FINISH_ESTIMATING) &&
                'sucess'} ${hasStateError(states.FINISH_ESTIMATING) && 'error'}`
            "
          >
            {{ $t('pages.ca.analysisobserve.pca') }}
          </li>
        </ul>
      </div>
      <div class="col-md-8 mb-3">
        <div class="position-relative terminal">
          <div class="text">
            <p v-for="log in logs" :key="log">{{ log }}</p>
          </div>
        </div>
      </div>
      <div class="button d-block">
        <viz-button
          type="button"
          class="btn btn-primary text-white fw-bold"
          @click="handleAnalysisCompletion"
          :disabled="!activateProceed"
        >
          {{ $t('common.buttonlabels.nextstep') }}
        </viz-button>
        <viz-button
          type="button"
          class="btn btn-secondary rounded-pill ms-3 btn-abort"
          data-bs-toggle="modal"
          data-bs-target="#abort-modal"
          v-if="abortActive"
        >
          分析を中止する
        </viz-button>
        <!-- Modal -->
        <div
          class="modal fade"
          id="abort-modal"
          tabindex="-1"
          aria-labelledby="abort"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">この進行を中止してもよろしいですか？</div>
              <div class="modal-footer justify-content-center">
                <button
                  type="button"
                  class="btn btn-primary rounded-pill ms-3 btn-sm"
                  data-bs-dismiss="modal"
                >
                  キャンセル
                </button>

                <viz-button
                  type="button"
                  class="btn btn-secondary rounded-pill ms-3 btn-sm"
                  data-bs-dismiss="modal"
                  @click="terminateProces"
                  v-if="abortActive"
                >
                  分析を中止する
                </viz-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';
import store from '@/store';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'AnalysisObserve',
  components: {
    VizButton
  },
  beforeMount() {
    const { taskId } = this.$route.params;
    if (!taskId) {
      this.$router.replace({ path: '/analysis' });
    }
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
  },

  unmounted() {
    this.setExitAllowed(true);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ContributionAnalysisResult' && this.taskId) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.resetKpiState();
        this.getTaskId();

        next();
      }
    } else {
      next();
    }
  },
  beforeUnmount() {
    store.dispatch('getStorageUsage', store.getters.userGroup?.id);
    clearInterval(this.analysisPoll);
  },
  setup() {
    const states = markRaw({
      LOADING: 'loading_dataset',
      PREPROCESSING: 'preprocessing',
      ESTIMATING: 'estimating_contribution',
      POSTPREPROCESSING: 'postprocessing',
      FINISH_ESTIMATING: 'finish_estimating_process'
    });

    return { analysisPoll: 0, errorMessage: '', abortActive: true, states };
  },
  computed: {
    ...mapGetters(['analysisState', 'taskId']),
    analysisError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.analysisState.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    activateProceed: function(): boolean {
      return (
        Array.isArray(this.analysisState?.states) &&
        this.analysisState.states.includes('finish_estimating_process')
      );
    },

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.analysisState?.logs)) {
        return logs;
      }
      this.analysisState.logs.forEach((item: Array<string>) => {
        logs = [...item, ...logs];
      });
      return logs;
    },

    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.analysisState?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },

    analysisPercentage: function(): number {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions([
      'getAnalysisState',
      'resetKpiState',
      'getTaskId',
      'terminateUpload',
      'resetKPIFileInputFormState'
    ]),
    handleAnalysisCompletion() {
      this.$router.push({
        name: 'ContributionAnalysisResult',
        path: '/analysis/result',
        params: { taskId: this.$route.params?.taskId }
      });
    },
    hasStateError(state: string) {
      return this.analysisError.failedSteps?.includes(state) ? true : false;
    },
    pullData() {
      this.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_ESTIMATING)) {
          this.abortActive = false;
          clearInterval(this.analysisPoll);
        }
        this.getAnalysisState(Number(this.$route.params?.taskId))
          .then(() => {
            this.errorMessage = '';
            if (this.analysisError.status) {
              this.abortActive = false;
              this.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            this.abortActive = false;

            if (err && Array.isArray(err.errors)) {
              this.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },

    isStateComplete(state: string) {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },
    terminateProces() {
      this.terminateUpload();
      this.resetKpiState();
      this.$router.push({ name: 'ContributionEnterData' });
    }
  },
  watch: {
    analysisError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.errorMessage = 'errors.analysis-failed';
        clearInterval(this.analysisPoll);
      }
    }
  }
});
</script>
<style scoped lang="scss">
.terminal {
  max-width: 540px;
  min-height: 400px;
  max-height: 400px;
  background-color: #333333;
  padding: 10px 20px;
  overflow-y: auto;
  border-radius: 7px;
  .text {
    color: #fff;
    font-family: Courier;
    font-weight: 700;
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
