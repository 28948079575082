<template>
  <!-- bills tab -->

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ $t('pages.settings.billing.billing-number') }}</th>
          <th scope="col">{{ $t('pages.settings.billing.billing-date') }}</th>
          <th scope="col">{{ $t('pages.settings.billing.org') }}</th>
          <th scope="col">{{ $t('pages.settings.billing.personin') }}</th>
          <th scope="col">{{ $t('pages.settings.billing.billing-amount') }}</th>
          <th scope="col">{{ $t('pages.settings.billing.invoice') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-if="state.errorMessage"
            class="text-danger text-center"
            colspan="6"
          >
            {{ state.errorMessage }}
          </td>
        </tr>
        <tr v-for="(invoice, index) in state.data.invoices" :key="index">
          <td>{{ invoice.invoice_number }}</td>
          <td>{{ moment(invoice.billing_date) }}</td>
          <td>{{ invoice.department }}</td>
          <td>{{ invoice.person_in_charge }}</td>
          <td>{{ invoice.billing_amount }}</td>
          <td>
            <viz-button
              @click="handleDownload(invoice.id)"
              type="button"
              class="btn btn-outline-primary"
            >
              {{ $t('labels.pdf-download') }}
            </viz-button>
          </td>
        </tr>
      </tbody>
    </table>
    <viz-pagination
      @onPageChange="handlePageChange"
      :totalEntries="state.data.totalEntries || 0"
      :currentPage="state.data.currentPage"
      :perPageCount="state.data.perPageCount"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { mapActions } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import moment from 'moment';

export default defineComponent({
  beforeMount() {
    this.state.data = { ...this.state.data, invoices: [], totalEntries: 0 };
    this.getInvoiceList({ page: 1, perPage: 10 })
      .then(data => {
        this.state.data = data;
        this.state.errorMessage = '';
      })
      .catch(err => {
        this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
      });
  },
  mounted() {
    const { tab, id } = this.$route.params;
    if (tab == 'billing') {
      if (id) {
        this.handleDownload(Number(id));
      }
    }
  },
  components: {
    VizButton,
    VizPagination
  },
  setup() {
    const state = reactive({
      data: { invoices: [], totalEntries: 0, currentPage: 1, perPageCount: 10 },
      errorMessage: ''
    });
    return { state };
  },
  methods: {
    ...mapActions(['getInvoiceList', 'downloadInvoice']),
    moment: function(date: string) {
      return date ? moment(date).format('YYYY/MM/DD') : '';
    },
    handleDownload(invoiceId: number) {
      this.downloadInvoice(invoiceId)
        .then(() => {
          this.state.errorMessage = '';
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
        });
    },
    handlePageChange(page: number, perPage: number) {
      this.getInvoiceList({ page, perPage })
        .then(data => {
          this.state.data = data;
          this.state.errorMessage = '';
          this.state.data = {
            ...this.state.data,
            currentPage: page,
            perPageCount: perPage
          };
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
        });
    }
  }
});
</script>

<style scoped></style>
