
import { defineComponent, reactive, markRaw } from 'vue';

import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('ms');

import StepsTrack from '@/components/ui/StepsTrack.vue';

import PatternAForm from './PatternAForm.vue';
import PatternBForm from './PatternBForm.vue';
import { UserGroup } from '@/store/modules/auth';

export default defineComponent({
  name: 'PatternSelection',
  props: {
    steps: Array
  },
  beforeMount() {
    this.getModelOpts();
  },
  components: {
    StepsTrack,
    PatternAForm,
    PatternBForm
  },
  setup() {
    const state = reactive({
      errorMessage: '',
      modelOptions: [],
      targetMeasure: [] as Array<string>
    });
    const defaultModelSelectValue = markRaw({
      text: '選択してください',
      value: '',
      selected: true
    }) as { text: string; value: string; selected?: boolean };

    return {
      currentStep: 1,
      defaultModelSelectValue,
      options: [defaultModelSelectValue],
      state
    };
  },
  computed: {
    ...mapGetters(['modelOptions']),
    ...mapRootGetters(['userGroup'])
  },
  methods: {
    ...mapActions(['getModelOptions']),
    transformModelOptions(
      modelOptions: Array<string>
    ): Array<{ text: string; value: string }> {
      const newModelOptions = [this.defaultModelSelectValue];

      if (Array.isArray(modelOptions)) {
        modelOptions.forEach(item => {
          newModelOptions.push({ text: item, value: item });
        });
      }
      return newModelOptions;
    },
    getModelOpts() {
      this.getModelOptions()
        .then(() => {
          this.state.errorMessage = '';
        })
        .catch(err => {
          this.state.errorMessage = err.errors ? err.errors[0]?.error : '';
        });
    }
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.getModelOpts();
      }
    }
  }
});
