/* eslint-disable @typescript-eslint/no-explicit-any */
const titleDirective = {
  mounted(el: any, binding: any) {
    document.title = binding.value + ' DC-Viz';
  },
  updated(el: any, binding: any) {
    document.title = binding.value + ' DC-Viz';
  }
};

export default titleDirective;
