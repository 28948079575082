const Login = () => import('@/pages/Auth/Login/Login.vue');
import Signup from '@/pages/Auth/Signup/Signup.vue';
import ConfirmEmail from '@/pages/Auth/ConfirmEmail/index.vue';

import SignupComplete from '@/pages/Auth/Signup/SignupComplete.vue';
import Forgot from '@/pages/Auth/ForgotPassword/Forgot.vue';
import ForgotComplete from '@/pages/Auth/ForgotPassword/ForgotComplete.vue';
import ResetComplete from '@/pages/Auth/ResetPassword/ResetComplete.vue';
import ErrorDisplayPage from '@/pages/Auth/ErrorPage/index.vue';
import AppRedirect from '@/middlewares/AppRedirect';

const loginMeta = {
  pageKey: 'login'
};
const errorDisplayMeta = {
  pageKey: 'error-display'
};
const signupMeta = {
  pageKey: 'signup'
};
const signupCompleteMeta = {
  pageKey: 'signupc'
};
const forgotMeta = {
  pageKey: 'forgot'
};
const forgotCompleteMeta = {
  pageKey: 'forgotc'
};
const resetCompleteMeta = {
  pageKey: 'resetc'
};
const confirmEmailMeta = {
  pageKey: 'confirm-email'
};

const authRoutes = [
  {
    path: '/login',
    beforeEnter: AppRedirect,
    alias: '',
    name: 'Login',
    component: Login,
    meta: loginMeta,
    props: true
  },
  {
    path: '/error-display',
    alias: 'error-display',
    name: 'ErrorDisplayPage',
    component: ErrorDisplayPage,
    meta: errorDisplayMeta,
    props: true
  },
  {
    path: '/signup',
    beforeEnter: AppRedirect,
    name: 'Signup',
    component: Signup,
    meta: signupMeta,
    props: true
  },
  {
    path: '/confirm_email',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: confirmEmailMeta,
    props: true
  },
  {
    path: '/signup/complete',
    name: 'SignupComplete',
    component: SignupComplete,
    meta: signupCompleteMeta
  },
  {
    path: '/forgot',
    beforeEnter: AppRedirect,
    name: 'Forgot',
    component: Forgot,
    meta: forgotMeta
  },
  {
    path: '/forgot/complete',
    beforeEnter: AppRedirect,
    name: 'ForgotComplete',
    component: ForgotComplete,
    meta: forgotCompleteMeta
  },

  {
    path: '/reset/complete',
    name: 'ResetComplete',
    component: ResetComplete,
    meta: resetCompleteMeta
  }
];

export default authRoutes;
