
import { defineComponent } from 'vue';
import { KPIEsimateInterface } from '@/store/modules/contribution-analysis';
import HorizontalBar from '@/components/contribution-analysis/ContributionHorizontalBar.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

interface DataColumnOption {
  value: number | null;
  color: string;
  color_intensity: string;
}
interface DataColumn {
  has_option: DataColumnOption | null;
  no_option: DataColumnOption;
}

export default defineComponent({
  name: 'KpiEffectResult',
  props: ['taskId'],

  components: {
    HorizontalBar,
  },
  beforeMount() {
    this.getKPIEffectEstimate(this.taskId)
      .then((data: Array<KPIEsimateInterface>) => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch((err) => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },

  data() {
    return { data: [] as Array<KPIEsimateInterface>, errorMessage: '' };
  },

  methods: {
    ...mapActions(['getKPIEffectEstimate']),
    getLabels(column: DataColumn) {
      return [
        column.no_option
          ? column.no_option.value === null
            ? ''
            : Number(column?.no_option?.value).toFixed(3)
          : '',
        column.has_option
          ? column.has_option.value === null
            ? ''
            : Number(column?.has_option?.value).toFixed(3)
          : '',
      ];
    },
    getMaxBarValue: function (data: Array<KPIEsimateInterface>): number {
      let maxValue = 0;

      if (!data) return 1;
      data.forEach((item: KPIEsimateInterface) => {
        const noOptionValue = Math.abs(Number(item.no_option?.value));
        const hasOptionValue = Math.abs(Number(item.has_option?.value));
        if (maxValue < noOptionValue) {
          maxValue = noOptionValue;
        }
        if (maxValue < hasOptionValue) {
          maxValue = hasOptionValue;
        }
      });
      return maxValue;
    },
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.data = [];
        this.getKPIEffectEstimate(this.taskId)
          .then((data: Array<KPIEsimateInterface>) => {
            this.errorMessage = '';
            this.data = data;
          })
          .catch((err) => {
            this.data = [];
            this.errorMessage = err?.errors ? err.errors[0].error : '';
          });
      }
    },
  },
});
