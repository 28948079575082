import { object, string, number, array } from 'yup';

const patternBSchema = object({
  modelName: string().required(() => 'errors.required-field'),
  targetKpi: number()
    .required(() => 'errors.required-field')
    .typeError(() => 'errors.must-be-number')
    .max(999999999999, () => 'errors.invalid-digit-length'),

  optimizeVariableList: array().when('modelName', {
    is: String,
    then: array()
      .min(1, () => 'errors.required-field')
      .required(() => 'errors.required-field')
  })
});

export default patternBSchema;
