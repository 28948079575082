<template>
  <div class="col">
    <div class="sub_title">
      <h4>
        {{ $t('pages.te.ar.section2.title') }}
      </h4>
    </div>
    <p class="text-danger">{{ errorMessage }}</p>

    <vertical-bar
      :data="barData"
      :labels="barLabels"
      height="450"
    ></vertical-bar>
  </div>
</template>
<script lang="ts">
import VerticalBar from '@/components/ui/VerticalBar.vue';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('te');

interface ScoreFrequency {
  score: string;
  frequency: string;
}
export default defineComponent({
  name: 'ScoreFrequency',
  props: ['taskId'],

  components: {
    VerticalBar
  },
  beforeMount() {
    this.getScoreFrequency(this.taskId)
      .then((data: Array<ScoreFrequency>) => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  data() {
    return {
      data: [] as Array<ScoreFrequency>,
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions(['getScoreFrequency'])
  },
  computed: {
    barData() {
      const tempData = [] as Array<number>;
      this.data.forEach((item: ScoreFrequency) => {
        tempData.push(Number(item.frequency));
      });

      return tempData;
    },
    barLabels() {
      const tempLabels = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10'
      ] as Array<string>;
      return tempLabels;
    }
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.getScoreFrequency(newtaskId)
          .then((data: Array<ScoreFrequency>) => {
            this.$data.data = data;
          })
          .catch(() => {
            this.$data.data = [];
          });
      }
    }
  }
});
</script>
