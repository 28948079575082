
import { defineComponent, markRaw } from 'vue';
import VizTabs from '@/components/ui/VizTabs.vue';
import ImportHistory from './ImportHistory.vue';
import AnalysisHistory from './AnalysisHistory.vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'History',
  beforeMount() {
    this.getImportHistory({ page: 1, perPage: 10 })
      .then(() => {
        this.errorMessage.import = '';
      })
      .catch(err => {
        if (err?.errors && err.errors[0].error) {
          this.errorMessage.import = err.errors[0].error;
        }
      });
    this.getAnalysisHistory({ page: 1, perPage: 10 })
      .then(() => {
        this.errorMessage.analysis = '';
      })
      .catch(err => {
        if (err?.errors && err.errors[0].error) {
          this.errorMessage.analysis = err.errors[0].error;
        }
      });
  },
  data() {
    const tabs = markRaw([
      {
        tabKey: 'import',
        title: 'Import History',
        tsKey: 'pages.ih.tab1.title'
      },
      {
        tabKey: 'analysis',
        title: 'Analysis History',
        tsKey: 'pages.ih.tab2.title'
      }
    ]);
    return {
      title: '',
      author: '',
      tabs,
      currentTab: tabs[0],
      errorMessage: {
        import: '',
        analysis: ''
      }
    };
  },
  components: {
    VizTabs,
    ImportHistory,
    AnalysisHistory
  },
  computed: {
    posts() {
      return [];
    },
    count() {
      return 0;
    }
  },
  methods: {
    ...mapActions(['getAnalysisHistory', 'getImportHistory']),
    handleTabChange(tab: any) {
      this.currentTab = tab;
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.errorMessage = {
          import: '',
          analysis: ''
        };
      }
    }
  }
});
