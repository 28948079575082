import CreateGroup from '@/pages/ManagerPostSignup/CreateGroup.vue';
import InviteMembers from '@/pages/ManagerPostSignup/InviteMembers.vue';
import InviteMembersComplete from '@/pages/ManagerPostSignup/InvitationComplete.vue';
import AddGroup from '@/pages/AddGroup/index.vue';
import { contractFunctions } from '@/constants/role';

const createGroupMeta = {
  pageKey: 'create-group',
  userFunction: contractFunctions.GROUP_CREATION,
  authentication: false
};

const addGroupMeta = {
  pageKey: 'add-group',
  userFunction: contractFunctions.GROUP_CREATION,
  authentication: true
};

const inviteMembersMeta = {
  pageKey: 'invite-members'
  // userFunction: contractFunctions.GROUP_CREATION,
  // authentication: true
};
const inviteMembersCompleteMeta = {
  pageKey: 'invite-members-c'
};

const managerRoutes = [
  {
    path: '/add-group',
    alias: 'add-group',
    name: 'AddGroup',
    component: AddGroup,
    meta: addGroupMeta
  },
  {
    path: '/create-group',
    alias: 'create-group',
    name: 'CreateGroup',
    component: CreateGroup,
    meta: createGroupMeta,
    props: true
  },
  {
    path: '/invite-members',
    alias: 'invite-members',
    name: 'InviteMembers',
    component: InviteMembers,
    meta: inviteMembersMeta,
    props: true
  },
  {
    path: '/invite-members/complete',
    alias: 'invite-members-complete',
    name: 'InviteMembersComplete',
    component: InviteMembersComplete,
    meta: inviteMembersCompleteMeta,
    props: true
  }
];

export default managerRoutes;
