<template>
  <div>
    <steps-track
      v-bind:steps="steps"
      v-bind:currentStep="state.currentStep"
    ></steps-track>
    <div class="analyzing mt-5">
      <div class="row">
        <p class="text-danger pb-4">{{ $t(state.errorMessage) }}</p>

        <div class="col-md-4">
          <ul class="process">
            <li class="mt-4">
              <div
                class="
                  progress-data
                  mb-2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <!-- <div class="time text-primary">
              {{ $t('pages.ca.analysisobserve.secondsleft', { seconds: 32 }) }}
            </div> -->
              </div>
              <div class="position-relative">
                <div class="progress" style="height: 5px">
                  <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    :aria-valuenow="analysisPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="`width: ${analysisPercentage}%`"
                  ></div>
                </div>
              </div>
            </li>
            <li
              :class="
                `mt-4 ${isStateComplete(states.LOADING) &&
                  'sucess'} ${hasStateError(states.LOADING) && 'error'}`
              "
            >
              {{ $t('pages.ms.so.loading') }}
            </li>
            <li
              :class="
                `mt-4 ${isStateComplete(states.PREPROCESSING) &&
                  'sucess'} ${hasStateError(states.PREPROCESSING) && 'error'}`
              "
            >
              {{ $t('pages.ms.so.preprocessing') }}
            </li>

            <li
              v-if="currentPattern === 'patternB'"
              :class="
                `mt-4 ${isStateComplete(states.OPTIMIZING) &&
                  'sucess'}  ${hasStateError(states.OPTIMIZING) && 'error'}`
              "
            >
              {{ $t('pages.ms.so.optimizing') }}
            </li>

            <li
              v-if="currentPattern === 'patternA'"
              :class="
                `mt-4 ${isStateComplete(states.SIMULATING) &&
                  'sucess'} ${hasStateError(states.SIMULATING) && 'error'}`
              "
            >
              {{ $t('pages.ms.so.simulating') }}
            </li>

            <li
              :class="
                `mt-4 ${isStateComplete(states.POSTPROCESSING) &&
                  'sucess'} ${hasStateError(states.POSTPROCESSING) && 'error'}`
              "
            >
              {{ $t('pages.ms.so.postprocessing') }}
            </li>
            <li
              :class="
                `mt-4 ${isStateComplete(states.FINISH_OPTIMIZATION) &&
                  'sucess'} ${hasStateError(states.FINISH_OPTIMIZATION) &&
                  'error'}`
              "
            >
              {{ $t('pages.ms.so.finish') }}
            </li>
          </ul>
        </div>
        <div class="col-md-8 mb-3">
          <div class="position-relative terminal">
            <div class="text">
              <p v-for="log in logs" :key="log">{{ log }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button d-block">
      <!-- <viz-button
        :disabled="!activateProceed"
        @click.prevent="handleGoBack"
        type="button"
        class="btn btn-default text-muted"
      >
        {{ $t('common.buttonlabels.goback') }}
      </viz-button> -->
      <viz-button
        :disabled="!activateProceed"
        @click.prevent="handleProceed"
        type="button"
        class="btn btn-primary text-white fw-bold"
      >
        {{ $t('common.buttonlabels.nextstep') }}
      </viz-button>
      <viz-button
        type="button"
        class="btn btn-secondary rounded-pill ms-3 btn-abort"
        data-bs-toggle="modal"
        data-bs-target="#abort-modal"
        v-if="abortActive"
      >
        分析を中止する
      </viz-button>
      <!-- Modal -->
      <div
        class="modal fade"
        id="abort-modal"
        tabindex="-1"
        aria-labelledby="abort"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">この進行を中止してもよろしいですか？</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-primary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
              >
                キャンセル
              </button>

              <viz-button
                type="button"
                class="btn btn-secondary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
                @click="terminateProces"
                v-if="abortActive"
              >
                分析を中止する
              </viz-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';
import { SimulationStateInterface } from '@/store/modules/measure-simulation';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('ms');

export default defineComponent({
  beforeMount() {
    const { pattern, taskId } = this.$route.params;
    if (!pattern || !taskId) {
      this.$router.replace({ path: '/simulation' });
    }
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
    window.addEventListener('beforeunload', this.handleClose);
  },
  props: {
    steps: Array
  },
  components: {
    StepsTrack,
    VizButton
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);

    this.setExitAllowed(true);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.activateProceed && !this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        next();
      }
    } else {
      next();
    }
  },
  beforeUnmount() {
    clearInterval(this.state.analysisPoll);
  },
  setup() {
    const state = reactive({
      currentStep: 2,
      analysisPoll: 0,
      errorMessage: '',
      simulationState: {} as SimulationStateInterface
    });

    const states = markRaw({
      LOADING: 'loading_data',
      PREPROCESSING: 'preprocessing',
      OPTIMIZING: 'optimizing',
      SIMULATING: 'simulating',
      POSTPROCESSING: 'postprocessing',
      FINISH_OPTIMIZATION: 'finish optimization process'
    });
    return {
      states,
      state,
      abortActive: true
    };
  },
  computed: {
    ...mapGetters(['isExitAllowed']),
    simulationError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.state.simulationState.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    currentPattern: function(): string {
      const pattern = this.$route.params?.pattern as string;
      if (pattern) return pattern;
      return '';
    },
    activateProceed: function() {
      if (
        Array.isArray(this.state.simulationState?.states) &&
        this.state.simulationState?.states.includes(
          this.states.FINISH_OPTIMIZATION
        )
      ) {
        return true;
      }
      return false;
    },

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.state.simulationState?.logs)) {
        return logs;
      }
      this.state.simulationState.logs.forEach((item: Array<string>) => {
        logs = [...item, ...logs];
      });
      return logs;
    },

    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.state.simulationState?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },

    analysisPercentage: function(): number {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions(['getSimulationState', 'termiateProcess']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasStateError(state: string) {
      return this.simulationError.failedSteps?.includes(state) ? true : false;
    },
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';

      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    handleGoBack() {
      this.$router.push('/simulation');
    },

    handleProceed() {
      const { pattern, taskId, modelName } = this.$route.params;
      if (pattern == 'patternA') {
        this.$router.push({
          path: '/simulation/patterna-report',
          name: 'EstimatedSales',
          params: {
            pattern,
            taskId,
            modelName
          }
        });
      }
      if (pattern == 'patternB') {
        this.$router.push({
          path: '/simulation/patternb-report',
          name: 'TargetSales',
          params: {
            pattern,
            taskId,
            modelName
          }
        });
      }
    },
    pullData() {
      const { taskId, pattern } = this.$route.params;

      this.state.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_OPTIMIZATION)) {
          this.abortActive = false;
          clearInterval(this.state.analysisPoll);
        }
        this.getSimulationState({ pattern, taskId })
          .then((data: SimulationStateInterface) => {
            this.state.errorMessage = '';
            this.state.simulationState = data;
            if (this.simulationError.status) {
              this.abortActive = false;
              this.state.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            if (err && Array.isArray(err.errors)) {
              this.abortActive = false;
              this.state.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },

    isStateComplete(state: string) {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },

    terminateProces() {
      this.termiateProcess();
      this.setExitAllowed(true);
      this.$router.push('/simulation');
    }
  },
  watch: {
    simulationError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.state.errorMessage = 'errors.analysis-failed';
        clearInterval(this.state.analysisPoll);
      }
    }
  }
});
</script>

<style scoped lang="scss">
.terminal {
  max-width: 540px;
  min-height: 400px;
  max-height: 400px;
  background-color: #333333;
  padding: 10px 20px;
  overflow-y: auto;
  border-radius: 7px;
  .text {
    color: #fff;
    font-family: Courier;
    font-weight: 700;
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
