
import { defineComponent } from 'vue';
import AppSidebarVue from '@/components/layout/AppSidebar.vue';
import AppHeaderVue from '@/components/layout/AppHeader.vue';
import VizToast from '@/components/ui/VizToast.vue';

import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppSidebar: AppSidebarVue,
    VizToast,

    AppHeader: AppHeaderVue
  },
  computed: {
    ...mapGetters(['toast'])
  },
  data() {
    return {
      isSidebarVisible: true,
      langs: ['en', 'ja']
    };
  },
  beforeMount() {
    this.loadUserAccountDetails();
  },
  methods: {
    ...mapActions([
      'fetchUserGroups',
      'fetchContractFunctions',
      'loadUserAccountDetails',
      'changeUserGroup'
    ]),

    sidebarToggler() {
      this.isSidebarVisible = !this.isSidebarVisible;
    }
  }
});
