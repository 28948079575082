<template>
  <div class="signup max__width--form bg-white p-4">
    <form
      action="post"
      class="signup__form"
      @submit.prevent="onSubmit"
      novalidate
    >
      <figure class="mb-3">
        <img :src="icons.logo" alt="" style="padding: 0 10px;height: 110px;" />
      </figure>
      <legend>
        {{
          state.role === roles.USER
            ? $t('pages.signup.title')
            : $t('pages.signup.manager-title')
        }}
      </legend>

      <p class="text-danger mb-4" v-if="state.errorMessage">
        {{ state.errorMessage }}
      </p>
      <div class="input-groups">
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          :placeholder="$t('labels.email')"
        />
        <label for="signup-email">{{ $t('labels.email') }}</label>
        <span class="text-danger">{{
          errors.email ? $t(errors.email) : ''
        }}</span>
      </div>

      <div class="input-groups">
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          :placeholder="$t('labels.password')"
        />

        <label for="signup-password">{{ $t('labels.password') }}</label>
        <span class="text-danger">{{
          errors.password ? $t(errors.password) : ''
        }}</span>
      </div>

      <div class="input-groups">
        <input
          type="password"
          class="form-control"
          id="confirmPassword"
          v-model="confirmPassword"
          :placeholder="$t('labels.confirm-password')"
        />

        <label for="signup-confirm_password">{{
          $t('labels.confirm-password')
        }}</label>

        <span class="text-danger">{{
          errors.confirmPassword ? $t(errors.confirmPassword) : ''
        }}</span>
      </div>

      <div class="d-block mt-3 button">
        <viz-button
          type="submit"
          class="btn btn-primary text-white px-4"
          :loading="state.isLoading"
        >
          {{
            state.role === roles.USER
              ? $t('pages.signup.btnTitle')
              : $t('pages.signup.manager-btn')
          }}
        </viz-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';

import { useStore, mapActions } from 'vuex';
import { useField, useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
import validationSchema from '@/validator/signup';
import { userRoles } from '@/constants/role';
import { logo } from '@/utils/icons';

interface ErrorList {
  error?: string;
  email?: string;
  password?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}
export default defineComponent({
  components: {
    VizButton
  },
  beforeMount() {
    const token = this.$route.query?.token;
    const role = this.$route.query?.role;
    if (!token || !role) {
      this.$router.push('/login');
    }

    this.state.role = role ? Number(role) : 0;
  },
  mounted() {
    const { token, role } = this.$route.query;
    this.preloadEmail({ token, role: Number(role) })
      .then(res => {
        const { email } = res?.data;
        this.setFieldValue('email', email);
      })
      .catch(err => {
        err.errors.forEach((item: any) => {
          let error = '';
          if (item.error) {
            error = item.error;
          }
          if (item.token) {
            error = item.token;
          }
          if (item.role) {
            error = item.role;
          }
          this.$router.push({
            name: 'ErrorDisplayPage',
            params: { errorMessage: error }
          });
          this.state.errorMessage = error;
        });
      });
  },
  methods: {
    ...mapActions(['preloadEmail'])
  },
  setup() {
    const state = reactive({
      errorMessage: '',
      role: 0,
      isLoading: false
    });
    const roles = markRaw(userRoles);
    const icons = markRaw({
      logo: logo
    });
    const { errors, handleSubmit, meta, setFieldValue } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const onSubmit = handleSubmit((values, { setFieldError }) => {
      state.isLoading = true;
      const token = route.query?.token as string;

      if (!token || !state.role) {
        router.push('/login');
      }

      store
        .dispatch('register', { ...values, token, role: state.role })
        .then(res => {
          state.isLoading = false;

          state.errorMessage = '';
          const {
            email,
            role,
            group_status,
            user_invitation_status
          } = res.data;

          if (role === userRoles.USER) {
            /**Redirects to Signup Complete for user */
            router.push('/signup/complete');
          }

          if (role === userRoles.MANAGER) {
            /**Redirects to Group Creation for manager*/

            if (group_status && user_invitation_status) {
              router.push('/signup/complete');
            } else {
              if (!group_status) {
                return router.push({
                  name: 'CreateGroup',
                  params: { token, email }
                });
              }
              if (!user_invitation_status) {
                router.push('/signup/complete');
              }
            }
          }
        })
        .catch((err: ApiErrorInterface) => {
          const fieldKeys = {
            email: 'email',
            password: 'password'
          };
          state.isLoading = false;
          if (Array.isArray(err.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.errors.forEach((item: any) => {
              if (item.error) {
                state.errorMessage = item.error;
              }
              if (item.token) {
                state.errorMessage = item.token;
              }
              if (item.role) {
                state.errorMessage = item.role;
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Object.values(fieldKeys).forEach((fieldName: any) => {
                if (item[fieldName]) {
                  setFieldError(fieldName, item[fieldName]);
                }
              });
            });
          }
        });
    });

    const { value: email } = useField('email');
    const { value: password } = useField('password');
    const { value: confirmPassword } = useField('confirmPassword');

    return {
      roles,
      email,
      password,
      confirmPassword,
      onSubmit,
      errors,
      state,
      meta,
      icons,
      setFieldValue
    };
  }
});
</script>

<style scoped></style>
