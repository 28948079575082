/* eslint-disable @typescript-eslint/camelcase */
import { http } from '@/utils/http';

interface User {
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
  token: string;
}

const LOGIN_URL = '/api/v1/account/login';
const REFRESH_TOKEN_URL = '/api/v1/account/refresh_token';
const REGISTER_URL = '/api/v1/account/register';
const LOGOUT_URL = '/api/v1/account/logout';
const FORGOT_PASSWORD_URL = '/api/v1/account/forgot_password';
const RESET_PASSWORD_URL = '/api/v1/account/reset_password';
const USER_GROUP_URL = '/api/v1/fetch_user_groups';
const CONTRACT_FUNCTION_URL = '/api/v1/fetch_contract_functions';
const ACCOUNT_EMAIL_CHANGE_URL = '/api/v1/account/change_email';
const ACCOUNT_DETAILS_URL = '/api/v1/fetch_user_email';
const STORAGE_USAGE_URL = '/api/v1/data_capacity';
const PRELOAD_EMAIL_URL = '/api/v1/preload_email';
const CONFIRM_CHANGE_EMAIL_URL = '/api/v1/account/confirm_change_email';
interface LOGINCREDENTIALS {
  email: string;
  password: string;
}
export interface AccountDetailsInterface {
  email: string;
}

export const login = ({ email, password }: LOGINCREDENTIALS) => {
  return http.post(LOGIN_URL, JSON.stringify({ email, password }));
};

export const fetchUserGroups = () => {
  return http.get(USER_GROUP_URL);
};

export const fetchContractFunctions = (groupId: number) => {
  return http.get(CONTRACT_FUNCTION_URL + `?group=${groupId}`);
};

export const refreshToken = (token: string) => {
  return http.post(REFRESH_TOKEN_URL, JSON.stringify({ refresh_token: token }));
};

export const updateAccountDetails = ({ email }: { email: string }) => {
  return http.put(
    ACCOUNT_EMAIL_CHANGE_URL,
    JSON.stringify({ email_address: email })
  );
};
export const loadUserAccountDetails = () => {
  return http.get(ACCOUNT_DETAILS_URL);
};

export const register = ({
  email,
  password,
  confirmPassword,
  role,
  token
}: User) => {
  const data = {
    email,
    password,
    confirm_password: confirmPassword,
    role,
    token
  };

  return http.post(REGISTER_URL, JSON.stringify(data));
};

export const logout = () => {
  return http.post(LOGOUT_URL, JSON.stringify({}));
};
export interface ResetPasswordCredentials {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
export const resetPassword = ({
  oldPassword,
  newPassword,
  confirmNewPassword
}: ResetPasswordCredentials) => {
  const data = {
    old_password: oldPassword,
    new_password: newPassword,
    confirm_password: confirmNewPassword
  };
  return http.put(RESET_PASSWORD_URL, JSON.stringify(data));
};
export const forgotPassword = (email: string) => {
  return http.post(FORGOT_PASSWORD_URL, JSON.stringify({ email }));
};
export const getStorageUsage = (groupId: number) => {
  return http.post(STORAGE_USAGE_URL, JSON.stringify({ group_id: groupId }));
};
export const preloadEmail = ({
  token,
  role
}: {
  token: string;
  role: number;
}) => {
  return http.post(PRELOAD_EMAIL_URL, JSON.stringify({ token, role }));
};

export const confirmChangeEmail = (token: string) => {
  return http.post(CONFIRM_CHANGE_EMAIL_URL, JSON.stringify({ token }));
};

export default {
  login,
  updateAccountDetails,
  loadUserAccountDetails,
  refreshToken,
  register,
  logout,
  forgotPassword,
  resetPassword,
  fetchUserGroups,
  fetchContractFunctions,
  getStorageUsage,
  preloadEmail,
  confirmChangeEmail
};
