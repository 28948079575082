import store from '@/store';

/**
 *
 * Redirects to top page if user is logged in
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default async function AppRedirect(to: any, from: any, next: any) {
  const isAuthenticated = await store.getters.isLoggedIn;
  if (isAuthenticated) {
    return next({ name: 'ContributionEnterData' });
  } else return next();
}
