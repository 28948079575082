
import { defineComponent, markRaw } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import {
  logo,
  caSmallIcon,
  tuneIcon,
  peopleIcon,
  settingIcon,
  importHistoryIcon
} from '@/utils/icons';

import { contractFunctions, userRoles } from '@/constants/role';
import { UserGroup } from '@/store/modules/auth';

export default defineComponent({
  name: 'Sidebar',
  props: {
    isSidebarVisible: Boolean
  },
  beforeMount() {
    this.errorMessage = '';
    if (this.userGroup?.id) {
      this.getStorageUsage(this.userGroup?.id).catch(err => {
        this.errorMessage = Array.isArray(err?.errors)
          ? err.errors[0].error
          : '';
      });
    }
  },

  data() {
    const icons = markRaw({
      logo
    });
    return {
      errorMessage: '',
      activeLink: '/dashboard',
      routes: [
        {
          path: '/analysis',
          icon: caSmallIcon,
          title: 'navigations.ca',
          userFunction: contractFunctions.CONTRIBUTION_ANALYSIS,
          enableManagerAccessControl: true
        },
        {
          path: '/simulation',
          icon: tuneIcon,
          title: 'navigations.ms',
          userFunction: contractFunctions.MEASURE_SIMULATION,
          enableManagerAccessControl: true
        },
        {
          path: '/extraction',
          icon: peopleIcon,
          title: 'navigations.te',
          userFunction: contractFunctions.TARGET_EXTRACTION,
          enableManagerAccessControl: true
        },

        {
          path: '/history',
          icon: importHistoryIcon,
          title: 'navigations.ih'
          // userFunction: contractFunctions.HISTORY
        },
        {
          path: '/settings',
          icon: settingIcon,
          title: 'navigations.s',
          userFunction: contractFunctions.SETTINGS
        }
      ],
      icons
    };
  },
  computed: {
    ...mapGetters(['user', 'userGroup', 'storageUsage']),
    usage: function(): number | string {
      const percent =
        (this.storageUsage?.used / this.storageUsage?.total) * 100;
      return isNaN(percent) ? 0 : percent.toFixed(2);
    }
  },
  methods: {
    ...mapActions(['getStorageUsage']),
    isFunctionAvailable({
      userFunction,
      enableManagerAccessControl
    }: {
      userFunction: string;
      enableManagerAccessControl?: boolean;
    }) {
      if (!userFunction) {
        return true;
      }
      if (this.user?.user_role == userRoles.MANAGER) {
        if (!enableManagerAccessControl) {
          return true;
        } else {
          return this.user?.user_functions?.includes(userFunction);
        }
      } else {
        return this.user?.user_functions?.includes(userFunction);
      }
    },
    changeActiveStatus(url: string) {
      this.activeLink = url;
    }
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.errorMessage = '';
        this.getStorageUsage(newVal.id).catch(err => {
          this.errorMessage = Array.isArray(err?.errors)
            ? err.errors[0].error
            : '';
        });
      }
    }
  }
});
