<template>
  <div class="main px-60">
    <transition name="fade">
      <register-data
        v-if="currentStep === 1"
        v-bind:steps="steps"
        v-bind:currentStep="currentStep"
        @proceed="loadAnalysis"
      ></register-data>
    </transition>

    <transition name="fade">
      <target-analysis-loader
        v-if="currentStep === 2 && !isAnalysed"
        v-bind:isAnalysed="isAnalysed"
        v-bind:steps="steps"
        v-bind:currentStep="currentStep"
        @saveAnalysis="saveAnalysis"
        @goBack="goBack"
      ></target-analysis-loader>
    </transition>

    <transition name="fade">
      <target-analysis-report
        v-if="currentStep === 2 && isAnalysed"
        v-bind:isAnalysed="isAnalysed"
        v-bind:steps="steps"
        v-bind:currentStep="currentStep"
        @goBack="goBack"
      ></target-analysis-report>
    </transition>
    <execution-history />
    <!-- tree-structure-modal -->
    <div
      class="modal fade"
      id="tree-structure-modal"
      tabindex="-1"
      aria-labelledby="tree-structure-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-fullscreen modal-dialog-centered h-auto">
        <div class="modal-content container">
          <div class="modal-header">
            <h5
              class="modal-title text-primary"
              id="tree-structure-modal-Label"
            >
              特徴量ごとの重要度を木構造で表示​
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body image-viewer-modal">
            <ImageViewer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RegisterData from './RegisterData.vue';
import TargetAnalysisLoader from '@/pages/TargetExtraction/TargetAnalysisLoader.vue';
import TargetAnalysisReport from '@/pages/TargetExtraction/TargetAnalysisReport.vue';
import { createNamespacedHelpers } from 'vuex';
import ExecutionHistory from '@/components/common/ExecutionHistory.vue';
import ImageViewer from '@/components/target-extraction/ImageViewer.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'TargetExtraction',

  mounted() {
    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.fileUploadStatus('aborted');
        this.resetTargetState();
        next();
      }
    } else {
      next();
    }
  },

  data() {
    return {
      currentStep: 1,
      isAnalysed: false,
      steps: [
        { title: 'pages.te.steps.1', number: 1 },
        { title: 'pages.te.steps.2', number: 2 }
      ]
    };
  },
  computed: {
    ...mapGetters(['isExitAllowed'])
  },
  methods: {
    ...mapActions(['fileUploadStatus', 'resetTargetState']),
    goBack() {
      this.currentStep = 1;
      this.isAnalysed = false;
    },
    loadAnalysis() {
      this.currentStep = 2;
      this.isAnalysed = false;
    },
    saveAnalysis() {
      this.isAnalysed = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';
      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    }
  },
  components: {
    RegisterData,
    TargetAnalysisLoader,
    TargetAnalysisReport,
    ExecutionHistory,
    ImageViewer
  }
});
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
