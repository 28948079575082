<template>
  <main>
    <ul class="buttons nav nav-tabs">
      <li
        class="nav-item"
        role="presentation"
        v-for="(tab, index) in tabs"
        :key="tab.tabKey"
        @click="() => handleChange(tab)"
      >
        <a
          :class="
            `nav-link btn btn-default ${
              tab.tabKey === currentTab.tabKey ? 'active' : ''
            }`
          "
          :id="`${tab.tabKey}-tab`"
          data-bs-toggle="tab"
          :href="`#${tab.tabKey}`"
          role="tab"
          :aria-controls="`${tab.tabKey}`"
          :aria-selected="index"
        >
          {{ $t(tab.tsKey) }}
        </a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="
          `tab-pane fade show  ${
            currentTab && tab.tabKey === currentTab.tabKey ? ' active' : ''
          }`
        "
        :id="`${tab.tabKey}`"
        role="tabpanel"
        :aria-labelledby="`${tab.tabKey}-tab`"
      >
        <!--
        NOTE: slot for including tabs
        -->

        <slot
          v-if="currentTab && tab.tabKey == currentTab.tabKey"
          :name="`${tab.tabKey}`"
        />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VizTabs',
  emits: ['onTabChange'],

  props: ['tabs', 'currentTab'],
  setup(props, { emit }) {
    const handleChange = (tab: any) => {
      emit('onTabChange', tab);
    };
    return { handleChange };
  }
});
</script>

<style lang="scss" scoped></style>
