<template>
  <!-- add group modal with input -->

  <div class="modal-content p-3">
    <div class="modal-header border-0">
      <h5 class="modal-title" id="addGroupModelInput">
        {{
          $t('pages.settings.groups.add-member.title', {
            group: groupName
          })
        }}
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="$emit('close')"
      ></button>
    </div>
    <div class="modal-body">
      <form action="#" @submit.prevent="() => {}" novalidate>
        <viz-multiple-email-input
          :excludables="users"
          type="email"
          :value="members"
          @change="handleChange"
          @onError="handleError"
        >
        </viz-multiple-email-input>
        <small class="text-muted my-2">{{
          $t('labels.multiple-input-guide')
        }}</small>
        <p class="text-danger">
          {{ errors && errors.members ? $t(errors.members) : '' }}
        </p>
      </form>
    </div>
    <div class="modal-footer justify-content-around border-0">
      <button
        type="button"
        id="close"
        @click="$emit('close')"
        class="btn btn-default text-muted"
        data-bs-dismiss="modal"
      >
        {{ $t('labels.cancel') }}
      </button>
      <viz-button
        type="button"
        :disabled="!meta.valid"
        :loading="state.isLoading"
        @click="onSubmit"
        class="btn btn-primary text-white"
      >
        {{ $t('pages.settings.groups.add-member.send') }}
      </viz-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import VizMultipleEmailInput from '@/components/ui/VizMultipleEmailInput.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { array, object } from 'yup';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';

export default defineComponent({
  props: ['id', 'groupName', 'users'],
  emits: ['close'],
  components: {
    VizMultipleEmailInput,
    VizButton
  },

  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      isLoading: false,
      closeModal: false
    });

    const validationSchema = object({
      members: array()
        .min(1, () => 'errors.required-field')
        .required(() => 'errors.required-field')
    });
    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setFieldValue
    } = useForm({
      validationSchema
    });

    const { value: members } = useField('members');

    const handleChange = (data: Array<string>) => {
      setFieldValue('members', data);
    };
    const handleError = (error: string) => {
      if (error) setFieldError('members', `errors.${error}`);
    };
    const onSubmit = handleSubmit(values => {
      if (Array.isArray(values.members) && values.members.length > 10) {
        setFieldError('members', 'errors.max-invitation-limit');
        return;
      }

      state.isLoading = true;

      store
        .dispatch('addGroupMembers', { groupId: props.id, ...values })
        .then(() => {
          state.isLoading = false;
          store.dispatch('addToast', {
            message: 'messages.invitation-sent',
            data: { receivers: values?.members?.length }
          });

          store.dispatch('loadGroupAndMembers', {
            page: 1,
            perPage: 10,
            groupId: props.id
          });

          document.getElementById('close')?.click();
          emit('close');
        })
        .catch(err => {
          const errs = err?.errors;
          state.isLoading = false;

          if (errs) {
            errs.forEach((item: any) => {
              if (item.error) {
                setFieldError('members', item.error);
              }
              if (item.members) {
                setFieldError('members', item.members);
              }
            });
          }
        });
    });
    return {
      onSubmit,
      handleChange,
      handleError,
      members,
      errors,
      meta,
      state,
      setFieldValue
    };
  }
});
</script>

<style scoped></style>
