
import { defineComponent, markRaw, reactive } from 'vue';
import { createNamespacedHelpers } from 'vuex';

import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';
// import VolumePrediction from '@/components/measure-simulation/VolumePrediction.vue';
import KpiForecast from '@/components/measure-simulation/KpiForecast.vue';

import { dropdownIcon } from '@/utils/icons';
import simulationAPI, {
  StringKeyValInterface,
  NumberKeyValInterface
} from '@/api/measure-simulation';
import { useForm, useField } from 'vee-validate';
import { object, string } from 'yup';
import { stringHelpers } from '@/utils/helpers';

const { mapActions } = createNamespacedHelpers('ms');

export default defineComponent({
  name: 'EstimatedSales',
  props: { steps: Array },
  beforeMount() {
    const { pattern, taskId } = this.$route.params;

    if (!pattern || !taskId) {
      this.$router.replace({ path: '/simulation' });
    }

    this.getMediaVolumeReport(Number(taskId));
    this.getTotalSales(Number(taskId));
  },

  setup() {
    const initialValues = {
      targetKpi: '0'
    };
    const validationSchema = object({
      targetKpi: string()
        .required('errors.required-field')
        .matches(/^[0-9]+(,[0-9]+)*$/, () => 'errors.must-be-number')
    });

    const { errors, setValues, handleSubmit, setFieldError } = useForm({
      initialValues,
      validationSchema
    });
    const { value: targetKpi } = useField('targetKpi');

    const icons = markRaw({
      dropdownIcon
    });
    const state = reactive({
      totalSales: 0,
      targetValues: {} as NumberKeyValInterface,
      errorMessage: '',
      targetSalesError: '',
      targetValuesError: '',
      errors: {} as StringKeyValInterface,
      isLoading: false,
      isReoptimizeLoading: false
    });

    return {
      currentStep: 2,
      icons,
      state,
      setValues,
      errors,
      targetKpi,
      handleSubmit,
      stringHelpers,
      setFieldError
    };
  },
  components: {
    StepsTrack,
    VizButton,
    // VolumePrediction,
    KpiForecast
  },
  computed: {
    targetKeys: function(): Array<string> {
      if (!this.state.targetValues) return [];
      return Object.keys(this.state.targetValues);
    }
  },
  methods: {
    ...mapActions(['runOptimizeModel', 'exportOptimizationReport']),
    handleExport: function() {
      this.state.isLoading = true;
      const { taskId } = this.$route.params;

      this.exportOptimizationReport(taskId)
        .then(() => {
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    },
    addCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.addCommas(target.value);
    },
    removeCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.removeCommas(target.value);
    },
    handleReoptimize() {
      if (Object.keys(this.errors).length > 0) return;
      this.state.isReoptimizeLoading = true;
      const { taskId, modelName, pattern } = this.$route.params;
      const targetKPINum = Number(
        stringHelpers.removeCommas(String(this.targetKpi))
      );

      this.runOptimizeModel({
        optimizeVariableList: Object.keys(this.state.targetValues),
        taskId,
        modelName,
        targetKpi: isNaN(targetKPINum) ? 0 : targetKPINum
      })
        .then(() => {
          this.state.isReoptimizeLoading = false;

          this.$router.push({
            path: '/simulate/observe',
            name: 'SimulationObserve',
            params: { taskId, modelName, pattern }
          });
        })
        .catch(err => {
          const errors = err.errors;
          if (Array.isArray(errors)) {
            errors.forEach(item => {
              if (item.error) {
                this.state.errorMessage = item.error;
              }
              if (item.target_kpi) {
                this.setFieldError('targetKpi', item.target_kpi);
              }
            });
          }
          this.state.isReoptimizeLoading = false;
        });
    },
    getTotalSales(taskId: number) {
      this.state.totalSales = 0;
      simulationAPI
        .getOptimizeTotalSalesReport(taskId)
        .then(res => {
          const results = res?.data?.results;
          const targetKpi = results
            ? Number(results[0]?.target_value).toFixed(0)
            : 0;
          this.setValues({
            targetKpi: stringHelpers.addCommas(String(targetKpi))
          });
          this.state.targetSalesError = '';
        })
        .catch(err => {
          this.setValues({ targetKpi: '0' });
          this.state.targetSalesError = err?.errors ? err.errors[0]?.error : '';
        });
    },
    getMediaVolumeReport(taskId: number) {
      this.state.targetValues = {};

      simulationAPI
        .getOptimizeMediaVolumeReport(taskId)
        .then(res => {
          const results = res.data?.results;
          let tempTarget = {};

          if (Array.isArray(results)) {
            results.forEach(item => {
              tempTarget = {
                ...tempTarget,
                [item.val]: isNaN(Number(item.budget))
                  ? item.budget
                  : Number(item.budget).toFixed(0)
              };
            });
          }
          this.state.targetValues = tempTarget;
          this.state.targetValuesError = '';
        })
        .catch(err => {
          this.state.targetValuesError = err?.errors
            ? err.errors[0]?.error
            : '';
        });
    }
  }
});
