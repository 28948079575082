import { object, string, boolean, array } from 'yup';

const kpiInputSchema = object({
  modelName: string().required(() => 'errors.required-field'),
  method: string().required(() => 'errors.required-field'),
  date: string().required(() => 'errors.required-field'),
  target: string().required(() => 'errors.required-field'),
  considerLag: boolean().required(() => 'errors.required-field'),
  seasonEffects: boolean().required(() => 'errors.required-field'),
  laglist: array().when('considerLag', {
    is: true,
    then: array()
      .min(1, () => 'errors.required-field')
      .required(() => 'errors.required-field')
  }),
  mediaName: string().when('seasonEffects', {
    is: true,
    then: string().required(() => 'errors.required-field')
  })
});

export default kpiInputSchema;
