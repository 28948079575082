import { http } from '@/utils/http';

const ANALYSIS_HISTORY_URL = '/api/v1/tasks_list';
const IMPORT_HISTORY_URL = '/api/v1/import_history';
const DELETE_ANALYSIS_HISTORY_URL = '/api/v1/execution_history/delete';
const DELETE_IMPORT_HISTORY_URL = '/api/v1/import_history/delete';
const DELETE_BULK_IMPORT_HISTORY_URL = '/api/v1/import_history/bulk_delete';
const DELETE_BULK_ANALYSIS_HISTORY_URL =
  '/api/v1/execution_history/bulk_delete';
const ABORT_CONTRIBUTION = '/api/v1/contribution/abort_analysis';
const ABORT_TARGET = '/api/v1/target_extraction/abort_analysis';
const ABORT_SIMULATION = '/api/v1/measure_simulation/simulate/abort_analysis';

export const getAnalysisHistory = ({
  groupId,
  page,
  perPage
}: {
  groupId: number;
  page: number;
  perPage?: number;
}) => {
  const query = `?page=${page}&perPage=${
    perPage ? perPage : 10
  }&group=${groupId}`;
  const requestURL = ANALYSIS_HISTORY_URL + query;
  return http.get(requestURL);
};

export const getImportHistory = ({
  groupId,
  page,
  perPage
}: {
  groupId: number;
  page: number;
  perPage?: number;
}) => {
  const query = `?page=${page}&perPage=${
    perPage ? perPage : 10
  }&group=${groupId}`;
  const requestURL = IMPORT_HISTORY_URL + query;
  return http.get(requestURL);
};

export const deleteImportHistory = ({
  taskId,
  feature
}: {
  taskId: number;
  feature: string;
}) => {
  return http.post(
    DELETE_IMPORT_HISTORY_URL,
    JSON.stringify({ task_id: taskId, feature })
  );
};

export const deleteMultipleImportHistory = (payload: object) => {
  return http.post(DELETE_BULK_IMPORT_HISTORY_URL, JSON.stringify(payload));
};
export const deleteMultipleAnalysisHistory = (payload: object) => {
  return http.post(DELETE_BULK_ANALYSIS_HISTORY_URL, JSON.stringify(payload));
};
export const deleteAnalysisHistory = ({
  taskId,
  feature
}: {
  taskId: number;
  feature: string;
}) => {
  return http.post(
    DELETE_ANALYSIS_HISTORY_URL,
    JSON.stringify({ task_id: taskId, feature })
  );
};

export const abortTask = ({
  taskId,
  feature
}: {
  taskId: number;
  feature: string;
}) => {
  const url =
    feature === 'contribution_analysis'
      ? ABORT_CONTRIBUTION
      : feature === 'target_extraction'
      ? ABORT_TARGET
      : ABORT_SIMULATION;

  return http.post(url, JSON.stringify({ task_id: taskId }));
};

export default {
  getAnalysisHistory,
  getImportHistory,
  deleteAnalysisHistory,
  deleteImportHistory,
  deleteMultipleImportHistory,
  deleteMultipleAnalysisHistory,
  abortTask
};
