<template>
  <div class="ms-2 custom-tooltip">
    <figure class="help-icon"></figure>
    <span class="custom-tooltiptext">
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.custom-tooltip {
  position: relative;
  top: 3px;
  left: 3px;
  display: inline-block;
  cursor: pointer;
  .help-icon {
    background: url('../../assets/icons/help_inactive.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
    transition: all ease 0.3s;
  }
  &:hover .help-icon {
    background: url('../../assets/icons/help_active.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  display: flex;
  min-width: 300px;
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.84);
  color: #fff;
  text-align: justify;
  border-radius: 6px;
  padding: 1rem;
  position: absolute;
  left: 110%;
  top: 0;
  z-index: 1;
  transition: visibility 0.3s;
}
.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}
</style>
