<template>
  <div class="main px-60">
    <main>
      <div class="account-setting">
        <!-- form of edit email address -->
        <div class="row">
          <form class="form-group__name" @submit.prevent="onSubmit" novalidate>
            <div class="col-lg-5 col-md-6">
              <legend class="mb-4">{{ $t('pages.account.title') }}</legend>
              <div class="input-groups">
                <input
                  type="email"
                  class="form-control"
                  id="mail_address"
                  v-model="email"
                  :readonly="!state.isEditable"
                  required
                />
                <label for="mail_address">{{ $t('labels.email') }}</label>
                <p class="text-danger pt-2">
                  {{ errors && errors.email ? $t(errors.email) : '' }}
                </p>
              </div>
              <div className="d-flex">
                <viz-button to="/reset" class="d-block text-primary mb-3"
                  >{{ $t('labels.change-password') }}
                </viz-button>
              </div>

              <viz-button
                v-if="!state.isEditable"
                type="button"
                @click="handleEdit"
                class="btn btn-primary rounded-pill text-white ms-2"
              >
                {{ $t('labels.edit') }}
              </viz-button>

              <div class="buttons" v-if="state.isEditable">
                <viz-button
                  @click="handleDiscard"
                  type="button"
                  class="btn btn-default text-muted"
                >
                  {{ $t('labels.discard-edits') }}
                </viz-button>
                <viz-button
                  type="submit"
                  :loading="state.isLoading"
                  class="btn btn-primary text-white rounded-pill"
                >
                  {{ $t('labels.save-edits') }}
                </viz-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { useForm, useField } from 'vee-validate';
import { mapActions, useStore } from 'vuex';
import { object, string } from 'yup';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton
  },
  beforeMount() {
    this.state.data = {};

    this.loadUserAccountDetails()
      .then(res => {
        this.state.errorMessage = '';
        const { email: email } = res?.data;
        this.state.data = { email };
        this.setValues({ email });
      })
      .catch(err => {
        this.state.data = {};
        this.state.errorMessage =
          err?.errors && err.errors[0].error ? err.errors[0].error : '';
      });
  },
  methods: {
    ...mapActions(['loadUserAccountDetails'])
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      isEditable: false,
      data: {},
      errorMessage: '',
      isLoading: false
    });
    const { errors, handleSubmit, setValues, setFieldError } = useForm({
      initialValues: { email: '' },
      validationSchema: object({
        email: string()
          .email(() => 'errors.invalid-email')
          .required(() => 'errors.required-field')
      })
    });
    const handleEdit = () => {
      state.isEditable = true;
    };
    const handleDiscard = () => {
      state.isEditable = false;
      setValues({ ...state.data });
    };
    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('updateAccountDetails', { ...values })
        .then(() => {
          state.isLoading = false;
          state.isEditable = false;
          store.dispatch('addToast', { message: 'messages.edits-saved' });
          store.commit('logoutSuccess');
          router.push({ name: 'Login' });
        })
        .catch(err => {
          state.isLoading = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errs.forEach((item: any) => {
              if (item.error) setFieldError('email', item.error);
              if (item.email_address)
                setFieldError('email', item.email_address);
            });
          }
        });
    });
    const { value: email } = useField('email');
    return {
      errors,
      state,
      email,
      onSubmit,
      handleEdit,
      handleDiscard,
      setValues
    };
  }
});
</script>

<style scoped></style>
