/* eslint-disable @typescript-eslint/no-explicit-any */

import router from '@/router';
import store from '@/store';
import {
  deleteAccessToken,
  deleteExpiryTime,
  deleteRefreshToken,
  getAccessToken
} from './local-storage';

/**
 * Wrapper for API Requests
 */

interface RequestOptionsType {
  path: string;
  payload: any;
  headers?: any;
}

class Http {
  get accessToken() {
    const token = getAccessToken();
    return token ? `Bearer ${token}` : null;
  }

  composeRequest(path: string, options: any) {
    const baseUrl = process.env.VUE_APP_API_URL;

    const requestOptions: RequestInit = {
      ...options,
      headers: {
        'Content-Type': 'application/json',

        ...options.headers,
        Authorization: this.accessToken
      }
    };

    const absolutePath = path.startsWith('/') ? baseUrl + path : path;

    return fetch(absolutePath, requestOptions).then(res => {
      if (res.status === 401) {
        if (router.currentRoute.value.path !== '/login') {
          deleteAccessToken();
          deleteRefreshToken();
          deleteExpiryTime();
          store.commit('logoutSuccess');
          window.location.replace('/login');
        }
      }
      return res;
    });
  }

  redirectTo = (document: any, path: string) => {
    document.location = path;
  };

  /*Returns an observable, use to draw custom response*/
  request(path: string, options: RequestInit) {
    return this.composeRequest(path, options).then(res => {
      if (!res.ok) {
        throw {
          ...res,
          name: 'HTTP Status ' + res.status,
          status: res.status
        };
      }

      return res;
    });
  }

  get(path: string) {
    return this.composeRequest(path, {
      method: 'GET'
    }).then(async res => {
      if (!res.ok) {
        throw {
          name: 'HTTP Status ' + res.status,
          status: res.status,
          ...(await res.json())
        };
      }

      return res.json();
    });
  }

  patch(path: string, payload: any, headers?: any) {
    return this.composeRequest(path, {
      method: 'PATCH',
      body: payload,
      headers: headers
    }).then(async res => {
      if (!res.ok) {
        throw {
          name: 'HTTP Status ' + res.status,
          status: res.status,
          ...(await res.json())
        };
      }

      return res.json();
    });
  }

  put(path: string, payload: any, headers?: any) {
    return this.composeRequest(path, {
      method: 'PUT',
      body: payload,
      headers: headers
    }).then(async res => {
      if (!res.ok) {
        throw {
          name: 'HTTP Status ' + res.status,
          status: res.status,
          ...(await res.json())
        };
      }

      return res.json();
    });
  }

  post(path: string, payload?: any, headers?: any) {
    return this.composeRequest(path, {
      method: 'POST',
      body: payload,
      headers: headers
    }).then(async res => {
      if (!res.ok) {
        throw {
          name: 'HTTP Status ' + res.status,
          status: res.status,
          ...(await res.json())
        };
      }

      return res.json();
    });
  }

  delete(path: string) {
    return this.composeRequest(path, {
      method: 'DELETE'
    }).then(async res => {
      if (!res.ok) {
        throw {
          name: 'HTTP Status ' + res.status,
          status: res.status,
          ...(await res.json())
        };
      }

      return res.json();
    });
  }
}

export const http = new Http();
