
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VizButton',

  props: {
    to: {
      type: String,
      default: null
    },
    loading: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      validator: (value: string | null) =>
        ['link', 'button', 'submit', 'reset', null].indexOf(value) >= 0
    }
  },
  computed: {
    componentType() {
      /**
       * @method componentType
       * Determines the type of html element to render
       */

      if (this.to) {
        /**
         * Use for Internal Links
         */
        return 'router-link';
      } else if (this.type === 'link') {
        /***
         * Use for External Links
         */
        return 'a';
      } else {
        /***
         * Use for Button Specific
         */
        return 'button';
      }
    }
  }
});
