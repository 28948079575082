<template>
  <div>
    <!-- delete member modal -->
    <div
      class="modal fade"
      id="delete_member_modal"
      tabindex="-1"
      aria-labelledby="deleteMemberModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="deleteGroupModal">
              {{ $t('pages.settings.groups.delete-member.title', { member }) }}
            </h5>
            <viz-button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></viz-button>
          </div>
          <div class="modal-body">
            <p>
              {{ $t('pages.settings.groups.delete-member.subtitle') }}
            </p>
            <p className="text-danger">{{ state.errorMessage }}</p>
          </div>
          <div class="modal-footer justify-content-end border-top-0">
            <button
              type="button"
              class="btn btn-default text-muted"
              data-bs-dismiss="modal"
            >
              {{ $t('labels.cancel') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary text-white rounded-pill"
              data-bs-target="#delete_member_spinner_modal"
              @click="handleMemberDelete"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
            >
              {{ $t('labels.delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- delete member sucess modal -->
    <div
      class="modal fade"
      id="delete_member_spinner_modal"
      tabindex="-1"
      aria-labelledby="deleteMemberSpinnerModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 text-center">
            <h5 class="modal-title text-center w-100" id="deleteSpinnerModal">
              {{ $t('pages.settings.groups.delete-member.title', { member }) }}
            </h5>
          </div>
          <button
            type="button"
            style="visibility:hidden"
            data-bs-dismiss="modal"
            data-bs-target="#delete_member_spinner_modal"
            aria-label="Close"
            id="dismissMemberDelete"
          ></button>

          <div class="modal-body">
            <div class="d-inline-block text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { mapActions } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';

export default defineComponent({
  props: ['member', 'groupId'],
  emits: ['refreshGroup'],
  components: {
    VizButton
  },
  setup() {
    const state = reactive({
      errorMessage: ''
    });
    return { state };
  },

  methods: {
    ...mapActions(['deleteMember', 'addToast', 'loadGroupAndMembers']),
    handleMemberDelete() {
      if (!this.groupId) return;
      this.deleteMember({
        groupId: Number(this.groupId),
        email: this.member
      })
        .then(() => {
          this.closeLoadingModal();
          this.state.errorMessage = '';
          this.addToast({
            message: 'pages.settings.member-deleted',
            data: { member: this.member }
          });
          this.$emit('refreshGroup');
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0].error : '';
          if (!err?.errors) {
            this.state.errorMessage = 'Internal Server Error';
          }
          this.closeLoadingModal();
        });
    },
    closeLoadingModal() {
      document.getElementById('dismissMemberDelete')?.click() as any;
    }
  }
});
</script>

<style scoped></style>
