
import { defineComponent } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { logo } from '@/utils/icons';

export default defineComponent({
  components: {
    VizButton
  },

  setup() {
    return {
      logo
    };
  },
  computed: {
    receivers() {
      const receivers = this.$route.params?.receivers;
      return receivers ? receivers : 0;
    }
  }
});
