/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import { RootState } from '../index';

export interface ToastState {
  toast: string;
}

const initialState = {
  toast: {}
};

export default {
  state: initialState,
  mutations: {
    setToastMessage(state: ToastState, toast: any) {
      state.toast = toast;
    }
  },
  actions: {
    addToast(
      context: ActionContext<ToastState, RootState>,
      { message, data }: { message: string; data: any }
    ) {
      context.commit('setToastMessage', { message, data });

      setTimeout(() => {
        context.commit('setToastMessage', {});
      }, 4000);
    }
  },

  getters: {
    toast: (state: ToastState) => state.toast
  }
};
