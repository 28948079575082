<template>
  <main>
    <div>
      <steps-track
        v-bind:steps="steps"
        v-bind:currentStep="currentStep"
      ></steps-track>
      <form
        class="target-extraction data-type__form mt-2 py-0"
        @submit.prevent="onSubmit"
        novalidate
      >
        <div class="row">
          <div class="col-12">
            <div class="sub_title">
              <h4>{{ $t('pages.te.rd.s3-input.title') }}</h4>
              <p class="text-primary d-none">
                データの要件・テンプレートについて
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex align-items-center mb-3 flex-wrap">
              <data-import-option
                v-for="option in dataImportOptions"
                :key="option.key"
                @click="setDataImportOption(option.key)"
                :label="$t(option.label)"
                :icon="option.icon"
                :class="option.key === state.importFrom ? 'active' : ''"
              />
            </div>
          </div>
        </div>
        <div :class="`${state.importFrom === 'ilocal' ? 'd-block' : 'd-none'}`">
          <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <p class="text-danger pb-1 mb-3">{{ state.errorMessage }}</p>
              <viz-file-input
                :label="$t('labels.select-file')"
                v-model="predictionDataInput"
                name="predictionDataInput"
                :uploadState="!state.isUploadComplete"
                @change="uploadFile"
                :uploadProgress="
                  registerData.uploadProgress
                    ? registerData.uploadProgress.predictionDataInput
                    : 0
                "
                @terminate="terminate"
                accept=".csv,.xls"
              />
              <span class="text-danger">{{
                errors.predictionDataInput ? $t(errors.predictionDataInput) : ''
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="sub_title">
              <h4>{{ $t('pages.te.rd.target.title') }}</h4>
            </div>
            <div class="col-xxl-6 col-xl-7 col-md-9 mb-2">
              <viz-select
                :options="state.dataHeaders"
                v-model="targetColumn"
                aria-label="select"
              >
              </viz-select>
              <span class="text-danger">{{
                errors.targetColumn ? $t(errors.targetColumn) : ''
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <viz-radio
                :options="[
                  {
                    value: 'classification',
                    text: $t('labels.binary-classification'),
                    tooltip: $t('pages.te.rd.method.cls-tooltip')
                  },
                  {
                    value: 'regression',
                    text: $t('labels.continuous-value-prediction'),
                    tooltip: $t('pages.te.rd.method.cvp-tooltip')
                  }
                ]"
                v-model="method"
              >
                {{ $t('pages.te.rd.method.title') }}
              </viz-radio>
              <span class="text-danger pb-2">{{
                errors.method ? $t(errors.method) : ''
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="sub_title">
                <h4>{{ $t('pages.te.rd.id.title') }}</h4>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <viz-select
                :options="state.dataHeaders"
                v-model="idColumn"
                aria-label=" select "
              >
              </viz-select>
            </div>
            <span class="text-danger">{{
              errors.idColumn ? $t(errors.idColumn) : ''
            }}</span>
          </div>
        </div>
        <div
          :class="
            `${
              state.importFrom === 'aws' || state.importFrom === 'gcs'
                ? 'd-block'
                : 'd-none'
            }`
          "
        >
          <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9  ">
              <p class="text-danger pb-1 mb-3">{{ state.errorMessage }}</p>

              <div class="aws-form">
                <div class="input-groups">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.aws_access_key_id"
                    id="aws_key"
                    placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxx"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_key">
                    {{ state.importFrom === 'aws' ? 'AWS' : 'Google' }} Access
                    Key ID
                  </label>
                  <p class="text-danger">
                    {{
                      state.awsError.aws_access_key_id
                        ? $t(state.awsError.aws_access_key_id)
                        : ''
                    }}
                  </p>
                </div>
                <div class="input-groups">
                  <input
                    type="password"
                    class="form-control"
                    v-model="state.aws_secret_access_key"
                    id="aws_secret"
                    placeholder="********************"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_secret">
                    {{ state.importFrom === 'aws' ? 'AWS' : 'Google' }} Secret
                    Access Key
                  </label>
                  <p class="text-danger">
                    {{
                      state.awsError.aws_secret_access_key
                        ? $t(state.awsError.aws_secret_access_key)
                        : ''
                    }}
                  </p>
                </div>
                <div class="input-groups">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.aws_region"
                    id="aws_region"
                    placeholder="ap-northeast-1"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_region">
                    リージョン
                  </label>
                  <p class="text-danger">
                    {{
                      state.awsError.aws_region
                        ? $t(state.awsError.aws_region)
                        : ''
                    }}
                  </p>
                </div>
                <div class="input-groups">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.bucket"
                    id="aws_bucket"
                    placeholder="bucket"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_bucket">
                    バケット
                  </label>
                  <p class="text-danger">
                    {{ state.awsError.bucket ? $t(state.awsError.bucket) : '' }}
                  </p>
                </div>
                <div class="input-groups">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.path"
                    id="aws_path"
                    placeholder="example"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_path">
                    ファイルパス(ファイル名を含まないバケット配下のパス)
                  </label>
                  <p class="text-danger">
                    {{ state.awsError.path ? $t(state.awsError.path) : '' }}
                  </p>
                </div>
                <div class="input-groups">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.filename"
                    id="aws_filename"
                    placeholder="202010_インプットKPI.csv"
                    autocomplete="off"
                    required
                  />
                  <label for="aws_filename">
                    ファイル名を入力
                  </label>
                  <p class="text-danger">
                    {{
                      state.awsError.filename ? $t(state.awsError.filename) : ''
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9 ">
              <p class="text-info pb-1" v-if="state.isUploadComplete">
                アップロード完了
              </p>
              <div class="d-flex align-items-center mb-3">
                <div
                  class="progress"
                  style="height: 10px; width: 90%"
                  v-if="state.isAwsUploading || state.isUploadComplete"
                >
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="`width: ${state.awsprogress}%`"
                    :aria-valuenow="state.awsprogress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div
                  class="progress-percent"
                  v-if="state.isAwsUploading || state.isUploadComplete"
                >
                  {{ state.awsprogress }}%
                </div>
                <button
                  v-if="state.isAwsUploading"
                  type="button"
                  class="btn btn-secondary rounded-pill ms-3 btn-sm btn-abort"
                  data-bs-toggle="modal"
                  data-bs-target="#abort-modal"
                >
                  インポートの中止
                </button>
                <!-- Modal -->
                <div
                  class="modal fade"
                  id="abort-modal"
                  tabindex="-1"
                  aria-labelledby="abort"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        この進行を中止してもよろしいですか？
                      </div>
                      <div class="modal-footer justify-content-center">
                        <button
                          type="button"
                          class="btn btn-primary rounded-pill ms-3 btn-sm"
                          data-bs-dismiss="modal"
                        >
                          {{ $t('labels.cancel') }}
                        </button>
                        <viz-button
                          type="button"
                          class="btn btn-secondary rounded-pill ms-3 btn-sm"
                          data-bs-dismiss="modal"
                          @click="terminateUpload"
                        >
                          インポートの中止
                        </viz-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mb-4 d-flex justify-content-start"
                v-if="!state.isAwsUploading && !state.isUploadComplete"
              >
                <viz-button
                  :loading="state.isLoading"
                  type="button"
                  class="btn btn-primary rounded-pill text-white py-2 px-3 fw-bold"
                  @click="awsFileUpload"
                >
                  アップロード
                </viz-button>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <viz-file-input
                :label="$t('labels.select-file')"
                v-model="predictionDataInput"
                name="predictionDataInput"
                :uploadState="!state.isUploadComplete"
                @change="uploadFile"
                :uploadProgress="
                  registerData.uploadProgress
                    ? registerData.uploadProgress.predictionDataInput
                    : 0
                "
                @terminate="terminate"
                accept=".csv,.xls"
              />
              <span class="text-danger">{{
                errors.predictionDataInput ? $t(errors.predictionDataInput) : ''
              }}</span>
            </div>
          </div> -->
          <div class="row">
            <div class="sub_title">
              <h4>{{ $t('pages.te.rd.target.title') }}</h4>
            </div>
            <div class="col-xxl-6 col-xl-7 col-md-9 mb-2">
              <viz-select
                :options="state.dataHeaders"
                v-model="targetColumn"
                aria-label="select"
              >
              </viz-select>
              <span class="text-danger">{{
                errors.targetColumn ? $t(errors.targetColumn) : ''
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <viz-radio
                :options="[
                  {
                    value: 'classification',
                    text: $t('labels.binary-classification'),
                    tooltip: $t('pages.te.rd.method.cls-tooltip')
                  },
                  {
                    value: 'regression',
                    text: $t('labels.continuous-value-prediction'),
                    tooltip: $t('pages.te.rd.method.cvp-tooltip')
                  }
                ]"
                v-model="method"
              >
                {{ $t('pages.te.rd.method.title') }}
              </viz-radio>
              <span class="text-danger pb-2">{{
                errors.method ? $t(errors.method) : ''
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="sub_title">
                <h4>{{ $t('pages.te.rd.id.title') }}</h4>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-7 col-md-9">
              <viz-select
                :options="state.dataHeaders"
                v-model="idColumn"
                aria-label=" select "
              >
              </viz-select>
            </div>
            <span class="text-danger">{{
              errors.idColumn ? $t(errors.idColumn) : ''
            }}</span>
          </div>
        </div>

        <div class="my-4 d-flex justify-content-start">
          <viz-button
            v-if="state.importFrom === 'ilocal'"
            :loading="state.isLoading"
            :disabled="(!meta.valid || !state.isUploadComplete) && meta.dirty"
            type="submit"
            class="a btn btn-primary rounded-pill text-white py-2 px-3 fw-bold"
          >
            {{ $t('common.buttonlabels.proceed') }}
          </viz-button>
          <viz-button
            v-if="state.importFrom === 'aws' || state.importFrom === 'gcs'"
            :loading="state.isLoading"
            :disabled="!state.isUploadComplete"
            type="submit"
            class="b btn btn-primary rounded-pill text-white py-2 px-3 fw-bold"
          >
            {{ $t('common.buttonlabels.proceed') }}
          </viz-button>
        </div>
      </form>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizFileInput from '@/components/ui/VizFileInput.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import VizRadio from '@/components/ui/VizRadio.vue';

import DataImportOption from '@/components/contribution-analysis/DataImportOption.vue';
import { cloudUploadIcon, awsIcon, googleCloudIcon } from '@/utils/icons';
import validationSchema from '@/validator/target-extraction/register-data';
import { useForm, useField } from 'vee-validate';
import { RootState } from '@/store';
import {
  useStore,
  createNamespacedHelpers,
  mapGetters as mapRootGetters
} from 'vuex';
import { UserGroup } from '@/store/modules/auth';
const { mapGetters, mapActions } = createNamespacedHelpers('te');

interface ErrorList {
  error?: string;
  task_id?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'RegisterData',
  emits: ['proceed'],
  beforeMount() {
    this.resetTargetState();
  },
  mounted() {
    if (!this.taskId) {
      this.getTaskId()
        .then(() => {
          this.state.errorMessage = '';
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: { errors: Array<any> }) => {
          if (err && Array.isArray(err?.errors)) {
            this.state.errorMessage = err?.errors[0]?.error;
          } else {
            this.state.errorMessage =
              'Error while loading task-id.Try Reloading!';
          }
        });
    }
  },
  props: {
    steps: Array,
    currentStep: Number
  },
  setup(props, { emit }) {
    const store = useStore<RootState>();
    const dataImportOptions = markRaw([
      {
        label: 'pages.ca.enterdata.section2.ilocal',
        key: 'ilocal',
        icon: cloudUploadIcon
      },
      { label: 'pages.ca.enterdata.section2.aws', key: 'aws', icon: awsIcon },
      {
        label: 'pages.ca.enterdata.section2.google',
        key: 'gcs',
        icon: googleCloudIcon
      }
    ]);

    const state = reactive({
      importFrom: 'ilocal',
      errorMessage: '',
      isLoading: false,
      isUploadComplete: false,
      isAwsUploading: false,
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_region: '',
      path: '',
      filename: '',
      bucket: '',
      checkFileUploadStatus: 0,
      awsprogress: 0,
      awsError: {
        aws_access_key_id: '',
        aws_secret_access_key: '',
        aws_region: '',
        bucket: '',
        filename: '',
        path: ''
      },
      dataHeaders: [] as Array<{ text: string; value: string }>
    });
    const setDataImportOption = (option: string) => {
      state.importFrom = option;
      state.aws_access_key_id = '';
      state.aws_secret_access_key = '';
      state.bucket = '';
      state.aws_region = '';
      state.path = '';
      state.filename = '';
      state.errorMessage = '';
    };
    const initialFormValues = {
      predictionDataInput: undefined,
      targetColumn: '',
      idColumn: '',
      method: ''
    };

    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setValues,
      resetForm
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });
    const { value: predictionDataInput } = useField('predictionDataInput');
    const { value: targetColumn } = useField('targetColumn');
    const { value: idColumn } = useField('idColumn');
    const { value: method } = useField('method');

    const awsFileUpload = () => {
      const {
        aws_access_key_id,
        aws_secret_access_key,
        aws_region,
        bucket,
        filename,
        path
      } = state;

      if (!aws_access_key_id) {
        state.awsError.aws_access_key_id = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_access_key_id = '';
      }

      if (!aws_secret_access_key) {
        state.awsError.aws_secret_access_key = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_secret_access_key = '';
      }

      if (!aws_region) {
        state.awsError.aws_region = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_region = '';
      }

      if (!bucket) {
        state.awsError.bucket = 'errors.required-field';
        return;
      } else {
        state.awsError.bucket = '';
      }

      if (!path) {
        state.awsError.path = 'errors.required-field';
        return;
      } else {
        state.awsError.path = '';
      }

      if (!filename) {
        state.awsError.filename = 'errors.required-field';
        return;
      } else {
        state.awsError.filename = '';
      }

      state.isUploadComplete = false;
      state.isAwsUploading = true;

      store
        .dispatch('te/awsFileUpload', {
          aws_access_key_id,
          aws_secret_access_key,
          aws_region,
          bucket,
          analysis_type: 'target_extraction',
          filename: filename,
          path: path,
          provider: state.importFrom
        })
        .then(() => {
          state.checkFileUploadStatus = setInterval(() => {
            store
              .dispatch('te/getAwsProgress')
              .then(d => {
                if (d.status === 'aborted') {
                  state.isUploadComplete = false;
                  state.isAwsUploading = false;
                  state.errorMessage = d?.message;

                  clearInterval(state.checkFileUploadStatus);
                } else if (d.status === 'pending') {
                  state.awsprogress = d.completed;
                } else if (d.status === 'complete') {
                  state.awsprogress = d.completed;
                  state.isUploadComplete = true;
                  state.isAwsUploading = false;
                  state.isLoading = false;
                  debugger;
                  clearInterval(state.checkFileUploadStatus);

                  setTimeout(() => {
                    store
                      .dispatch('te/getInputFileHeaders')
                      .then((headers: Array<string>) => {
                        state.dataHeaders = headers.map(val => ({
                          text: val,
                          value: val
                        }));
                      })
                      .catch(err => {
                        const error = err?.errors[0]?.error;
                        // setFieldError(fieldname, error);
                        state.dataHeaders = [];
                      });
                  });
                }
              })
              .catch(e => {
                state.isUploadComplete = false;
                state.isAwsUploading = false;
                clearInterval(state.checkFileUploadStatus);
                console.log(e);
              });
          }, 2000);
        })
        .catch(err => {
          debugger;
          state.isUploadComplete = false;
          state.isAwsUploading = false;
          console.log(err);
        });
    };

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('te/registerTargetData', {
          ...values
        })
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';
          emit('proceed');
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;
          type FieldOptions = {
            [key: string]: string;
          };

          const fieldMaps: FieldOptions = {
            target_col: 'targetColumn',
            problem_type: 'method',
            user_id_col: 'idColumn'
          };

          if (Array.isArray(err?.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.errors.forEach((error: any) => {
              Object.keys(fieldMaps).forEach((fieldname: string) => {
                if (error[fieldname]) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const errorField: any = fieldMaps[fieldname];
                  setFieldError(errorField, error[fieldname]);
                }
              });
            });
          }
          if (err.errors[0].error) {
            state.errorMessage = err.errors[0].error as string;
          }
        });
    });
    const uploadFile = (ev: Event) => {
      ev.preventDefault();
      if (!store.getters.isStorageAvailable) {
        return setFieldError('predictionDataInput', 'messages.storage-full');
      }
      const target = ev.target as HTMLInputElement;
      const files = target.files;

      const fieldname = target?.name as 'predictionDataInput';
      if (files && files[0]) {
        const file = files[0];
        const { used, total } = store.getters.storageUsage;

        const availableSize = total * 1073741824 - used * 1073741824;

        if (availableSize <= file.size) {
          return setFieldError(
            'predictionDataInput',
            'messages.file-limit-exceed'
          );
        }
        state.isUploadComplete = false;
        store
          .dispatch('te/localFileUpload', {
            file: files[0],
            fieldname
          })
          .then(() => {
            //set filename returned from signed
            store.dispatch('getStorageUsage', store.getters.userGroup?.id);

            setFieldError(fieldname, undefined);
            if (fieldname === 'predictionDataInput') {
              state.isUploadComplete = true;
              store.dispatch('te/fileUploadStatus', 'completed');
            }

            store
              .dispatch('te/getInputFileHeaders')
              .then((headers: Array<string>) => {
                state.dataHeaders = headers.map(val => ({
                  text: val,
                  value: val
                }));
              })
              .catch(err => {
                const error = err?.errors[0]?.error;
                setFieldError(fieldname, error);
                state.dataHeaders = [];
              });
          })
          .catch(err => {
            state.isUploadComplete = false;
            store.dispatch('te/fileUploadStatus', 'aborted');

            if (err.status === 403) {
              setFieldError(fieldname, 'errors.invalid-file');
            } else if (err && Array.isArray(err?.errors)) {
              setFieldError(fieldname, err.errors[0].error);
            } else if (err.status === 408) {
              setFieldError(fieldname, 'errors.request-timeout');
            } else if (err.status === '503') {
              setFieldError(fieldname, 'errors.service-unavailable');
            }

            // else {
            //   setFieldError(fieldname, 'errors.network-error');
            // }
          });
      }
    };
    return {
      predictionDataInput,
      targetColumn,
      idColumn,
      method,
      errors,
      setValues,
      uploadFile,
      onSubmit,
      resetForm,
      awsFileUpload,
      meta,
      state,
      dataImportOptions,
      setDataImportOption
    };
  },
  components: {
    StepsTrack,
    VizFileInput,
    VizButton,
    VizSelect,
    VizRadio,
    DataImportOption
  },
  methods: {
    ...mapActions([
      'getTaskId',
      'resetTargetState',
      'resetTargetFormState',
      'fileUploadStatus',
      'terminateUploadAws'
    ]),

    terminate() {
      this.resetForm();
      // this.fileUploadStatus('aborted');
      this.resetTargetFormState();
    },

    terminateUpload() {
      this.terminateUploadAws();
    }
  },
  computed: {
    ...mapGetters(['registerData', 'taskId']),
    ...mapRootGetters(['userGroup'])
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.resetForm();
        this.resetTargetFormState();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.btn-abort {
  white-space: nowrap;
}
input::placeholder {
  color: transparent;
  transition: all 0.3s ease;
}
input:focus::placeholder {
  color: inherit;
}
</style>
