/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { http } from '@/utils/http';
import { NumberFormat } from '@intlify/core-base';

const KPI_INPUT_URL = '/api/v1/kpi_data';
const RECENT_RECORDS_URL = '/api/v1/s3/recent_records';
const TASK_ID_URL = '/api/v1/task_id';
const PRE_SIGNED_REQUEST_ROUTE = '/api/v1/s3/presigned_url';
const ANALYSIS_STATE_URL = '/api/v1/kpi_status';
const KPI_RESULTS_URL = '/api/v1/kpi_results';
const KPI_CORR_URL = '/api/v1/kpi_output_estimate_corr';
const KPI_COEF_URL = '/api/v1/kpi_output_estimate_coef';
const KPI_EFFECT_URL = '/api/v1/kpi_output_estimate_effect';
const EXPORT_REPORT_URL = '/api/v1/contribution/download_output';
const UPLOAD_STATUS = '/api/v1/contribution/file_upload_status';
const TERMINATE_PROCESS = '/api/v1/contribution/abort_analysis';
const AWS_FILE_UPOAD = '/api/v1/s3/file-upload/upload';
const AWS_PROGRESS = '/api/v1/s3/file-upload/progress';
const TERMINATE_AWS_PROCESS = '/api/v1/s3/file-upload/abort';

export interface KpiInputCredentials {
  considerLag: boolean;
  seasonEffects: boolean;
  method: string;
  modelName: string;
  date: string;
  target: string;
  mediaName?: string;
  laglist?: Array<string>;
  taskId: number;
}

export interface AWSInputCredentials {
  aws_access_key_id: string;
  aws_secret_access_key: string;
  aws_region: string;
  bucket: string;
  task_id: number;
  group_id: number;
  analysis_type: string;
  filename: string;
  path: string;
  provider: string;
}

export interface KpiFileInputCredentials {
  s3InputFile1: FileList;
  s3file1: string;
}

const uploadKPIData = ({
  taskId,
  method,
  modelName,
  considerLag,
  seasonEffects,
  mediaName,
  laglist,
  date,
  target
}: KpiInputCredentials) => {
  const data: any = {
    task_id: taskId,
    model_name: modelName,
    method: method,
    date_column: date,
    target_column: target,
    lag: considerLag,
    season_effect: seasonEffects
  };

  if (seasonEffects && mediaName) {
    data.media_name = mediaName;
  }

  if (considerLag && laglist) {
    data.lag_list = laglist;
  }

  return http.post(KPI_INPUT_URL, JSON.stringify(data), {
    'Content-Type': 'application/json'
  });
};

const fetchMeasureData = ({
  groupId,
  taskId
}: {
  groupId: number;
  taskId: number;
}) => {
  return http.post(
    RECENT_RECORDS_URL,
    JSON.stringify({ group_id: groupId, task_id: taskId })
  );
};

const getPresignedUrl = ({
  taskId,
  filename,
  groupId
}: {
  taskId: number;
  filename: string;
  groupId: number;
}) => {
  return http.post(
    PRE_SIGNED_REQUEST_ROUTE,
    JSON.stringify({ group_id: groupId, task_id: taskId, filename: filename })
  );
};

const getTaskId = () => {
  return http.get(TASK_ID_URL);
};

const postAwsFile = ({  aws_access_key_id,
  aws_secret_access_key,
  aws_region,
  bucket,
  task_id,
  group_id,
  analysis_type,
  filename,
  path,
  provider }: AWSInputCredentials) => {
  return http.post(AWS_FILE_UPOAD, JSON.stringify({  aws_access_key_id,
  aws_secret_access_key,
  aws_region,
  bucket,
  task_id,
  group_id,
  analysis_type,
  filename,
  path,
  provider}));
};

const getAnalysisState = (taskId: number) => {
  return http.post(ANALYSIS_STATE_URL, JSON.stringify({ task_id: taskId }));
};

const getAwsProgress = (taskId: number, analysis_type: string) => {
  return http.post(AWS_PROGRESS, JSON.stringify({ task_id: taskId, analysis_type }));
};


const getKPIResults = (taskId: number) => {
  return http.post(KPI_RESULTS_URL, JSON.stringify({ task_id: taskId }));
};
const getKPICorrEstimate = (taskId: number) => {
  return http.post(KPI_CORR_URL, JSON.stringify({ task_id: taskId }));
};
const getKPICoefEstimate = (taskId: number) => {
  return http.post(KPI_COEF_URL, JSON.stringify({ task_id: taskId }));
};
const getKPIEffectEstimate = (taskId: number) => {
  return http.post(KPI_EFFECT_URL, JSON.stringify({ task_id: taskId }));
};
const setUploadStatus = (taskId: number, groupId: number, status: string) => {
  return http.post(
    UPLOAD_STATUS,
    JSON.stringify({ task_id: taskId, status: status, group_id: groupId })
  );
};

const termiateProcess = (taskId: number) => {
  return http.post(
    TERMINATE_PROCESS,
    JSON.stringify({ task_id: taskId })
  );
};

const termiateAwsProcess = (taskId: number, analysis_type: string) => {
  return http.post(
    TERMINATE_AWS_PROCESS,
    JSON.stringify({ task_id: taskId, analysis_type })
  );
};

const exportAnalysisReport = (taskId: number) => {
  return http.request(EXPORT_REPORT_URL, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId })
  });
};

const caAPI = {
  uploadKPIData,
  fetchMeasureData,
  getTaskId,
  getPresignedUrl,
  getAnalysisState,
  getKPIResults,
  getKPICoefEstimate,
  getKPICorrEstimate,
  getKPIEffectEstimate,
  exportAnalysisReport,
  setUploadStatus,
  termiateProcess,
  postAwsFile,
  getAwsProgress,
  termiateAwsProcess
};

export default caAPI;
