<template>
  <div class="d-flex align-items-center">
    <div class="d-flex-column pb-4" style="width: 40%">
      <p class="fw-bold py-2">
        {{
          state.data && state.data[0] && state.data[0].category
            ? getTitle(state.data[0].category)
            : ''
        }}
      </p>
      <p class="fw-bold">
        {{
          state.data && state.data[1] && state.data[1].category
            ? getTitle(state.data[1].category)
            : ''
        }}
      </p>
    </div>

    <div style="width: 60%">
      <stacked-horizontal-bar
        v-if="Array.isArray(getCategories(state.data)) && state.data.length > 0"
        :chartdata="getFormatedData(state.data)"
        :categories="getCategories(state.data)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import StackedHorizontalBar from '@/components/ui/StackedHorizontalBar.vue';
import simulationAPI from '@/api/measure-simulation';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  components: {
    StackedHorizontalBar
  },
  props: ['taskId'],
  mounted() {
    if (!this.taskId) return;
    this.getSalesBarData(this.taskId);
  },

  setup() {
    const state = reactive({
      data: []
    });
    return {
      state,
      errorMessage: ''
    };
  },
  watch: {
    taskId: function(newVal) {
      if (newVal) {
        this.state.data = [];
        this.getSalesBarData(newVal);
      }
    }
  },
  methods: {
    getSalesBarData: function(taskId: number) {
      simulationAPI
        .getSimulateSaleBarReport(taskId)
        .then(res => {
          this.state.data = res?.data?.results;
          this.errorMessage = '';
        })
        .catch(err => {
          this.errorMessage = err?.errors[0]?.error;
        });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCategories(data: Array<any>) {
      if (Array.isArray(data) && data.length > 0) {
        const numOr0 = (n: any) => (isNaN(n) ? 0 : n);

        const total1 = Object.values(data[0])
          .map(Number)
          .reduce((a: any, b: any) => numOr0(a) + numOr0(b));

        if (data[1]) {
          const total2 = Object.values(data[1])
            .map(Number)
            .reduce((a: any, b: any) => numOr0(a) + numOr0(b));

          return [total1.toFixed(2), total2.toFixed(2)];
        } else {
          return [total1.toFixed(2)];
        }
      }
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFormatedData(data: Array<any>) {
      if (Array.isArray(data) && data.length > 0) {
        // const tempData = [] as Array<{ name: string; data: Array<number> }>;

        const tempKeys = Object.keys(data[0]).filter(
          item => item !== 'category'
        );

        return tempKeys.map(el => {
          return { name: el, data: data.map(d => Number(d[el]).toFixed(2)) };
        });
      }
      return [];
    },

    getTitle(title: string) {
      const noSeasonRegex = new RegExp('no_season');
      const seasonRegex = new RegExp('_season');

      if (noSeasonRegex.test(title)) return '季節効果なし';
      else if (seasonRegex.test(title)) return '季節効果あり';
      else return title;
    }
  }
});
</script>

<style scoped></style>
