
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    isModalVisible: Boolean
  },
  data() {
    return {};
  },
  methods: {}
});
