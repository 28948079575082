<template>
  <div class="d-block graph">
    <div class="sub_title">
      <h4>
        {{ $t('pages.ms.report.patterna.kf') }}
      </h4>
    </div>
    <p class="text-danger">{{ errorMessage }}</p>
    <line-column-chart
      v-if="state.data.length > 0"
      :chartdata="getFormatedData(state.data)"
      :categories="getCategories(state.data)"
      :target="state.targetColumn"
      :yaxis="getFormattedYAxisData(state.data)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import LineColumnChart from '@/components/ui/LineColumnChart.vue';
import simulationAPI from '@/api/measure-simulation';

export default defineComponent({
  components: {
    LineColumnChart,
  },
  props: ['taskId', 'pattern'],
  mounted() {
    if (!this.taskId) return;
    this.getSalesData(this.taskId, this.pattern);
  },

  methods: {
    getSalesData(taskId: number, pattern: string) {
      if (pattern == 'patternA') {
        simulationAPI
          .getSimulateSalesPredictionReport(taskId)
          .then((res) => {
            this.state.data = res?.data?.results;
            this.state.dateColumn = res?.data?.date_column;
            this.state.targetColumn = res?.data?.target_value;

            this.errorMessage = '';
          })
          .catch((err) => {
            this.errorMessage = err?.errors[0]?.error;
          });
      }
      if (pattern == 'patternB') {
        simulationAPI
          .getOptimizeSalesPredictionReport(taskId)
          .then((res) => {
            this.state.data = res?.data?.results;
            this.state.dateColumn = res?.data?.date_column;
            this.state.targetColumn = res?.data?.target_value;

            this.errorMessage = '';
          })
          .catch((err) => {
            this.errorMessage = err?.errors[0]?.error;
          });
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCategories(data: Array<any>) {
      if (Array.isArray(data) && data.length > 0) {
        return data.map((d) => d[this.state.dateColumn]);
      }
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFormatedData(data: Array<any>) {
      if (Array.isArray(data) && data.length > 0) {
        const tempKeys = Object.keys(data[0]).filter(
          (item) => item !== this.state.dateColumn
        );
        return tempKeys.map((el) => {
          if (el == this.state.targetColumn) {
            return { name: el, type: 'column', data: data.map((d) => d[el]) };
          }
          return { name: el, type: 'line', data: data.map((d) => d[el]) };
        });
      }
      return [];
    },
    getMinMaxMeasureVolume(data: Array<any>) {
      const minmax = {
        mv: {
          max: 0,
          min: 0,
        },
        kpi: {
          max: 0,
          min: 0,
        },
      };
      data.forEach((item) => {
        const invalidKeys = [this.state.dateColumn, this.state.targetColumn];

        const invalidIndex = Object.keys(item)
          .map((el, index) => (invalidKeys.includes(el) ? index : undefined))
          .filter(Boolean);

        const validValues = Object.values(item).filter(
          (el, index) => Boolean(Number(el)) && !invalidIndex.includes(index)
        ) as Array<any>;
        const kpiCol = Number(item[this.state.targetColumn]);

        const max = Math.max(...[].concat(...validValues));
        const min = Math.min(...[].concat(...validValues));
        if (max > minmax.mv.max) {
          minmax.mv.max = max;
        }
        if (min < minmax.mv.min) {
          minmax.mv.min = min;
        }

        if (minmax.kpi.max < kpiCol) {
          minmax.kpi.max = kpiCol;
        }
        if (minmax.kpi.min > kpiCol) {
          minmax.kpi.min = kpiCol;
        }
      });

      if (Math.abs(minmax.kpi.min) > minmax.kpi.max) {
        minmax.kpi.max = Math.abs(minmax.kpi.min);
      }
      return minmax;
    },

    getFormattedYAxisData(data: Array<any>) {
      const minmax = this.getMinMaxMeasureVolume(data);
      if (Array.isArray(data) && data.length > 0) {
        const tempKeys = Object.keys(data[0]).filter(
          (item) => item !== this.state.dateColumn
        );

        let isAxisShown = false;
        return tempKeys.map((el) => {
          if (el == this.state.targetColumn) {
            return {
              opposite: true,
              reversed: false,
              seriesName: el,
              forceNiceScale: true,
              show: true,
              showAlways: true,
              title: {
                text: 'KPI',
              },
              labels: {
                formatter: function (val: any) {
                  if (!Number(val)) return val;
                  return Number(val).toFixed(2);
                },
              },
              min: 0,
              max: minmax.kpi.max,
            };
          }
          const yaxisOther = {
            seriesName: el,
            show: !isAxisShown,
            showAlways: !isAxisShown,
            forceNiceScale: true,

            title: {
              text: '施策ボリューム（円）',
            },
            labels: {
              formatter: function (val: any) {
                if (!Number(val)) return val;
                return Number(val).toFixed(2);
              },
            },
            min: 0,
            max: minmax.mv.max,
          };

          isAxisShown = true;
          return yaxisOther;
        });
      }
      return [];
    },
  },
  setup() {
    const state = reactive({
      data: [],
      dateColumn: '',
      targetColumn: '',
    });
    return {
      errorMessage: '',
      state,
    };
  },
  watch: {
    taskId: function (newVal) {
      if (newVal) {
        this.state.data = [];
        this.getSalesData(newVal, this.pattern);
      }
    },
  },
});
</script>

<style scoped></style>
