<template>
  <!-- modal of the view result  -->
  <teleport to="body">
    <div
      class="modal fade"
      id="resultModal"
      tabindex="-1"
      aria-labelledby="resultModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog  modal-fullscreen">
        <div class="modal-content container">
          <div class="modal-header">
            <h5 class="modal-title" id="resultModalLabel">
              <slot name="title"></slot>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal"
            ></button>
          </div>
          <slot name="body"> </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    isModalVisible: Boolean
  },
  data() {
    return {};
  },
  methods: {}
});
</script>
