<template>
  <div>
    <steps-track
      v-bind:steps="steps"
      v-bind:currentStep="currentStep"
    ></steps-track>
    <div class="target-sales mt-4">
      <div class="row">
        <div class="col-6">
          <div class="sub_title">
            <h4>
              {{ $t('pages.ms.report.patterna.title') }}
            </h4>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end">
            <viz-button
              type="button"
              class="btn btn-outline-primary has-dropdown"
              @click="handleExport"
              :loading="state.isLoading"
            >
              {{ $t('common.buttonlabels.export') }}
              <span class="d-inline-block ms-3 me-2">
                <figure>
                  <img :src="icons.dropdownIcon" alt="" />
                </figure>
              </span>
            </viz-button>
          </div>
        </div>
      </div>
      <div class="estimated-sales">
        <div class="row">
          <div class="sub_title">
            <h4>
              {{ $t('pages.ms.report.patterna.pred-result') }}
            </h4>
          </div>
          <div class="d-flex px-2 ">
            <div class="border">
              <div class="fw-bold">
                {{
                  stringHelpers.addCommas(
                    String(Number(state.totalSales).toFixed(0))
                  )
                }}
              </div>
            </div>
            <p class="text-danger">{{ state.totalSalesError }}</p>
          </div>
        </div>
      </div>

      <div class="measure-volume">
        <div class="sub_title">
          <h4>
            {{ $t('pages.ms.report.patterna.adjust-field.title') }}
          </h4>
          <div class="text-muted">
            {{ $t('pages.ms.report.patterna.adjust-field.desc') }}
          </div>
        </div>
        <!-- <div class="text-primary mb-3">
          {{ $t('pages.ms.report.patterna.adjust-field.info') }}
        </div> -->
        <div class="row">
          <div
            v-for="label in targetKeys"
            :key="label"
            class="col-xl-3 col-lg-4 col-md-6"
          >
            <div class="border px-2">
              <div class="text-field">
                <input
                  type="text"
                  :name="label"
                  class="text-center w-100 m-0"
                  :value="state.targetValues[label]"
                  @change="handleTargetValuesChange"
                  @blur="addCommas"
                  @focus="removeCommas"
                  required
                  placeholder="1,000,000"
                  maxlength="12"
                />
              </div>

              <div class="text-muted pt-4">{{ label }}</div>
            </div>
          </div>
          <p class="text-danger">{{ state.targetValuesError }}</p>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-lg-6">
          <volume-prediction
            :taskId="Number($route.params.taskId)"
            :pattern="$route.params.pattern"
          />
        </div> -->
        <div class="col">
          <kpi-forecast
            :taskId="Number($route.params.taskId)"
            :pattern="$route.params.pattern"
          />
        </div>
      </div>
      <sales-bar-graph :taskId="Number($route.params.taskId)" />

      <div class="d-block mt-3 button">
        <viz-button to="/simulation/" class="btn btn-default text-muted mr-3">
          {{ $t('pages.ms.report.start-new') }}
        </viz-button>
        <viz-button
          type="button"
          @click="handleResimulate"
          :loading="state.isResimulateLoading"
          class="btn btn-primary text-white"
        >
          {{ $t('pages.ms.report.resimulate') }}
        </viz-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';
// import VolumePrediction from '@/components/measure-simulation/VolumePrediction.vue';
import KpiForecast from '@/components/measure-simulation/KpiForecast.vue';
import { dropdownIcon } from '@/utils/icons';
import simulationAPI from '@/api/measure-simulation';
import { createNamespacedHelpers } from 'vuex';
import SalesBarGraph from '@/components/measure-simulation/SalesBarGraph.vue';
import { stringHelpers } from '@/utils/helpers';

const { mapActions } = createNamespacedHelpers('ms');

interface NumberKeyValInterface {
  [key: string]: string | number;
}

export default defineComponent({
  name: 'TargetSales',
  props: { steps: Array },

  beforeMount() {
    const { pattern, taskId } = this.$route.params;

    if (!pattern || !taskId) {
      this.$router.replace({ path: '/simulation' });
    }

    this.getMediaVolumeReport(Number(taskId));
    this.getTotalSales(Number(taskId));
  },
  computed: {
    targetKeys: function(): Array<string> {
      if (!this.state.targetValues) return [];
      return Object.keys(this.state.targetValues);
    }
  },
  methods: {
    ...mapActions(['runSimulateModel', 'exportSimulationReport']),
    addCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.addCommas(target.value);
    },
    removeCommas: function(ev: Event) {
      const target = ev.target as HTMLInputElement;
      target.value = stringHelpers.removeCommas(target.value);
    },
    handleExport: function() {
      this.state.isLoading = true;
      const { taskId } = this.$route.params;

      this.exportSimulationReport(taskId)
        .then(() => {
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    },
    handleTargetValuesChange(ev: Event) {
      const target = ev.target as HTMLInputElement;
      this.state.targetValues[target.name] = target.value;
    },

    handleResimulate() {
      const { taskId, modelName, pattern } = this.$route.params;

      this.state.isResimulateLoading = true;

      Object.keys(this.state.targetValues).forEach(el => {
        const tempNum = Number(
          stringHelpers.removeCommas(String(this.state.targetValues[el]))
        );

        if (!isNaN(tempNum)) {
          this.state.targetValues[el] = tempNum;
        }
      });

      this.runSimulateModel({
        targetValues: this.state.targetValues,
        taskId,
        modelName
      })
        .then(() => {
          this.state.isResimulateLoading = false;

          this.$router.push({
            path: '/simulate/observe',
            name: 'SimulationObserve',
            params: { taskId, modelName, pattern }
          });
        })
        .catch(err => {
          this.state.isResimulateLoading = false;

          const errors = err?.errors;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((item: any) => {
            if (item.error) {
              this.state.targetValuesError = item.error;
            }
            if (item.target_values) {
              this.state.targetValuesError = item.target_values;
            }
          });
        });
    },
    getTotalSales(taskId: number) {
      this.state.totalSales = 0;

      simulationAPI
        .getSimulateTotalSalesReport(taskId)
        .then(res => {
          const results = res?.data?.results;
          this.state.totalSales = results
            ? Number(Number(results[0]?.total_sales).toFixed(0))
            : 0;
          this.state.targetSalesError = '';
        })
        .catch(err => {
          this.state.totalSales = 0;
          this.state.targetSalesError = err?.errors ? err.errors[0]?.error : '';
        });
    },
    getMediaVolumeReport(taskId: number) {
      this.state.targetValues = {};
      simulationAPI
        .getSimulateMediaVolumeReport(taskId)
        .then(res => {
          const results = res.data?.results;
          let tempTarget = {};

          if (Array.isArray(results)) {
            results.forEach(item => {
              tempTarget = {
                ...tempTarget,
                [item.val]: stringHelpers.addCommas(
                  String(
                    isNaN(Number(item.budget))
                      ? item.budget
                      : Number(item.budget).toFixed(0)
                  )
                )
              };
            });
          }

          this.state.targetValues = tempTarget;
          this.state.targetValuesError = '';
        })
        .catch(err => {
          this.state.targetValuesError = err?.errors
            ? err.errors[0]?.error
            : '';
        });
    }
  },
  setup() {
    const icons = markRaw({
      dropdownIcon
    });
    const state = reactive({
      totalSales: 0,
      targetValues: {} as NumberKeyValInterface,
      errorMessage: '',
      targetSalesError: '',
      targetValuesError: '',
      isLoading: false,
      isResimulateLoading: false
    });
    return { currentStep: 2, icons, state, stringHelpers };
  },

  components: {
    StepsTrack,
    VizButton,
    // VolumePrediction,
    KpiForecast,
    SalesBarGraph
  }
});
</script>

<style lang="scss" scoped>
.input-target-parent {
  position: relative;
  z-index: 2;
}
.input-target {
  background: transparent;
  border: none;
  height: 60%;
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  color: #000;
  &:hover,
  &:focus {
    outline: none;
  }
}
</style>
