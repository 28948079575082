
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import validationSchema from '@/validator/forgot';
import { logo } from '@/utils/icons';

interface ErrorList {
  error?: string;
  email?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  components: {
    VizButton
  },
  setup() {
    const icons = markRaw({
      logo: logo
    });
    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const { errors, handleSubmit, meta } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();

    const onSubmit = handleSubmit((values, { setFieldError }) => {
      state.isLoading = true;
      store
        .dispatch('forgotPassword', values.email)
        .then(() => {
          state.isLoading = false;

          router.push('/forgot/complete');
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;

          if (err.errors[0]?.error) {
            state.errorMessage = err.errors[0].error;
          }
          if (err.errors[0]?.email) {
            setFieldError('email', err.errors[0].email);
          }
        });
    });

    const { value: email } = useField('email');

    return {
      email,
      state,
      onSubmit,
      errors,
      meta,
      icons
    };
  }
});
