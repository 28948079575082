import { http } from '@/utils/http';

const REGISTER_DATA_URL = '/api/v1/target_extraction/extraction_data';
const TASK_ID_URL = '/api/v1/target_extraction/task_id';
const PRE_SIGNED_REQUEST_ROUTE = '/api/v1/target_extraction/presigned_url';
const EXTRACTION_STATUS_URL = '/api/v1/target_extraction/extraction_status';

const USER_SCORE_URL = '/api/v1/target_extraction/output_user_score';
const SCORE_FREQUENCY_URL = '/api/v1/target_extraction/output_score_frequency';
const IMPORTANCE_URL = '/api/v1/target_extraction/output_importance';
const INPUT_FILE_HEADERS_URL = '/api/v1/target_extraction/input_headers';
const EXPORT_REPORT_URL = '/api/v1/target_extraction/download_output';
const UPLOAD_STATUS = '/api/v1/target_extraction/file_upload_status';
const TERMINATE_PROCESS = '/api/v1/target_extraction/abort_analysis';
const AWS_FILE_UPOAD = '/api/v1/s3/file-upload/upload';
const AWS_PROGRESS = '/api/v1/s3/file-upload/progress';
const TERMINATE_AWS_PROCESS = '/api/v1/s3/file-upload/abort';
const DECISION_TREE_IMAGE_URL = '/api/v1/target_extraction/read_decision_tree';

export interface TargetData {
  method: string;
  targetColumn: string;
  idColumn: string;
  taskId: number;
}

export interface AWSInputCredentials {
  aws_access_key_id: string;
  aws_secret_access_key: string;
  aws_region: string;
  bucket: string;
  task_id: number;
  group_id: number;
  analysis_type: string;
  filename: string;
  path: string;
  provider: string;
}

const registerTargetData = ({
  method,
  targetColumn,
  idColumn,
  taskId
}: TargetData) => {
  const payload = {
    problem_type: method,
    target_col: targetColumn,
    user_id_col: idColumn,
    task_id: taskId
  };
  return http.post(REGISTER_DATA_URL, JSON.stringify(payload));
};

const getPresignedUrl = ({
  taskId,
  filename,
  groupId
}: {
  taskId: number;
  filename: string;
  groupId: number;
}) => {
  return http.post(
    PRE_SIGNED_REQUEST_ROUTE,
    JSON.stringify({ group_id: groupId, task_id: taskId, filename })
  );
};

const getTaskId = () => {
  return http.get(TASK_ID_URL);
};

const getInputFileHeaders = ({
  groupId,
  taskId
}: {
  groupId: number;
  taskId: number;
}) => {
  return http.post(
    INPUT_FILE_HEADERS_URL,
    JSON.stringify({ group_id: groupId, task_id: taskId })
  );
};

const getExtractionStatus = (taskId: number) => {
  return http.post(EXTRACTION_STATUS_URL, JSON.stringify({ task_id: taskId }));
};

const postAwsFile = ({
  aws_access_key_id,
  aws_secret_access_key,
  aws_region,
  bucket,
  task_id,
  group_id,
  analysis_type,
  filename,
  path,
  provider
}: AWSInputCredentials) => {
  return http.post(
    AWS_FILE_UPOAD,
    JSON.stringify({
      aws_access_key_id,
      aws_secret_access_key,
      aws_region,
      bucket,
      task_id,
      group_id,
      analysis_type,
      filename,
      path,
      provider
    })
  );
};

const getAwsProgress = (taskId: number, analysis_type: string) => {
  return http.post(
    AWS_PROGRESS,
    JSON.stringify({ task_id: taskId, analysis_type })
  );
};

const termiateAwsProcess = (taskId: number, analysis_type: string) => {
  return http.post(
    TERMINATE_AWS_PROCESS,
    JSON.stringify({ task_id: taskId, analysis_type })
  );
};

const getUserScore = (taskId: number) => {
  return http.post(USER_SCORE_URL, JSON.stringify({ task_id: taskId }));
};
const getScoreFrequency = (taskId: number) => {
  return http.post(SCORE_FREQUENCY_URL, JSON.stringify({ task_id: taskId }));
};
const getImportance = (taskId: number) => {
  return http.post(IMPORTANCE_URL, JSON.stringify({ task_id: taskId }));
};

const setUploadStatus = (taskId: number, groupId: number, status: string) => {
  return http.post(
    UPLOAD_STATUS,
    JSON.stringify({ task_id: taskId, status: status, group_id: groupId })
  );
};

const termiateProcess = (taskId: number) => {
  return http.post(TERMINATE_PROCESS, JSON.stringify({ task_id: taskId }));
};

const exportExtractionReport = (taskId: number) => {
  return http.request(EXPORT_REPORT_URL, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId })
  });
};
const getDecisionTreeImage = (taskId: number) => {
  const payload = {
    task_id: taskId
  };
  const response = http.post(DECISION_TREE_IMAGE_URL, JSON.stringify(payload));
  return response;
};
const targetAPI = {
  getTaskId,
  getInputFileHeaders,
  getPresignedUrl,
  registerTargetData,
  getExtractionStatus,
  getUserScore,
  getScoreFrequency,
  getImportance,
  exportExtractionReport,
  setUploadStatus,
  termiateProcess,
  postAwsFile,
  getAwsProgress,
  termiateAwsProcess,
  getDecisionTreeImage
};

export default targetAPI;
