<template>
  <section class="image-viewer-card d-flex justify-content-center w-100 py-3">
    <div class="card w-100">
      <div class="card-body">
        <div v-if="isLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <canvas v-else id="viewerCanvas" width="1024" height="420"></canvas>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { ref } from '@vue/reactivity';
import { defineComponent, onMounted, watchEffect } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ImageViewer from '@/utils/image-viewer.js';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ImageViewer',
  setup() {
    const canvasId = 'viewerCanvas';
    const imageViewer = ref<any>(null);
    const store = useStore();
    const imageURL = ref<any>(null);
    const isLoading = ref<boolean>(false);
    const getImage = () => {
      return new Promise((resolve, reject) => {
        isLoading.value = true;
        try {
          imageViewer.value = new ImageViewer(canvasId, imageURL.value);
          resolve(imageViewer.value);
        } catch (err) {
          reject(err);
        } finally {
          isLoading.value = false;
        }
      });
    };
    onMounted(() => {
      if (imageURL.value && canvasId) {
        getImage().catch(err => console.log(err));
      }
    });
    watchEffect(() => {
      imageURL.value = store.state.te.decisionTreeImageURL;
      if (imageURL.value && canvasId) {
        getImage().catch(err => console.error(err));
      }
    });
    return { isLoading };
  }
});
</script>
<style lang="scss" scoped>
</style>
