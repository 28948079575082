import { http } from '@/utils/http';

const CREATE_GROUP_ROUTE = '/api/v1/group/create';
const INVITE_MEMBERS_ROUTE = '/api/v1/invite_users';
const CONTRACT_URL = '/api/v1/manager_contract';
const UPDATE_GROUP_NAME_URL = '/api/v1/manager_group_edit';
const ADD_GROUP_MEMBERS_URL = '/api/v1/add_member';
const ADD_GROUP_URL = '/api/v1/add_group';
const GROUP_MEMBERS_URL = '/api/v1/manager_group';
const INVOICE_LIST_URL = '/api/v1/invoice/list';
const INVOICE_DOWNLOAD_URL = '/api/v1/invoice/detail';
const DELETE_GROUP_URL = '/api/v1/delete_group';
const DELETE_MEMBER_URL = '/api/v1/delete_members';
const GROUP_QUOTA_INCREASE_URL = '/api/v1/increase_group_quota';
export interface CreateGroupData {
  name: string;
  email: string;
  token: string;
}

export interface InviteMembersData {
  group_id: number;
  receiver: Array<string>;
}

export interface ContractUpdateInterface {
  id: number;
  contractNumber: string;
  contractDate: string;
  company: string;
  friganaCompany: string;
  org: string;
  person: string;
  friganaPerson: string;
  phone: string;
  address: string;
  contractFunction: Array<string>;
}
export interface ContractDataInterface {
  id: number;
  contractNumber: string;
  contractDate: string;
  company: string;
  friganaCompany: string;
  org: string;
  person: string;
  friganaPerson: string;
  phone: string;
  address: string;
  contractFunction: Array<string>;
}

const createGroup = (data: CreateGroupData) => {
  return http.post(CREATE_GROUP_ROUTE, JSON.stringify(data));
};

const inviteMembers = (data: InviteMembersData) => {
  return http.post(INVITE_MEMBERS_ROUTE, JSON.stringify(data));
};

export const loadContract = () => {
  return http.get(CONTRACT_URL);
};

export const updateContract = (data: ContractUpdateInterface) => {
  const newdata = {
    id: data.id,
    company_name: data.company,
    frigana_company_name: data.friganaCompany,
    organization_department: data.org,
    person_incharge: data.person,
    frigana_person_incharge: data.friganaPerson,
    phone_number: data.phone,
    street_address: data.address
  };
  return http.put(CONTRACT_URL, JSON.stringify(newdata));
};

export const loadGroupAndMembers = ({
  page,
  perPage,
  groupId
}: {
  page: number;
  perPage: number;
  groupId: number;
}) => {
  const url = `${GROUP_MEMBERS_URL}?page=${page}&perPage=${perPage}`;
  return http.post(url, JSON.stringify({ group_id: groupId }));
};

export const updateGroupName = ({ id, name }: { id: number; name: string }) => {
  return http.put(UPDATE_GROUP_NAME_URL, JSON.stringify({ id, name }));
};

export const addGroup = ({
  name,
  receiver
}: {
  name: string;
  receiver: Array<string>;
}) => {
  return http.post(ADD_GROUP_URL, JSON.stringify({ name, receiver }));
};
export const addGroupMembers = (groupId: number, members: Array<string>) => {
  return http.post(
    ADD_GROUP_MEMBERS_URL,
    JSON.stringify({ group_id: groupId, members })
  );
};

export const getInvoiceList = (page: number, perPage?: number) => {
  const query = `?page=${page}&perPage=${perPage ? perPage : 10}`;
  const requestURL = INVOICE_LIST_URL + query;
  return http.get(requestURL);
};

export const downloadInvoice = (invoiceId: number) => {
  return http.request(INVOICE_DOWNLOAD_URL, {
    method: 'POST',
    body: JSON.stringify({ invoice_id: invoiceId })
  });
};

export const deleteGroup = (groupId: number) => {
  return http.post(DELETE_GROUP_URL, JSON.stringify({ group_id: groupId }));
};

export const deleteMember = ({
  groupId,
  email
}: {
  groupId: number;
  email: string;
}) => {
  return http.post(
    DELETE_MEMBER_URL,
    JSON.stringify({ group_id: groupId, email })
  );
};

export const increaseGroupQuota = (groupId: number) => {
  return http.post(
    GROUP_QUOTA_INCREASE_URL,
    JSON.stringify({ group_id: groupId })
  );
};

export default {
  createGroup,
  inviteMembers,
  loadContract,
  updateContract,
  loadGroupAndMembers,
  updateGroupName,
  addGroup,
  addGroupMembers,
  getInvoiceList,
  downloadInvoice,
  deleteGroup,
  deleteMember,
  increaseGroupQuota
};
