import { object, string, ref } from 'yup';
const resetSchema = object({
  oldPassword: string().required(() => 'errors.required-password'),
  newPassword: string()
    .required(() => 'errors.required-field')
    .min(8, () => 'errors.password-short'),
  confirmNewPassword: string()
    .required(() => 'errors.required-field')
    .oneOf([ref('newPassword'), null], () => 'errors.passwords-not-match')
});
export default resetSchema;
