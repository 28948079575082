
import { defineComponent, markRaw } from 'vue';

import { dropdownIcon } from '@/utils/icons';
import UserScore from '@/components/target-extraction/UserScore.vue';
import ScoreFrequency from '@/components/target-extraction/ScoreFrequency.vue';
import ImportanceScore from '@/components/target-extraction/ImportanceScore.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'ExtractionReport',
  props: ['taskId'],
  setup() {
    const icons = markRaw({
      dropdownIcon
    });
    return { isLoading: false, icons };
  },
  components: {
    UserScore,
    ScoreFrequency,
    ImportanceScore,
    VizButton
  },
  methods: {
    ...mapActions(['exportExtractionReport']),
    handleExport: function() {
      this.isLoading = true;

      this.exportExtractionReport(this.taskId)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
});
