<template>
  <div class="login max__width--form bg-white p-4">
    <form
      action="post"
      class="login__form"
      @submit.prevent="onSubmit"
      novalidate
    >
      <figure class="mb-3">
        <img :src="icons.logo" alt="" style="padding: 0 10px;height: 110px;" />
      </figure>
      <legend>{{ $t('pages.forgot.h1') }}</legend>
      <p class="text-muted mb-2">
        {{ $t('pages.forgot.h2') }}
      </p>
      <p class="text-danger pb-3">{{ state.errorMessage }}</p>
      <div class="input-groups">
        <input
          type="email"
          class="form-control"
          v-model="email"
          :placeholder="$t('labels.email')"
          id="login-email"
        />
        <label for="login-email">{{ $t('labels.email') }}</label>
        <span class="text-danger">{{
          errors.email ? $t(errors.email) : ''
        }}</span>
      </div>

      <div class="d-block mt-3 button">
        <viz-button
          type="submit"
          class="btn btn-primary text-white px-4"
          :loading="state.isLoading"
          :disabled="!meta.valid"
        >
          {{ $t('labels.send') }}
        </viz-button>
      </div>
      <router-link to="/login" class="text-primary d-block mt-3">
        {{ $t('common.buttonlabels.back-to-login') }}
      </router-link>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import validationSchema from '@/validator/forgot';
import { logo } from '@/utils/icons';

interface ErrorList {
  error?: string;
  email?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  components: {
    VizButton
  },
  setup() {
    const icons = markRaw({
      logo: logo
    });
    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const { errors, handleSubmit, meta } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();

    const onSubmit = handleSubmit((values, { setFieldError }) => {
      state.isLoading = true;
      store
        .dispatch('forgotPassword', values.email)
        .then(() => {
          state.isLoading = false;

          router.push('/forgot/complete');
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;

          if (err.errors[0]?.error) {
            state.errorMessage = err.errors[0].error;
          }
          if (err.errors[0]?.email) {
            setFieldError('email', err.errors[0].email);
          }
        });
    });

    const { value: email } = useField('email');

    return {
      email,
      state,
      onSubmit,
      errors,
      meta,
      icons
    };
  }
});
</script>

<style lang="scss" scoped></style>
