export const contractFunctions = {
  CONTRIBUTION_ANALYSIS: 'contribution_analysis',
  TARGET_EXTRACTION: 'target_extraction',
  MEASURE_SIMULATION: 'measure_simulation',
  HISTORY: 'import_history',
  SETTINGS: 'settings',
  GROUP_CREATION: 'group_creation'
};

export const userRoles = {
  USER: 2,
  MANAGER: 1
};
