
import { defineComponent, reactive } from 'vue';
import { useStore, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import VizButton from '@/components/ui/VizButton.vue';

export default defineComponent({
  name: 'Header',
  emits: ['toggleSidebar', 'changeLocale'],
  components: {
    VizButton
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      errorMessage: ''
    });

    const handleLogout = () => {
      store.dispatch('logout').then(() => {
        router.push({ name: 'Login', path: '/login' });
      });
    };

    const handleGroupSelect = (group: { id: number; name: string }) => {
      store
        .dispatch('changeUserGroup', group)
        .then(() => {
          state.errorMessage = '';
        })
        .catch(err => {
          state.errorMessage = err?.errors ? err?.errors[0]?.error : '';
        });
    };

    return { handleLogout, handleGroupSelect, state };
  },
  computed: {
    ...mapGetters(['userGroup', 'groups', 'accountDetails', 'user']),
    pageKey: function() {
      const pageKey: string = this.$route.meta.pageKey;

      return pageKey;
    },

    headerIcon: function() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const headerIcon: any = this.$route.meta.headerIcon;
      return headerIcon;
    }
  },

  methods: {
    toggleSidebar() {
      this.$emit('toggleSidebar');
    },
    handleCreateGroup() {
      this.$router.push({ name: 'AddGroup' });
    }
  }
});
