<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="130"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },
  props: ['chartdata', 'categories'],

  setup(props) {
    return {
      series: props.chartdata || [],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 'auto',
          stacked: true,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false
          }
        },
        colors: [
          '#F9DA54',
          '#f9a212',
          '#ED7A4E',
          '#DA4B55',
          '#F9A212',
          '#BE5504',
          '#CC5801',
          '#893101',
          '#2F65DD',
          '#B56727'
        ],

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20px',
            rangeBarGroupRows: true
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true
        },
        stroke: {
          show: false
        },
        xaxis: {
          show: true,
          categories: props.categories || [],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        grid: {
          show: true,
          borderColor: '#f7f7f7',
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 'bold',
              fontSize: '12px'
            },
            offsetX: 3,
            offsetY: 3
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },

        legend: {
          show: true,
          offsetX: 40,

          horizontalAlign: 'left'
        }
      }
    };
  },
  watch: {
    chartdata: function(newVal) {
      this.series = newVal;
    },
    categories: function(newVal) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: newVal
          }
        }
      };
    }
  }
});
</script>

<style scoped>
.row {
  overflow: hidden;
}
.p-40 {
  padding-top: 40px;
}
.text {
  padding-bottom: 12px;
  border-bottom: 1px solid #f7f7f7;
  margin-bottom: 12px;
  position: relative;
}
.text::after {
  position: absolute;
  content: '';
  width: 100%;
  right: -100%;
  height: 1px;
  top: 100%;
  background: #f7f7f7;
}
</style>
