
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('te');
import store from '@/store';

export default defineComponent({
  name: 'TargetAnalysisLoader',
  props: {
    isAnalysed: Boolean,
    steps: Array,
    currentStep: Number
  },

  components: {
    StepsTrack,
    VizButton
  },

  emits: ['saveAnalysis', 'goBack'],
  setup() {
    const states = markRaw({
      LOADING: 'loading_data',
      PREPROCESSING: 'preprocessing',
      TRAINING_AND_PREDICT: 'training_and_predicting',
      EVALUATING: 'evaluating',
      CALCULATE_IMPORTANCE: 'calculating importances',
      FINISH_ESTIMATING: 'finish_process'
    });
    const state = reactive({
      analysisPoll: 0,
      errorMessage: ''
    });
    return { state, states, abortActive: true };
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
  },

  unmounted() {
    this.setExitAllowed(true);
  },
  beforeUnmount() {
    store.dispatch('getStorageUsage', store.getters.userGroup?.id);

    clearInterval(this.state.analysisPoll);
  },
  computed: {
    ...mapGetters(['extractionStatus']),

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.extractionStatus?.logs)) {
        return logs;
      }
      this.extractionStatus.logs.forEach((item: Array<string>) => {
        logs = [...logs, ...item];
      });
      return logs;
    },
    analysisError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.extractionStatus.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.extractionStatus?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },
    analysisPercentage: function() {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    },
    isProcessComplete: function(): boolean {
      return this.isStateComplete(this.states.FINISH_ESTIMATING);
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions([
      'getExtractionStatus',
      'terminateUpload',
      'resetTargetState'
    ]),
    pullData() {
      this.state.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_ESTIMATING)) {
          this.abortActive = false;
          clearInterval(this.state.analysisPoll);
        }
        this.getExtractionStatus()
          .then(() => {
            this.state.errorMessage = '';

            if (this.analysisError.status) {
              this.abortActive = false;
              this.state.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            if (err && Array.isArray(err.errors)) {
              this.abortActive = false;
              this.state.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },
    hasStateError(state: string) {
      return this.analysisError.failedSteps?.includes(state) ? true : false;
    },
    isStateComplete(state: string): boolean {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },
    terminateProces() {
      this.terminateUpload();
      this.resetTargetState();
      this.$emit('goBack');
    }
  },
  watch: {
    analysisError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.state.errorMessage = 'errors.analysis-failed';
        clearInterval(this.state.analysisPoll);
      }
    }
  }
});
