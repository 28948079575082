
import { ref } from '@vue/reactivity';
import { defineComponent, onMounted, watchEffect } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ImageViewer from '@/utils/image-viewer.js';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ImageViewer',
  setup() {
    const canvasId = 'viewerCanvas';
    const imageViewer = ref<any>(null);
    const store = useStore();
    const imageURL = ref<any>(null);
    const isLoading = ref<boolean>(false);
    const getImage = () => {
      return new Promise((resolve, reject) => {
        isLoading.value = true;
        try {
          imageViewer.value = new ImageViewer(canvasId, imageURL.value);
          resolve(imageViewer.value);
        } catch (err) {
          reject(err);
        } finally {
          isLoading.value = false;
        }
      });
    };
    onMounted(() => {
      if (imageURL.value && canvasId) {
        getImage().catch(err => console.log(err));
      }
    });
    watchEffect(() => {
      imageURL.value = store.state.te.decisionTreeImageURL;
      if (imageURL.value && canvasId) {
        getImage().catch(err => console.error(err));
      }
    });
    return { isLoading };
  }
});
