<template>
  <div id="chart">
    <apexchart
      type="bar"
      v-bind="$attrs"
      :options="chartOptions"
      :series="series"
      width="100%"
    ></apexchart>
  </div>
</template>

<script lang="ts">
import VueApexCharts from 'vue3-apexcharts';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },
  props: {
    data: Array,
    labels: Array
  },

  data(props) {
    return {
      series: [
        {
          data: props.data || []
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false
          },
          offsetX: 10,
          offsetY: 0
        },
        colors: ['#f9a212'],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            columnWidth: '15%'
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },

        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          type: 'category',
          categories: props.labels,
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1
        }
      }
    };
  },
  watch: {
    data: function(newVal) {
      this.series = [{ data: newVal }];
    },
    labels: function(newLabels) {
      this.chartOptions.xaxis.categories = newLabels;
    }
  }
});
</script>

<style></style>
