
import VueApexCharts from 'vue3-apexcharts';
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },
  props: ['chartdata', 'categories', 'target', 'yaxis'],

  data(props) {
    return {
      series: props.chartdata || [],

      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          },

          sparkline: {
            enabled: false
          }
        },

        fill: {
          opacity: Array.isArray(props.chartdata)
            ? props.chartdata.map(item => (item.type == 'column' ? 0.7 : 1))
            : 1
        },
        stroke: {
          width: Array.isArray(props.chartdata)
            ? props.chartdata.map(item => (item.type == 'column' ? 0 : 2))
            : 2
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true
        },
        title: {
          text: ''
        },

        colors: ['#F9DA54', '#f9a212', '#ED7A4E', '#DA4B55', '#F9A212'],

        xaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            rotate: 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function(value: any) {
              return value ? moment(value).format('YY/MM/DD') : value;
            }
          },
          categories: props.categories,
          tickAmount: Array.isArray(props.categories)
            ? props.categories.length > 20
              ? 10
              : props.categories.length / 2
            : 0
        },
        yaxis: props.yaxis
      }
    };
  },
  watch: {
    chartdata: function(newVal) {
      this.series = newVal;
    },
    categories: function(newCategories) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: newCategories
          }
        }
      };
    }
    // yaxis: function(newVal) {
    //   this.chartOptions = { ...this.chartOptions, yaxis: newVal };
    // }
  }
});
