<template>
  <div class="custom-switch mb-4">
    <div class="d-flex align-items-center">
      <div class="form-switch_custom">
        <input
          type="checkbox"
          :id="fieldId"
          :checked="modelValue"
          @change="onChange"
        />
        <label class="form-check-label bg-primary" :for="fieldId"></label>
      </div>
      <div class="ms-2 mt-2">
        <slot></slot>
        <viz-tooltip>
          {{ tooltip }}
        </viz-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';

export default defineComponent({
  name: 'VizSwitchbox',
  emits: ['update:modelValue'],
  props: ['fieldId', 'modelValue', 'tooltip'],
  components: {
    VizTooltip
  },
  setup() {
    return {};
  },
  methods: {
    changeValue(value: any) {
      this.$emit('update:modelValue', value); // previously was `this.$emit('input', title)`
    },
    onChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const value = target?.checked;
      this.changeValue(value);
    }
  }
});
</script>

<style scoped></style>
