<template>
  <div class="login max__width--form bg-white p-4">
    <form action="post" class="login__form">
      <figure class="mb-3">
        <img :src="icons.logo" alt="" style="padding: 0 10px;height: 110px;" />
      </figure>
      <legend>{{ $t('pages.resetc.title') }}</legend>

      <div class="d-block mt-3 button">
        <viz-button
          type="button"
          @click="handleProceed"
          class="btn btn-primary text-white px-4"
        >
          {{ $t('pages.resetc.btnTitle') }}
        </viz-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { logo } from '@/utils/icons';
import VizButton from '@/components/ui/VizButton.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ResetComplete',
  components: {
    VizButton
  },
  setup() {
    const router = useRouter();

    const icons = markRaw({
      logo: logo
    });
    const handleProceed = async () => {
      router.push('/login');
    };
    return { icons, handleProceed };
  }
});
</script>

<style scoped></style>
