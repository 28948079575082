
import { defineComponent } from 'vue';
import { helpIcon } from '@/utils/icons';
import HorizontalBar from '@/components/contribution-analysis/ContributionHorizontalBar.vue';
import { KPIEsimateInterface } from '@/store/modules/contribution-analysis';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

interface DataColumnOption {
  value: number | null;
  color: string;
  color_intensity: string;
}
interface DataColumn {
  has_option: DataColumnOption | null;
  no_option: DataColumnOption;
}

export default defineComponent({
  name: 'KpiCoefResult',
  props: ['taskId'],
  data() {
    return {
      data: [] as Array<KPIEsimateInterface>,
      helpIcon,
      errorMessage: '',
    };
  },
  beforeMount() {
    this.getKPICoefEstimate(this.taskId)
      .then((data: Array<KPIEsimateInterface>) => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch((err) => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  components: {
    HorizontalBar,
  },

  methods: {
    ...mapActions(['getKPICoefEstimate']),
    getLabels(column: DataColumn) {
      return [
        column.no_option
          ? column.no_option.value === null
            ? ''
            : Number(column?.no_option?.value).toFixed(3)
          : '',
        column.has_option
          ? column.has_option.value === null
            ? ''
            : Number(column?.has_option?.value).toFixed(3)
          : '',
      ];
    },
    getBarValue: function (
      data: Array<KPIEsimateInterface>,
      type: string
    ): any {
      if (!data) return 1;

      const no_option: number[] = data.map((e) => Number(e.no_option?.value));
      const has_option: number[] = data.map((e) => Number(e.has_option?.value));
      const mergedArr: number[] = [...no_option, ...has_option].filter(Number);

      if (type === 'max') {
        return Math.max(...mergedArr);
      } else {
        return Math.min(...mergedArr);
      }
    },
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.data = [];

        this.getKPICoefEstimate(this.taskId)
          .then((data: Array<KPIEsimateInterface>) => {
            this.errorMessage = '';
            this.data = data;
          })
          .catch((err) => {
            this.data = [];
            this.errorMessage = err?.errors ? err.errors[0].error : '';
          });
      }
    },
  },
});
