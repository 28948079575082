
import VueApexCharts from 'vue3-apexcharts';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },
  props: [
    'colors',
    'colorIntensities',
    'data',
    'yaxisMin',
    'yaxisMax',
    'labels',
  ],
  setup(props) {
    const getColors = props.colors
      ? props.colors.map((color: string, index: number) => {
          const colorCode = color === 'orange' ? '#e1683d' : '#f9a212';
          if (
            props.colorIntensities &&
            props.colorIntensities[index] === 'light'
          ) {
            return colorCode.concat('7f');
          }
          return colorCode;
        })
      : ['#aaa'];

    return {
      series: [
        {
          data: props.data || [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          // stacked: true,
          height: 'auto',
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No data available',
        },
        legend: {
          show: false,
        },
        colors: getColors,
        plotOptions: {
          bar: {
            horizontal: true,
            //to show different color for each bar the distributed property needs to be true
            distributed: true,
            columnWidth: '100%',
            barHeight: '50%',
            borderRadius: 4,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '11px',
            colors: ['#999999'],
          },
          formatter: function (val: any, { dataPointIndex }: any) {
            /*
            ?NOTE: dataPointIndex 1 is for has_option values , 0 is for no_option value
              */
            const isNull = val === null;

            if (isNull && dataPointIndex == 1) {
              return '季節効果に関わるボリュームデータ無し';
            }
            if (isNull && dataPointIndex == 0) {
              return '季節効果に関わるボリュームデータ無し';
            }
          },
        },

        stroke: {
          show: false,
          colors: ['transparent'],
          width: 0,
        },

        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            bottom: 10,
            left: 50,
          },
        },
        yaxis: {
          min: props.yaxisMin,
          max: props.yaxisMax,
          showForNullSeries: false,
          title: {
            // text: 'Age',
          },
          labels: {
            maxWidth: 250,
            align: 'right',

            style: {
              colors: getColors,
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'Helvetica, Arial, sans-serif',
              overflow: 'visible',
              textOverflow: 'show',
            },

            offsetX: 3,
            offsetY: 3,
          },
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
          shared: false,
          x: {
            formatter: function (val: number) {
              return val;
            },
          },
          y: {
            formatter: function (val: number) {
              return Math.abs(val) + '%';
            },
          },
        },
        title: {
          text: '',
        },
        xaxis: {
          show: false,
          fill: {
            colors: ['#F44336', '#E91E63', '#9C27B0'],
          },
          categories: props.labels || [],
          title: {
            text: '',
          },
          axisBorder: {
            show: false,
          },

          labels: {
            show: false,

            formatter: function (val: number) {
              return Math.abs(Math.round(val)) + '%';
            },
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
      },
    };
  },
  watch: {
    data: function (newVal) {
      this.series = [{ data: newVal }];
    },
    labels: function (newLabels) {
      this.chartOptions.xaxis.categories = newLabels;
    },
  },
});
