
import { defineComponent, markRaw } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';
import store from '@/store';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'AnalysisObserve',
  components: {
    VizButton
  },
  beforeMount() {
    const { taskId } = this.$route.params;
    if (!taskId) {
      this.$router.replace({ path: '/analysis' });
    }
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
  },

  unmounted() {
    this.setExitAllowed(true);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ContributionAnalysisResult' && this.taskId) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.resetKpiState();
        this.getTaskId();

        next();
      }
    } else {
      next();
    }
  },
  beforeUnmount() {
    store.dispatch('getStorageUsage', store.getters.userGroup?.id);
    clearInterval(this.analysisPoll);
  },
  setup() {
    const states = markRaw({
      LOADING: 'loading_dataset',
      PREPROCESSING: 'preprocessing',
      ESTIMATING: 'estimating_contribution',
      POSTPREPROCESSING: 'postprocessing',
      FINISH_ESTIMATING: 'finish_estimating_process'
    });

    return { analysisPoll: 0, errorMessage: '', abortActive: true, states };
  },
  computed: {
    ...mapGetters(['analysisState', 'taskId']),
    analysisError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.analysisState.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    activateProceed: function(): boolean {
      return (
        Array.isArray(this.analysisState?.states) &&
        this.analysisState.states.includes('finish_estimating_process')
      );
    },

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.analysisState?.logs)) {
        return logs;
      }
      this.analysisState.logs.forEach((item: Array<string>) => {
        logs = [...item, ...logs];
      });
      return logs;
    },

    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.analysisState?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },

    analysisPercentage: function(): number {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions([
      'getAnalysisState',
      'resetKpiState',
      'getTaskId',
      'terminateUpload',
      'resetKPIFileInputFormState'
    ]),
    handleAnalysisCompletion() {
      this.$router.push({
        name: 'ContributionAnalysisResult',
        path: '/analysis/result',
        params: { taskId: this.$route.params?.taskId }
      });
    },
    hasStateError(state: string) {
      return this.analysisError.failedSteps?.includes(state) ? true : false;
    },
    pullData() {
      this.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_ESTIMATING)) {
          this.abortActive = false;
          clearInterval(this.analysisPoll);
        }
        this.getAnalysisState(Number(this.$route.params?.taskId))
          .then(() => {
            this.errorMessage = '';
            if (this.analysisError.status) {
              this.abortActive = false;
              this.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            this.abortActive = false;

            if (err && Array.isArray(err.errors)) {
              this.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },

    isStateComplete(state: string) {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },
    terminateProces() {
      this.terminateUpload();
      this.resetKpiState();
      this.$router.push({ name: 'ContributionEnterData' });
    }
  },
  watch: {
    analysisError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.errorMessage = 'errors.analysis-failed';
        clearInterval(this.analysisPoll);
      }
    }
  }
});
