
import { defineComponent, markRaw } from 'vue';
import { logo } from '@/utils/icons';

export default defineComponent({
  setup() {
    const icons = markRaw({
      logo: logo
    });
    return { icons };
  }
});
