<template>
  <div
    class="pagination d-flex align-items-center justify-content-end"
    v-if="totalEntries > 0"
  >
    <div class="pagination-number d-flex align-items-center">
      <div class="line me-4">{{ $t('labels.paginate-title') }}</div>
      <div class="number me-4">
        <form action="">
          <select
            name="perPageEntries"
            class="d-block"
            @change="handlePageCountChange"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <i class="fa">
            <img :src="icons.down" alt="" />
          </i>
        </form>
      </div>
      <div class="page me-4">
        {{ perPageCount * (currentPage - 1) + 1 }}-{{ numberOfLines }} 件目 /
        {{ totalEntries }}
      </div>
    </div>
    <div
      class="pagination-nav d-flex align-items-center justify-content-between p-2"
    >
      <div class="prev">
        <button type="button" class="btn" @click="previousPage">
          <figure>
            <img :src="icons.prev" alt=" previous" />
          </figure>
        </button>
      </div>
      <div class="next">
        <button type="button" class="btn" @click="nextPage">
          <figure>
            <img :src="icons.next" alt="next " />
          </figure>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import { downIcon, nextIcon, prevIcon } from '@/utils/icons';

export default defineComponent({
  props: {
    currentPage: { type: Number, default: 1 },
    perPageCount: { type: Number, default: 10 },
    totalEntries: { type: Number, default: 0 }
  },
  emits: ['onPageChange'],
  setup(props) {
    const state = reactive({
      totalPages: Math.ceil(props.totalEntries / props.perPageCount)
    });

    const icons = markRaw({
      down: downIcon,
      next: nextIcon,
      prev: prevIcon
    });
    return { icons, state };
  },
  computed: {
    numberOfLines(): number {
      const totalLines = this.perPageCount * this.currentPage;
      if (this.totalEntries < totalLines) {
        return this.totalEntries;
      }
      return totalLines;
    }
  },
  watch: {
    totalEntries(newVal) {
      if (newVal) {
        this.state.totalPages = Math.ceil(newVal / this.perPageCount);
      } else {
        this.state.totalPages = 1;
      }
    }
    // perPageCount(oldVal, newVal) {

    //   if (newVal) {
    //     this.state.totalPages = Math.ceil(Number(this.totalEntries) / newVal);
    //   } else {
    //     this.state.totalPages = 1;
    //   }
    // }
    // currentPage(oldVal, newVal) {

    //   if (newVal) {
    //     this.currentPage = Number(this.currentPage);
    //   } else {
    //     this.currentPage = 1;
    //   }
    // }
  },
  methods: {
    handlePageCountChange(ev: Event) {
      const target = ev.target as HTMLInputElement;
      const newPerPageCount = Number(target.value);
      this.state.totalPages = Math.ceil(
        Number(this.totalEntries) / newPerPageCount
      );
      this.$emit('onPageChange', 1, newPerPageCount);
    },
    handlePageChange(page: number) {
      this.$emit('onPageChange', page, this.perPageCount);
    },
    nextPage() {
      const newPage =
        !this.currentPage ||
        this.state.totalPages <= 1 ||
        this.currentPage >= this.state.totalPages
          ? this.currentPage
          : this.currentPage + 1;
      if (newPage !== this.currentPage) {
        this.handlePageChange(newPage);
      }
    },
    previousPage() {
      const newPage =
        !this.currentPage ||
        this.currentPage === 1 ||
        this.state.totalPages <= 1
          ? this.currentPage
          : this.currentPage - 1;

      if (newPage !== this.currentPage) {
        this.handlePageChange(newPage);
      }
    }
  }
});
</script>

<style scoped></style>
