/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import { RootState } from '../index';
import historyAPI from '@/api/history';

export interface HistoryState {
  status: string | null;
  taskId: number | null;
  analysisHistory: { data: Array<AnalysisHistory>; totalEntries: number };
  importHistory: { data: Array<ImportHistory>; totalEntries: number };
}
export interface AnalysisHistory {
  task_id: number;
  execution_date: string;
  name: string;
  type_of_analysis: string;
  username: string;
  feature: string;
}

export interface ImportHistory {
  task_id: string;
  file_upload_date: string;
  filename: string;
  username: string;
  feature: string;
}

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  status: null,
  analysisHistory: {
    data: [],
    totalEntries: 0
  },
  importHistory: {
    data: [],
    totalEntries: 0
  }
};

export default {
  state: initialState,
  mutations: {
    setAnalysisHistory(
      state: HistoryState,
      data: { data: Array<AnalysisHistory>; totalEntries: number }
    ) {
      state.analysisHistory = data;
    },
    setImportHistory(
      state: HistoryState,
      data: { data: Array<ImportHistory>; totalEntries: number }
    ) {
      state.importHistory = data;
    },

    statusPending(state: HistoryState) {
      state.status = STATUS.LOADING;
    },
    statusSuccess(state: HistoryState) {
      state.status = STATUS.SUCCESS;
    },
    statusError(state: HistoryState) {
      state.status = STATUS.ERROR;
    }
  },
  actions: {
    getAnalysisHistory(
      context: ActionContext<HistoryState, RootState>,
      { page, perPage }: { page: number; perPage: number }
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        historyAPI
          .getAnalysisHistory({ groupId, page, perPage })
          .then(res => {
            const { tasks, total_entries } = res.data;
            const histories = { data: tasks, totalEntries: total_entries };
            context.commit('setAnalysisHistory', histories);
            resolve(histories);
          })
          .catch(err => {
            context.commit('setAnalysisHistory', { data: [], totalEntries: 0 });
            reject(err);
          });
      });
    },
    getImportHistory(
      context: ActionContext<HistoryState, RootState>,
      { page, perPage }: { page: number; perPage: number }
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        historyAPI
          .getImportHistory({ groupId, page, perPage })
          .then(res => {
            const { import_history, total_entries } = res?.data;
            const histories = {
              data: import_history,
              totalEntries: total_entries
            };
            context.commit('setImportHistory', histories);
            resolve(histories);
          })
          .catch(err => {
            context.commit('setImportHistory', { data: [], totalEntries: 0 });
            reject(err);
          });
      });
    },
    deleteImportHistory(
      context: ActionContext<HistoryState, RootState>,
      { taskId, feature }: { taskId: number; feature: string }
    ) {
      return new Promise((resolve, reject) => {
        historyAPI
          .deleteImportHistory({ taskId, feature })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteMultipleImportHistory(
      context: ActionContext<HistoryState, RootState>,
      payload: object
    ) {
      return new Promise((resolve, reject) => {
        historyAPI
          .deleteMultipleImportHistory(payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    deleteMultipleAnalysisHistory(
      context: ActionContext<HistoryState, RootState>,
      payload: object
    ) {
      return new Promise((resolve, reject) => {
        historyAPI
          .deleteMultipleAnalysisHistory(payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    deleteAnalysisHistory(
      context: ActionContext<HistoryState, RootState>,
      { taskId, feature }: { taskId: number; feature: string }
    ) {
      return new Promise((resolve, reject) => {
        historyAPI
          .deleteAnalysisHistory({ taskId, feature })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    abortTask(
      context: ActionContext<HistoryState, RootState>,
      { taskId, feature }: { taskId: number; feature: string }
    ) {
      return new Promise((resolve, reject) => {
        historyAPI
          .abortTask({ taskId, feature })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },

  getters: {
    analysisHistory: (state: HistoryState) => state.analysisHistory,
    importHistory: (state: HistoryState) => state.importHistory
  }
};
