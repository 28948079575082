import { object, mixed, string } from 'yup';

const registerDataSchema = object({
  predictionDataInput: mixed(),
  targetColumn: string().required(() => 'errors.required-field'),
  method: string().required(() => 'errors.required-field'),
  idColumn: string().required(() => 'errors.required-field')
});

export default registerDataSchema;
