<template>
  <div class="explanatory">
    <div class="row">
      <form action="" class="row">
        <div class="col-sm-4">
          <div class="sub_title">
            <h4>{{ $t('pages.te.ar.title') }}</h4>
          </div>
        </div>
        <div
          class="col col-sm-8 d-flex align-items-center  justify-content-center justify-content-sm-end"
        >
          <viz-button
            type="button"
            class="btn btn-outline-primary has-dropdown"
            @click="handleExport"
            :loading="isLoading"
          >
            {{ $t('common.buttonlabels.export') }}
            <span class="d-inline-block ms-3 me-2">
              <figure>
                <img :src="icons.dropdownIcon" alt="" />
              </figure>
            </span>
          </viz-button>
        </div>
      </form>
      <div class="col col-lg-7">
        <user-score :taskId="taskId"></user-score>
      </div>
      <div class=" col col-lg-5">
        <score-frequency :taskId="taskId"></score-frequency>
      </div>
    </div>
    <importance-score :taskId="taskId"></importance-score>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';

import { dropdownIcon } from '@/utils/icons';
import UserScore from '@/components/target-extraction/UserScore.vue';
import ScoreFrequency from '@/components/target-extraction/ScoreFrequency.vue';
import ImportanceScore from '@/components/target-extraction/ImportanceScore.vue';
import VizButton from '@/components/ui/VizButton.vue';

import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'ExtractionReport',
  props: ['taskId'],
  setup() {
    const icons = markRaw({
      dropdownIcon
    });
    return { isLoading: false, icons };
  },
  components: {
    UserScore,
    ScoreFrequency,
    ImportanceScore,
    VizButton
  },
  methods: {
    ...mapActions(['exportExtractionReport']),
    handleExport: function() {
      this.isLoading = true;

      this.exportExtractionReport(this.taskId)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
