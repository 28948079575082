
import { defineComponent, reactive } from 'vue';
import { mapActions } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';

export default defineComponent({
  props: ['member', 'groupId'],
  emits: ['refreshGroup'],
  components: {
    VizButton
  },
  setup() {
    const state = reactive({
      errorMessage: ''
    });
    return { state };
  },

  methods: {
    ...mapActions(['deleteMember', 'addToast', 'loadGroupAndMembers']),
    handleMemberDelete() {
      if (!this.groupId) return;
      this.deleteMember({
        groupId: Number(this.groupId),
        email: this.member
      })
        .then(() => {
          this.closeLoadingModal();
          this.state.errorMessage = '';
          this.addToast({
            message: 'pages.settings.member-deleted',
            data: { member: this.member }
          });
          this.$emit('refreshGroup');
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0].error : '';
          if (!err?.errors) {
            this.state.errorMessage = 'Internal Server Error';
          }
          this.closeLoadingModal();
        });
    },
    closeLoadingModal() {
      document.getElementById('dismissMemberDelete')?.click() as any;
    }
  }
});
