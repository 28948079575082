<template>
  <div class="row">
    <form
      action="#"
      class="form-group__name"
      @submit.prevent="onSubmit"
      novalidate
    >
      <div class="col col-lg-6 col-xl-5">
        <legend>
          {{ $t('pages.settings.groups.group-field.title') }}
        </legend>
        <div class="d-flex align-items-center">
          <div class="input-groups mb-0 flex-grow-1">
            <input
              type="text"
              class="form-control"
              v-model="name"
              id="input-group__name"
              :readonly="!state.isEditing"
              required
            />

            <label for="input-group__name">
              {{ $t('pages.settings.groups.group-field.label') }}
            </label>
          </div>
          <viz-button
            v-if="state.isEditing"
            type="submit"
            :loading="state.isLoading"
            :disabled="!meta.valid"
            class="btn btn-primary rounded-pill text-white ms-2 text-nowrap"
          >
            {{ $t('labels.save-edits') }}
          </viz-button>
          <viz-button
            v-if="!state.isEditing"
            type="button"
            class="btn btn-primary rounded-pill text-white ms-2"
            style="min-width:100px"
            @click="handleEdit"
          >
            {{ $t('labels.edit') }}
          </viz-button>
        </div>
        <p class="text-danger">
          {{ errors && errors.name ? $t(errors.name) : '' }}
        </p>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useForm, useField } from 'vee-validate';
import { object, string } from 'yup';
import { useStore } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';

export default defineComponent({
  props: ['id', 'groupName'],
  components: {
    VizButton
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      isLoading: false,
      isEditing: false,
      toastMessage: ''
    });
    const {
      meta,
      errors,
      setFieldError,
      handleSubmit,
      setFieldValue
    } = useForm({
      initialValues: { name: '' },
      validationSchema: object({
        name: string().required(() => 'errors.required-field')
      })
    });
    const { value: name } = useField('name');
    const handleEdit = () => {
      state.isEditing = true;
    };
    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('updateGroupName', { id: props.id, ...values })
        .then(() => {
          store.dispatch('changeUserGroup', {
            id: props.id,
            name: values.name
          });
          //refetch user groups
          store.dispatch('fetchUserGroups');
          store.dispatch('addToast', {
            message: 'messages.group-name-changed'
          });
          state.isEditing = false;
          state.isLoading = false;
        })
        .catch(err => {
          const errs = err?.errors;
          if (Array.isArray(errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errs.forEach((item: any) => {
              if (item.error) setFieldError('name', item.error);
              if (item.name) setFieldError('name', item.name);
            });
          }

          state.isLoading = false;
        });
    });

    return { onSubmit, handleEdit, errors, meta, state, name, setFieldValue };
  },
  watch: {
    groupName(newVal) {
      this.setFieldValue('name', newVal);
    }
  }
});
</script>

<style scoped></style>
