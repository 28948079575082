
import { defineComponent } from 'vue';
import { attachFileIcon } from '@/utils/icons';
export default defineComponent({
  name: 'VizFileInput',
  data() {
    return {
      attachFileIcon,
    };
  },
  methods: {
    changeFiles(files: FileList | null) {
      this.$emit('update:modelValue', files); // previously was `this.$emit('input', title)`
    },
    onFileChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const files = target?.files;
      this.changeFiles(files);
    },
    terminateUpload() {
      this.$emit('terminate', '');
    },
  },

  props: ['label', 'modelValue', 'uploadProgress', 'uploadState'],
  emits: ['update:modelValue', 'terminate'],
  watch: {
    modelValue(newVal) {
      if (!newVal) {
        const fileRef = this.$refs.inputFileRef as any;
        fileRef.value = null;
      }
    },
  },
});
