<template>
  <div class="create-group max__width--form bg-white p-4">
    <form action="#" class="create-group__form">
      <figure class="mb-3">
        <img :src="logo" alt="" style="padding: 0 10px;height: 110px;" />
      </figure>
      <legend>{{ $t('pages.invite-members-c.title') }}</legend>

      <div class="px-2">
        {{ $t('pages.invite-members-c.subtitle', { receivers }) }}
      </div>

      <div class="d-block mt-3 button">
        <viz-button to="/login" class="btn btn-primary text-white px-4">
          {{ $t('pages.invite-members-c.btnLabel') }}
        </viz-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import { logo } from '@/utils/icons';

export default defineComponent({
  components: {
    VizButton
  },

  setup() {
    return {
      logo
    };
  },
  computed: {
    receivers() {
      const receivers = this.$route.params?.receivers;
      return receivers ? receivers : 0;
    }
  }
});
</script>

<style scoped></style>
