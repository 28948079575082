<template>
  <header class="header">
    <div class="px-60">
      <div class=" pt-3">
        <div class="d-flex justify-content-between  align-items-center">
          <div class="d-flex align-items-center">
            <!-- Example split danger button -->
            <button
              class="btn btn-primary  shadow-none"
              v-on:click="toggleSidebar"
            >
              <span
                style="transform: rotate(90deg);display: block;font-size: 24px;"
                class="menu-toggle text-white "
                >|||</span
              >
            </button>

            <div class="dropdown">
              <button
                class="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownAddGroup"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ userGroup ? userGroup.name : 'Group not assigned' }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownAddGroup">
                <li
                  v-for="group in groups"
                  :key="group.id"
                  @click.prevent="() => handleGroupSelect(group)"
                >
                  <a
                    :class="
                      `dropdown-item p-3 ${
                        userGroup && userGroup.id == group.id
                          ? 'dropdown-item-active'
                          : ''
                      }`
                    "
                    href="#"
                    >{{ group.name }}</a
                  >
                </li>

                <li
                  v-if="user && user.user_role && user.user_role == 1"
                  class="text-center"
                >
                  <button
                    class="btn btn-primary btn-sm rounded-pill text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#add_group_confirm_modal"
                  >
                    {{ $t('labels.add-group') }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!-- Add group Modal -->
          <div
            class="modal fade"
            id="add_group_confirm_modal"
            tabindex="-1"
            aria-labelledby="addGroupModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0">
                  <h5 class="modal-title text-dark" id="addGroupModal">
                    {{ $t('pages.add-group.title') }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-dark">
                  {{ $t('pages.add-group.confirm-text') }}
                </div>
                <div class="modal-footer justify-content-around border-top-0">
                  <button
                    type="button"
                    class="btn btn-default text-muted"
                    data-bs-dismiss="modal"
                  >
                    {{ $t('labels.cancel') }}
                  </button>
                  <viz-button
                    type="button"
                    data-bs-dismiss="modal"
                    @click="handleCreateGroup"
                    class="btn btn-primary text-white rounded-pill"
                  >
                    {{ $t('pages.add-group.proceed') }}
                  </viz-button>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-default dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="text pe-2">
                {{
                  accountDetails && accountDetails.email
                    ? accountDetails.email
                    : ''
                }}
              </span>

              <span class="visually-hidden">Toggle Dropdown</span>
            </button>

            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <viz-button class="dropdown-item" to="/account">{{
                  $t('header.account')
                }}</viz-button>
              </li>

              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="handleLogout"
                >
                  {{ $t('header.logout') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <figure>
            <img :src="headerIcon" alt="dataviz" />
          </figure>
          <div class="text d-inline-block">
            <div class="title">
              <h3>{{ $t(`pages.${pageKey}.title`) }}</h3>
            </div>
            <div>
              <p>{{ $t(`pages.${pageKey}.subtitle`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useStore, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import VizButton from '@/components/ui/VizButton.vue';

export default defineComponent({
  name: 'Header',
  emits: ['toggleSidebar', 'changeLocale'],
  components: {
    VizButton
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      errorMessage: ''
    });

    const handleLogout = () => {
      store.dispatch('logout').then(() => {
        router.push({ name: 'Login', path: '/login' });
      });
    };

    const handleGroupSelect = (group: { id: number; name: string }) => {
      store
        .dispatch('changeUserGroup', group)
        .then(() => {
          state.errorMessage = '';
        })
        .catch(err => {
          state.errorMessage = err?.errors ? err?.errors[0]?.error : '';
        });
    };

    return { handleLogout, handleGroupSelect, state };
  },
  computed: {
    ...mapGetters(['userGroup', 'groups', 'accountDetails', 'user']),
    pageKey: function() {
      const pageKey: string = this.$route.meta.pageKey;

      return pageKey;
    },

    headerIcon: function() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const headerIcon: any = this.$route.meta.headerIcon;
      return headerIcon;
    }
  },

  methods: {
    toggleSidebar() {
      this.$emit('toggleSidebar');
    },
    handleCreateGroup() {
      this.$router.push({ name: 'AddGroup' });
    }
  }
});
</script>
