
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizFileInput from '@/components/ui/VizFileInput.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import VizRadio from '@/components/ui/VizRadio.vue';

import DataImportOption from '@/components/contribution-analysis/DataImportOption.vue';
import { cloudUploadIcon, awsIcon, googleCloudIcon } from '@/utils/icons';
import validationSchema from '@/validator/target-extraction/register-data';
import { useForm, useField } from 'vee-validate';
import { RootState } from '@/store';
import {
  useStore,
  createNamespacedHelpers,
  mapGetters as mapRootGetters
} from 'vuex';
import { UserGroup } from '@/store/modules/auth';
const { mapGetters, mapActions } = createNamespacedHelpers('te');

interface ErrorList {
  error?: string;
  task_id?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'RegisterData',
  emits: ['proceed'],
  beforeMount() {
    this.resetTargetState();
  },
  mounted() {
    if (!this.taskId) {
      this.getTaskId()
        .then(() => {
          this.state.errorMessage = '';
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: { errors: Array<any> }) => {
          if (err && Array.isArray(err?.errors)) {
            this.state.errorMessage = err?.errors[0]?.error;
          } else {
            this.state.errorMessage =
              'Error while loading task-id.Try Reloading!';
          }
        });
    }
  },
  props: {
    steps: Array,
    currentStep: Number
  },
  setup(props, { emit }) {
    const store = useStore<RootState>();
    const dataImportOptions = markRaw([
      {
        label: 'pages.ca.enterdata.section2.ilocal',
        key: 'ilocal',
        icon: cloudUploadIcon
      },
      { label: 'pages.ca.enterdata.section2.aws', key: 'aws', icon: awsIcon },
      {
        label: 'pages.ca.enterdata.section2.google',
        key: 'gcs',
        icon: googleCloudIcon
      }
    ]);

    const state = reactive({
      importFrom: 'ilocal',
      errorMessage: '',
      isLoading: false,
      isUploadComplete: false,
      isAwsUploading: false,
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_region: '',
      path: '',
      filename: '',
      bucket: '',
      checkFileUploadStatus: 0,
      awsprogress: 0,
      awsError: {
        aws_access_key_id: '',
        aws_secret_access_key: '',
        aws_region: '',
        bucket: '',
        filename: '',
        path: ''
      },
      dataHeaders: [] as Array<{ text: string; value: string }>
    });
    const setDataImportOption = (option: string) => {
      state.importFrom = option;
      state.aws_access_key_id = '';
      state.aws_secret_access_key = '';
      state.bucket = '';
      state.aws_region = '';
      state.path = '';
      state.filename = '';
      state.errorMessage = '';
    };
    const initialFormValues = {
      predictionDataInput: undefined,
      targetColumn: '',
      idColumn: '',
      method: ''
    };

    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setValues,
      resetForm
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });
    const { value: predictionDataInput } = useField('predictionDataInput');
    const { value: targetColumn } = useField('targetColumn');
    const { value: idColumn } = useField('idColumn');
    const { value: method } = useField('method');

    const awsFileUpload = () => {
      const {
        aws_access_key_id,
        aws_secret_access_key,
        aws_region,
        bucket,
        filename,
        path
      } = state;

      if (!aws_access_key_id) {
        state.awsError.aws_access_key_id = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_access_key_id = '';
      }

      if (!aws_secret_access_key) {
        state.awsError.aws_secret_access_key = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_secret_access_key = '';
      }

      if (!aws_region) {
        state.awsError.aws_region = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_region = '';
      }

      if (!bucket) {
        state.awsError.bucket = 'errors.required-field';
        return;
      } else {
        state.awsError.bucket = '';
      }

      if (!path) {
        state.awsError.path = 'errors.required-field';
        return;
      } else {
        state.awsError.path = '';
      }

      if (!filename) {
        state.awsError.filename = 'errors.required-field';
        return;
      } else {
        state.awsError.filename = '';
      }

      state.isUploadComplete = false;
      state.isAwsUploading = true;

      store
        .dispatch('te/awsFileUpload', {
          aws_access_key_id,
          aws_secret_access_key,
          aws_region,
          bucket,
          analysis_type: 'target_extraction',
          filename: filename,
          path: path,
          provider: state.importFrom
        })
        .then(() => {
          state.checkFileUploadStatus = setInterval(() => {
            store
              .dispatch('te/getAwsProgress')
              .then(d => {
                if (d.status === 'aborted') {
                  state.isUploadComplete = false;
                  state.isAwsUploading = false;
                  state.errorMessage = d?.message;

                  clearInterval(state.checkFileUploadStatus);
                } else if (d.status === 'pending') {
                  state.awsprogress = d.completed;
                } else if (d.status === 'complete') {
                  state.awsprogress = d.completed;
                  state.isUploadComplete = true;
                  state.isAwsUploading = false;
                  state.isLoading = false;
                  debugger;
                  clearInterval(state.checkFileUploadStatus);

                  setTimeout(() => {
                    store
                      .dispatch('te/getInputFileHeaders')
                      .then((headers: Array<string>) => {
                        state.dataHeaders = headers.map(val => ({
                          text: val,
                          value: val
                        }));
                      })
                      .catch(err => {
                        const error = err?.errors[0]?.error;
                        // setFieldError(fieldname, error);
                        state.dataHeaders = [];
                      });
                  });
                }
              })
              .catch(e => {
                state.isUploadComplete = false;
                state.isAwsUploading = false;
                clearInterval(state.checkFileUploadStatus);
                console.log(e);
              });
          }, 2000);
        })
        .catch(err => {
          debugger;
          state.isUploadComplete = false;
          state.isAwsUploading = false;
          console.log(err);
        });
    };

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('te/registerTargetData', {
          ...values
        })
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';
          emit('proceed');
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;
          type FieldOptions = {
            [key: string]: string;
          };

          const fieldMaps: FieldOptions = {
            target_col: 'targetColumn',
            problem_type: 'method',
            user_id_col: 'idColumn'
          };

          if (Array.isArray(err?.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.errors.forEach((error: any) => {
              Object.keys(fieldMaps).forEach((fieldname: string) => {
                if (error[fieldname]) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const errorField: any = fieldMaps[fieldname];
                  setFieldError(errorField, error[fieldname]);
                }
              });
            });
          }
          if (err.errors[0].error) {
            state.errorMessage = err.errors[0].error as string;
          }
        });
    });
    const uploadFile = (ev: Event) => {
      ev.preventDefault();
      if (!store.getters.isStorageAvailable) {
        return setFieldError('predictionDataInput', 'messages.storage-full');
      }
      const target = ev.target as HTMLInputElement;
      const files = target.files;

      const fieldname = target?.name as 'predictionDataInput';
      if (files && files[0]) {
        const file = files[0];
        const { used, total } = store.getters.storageUsage;

        const availableSize = total * 1073741824 - used * 1073741824;

        if (availableSize <= file.size) {
          return setFieldError(
            'predictionDataInput',
            'messages.file-limit-exceed'
          );
        }
        state.isUploadComplete = false;
        store
          .dispatch('te/localFileUpload', {
            file: files[0],
            fieldname
          })
          .then(() => {
            //set filename returned from signed
            store.dispatch('getStorageUsage', store.getters.userGroup?.id);

            setFieldError(fieldname, undefined);
            if (fieldname === 'predictionDataInput') {
              state.isUploadComplete = true;
              store.dispatch('te/fileUploadStatus', 'completed');
            }

            store
              .dispatch('te/getInputFileHeaders')
              .then((headers: Array<string>) => {
                state.dataHeaders = headers.map(val => ({
                  text: val,
                  value: val
                }));
              })
              .catch(err => {
                const error = err?.errors[0]?.error;
                setFieldError(fieldname, error);
                state.dataHeaders = [];
              });
          })
          .catch(err => {
            state.isUploadComplete = false;
            store.dispatch('te/fileUploadStatus', 'aborted');

            if (err.status === 403) {
              setFieldError(fieldname, 'errors.invalid-file');
            } else if (err && Array.isArray(err?.errors)) {
              setFieldError(fieldname, err.errors[0].error);
            } else if (err.status === 408) {
              setFieldError(fieldname, 'errors.request-timeout');
            } else if (err.status === '503') {
              setFieldError(fieldname, 'errors.service-unavailable');
            }

            // else {
            //   setFieldError(fieldname, 'errors.network-error');
            // }
          });
      }
    };
    return {
      predictionDataInput,
      targetColumn,
      idColumn,
      method,
      errors,
      setValues,
      uploadFile,
      onSubmit,
      resetForm,
      awsFileUpload,
      meta,
      state,
      dataImportOptions,
      setDataImportOption
    };
  },
  components: {
    StepsTrack,
    VizFileInput,
    VizButton,
    VizSelect,
    VizRadio,
    DataImportOption
  },
  methods: {
    ...mapActions([
      'getTaskId',
      'resetTargetState',
      'resetTargetFormState',
      'fileUploadStatus',
      'terminateUploadAws'
    ]),

    terminate() {
      this.resetForm();
      // this.fileUploadStatus('aborted');
      this.resetTargetFormState();
    },

    terminateUpload() {
      this.terminateUploadAws();
    }
  },
  computed: {
    ...mapGetters(['registerData', 'taskId']),
    ...mapRootGetters(['userGroup'])
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.resetForm();
        this.resetTargetFormState();
      }
    }
  }
});
