<template>
  <div class="input-groups">
    <label>
      <!-- slot for title  -->
      <slot></slot>
    </label>

    <select
      class="form-select"
      :value="modelValue"
      aria-label="Select"
      @change="onChange"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="option.selected"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VizSelect',
  props: {
    options: Array,
    modelValue: null
  },

  emits: ['update:modelValue'],

  methods: {
    changeValue(value: string | null) {
      this.$emit('update:modelValue', value); // previously was `this.$emit('input', title)`
    },
    onChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const value = target?.value;
      this.changeValue(value);
    }
  }
});
</script>

<style lang="scss" scoped>
.input-groups {
  position: relative;
  label {
    position: absolute;
    left: 10px;
    top: -8px;
    background: white;
    font-size: 12px;
    padding: 0 4px;
    color: #999999;
  }
  select {
    height: 56px;
  }
}
</style>
