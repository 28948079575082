
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizToast from '@/components/ui/VizToast.vue';
import validationSchema from '@/validator/settings/contract-management';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { ContractDataInterface } from '@/api/manager';
import moment from 'moment';

export default defineComponent({
  components: {
    VizButton,
    VizToast
  },
  beforeMount() {
    this.state.contract = {};
    this.populateContract();
  },

  setup() {
    const store = useStore();

    const state = reactive({
      isEditable: false,
      errorMessage: '',
      contract: {} as ContractDataInterface | {},
      isLoading: false,
      toastMessage: ''
    });

    const initialFormValues = {
      company: '',
      friganaCompany: '',
      org: '',
      person: '',
      friganaPerson: '',
      phone: '',
      address: ''
    };

    const { errors, handleSubmit, meta, setFieldError, setValues } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });
    const { value: company } = useField('company');
    const { value: friganaCompany } = useField('friganaCompany');
    const { value: org } = useField('org');
    const { value: person } = useField('person');
    const { value: friganaPerson } = useField('friganaPerson');
    const { value: phone } = useField('phone');
    const { value: address } = useField('address');

    const populateContract = () => {
      store
        .dispatch('loadContract')
        .then((contract: ContractDataInterface) => {
          state.contract = contract;
          setValues({ ...contract });
          state.errorMessage = '';
        })
        .catch(err => {
          state.contract = {};
          state.errorMessage =
            err?.errors && err?.errors[0].error ? err.errors[0].error : '';
        });
    };

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('updateContract', { ...state.contract, ...values })
        .then(() => {
          populateContract();
          state.isEditable = false;
          state.isLoading = false;
          state.toastMessage = 'messages.edits-saved';
          setTimeout(() => {
            state.toastMessage = '';
          }, 5000);
        })
        .catch(err => {
          state.isLoading = false;
          const errors = err?.errors;
          if (Array.isArray(errors)) {
            errors.forEach(item => {
              if (item.error) state.errorMessage = item.error;
              if (item.company_name)
                setFieldError('company', item.company_name);
              if (item.frigana_company_name)
                setFieldError('friganaCompany', item.frigana_company_name);
              if (item.organization_department)
                setFieldError('org', item.organization_department);
              if (item.person_incharge)
                setFieldError('person', item.person_incharge);
              if (item.frigana_person_incharge)
                setFieldError('friganaPerson', item.frigana_person_incharge);
              if (item.phone_number) setFieldError('phone', item.phone_number);
              if (item.street_address)
                setFieldError('address', item.street_address);
            });
          }
        });
    });

    return {
      company,
      friganaCompany,
      org,
      person,
      friganaPerson,
      phone,
      address,
      onSubmit,
      state,
      errors,
      meta,
      setValues,
      populateContract
    };
  },
  methods: {
    handleEdit() {
      this.state.isEditable = true;
    },
    handleDiscard() {
      this.state.isEditable = false;
      this.setValues({ ...this.state.contract });
    },
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD');
    }
  }
});
