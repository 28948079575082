
import targetAPI from '@/api/target-extraction';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'KpiEffectResult',
  props: ['taskId'],
  beforeMount() {
    this.getImportance(this.taskId)
      .then(data => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  data() {
    return { data: [], errorMessage: '' };
  },

  methods: {
    ...mapActions(['getImportance', 'getDecisionTreeImageURL']),
    getBarPercentage(val: number) {
      return (Number(val) / 1) * 100;
    },

    getDecisionTreeImageOnClick() {
      this.getDecisionTreeImageURL(this.taskId);
    }
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.getImportance(this.taskId)
          .then(data => {
            this.data = data;
          })
          .catch(() => (this.data = []));
      }
    }
  }
});
