import { ActionContext } from 'vuex';
import { RootState } from '../index';
import managerAPI, {
  ContractDataInterface,
  ContractUpdateInterface
} from '@/api/manager';

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  status: null,
  contract: {},
  groupDetails: {
    id: 0,
    groupName: '',
    members: [],
    totalMembers: 0,
    totalInvitations: 0,
    quota: 0
  }
};

export interface GroupDetailInterface {
  id: number;
  groupName: string;
  members: Array<any>;
  totalMembers: number;
  totalInvitations: number;
  quota: number;
}
export interface ManagerState {
  status: string | null;
  contract: ContractDataInterface | {};
  groupDetails: GroupDetailInterface;
}

export default {
  state: initialState,
  mutations: {
    statusPending(state: ManagerState) {
      state.status = STATUS.LOADING;
    },
    statusSuccess(state: ManagerState) {
      state.status = STATUS.SUCCESS;
    },
    statusError(state: ManagerState) {
      state.status = STATUS.ERROR;
    },
    setContract(state: ManagerState, contract: ContractDataInterface | {}) {
      state.contract = contract;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetState(state: ManagerState) {
      state = initialState;
      state.contract = {};
    },
    setGroupDetails(state: ManagerState, groupDetails: GroupDetailInterface) {
      state.groupDetails = groupDetails;
    }
  },
  actions: {
    loadContract(context: ActionContext<ManagerState, RootState>) {
      return new Promise((resolve, reject) => {
        managerAPI
          .loadContract()
          .then(res => {
            const {
              id,
              contract_number: contractNumber,
              contract_date: contractDate,
              company_name: company,
              frigana_company_name: friganaCompany,
              organization_department: org,
              person_incharge: person,
              frigana_person_incharge: friganaPerson,
              phone_number: phone,
              street_address: address,
              contract_function: contractFunction
            } = res?.data;
            const contract = {
              id,
              contractNumber,
              contractDate,
              company,
              friganaCompany,
              org,
              person,
              friganaPerson,
              phone,
              address,
              contractFunction
            };
            context.commit('setContract', contract);
            resolve(contract);
          })
          .catch(err => {
            context.commit('setContract', {});
            reject(err);
          });
      });
    },

    updateContract(
      context: ActionContext<ManagerState, RootState>,
      data: ContractUpdateInterface
    ) {
      return new Promise((resolve, reject) => {
        managerAPI
          .updateContract(data)
          .then(res => {
            const {
              id,
              contract_number: contractNumber,
              contract_date: contractDate,
              company_name: company,
              frigana_company_name: friganaCompany,
              organization_department: org,
              person_incharge: person,
              frigana_person_incharge: friganaPerson,
              phone_number: phone,
              street_address: address,
              contract_function: contractFunction
            } = res?.data;
            const contract = {
              id,
              contractNumber,
              contractDate,
              company,
              friganaCompany,
              org,
              person,
              friganaPerson,
              phone,
              address,
              contractFunction
            };
            context.commit('setContract', contract);
            resolve(contract);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    loadGroupAndMembers(
      context: ActionContext<ManagerState, RootState>,
      {
        page,
        perPage,
        groupId
      }: { page: number; perPage: number; groupId: number }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        context.commit('setGroupDetails', {});

        managerAPI
          .loadGroupAndMembers({ page, perPage, groupId })
          .then(res => {
            const {
              id,
              group_name: groupName,
              members,
              total_members: totalMembers,
              total_invitation_sent: totalInvitations,
              quota
            } = res?.data;
            context.commit('statusSuccess');
            context.commit('setGroupDetails', {
              id,
              groupName,
              members,
              totalMembers,
              totalInvitations,
              quota
            });
            resolve({
              id,
              groupName,
              members,
              totalMembers,
              totalInvitations,
              quota
            });
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    updateGroupName(
      context: ActionContext<ManagerState, RootState>,
      { id, name }: { id: number; name: string }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .updateGroupName({ id, name })
          .then(res => {
            const { members } = res?.data;
            context.commit('statusSuccess');
            resolve(members);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    getInvoiceList(
      context: ActionContext<ManagerState, RootState>,
      { page, perPage }: { page: number; perPage: number }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .getInvoiceList(page, perPage)
          .then(res => {
            const { invoices, total_entries } = res?.data;
            context.commit('statusSuccess');
            resolve({ invoices, totalEntries: total_entries });
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    downloadInvoice(
      context: ActionContext<ManagerState, RootState>,
      invoiceId: number
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        const invoice = managerAPI.downloadInvoice(invoiceId);
        let filename = '' as any;
        invoice.then(res => {
          const content = res.headers.get('content-disposition');
          filename = String(content?.split('filename=')[1]).trim();
        });
        invoice
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    addGroup(
      context: ActionContext<ManagerState, RootState>,
      { name, receiver }: { name: string; receiver: Array<string> }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .addGroup({ name, receiver })
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    addGroupMembers(
      context: ActionContext<ManagerState, RootState>,
      { members, groupId }: { members: Array<string>; groupId: number }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .addGroupMembers(groupId, members)
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    deleteGroup(
      context: ActionContext<ManagerState, RootState>,
      groupId: number
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .deleteGroup(groupId)
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            reject(err);
            context.commit('statusError');
          });
      });
    },
    deleteMember(
      context: ActionContext<ManagerState, RootState>,
      { groupId, email }: { groupId: number; email: string }
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .deleteMember({ groupId, email })
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            reject(err);
            context.commit('statusError');
          });
      });
    },
    increaseGroupQuota(
      context: ActionContext<ManagerState, RootState>,
      groupId: number
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        managerAPI
          .increaseGroupQuota(groupId)
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            reject(err);
            context.commit('statusError');
          });
      });
    }
  },

  getters: {
    groupDetails: (state: ManagerState) => state.groupDetails
  }
};
