
import { defineComponent, markRaw, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
// import VolumePrediction from '@/components/measure-simulation/VolumePrediction.vue';
import KpiForecast from '@/components/measure-simulation/KpiForecast.vue';
import { dropdownIcon } from '@/utils/icons';
import simulationAPI from '@/api/measure-simulation';
import SalesBarGraph from '@/components/measure-simulation/SalesBarGraph.vue';
import { createNamespacedHelpers } from 'vuex';
import { stringHelpers } from '@/utils/helpers';

const { mapActions } = createNamespacedHelpers('ms');

interface NumberKeyValInterface {
  [key: string]: string | number;
}

export default defineComponent({
  name: 'TargetSales',
  props: ['taskId'],

  beforeMount() {
    this.getMediaVolumeReport(Number(this.taskId));
    this.getTotalSales(Number(this.taskId));
  },
  computed: {
    targetKeys: function(): Array<string> {
      if (!this.state.targetValues) return [];
      return Object.keys(this.state.targetValues);
    }
  },
  methods: {
    ...mapActions(['exportSimulationReport']),
    handleExport: function() {
      this.state.isLoading = true;

      this.exportSimulationReport(this.taskId)
        .then(() => {
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    },
    getTotalSales(taskId: number) {
      simulationAPI
        .getSimulateTotalSalesReport(taskId)
        .then(res => {
          const results = res?.data?.results;
          this.state.totalSales = results ? Number(results[0]?.total_sales) : 0;
          this.state.targetSalesError = '';
        })
        .catch(err => {
          this.state.totalSales = 0;
          this.state.targetSalesError = err?.errors ? err.errors[0]?.error : '';
        });
    },
    getMediaVolumeReport(taskId: number) {
      simulationAPI
        .getSimulateMediaVolumeReport(taskId)
        .then(res => {
          const results = res.data?.results;
          let tempTarget = {};

          if (Array.isArray(results)) {
            results.forEach(item => {
              tempTarget = {
                ...tempTarget,
                [item.val]: isNaN(Number(item.budget))
                  ? item.budget
                  : Number(item.budget).toFixed(0)
              };
            });
          }

          this.state.targetValues = tempTarget;
          this.state.targetValuesError = '';
        })
        .catch(err => {
          this.state.targetValuesError = err?.errors
            ? err.errors[0]?.error
            : '';
        });
    }
  },
  setup() {
    const icons = markRaw({
      dropdownIcon
    });

    const state = reactive({
      totalSales: 0,
      targetValues: {} as NumberKeyValInterface,
      errorMessage: '',
      targetSalesError: '',
      targetValuesError: '',
      isLoading: false
    });
    return { icons, state, stringHelpers };
  },

  components: {
    VizButton,
    // VolumePrediction,
    KpiForecast,
    SalesBarGraph
  },
  watch: {
    taskId: function(newTaskId) {
      this.getMediaVolumeReport(Number(newTaskId));
      this.getTotalSales(Number(newTaskId));
    }
  }
});
