/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import { RootState } from '../index';
import caAPI, {
  KpiFileInputCredentials,
  KpiInputCredentials,

} from '@/api/contribution-analysis';
const fileUploadRequest = new XMLHttpRequest();

interface LocalFileUploadInterface {
  file: File;
  fieldname: string;
}

export interface KPIEsimateInterface {
  name: string;
  no_option: {
    color: string;
    value: string;
    color_intensity?: string;
  };
  has_option: {
    color: string;
    value: string;
    color_intensity?: string;
  };
}

export interface KPIAWS {
  aws_access_key_id: string;
  aws_secret_access_key: string;
  aws_region: string;
  bucket: string;
  task_id: number;
  group_id: number;
  analysis_type: string;
  filename: string;
  path: string;
  provider: string;
}

export interface KPIOutput {
  task_id: number;
  execution_date: string;
  name: string;
  type_of_analysis: string;
  username: string;
}
interface KPIResultInterface {
  val: string;
  corr: string;
  col: string;
  effect: string;
  p_value: string;
}

interface AnalysisStateInterface {
  states: Array<string>;
  logs: Array<Array<string>>;
}
export interface CAState {
  status: string | null;
  taskId: number | null;
  analysisState: AnalysisStateInterface;
  kpiFileInputForm: {
    formState: KpiFileInputCredentials | null;
    progress: { s3FileInput1?: number };
  };
  kpiInputForm: {
    formState: KpiInputCredentials | null;
  };
  costIndicators: {
    headers?: Array<string>;
    body?: Array<Array<string>>;
  };
  isExitAllowed: boolean;
  kpiResults: Array<KPIResultInterface>;
  kpiCorrEstimate: Array<KPIEsimateInterface>;
  kpiCoefEstimate: Array<KPIEsimateInterface>;
  kpiEffectEstimate: Array<KPIEsimateInterface>;
}

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  status: null,
  taskId: null,

  kpiFileInputForm: {
    formState: null,
    progress: null
  },
  kpiInputForm: {
    formState: null,
    progress: null
  },
  costIndicators: {
    headers: [],
    body: []
  },
  isExitAllowed: true,
  analysisState: {
    states: [],
    logs: null
  },
  kpiResults: [],
  kpiCorrEstimate: [],
  kpiEffectEstimate: [],
  kpiCoefEstimate: []
};

interface KpiInputResponse {
  data: {
    task_id: number;
  };
}
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setTaskId(state: CAState, taskID: number | null) {
      state.taskId = taskID;
    },

    setUploadProgress(
      state: CAState,
      progressData: { fieldname: string; percentComplete: number }
    ) {
      if (!progressData) {
        state.kpiFileInputForm.progress = {};
      } else {
        const { fieldname, percentComplete } = progressData;
        state.kpiFileInputForm.progress = {
          ...state.kpiFileInputForm.progress,
          [fieldname]: percentComplete
        };
      }
    },
    setKpiFormState(state: CAState, kpiFormData: KpiInputCredentials | null) {
      state.kpiInputForm.formState = kpiFormData;
    },
    setKpiFileInputFormState(
      state: CAState,
      kpiFormData: KpiFileInputCredentials | null
    ) {
      state.kpiFileInputForm.formState = kpiFormData;
    },

    setRecentRecords(state: CAState, records: any | null) {
      if (!records) {
        state.costIndicators = {};
        return;
      }
      const s3file1 = state.kpiFileInputForm.formState?.s3file1;
      if (s3file1) {
        state.costIndicators = records[s3file1];
      } else {
        state.costIndicators = {};
      }
    },

    setRecentRecordsAws(state: CAState, records: any | null) {

      if (records) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        state.costIndicators = Object.values(records)[0];
      } else {
        state.costIndicators = {};
      }
    },


    setExitAllowed(state: CAState, isExitAllowed: boolean) {
      state.isExitAllowed = isExitAllowed;
    },

    setAnalysisState(state: CAState, analysisState: AnalysisStateInterface) {
      state.analysisState = analysisState;
    },
    setKPIResults(state: CAState, results: KPIResultInterface[]) {
      state.kpiResults = results;
    },
    setKPICorrEstimate(state: CAState, data: Array<KPIEsimateInterface>) {
      state.kpiCorrEstimate = data;
    },
    setKPICoefEstimate(state: CAState, data: Array<KPIEsimateInterface>) {
      state.kpiCoefEstimate = data;
    },
    setKPIEffectEstimate(state: CAState, data: Array<KPIEsimateInterface>) {
      state.kpiEffectEstimate = data;
    },
    statusPending(state: CAState) {
      state.status = STATUS.LOADING;
    },

    statusSuccess(state: CAState) {
      state.status = STATUS.SUCCESS;
    },

    statusError(state: CAState) {
      state.status = STATUS.ERROR;
    }
  },
  actions: {
    getTaskId(context: ActionContext<CAState, RootState>) {
      return new Promise((resolve, reject) => {
        caAPI
          .getTaskId()
          .then(res => {
            const taskId: number = res.data?.task_id;
            context.commit('setTaskId', taskId);
            resolve(taskId);
          })
          .catch(err => {
            context.commit('setTaskId', null);

            reject(err);
          });
      });
    },
    localFileUpload(
      context: ActionContext<CAState, RootState>,
      { file, fieldname }: LocalFileUploadInterface
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        context.commit('setExitAllowed', false);
        context.commit('setUploadProgress', { fieldname, percentComplete: 0 });
        const taskId = context.state.taskId;
        const groupId = context.rootState.auth.userGroup?.id;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .getPresignedUrl({
            taskId: Number(taskId),
            filename: file?.name,
            groupId: Number(groupId)
          })
          .then((res: any) => {
            const url = res?.data?.url;
            const fields = res?.data?.fields;
            const filename = file?.name; //default filename,return after upload

            const requestData = new FormData();

            Object.keys(fields).forEach(field => {
              requestData.append(field, fields[field]);
            });
            requestData.append('file', file, filename);
            requestData.append('Content-Type', file.type);

            function updateProgress(oEvent: ProgressEvent<EventTarget>) {
              if (oEvent.lengthComputable) {
                const percentComplete = (
                  (oEvent.loaded / oEvent.total) *
                  100
                ).toFixed(2);
                context.commit('setUploadProgress', {
                  fieldname,
                  percentComplete
                });
              }
            }

            function transferComplete() {
              context.commit('statusSuccess');
              context.commit('setExitAllowed', true);
              const response = { filename, status: fileUploadRequest.status };
              resolve(response);
            }

            function transferError() {
              context.commit('setExitAllowed', true);
              context.commit('statusError');
              context.commit('setUploadProgress', {
                fieldname,
                percentComplete: 0
              });
              const response = { filename, status: fileUploadRequest.status };
              reject(response);
            }

            fileUploadRequest.upload.onprogress = updateProgress;
            fileUploadRequest.onreadystatechange = function () {
              if (fileUploadRequest.readyState === 4) {
                if (
                  fileUploadRequest.status === 200 ||
                  fileUploadRequest.status === 204
                ) {
                  transferComplete();
                } else {
                  transferError();
                }
              }
            };
            fileUploadRequest.open('POST', url);
            fileUploadRequest.send(requestData);
          })
          .catch(err => {
            context.commit('setExitAllowed', true);
            reject(err);
          });
      });
    },

    awsFileUpload(
      context: ActionContext<CAState, RootState>,
      credentials: KPIAWS
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId || 0;
        const groupId = Number(context.rootState.auth.userGroup?.id);

        caAPI
          .postAwsFile({...credentials, task_id: taskId, group_id: groupId })
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            // context.commit('setTaskId', null);

            // reject(err);
          });
      });
    },

    updateKPIFileInputData(
      context: ActionContext<CAState, RootState>,
      credentials: KpiInputCredentials
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        const kpiFileData = {
          ...credentials,
          taskId: Number(taskId)
        };
        context.commit('setKpiFileInputFormState', kpiFileData);
        resolve('');
      });
    },
    uploadKPIData(
      context: ActionContext<CAState, RootState>,
      credentials: KpiInputCredentials
    ) {
      return new Promise((resolve, reject) => {
        const kpiCredentials = {
          ...credentials
        };

        context.commit('statusPending');

        caAPI
          .uploadKPIData(kpiCredentials)
          .then((res: KpiInputResponse) => {
            context.commit('statusSuccess');
            context.commit('setKpiFormState', credentials);
            resolve(res.data);
          })
          .catch((err: any) => {
            context.commit('statusError');
            reject(err);
          });
      });
    },

    fetchMeasureData(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'ファイルの中身をロード中にエラーが起こりました。タスクIDが必要です。'
              }
            ]
          };
          return reject(error);
        }

        context.commit('statusPending');

        caAPI
          .fetchMeasureData({ groupId, taskId })
          .then((res: any) => {
            context.commit('statusSuccess');
            context.commit('setRecentRecords', res.data);

            resolve(res.data);
          })
          .catch((err: any) => {
            context.commit('setRecentRecords', []);

            context.commit('statusError');
            reject(err);
          });
      });
    },

    fetchMeasureDataAws(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'ファイルの中身をロード中にエラーが起こりました。タスクIDが必要です。'
              }
            ]
          };
          return reject(error);
        }

        context.commit('statusPending');

        caAPI
          .fetchMeasureData({ groupId, taskId })
          .then((res: any) => {
            context.commit('statusSuccess');
            context.commit('setRecentRecordsAws', res.data);

            resolve(res.data);
          })
          .catch((err: any) => {
            context.commit('setRecentRecordsAws', []);

            context.commit('statusError');
            reject(err);
          });
      });
    },
    getAnalysisState(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .getAnalysisState(taskId)
          .then(res => res.data)
          .then((data: AnalysisStateInterface) => {
            context.commit('setAnalysisState', data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getAwsProgress(
      context: ActionContext<CAState, RootState>
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .getAwsProgress(taskId, "contribution_analysis")
          .then(res => res.data)
          .then((data: any) => {
            context.commit('statusSuccess');
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    
    getKPIResults(context: ActionContext<CAState, RootState>) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .getKPIResults(taskId)
          .then(res => res.data.results)
          .then((results: Array<KPIResultInterface>) => {
            context.commit('setKPIResults', results);
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    resetKpiState(context: ActionContext<CAState, RootState>) {
      context.commit('setKpiFormState', null);
      context.commit('setKpiFileInputFormState', null);
      context.commit('setUploadProgress', null);
      context.commit('setAnalysisState', []);
      context.commit('setTaskId', null);
      context.commit('setExitAllowed', true);
      fileUploadRequest?.abort();
    },
    resetKPIFileInputFormState(context: ActionContext<CAState, RootState>) {
      context.commit('setKpiFileInputFormState', null);
      context.commit('setUploadProgress', null);
      context.commit('setExitAllowed', true);
      fileUploadRequest?.abort();
    },

    terminateUpload(context: ActionContext<CAState, RootState>) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .termiateProcess(Number(taskId))
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });

    },

    terminateUploadAws(context: ActionContext<CAState, RootState>) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .termiateAwsProcess(Number(taskId), "contribution_analysis")
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });

    },
    fileUploadStatus(context: ActionContext<CAState, RootState>, status: string) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        const groupId = context.rootState.auth.userGroup?.id || 0;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        caAPI
          .setUploadStatus(Number(taskId), Number(groupId), status)
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getKPICorrEstimate(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        caAPI
          .getKPICorrEstimate(taskId)
          .then(res => {
            context.commit('setKPICorrEstimate', res?.data?.results);

            resolve(res?.data?.results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getKPICoefEstimate(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        caAPI
          .getKPICoefEstimate(taskId)
          .then(res => {
            context.commit('setKPICoefEstimate', res?.data?.results);

            resolve(res?.data?.results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getKPIEffectEstimate(
      context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };

          return reject(error);
        }

        caAPI
          .getKPIEffectEstimate(taskId)
          .then(res => {
            context.commit('setKPIEffectEstimate', res?.data?.results);
            resolve(res?.data?.results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    exportAnalysisReport(
      _context: ActionContext<CAState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        caAPI
          .exportAnalysisReport(taskId)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${taskId}.zip`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },

  getters: {
    kpiFileInputForm: (state: CAState) => state.kpiFileInputForm,
    kpiForm: (state: CAState) => state.kpiInputForm,
    analysisState: (state: CAState) => state.analysisState,
    isExitAllowed: (state: CAState) => state.isExitAllowed,
    taskId: (state: CAState) => state.taskId,
    costIndicators: (state: CAState) => {
      return {
        headers: state.costIndicators?.headers
          ? state.costIndicators.headers
          : [],
        data: state.costIndicators?.body ? state.costIndicators.body : []
      };
    }
  }
};
