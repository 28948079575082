<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <figure class="mr-3" style="width: 24px">
        <img :src="attachFileIcon" alt="" />
      </figure>
      <div class="input-groups w-100">
        <label class="custom-file-label" :for="`customFile${label}`">
          {{ label }}
        </label>
        <div class="custom-file">
          <input
            v-bind="$attrs"
            type="file"
            :files="modelValue"
            class="custom-file-input"
            :id="`customFile${label}`"
            ref="inputFileRef"
            @change="onFileChange"
          />
        </div>
      </div>
    </div>
    <div v-if="uploadProgress" class="d-flex align-items-center">
      <div class="progress ms-4" style="height: 10px; width: 90%">
        <div
          :class="`progress-bar ${
            Number(uploadProgress) >= 100
              ? ''
              : 'progress-bar-striped progress-bar-animated'
          }`"
          role="progressbar"
          :style="`width: ${uploadProgress}%`"
          :aria-valuenow="uploadProgress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
         
      <div class="progress-percent">{{ uploadProgress }}%</div>
      <button
        v-if="uploadState"
        type="button"
        class="btn btn-secondary rounded-pill ms-3 btn-sm btn-abort"
        data-bs-toggle="modal"
        data-bs-target="#abort-modal"
      >
       インポートの中止
      </button>
      <!-- Modal -->
      <div
        class="modal fade"
        id="abort-modal"
        tabindex="-1"
        aria-labelledby="abort"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">この進行を中止してもよろしいですか？</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-primary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <viz-button
                v-if="uploadState" 
                type="button"
                class="btn btn-secondary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
                @click="terminateUpload"
              >
                インポートの中止
              </viz-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { attachFileIcon } from '@/utils/icons';
export default defineComponent({
  name: 'VizFileInput',
  data() {
    return {
      attachFileIcon,
    };
  },
  methods: {
    changeFiles(files: FileList | null) {
      this.$emit('update:modelValue', files); // previously was `this.$emit('input', title)`
    },
    onFileChange(ev: Event) {
      ev.preventDefault();
      const target = ev.target as HTMLInputElement;
      const files = target?.files;
      this.changeFiles(files);
    },
    terminateUpload() {
      this.$emit('terminate', '');
    },
  },

  props: ['label', 'modelValue', 'uploadProgress', 'uploadState'],
  emits: ['update:modelValue', 'terminate'],
  watch: {
    modelValue(newVal) {
      if (!newVal) {
        const fileRef = this.$refs.inputFileRef as any;
        fileRef.value = null;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.progress-percent {
  width: 10%;
  line-height: 10px;
  color: black;
  text-align: right;
  font-weight: 600;
}
.btn-secondary {
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
}
.btn-abort {
  white-space: nowrap;
}

</style>
