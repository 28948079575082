<template>
  <div class="col-md-6 gx-3">
    <div class="border">
      <div class="text-primary fw-bold">
        {{ $t('pages.ms.ps.patternb.title') }}
      </div>
      <div class="sub_title">
        <h4>{{ $t('pages.ms.ps.patternb.subtitle') }}</h4>
      </div>
      <div class="paragraph">
        <p>
          {{ $t('pages.ms.ps.patternb.desc') }}
        </p>
      </div>

      <form action="" class="mt-4" @submit.prevent="onSubmit" novalidate>
        <viz-select
          :options="modelOptions"
          v-model="modelName"
          @change="handleModelNameChange"
        >
          {{ $t('pages.ms.ps.patternb.model-input.label') }}
        </viz-select>
        <p class="text-danger">
          {{ errors && errors.modelName ? $t(errors.modelName) : '' }}
        </p>

        <div v-if="modelName" :class="`d-block`">
          <div class="sub_title mb-3">
            <h4>{{ $t('pages.ms.ps.patternb.target-measure.title') }}</h4>
          </div>
          <!-- <span
            class="text-muted"
            v-if="
              !Array.isArray(state.targetMeasureOptions) ||
                state.targetMeasureOptions.length < 1
            "
            >{{ $t('labels.options-not-available') }}</span
          > -->
          <div
            v-for="item in state.targetMeasureOptions"
            :key="item.text"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="item.value"
              @click="handleOptimizeVariablesChange"
              :checked="
                Array.isArray(optimizeVariableList) &&
                  optimizeVariableList.includes(item.value)
              "
            />
            <label class="form-check-label">
              {{ item.text }}
            </label>
          </div>
        </div>
        <p class="text-danger">
          {{
            errors && errors.optimizeVariableList
              ? $t(errors.optimizeVariableList)
              : ''
          }}
        </p>

        <div class="d-block my-5">
          <div class="sub_title">
            <h4>
              {{ $t('pages.ms.ps.patternb.target-kpi.title') }}
            </h4>
          </div>
          <div class="text-field">
            <input
              type="number"
              class="text-end"
              v-model="targetKpi"
              placeholder="0"
            />
          </div>
          <p class="text-danger">
            {{ errors && errors.targetKpi ? $t(errors.targetKpi) : '' }}
          </p>
        </div>
        <p class="text-danger">{{ state.errorMessage }}</p>

        <div class="d-block mt-4 button position-absolute">
          <viz-button
            :disabled="!meta.valid"
            :loading="state.isLoading"
            type="submit"
            class="btn btn-primary text-white fw-bold"
          >
            {{ $t('pages.ms.ps.patternb.btnTitle') }}
          </viz-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import { useForm, useField } from 'vee-validate';
import { useRouter } from 'vue-router';

import { useStore, createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ms');

import validationSchema from '@/validator/measure-simulation/pattern-b-schema';

export default defineComponent({
  components: {
    VizButton,
    VizSelect
  },

  methods: {
    ...mapActions(['getMeasureOptions'])
  },
  props: {
    modelOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const initialFormValues = {
      modelName: '',
      optimizeVariableList: [],
      targetKpi: undefined
    };

    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setFieldValue
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });

    const { value: modelName } = useField('modelName');
    const { value: targetKpi } = useField('targetKpi');
    const { value: optimizeVariableList } = useField<Array<string>>(
      'optimizeVariableList'
    );

    const state = reactive({
      errorMessage: '',
      isLoading: false,
      targetMeasureOptions: [] as Array<{ text: string; value: string }>
    });

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;

      store
        .dispatch('ms/runOptimizeModel', values)
        .then(taskId => {
          state.isLoading = false;
          router.push({
            path: '/simulation/observe',
            name: 'SimulationObserve',
            params: {
              pattern: 'patternB',
              taskId,
              modelName: String(values.modelName)
            }
          });
        })
        .catch(err => {
          state.isLoading = false;

          const errors = err?.errors;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((error: any) => {
            if (error?.error) {
              state.errorMessage = error.error;
            }
            if (error?.group_id) {
              state.errorMessage = error.group_id[0];
            }
            if (error?.target_kpi) {
              setFieldError('targetKpi', error.target_kpi);
            }
            if (error?.optimize_variable_list) {
              setFieldError(
                'optimizeVariableList',
                error.optimize_variable_list
              );
            }
          });
        });
    });

    const handleModelNameChange = () => {
      if (modelName.value) {
        store
          .dispatch('ms/getMeasureOptions', modelName.value)
          .then((measureOptions: Array<string>) => {
            if (Array.isArray(measureOptions)) {
              const tempVals = [] as Array<string>;

              state.targetMeasureOptions = measureOptions.map(item => {
                tempVals.push(item);
                return {
                  text: item,
                  value: item
                };
              });
              setFieldValue('optimizeVariableList', tempVals);
            } else {
              state.targetMeasureOptions = [];
            }
          })
          .catch(() => {
            state.targetMeasureOptions = [];
          });
      }
    };

    const handleOptimizeVariablesChange = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      let tempVars = optimizeVariableList.value
        ? optimizeVariableList.value
        : [];

      if (target.value) {
        if (tempVars.includes(target.value)) {
          tempVars = tempVars.filter(item => item !== target.value);
        } else {
          tempVars.push(target.value);
        }

        setFieldValue('optimizeVariableList', tempVars);
      }
    };

    return {
      onSubmit,
      handleModelNameChange,
      handleOptimizeVariablesChange,
      modelName,
      targetKpi,
      optimizeVariableList,
      state,
      errors,
      meta
    };
  }
});
</script>

<style scoped></style>
