<template>
  <div class="correlation">
    <div class="sub_title">
      <h4>{{ $t('pages.ca.analysisreport.section1.h1') }}</h4>
    </div>
    <div class="paragraph">
      <p class="text-muted">
        {{ $t('pages.ca.analysisreport.section1.h2') }}
      </p>
    </div>
    <p class="text-danger">{{ errorMessage }}</p>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr v-for="(column, key) in data" :key="key">
            <td>{{ column.name }}</td>

            <td class="p-0 m-0">
              <horizontal-bar
                :data="[
                  column.no_option
                    ? !column.no_option.value
                      ? column.no_option.value
                      : column.no_option.value * 100
                    : 0,
                  column.has_option
                    ? !column.has_option.value
                      ? column.has_option.value
                      : column.has_option.value * 100
                    : 0
                ]"
                :colors="
                  column.has_option
                    ? [column.no_option.color, column.has_option.color]
                    : [column.no_option.color, '']
                "
                :colorIntensities="[
                  column.no_option.color_intensity,
                  column.has_option
                    ? column.has_option.color_intensity
                    : 'light'
                ]"
                :labels="getLabels(column)"
                :yaxisMin="getBarValue(data, 'min')"
                :yaxisMax="getBarValue(data, 'max')"
              ></horizontal-bar>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="index d-flex justify-content-end">
        <div class="d-flex align-items-center me-3">
          <div
            class="color me-2"
            style="
              width: 8px;
              height: 8px;
              background: #f9a212;
              border-radius: 2px;
            "
          ></div>
          <div class="text">
            {{ $t('pages.ca.analysisreport.legends.no_option') }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="color me-2"
            style="
              width: 8px;
              height: 8px;
              background: #e1683d;
              border-radius: 2px;
            "
          ></div>
          <div class="text">
            {{ $t('pages.ca.analysisreport.legends.with_option') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { KPIEsimateInterface } from '@/store/modules/contribution-analysis';
import HorizontalBar from '@/components/contribution-analysis/ContributionHorizontalBar.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('ca');

interface DataColumnOption {
  value: number | null;
  color: string;
  color_intensity: string;
}
interface DataColumn {
  has_option: DataColumnOption | null;
  no_option: DataColumnOption;
}

export default defineComponent({
  name: 'KpiCorrResult',
  props: ['taskId'],

  components: {
    HorizontalBar
  },
  beforeMount() {
    this.getKPICorrEstimate(this.taskId)
      .then((data: Array<KPIEsimateInterface>) => {
        this.data = [];
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },
  data() {
    return { data: [] as Array<KPIEsimateInterface>, errorMessage: '' };
  },

  methods: {
    ...mapActions(['getKPICorrEstimate']),
    getLabels(column: DataColumn) {
      return [
        column.no_option
          ? column.no_option.value === null
            ? ''
            : (Number(column?.no_option?.value) * 100).toFixed(3)
          : '',
        column.has_option
          ? column.has_option.value === null
            ? ''
            : (Number(column?.has_option?.value) * 100).toFixed(3)
          : ''
      ];
    },

    getBarValue: function(data: Array<KPIEsimateInterface>, type: string): any {
      if (!data) return 1;

      const no_option: number[] = data.map(
        e => Number(e.no_option?.value) * 100
      );
      const has_option: number[] = data.map(
        e => Number(e.has_option?.value) * 100
      );
      const mergedArr: number[] = [...no_option, ...has_option].filter(Number);

      if (type === 'max') {
        return Math.abs(Math.max(...mergedArr));
      } else {
        return Math.min(...mergedArr);
      }
    }
  },

  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.data = [];

        this.getKPICorrEstimate(this.taskId)
          .then((data: Array<KPIEsimateInterface>) => {
            this.errorMessage = '';
            this.data = data;
          })
          .catch(err => {
            this.data = [];
            this.errorMessage = err?.errors ? err.errors[0].error : '';
          });
      }
    }
  }
});
</script>

<style scoped></style>
