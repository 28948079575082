
import { defineComponent, markRaw } from 'vue';
import { logo } from '@/utils/icons';
import VizButton from '@/components/ui/VizButton.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ResetComplete',
  components: {
    VizButton
  },
  setup() {
    const router = useRouter();

    const icons = markRaw({
      logo: logo
    });
    const handleProceed = async () => {
      router.push('/login');
    };
    return { icons, handleProceed };
  }
});
