import { createStore } from 'vuex';
import auth from './modules/auth';
import { AuthState } from './modules/auth';
import ca, { CAState } from '@/store/modules/contribution-analysis';
import te, { TargetExtractionState } from '@/store/modules/target-extraction';
import ms, { MeasureSimulationState } from '@/store/modules/measure-simulation';

import history, { HistoryState } from '@/store/modules/history';
import manager, { ManagerState } from '@/store/modules/manager';
import toast, { ToastState } from '@/store/modules/toast';

export interface RootState {
  auth: AuthState;
  ca: CAState;
  ms: MeasureSimulationState;
  te: TargetExtractionState;
  history: HistoryState;
  manager: ManagerState;
  toast: ToastState;
}

export default createStore<RootState>({
  modules: {
    auth,
    ca,
    ms,
    te,
    history,
    manager,
    toast
  }
});
