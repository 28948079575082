/* eslint-disable @typescript-eslint/no-explicit-any */
import { nextTick } from "vue";
import { createI18n, I18nOptions } from "vue-i18n";
import en from "@/locales/en.json";
import ja from "@/locales/ja.json";

export const SUPPORT_LOCALES = ["ja", "en"];
export const DEFAULT_LOCALE = "ja";
export const FALLBACK_LOCALE = "en";
const messages = {
  ja,
  en,
};

const defaultOptions: I18nOptions = {
  globalInjection: true,
  legacy: false,
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages,
  silentFallbackWarn: true,
};
export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html")?.setAttribute("lang", locale);
}

export function setupI18n(options = { locale: DEFAULT_LOCALE }) {
  const i18n = createI18n(defaultOptions);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export async function loadLocaleMessages(i18n: any, locale: string) {
  // load locale messages with dynami import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

const i18n = setupI18n();
export default i18n;
