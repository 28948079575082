import { ActionContext } from 'vuex';
import { RootState } from '../index';
import targetAPI, { TargetData } from '@/api/target-extraction';

const fileUploadRequest = new XMLHttpRequest();

interface ExtractionStatusInterface {
  states: Array<string>;
  logs: Array<Array<string>>;
}
interface LocalFileUploadInterface {
  file: File;
  fieldname: string;
}

export interface AWSFileUpload {
  aws_access_key_id: string;
  aws_secret_access_key: string;
  aws_region: string;
  bucket: string;
  task_id: number;
  group_id: number;
  analysis_type: string;
  filename: string;
  path: string;
  provider: string;
}

interface RegisterTargetResponse {
  data: {
    task_id: number;
  };
}
interface RegisterDataCredentials extends TargetData {
  predictionDataInput: FileList;
}
interface ProgressData {
  [key: string]: number;
}
export interface TargetExtractionState {
  status: string | null;
  taskId: number | null;

  decisionTreeImageURL: string | null;
  extractionStatus: ExtractionStatusInterface;
  registerData: {
    formState: RegisterDataCredentials;
    uploadProgress?: ProgressData;
    fileHeaders?: Array<string>;
  };

  isExitAllowed: boolean;
  scoreFrequency: Array<{ score: string; frequency: string }>;
}

interface TargetStateInterface {
  states: Array<string>;
  logs: Array<Array<string>>;
}

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  status: null,
  taskId: null,
  decisionTreeImageURL: null,
  registerData: {
    formState: null,
    uploadProgress: null,
    fileHeaders: []
  },
  extractionStatus: {
    states: [],
    logs: []
  },
  isExitAllowed: true,
  scoreFrequency: []
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setTaskId(state: TargetExtractionState, taskId: number | null) {
      state.taskId = taskId;
    },

    setDecisionTreeImageURL(
      state: TargetExtractionState,
      decisionTreeImageURL: string | null
    ) {
      state.decisionTreeImageURL = decisionTreeImageURL;
    },
    setRegisterDataFormState(
      state: TargetExtractionState,
      formState: RegisterDataCredentials
    ) {
      state.registerData.formState = formState;
    },
    setInputFileHeaders(state: TargetExtractionState, headers?: Array<string>) {
      state.registerData.fileHeaders = headers;
    },
    setUploadProgress(
      state: TargetExtractionState,
      progressData: { fieldname: string; percentComplete: number }
    ) {
      if (!progressData) {
        state.registerData.uploadProgress = {};
      } else {
        const { fieldname, percentComplete } = progressData;
        state.registerData.uploadProgress = {
          ...state.registerData.uploadProgress,
          [fieldname]: percentComplete
        };
      }
    },
    setExtractionStatus(
      state: TargetExtractionState,
      extractionStatus: ExtractionStatusInterface
    ) {
      state.extractionStatus = extractionStatus;
    },
    setExitAllowed(state: TargetExtractionState, isExitAllowed: boolean) {
      state.isExitAllowed = isExitAllowed;
    },

    statusPending(state: TargetExtractionState) {
      state.status = STATUS.LOADING;
    },

    statusSuccess(state: TargetExtractionState) {
      state.status = STATUS.SUCCESS;
    },

    statusError(state: TargetExtractionState) {
      state.status = STATUS.ERROR;
    }
  },
  actions: {
    getTaskId(context: ActionContext<TargetExtractionState, RootState>) {
      return new Promise((resolve, reject) => {
        targetAPI
          .getTaskId()
          .then(res => {
            const taskId: number = res.data?.task_id;
            context.commit('setTaskId', taskId);
            resolve(taskId);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getDecisionTreeImageURL(
      context: ActionContext<TargetExtractionState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (taskId) {
          try {
            targetAPI
              .getDecisionTreeImage(taskId)
              .then(response => {
                try {
                  const imageURL: string = response.data;
                  context.commit('setDecisionTreeImageURL', imageURL);
                  resolve(imageURL);
                } catch (error) {
                  console.error(error);
                }
              })
              .catch(err => reject(err));
          } catch (err) {
            console.error(err);
          }
        }
      });
    },
    localFileUpload(
      context: ActionContext<TargetExtractionState, RootState>,
      { file, fieldname }: LocalFileUploadInterface
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        context.commit('setExitAllowed', false);
        context.commit('setUploadProgress', { fieldname, percentComplete: 0 });
        const taskId = context.state.taskId;
        const groupId = context.rootState.auth.userGroup?.id;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        targetAPI
          .getPresignedUrl({
            taskId: Number(taskId),
            filename: file?.name,
            groupId: Number(groupId)
          })
          .then(res => {
            const url = res?.data?.url;
            const fields = res?.data?.fields;
            const filename = file?.name;

            const requestData = new FormData();

            Object.keys(fields).forEach(field => {
              requestData.append(field, fields[field]);
            });
            requestData.append('file', file, filename);
            requestData.append('Content-Type', file.type);

            function updateProgress(oEvent: ProgressEvent<EventTarget>) {
              if (oEvent.lengthComputable) {
                const percentComplete = (
                  (oEvent.loaded / oEvent.total) *
                  100
                ).toFixed(2);
                context.commit('setUploadProgress', {
                  fieldname,
                  percentComplete
                });
              } else {
                console.log('Unable to compute progress info.');
              }
            }

            function transferComplete() {
              context.commit('statusSuccess');
              context.commit('setExitAllowed', true);
              const response = { status: fileUploadRequest.status };
              resolve(response);
            }

            function transferError() {
              context.commit('setExitAllowed', true);
              context.commit('statusError');
              context.commit('setUploadProgress', {
                fieldname,
                percentComplete: 0
              });
              const response = { status: fileUploadRequest.status };
              reject(response);
            }

            fileUploadRequest.upload.onprogress = updateProgress;
            fileUploadRequest.onreadystatechange = function() {
              if (fileUploadRequest.readyState === 4) {
                if (
                  fileUploadRequest.status === 200 ||
                  fileUploadRequest.status === 204
                ) {
                  transferComplete();
                } else {
                  transferError();
                }
              }
            };
            fileUploadRequest.open('POST', url);
            fileUploadRequest.send(requestData);
          })
          .catch(err => {
            context.commit('setExitAllowed', true);
            reject(err);
          });
      });
    },

    awsFileUpload(
      context: ActionContext<TargetExtractionState, RootState>,
      credentials: AWSFileUpload
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId || 0;
        const groupId = Number(context.rootState.auth.userGroup?.id);

        targetAPI
          .postAwsFile({ ...credentials, task_id: taskId, group_id: groupId })
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            // context.commit('setTaskId', null);
            // reject(err);
          });
      });
    },

    getAwsProgress(context: ActionContext<TargetExtractionState, RootState>) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        targetAPI
          .getAwsProgress(taskId, 'target_extraction')
          .then(res => res.data)
          .then((data: any) => {
            context.commit('statusSuccess');
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    terminateUploadAws(
      context: ActionContext<TargetExtractionState, RootState>
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        targetAPI
          .termiateAwsProcess(Number(taskId), 'target_extraction')
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getInputFileHeaders(
      context: ActionContext<TargetExtractionState, RootState>
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        const groupId = Number(context.rootState.auth.userGroup?.id);

        context.commit('statusPending');

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          context.commit('statusError');

          return reject(error);
        }
        targetAPI
          .getInputFileHeaders({ groupId, taskId })
          .then(res => {
            const headers = res?.data?.headers;
            context.commit('statusSuccess');
            context.commit('setInputFileHeaders', headers);
            resolve(headers);
          })
          .catch(err => {
            reject(err);
            context.commit('setInputFileHeaders', []);
            context.commit('statusError');
          });
      });
    },
    registerTargetData(
      context: ActionContext<TargetExtractionState, RootState>,
      credentials: RegisterDataCredentials
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          reject('Error');
        }
        context.commit('statusPending');

        targetAPI
          .registerTargetData({ ...credentials, taskId: Number(taskId) })
          .then((res: RegisterTargetResponse) => {
            context.commit('statusSuccess');
            context.commit('setRegisterDataFormState', credentials);
            resolve(res.data);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    getExtractionStatus(
      context: ActionContext<TargetExtractionState, RootState>
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        context.commit('statusPending');

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          context.commit('statusError');

          return reject(error);
        }

        targetAPI
          .getExtractionStatus(taskId)
          .then(res => res.data)
          .then((data: TargetStateInterface) => {
            context.commit('statusSuccess');

            context.commit('setExtractionStatus', data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
            context.commit('statusError');
          });
      });
    },
    getUserScore(
      _context: ActionContext<TargetExtractionState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        targetAPI
          .getUserScore(taskId)
          .then(res => resolve(res?.data?.results))
          .catch(err => reject(err));
      });
    },
    getScoreFrequency(
      _context: ActionContext<TargetExtractionState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        targetAPI
          .getScoreFrequency(taskId)
          .then(res => resolve(res?.data?.results))
          .catch(err => reject(err));
      });
    },
    getImportance(
      _context: ActionContext<TargetExtractionState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        targetAPI
          .getImportance(taskId)
          .then(res => resolve(res?.data?.results))
          .catch(err => reject(err));
      });
    },
    exportExtractionReport(
      _context: ActionContext<TargetExtractionState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        targetAPI
          .exportExtractionReport(taskId)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${taskId}.zip`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    resetTargetState(context: ActionContext<TargetExtractionState, RootState>) {
      context.commit('setRegisterDataFormState', null);
      context.commit('setExtractionStatus', { states: [], logs: [] });
      context.commit('setUploadProgress', null);
      context.commit('setTaskId', null);
      context.commit('setInputFileHeaders', []);
      context.commit('setExitAllowed', true);
      fileUploadRequest?.abort();
    },
    resetTargetFormState(
      context: ActionContext<TargetExtractionState, RootState>
    ) {
      context.commit('setRegisterDataFormState', null);
      context.commit('setExtractionStatus', { states: [], logs: [] });
      context.commit('setUploadProgress', null);
      context.commit('setInputFileHeaders', []);
      context.commit('setExitAllowed', true);
      fileUploadRequest?.abort();
    },
    terminateUpload(context: ActionContext<TargetExtractionState, RootState>) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        targetAPI
          .termiateProcess(Number(taskId))
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fileUploadStatus(
      context: ActionContext<TargetExtractionState, RootState>,
      status: string
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        const groupId = context.rootState.auth.userGroup?.id || 0;

        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        targetAPI
          .setUploadStatus(Number(taskId), Number(groupId), status)
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },

  getters: {
    registerData: (state: TargetExtractionState) => state.registerData,
    isExitAllowed: (state: TargetExtractionState) => state.isExitAllowed,
    taskId: (state: TargetExtractionState) => state.taskId,
    extractionStatus: (state: TargetExtractionState) => state.extractionStatus
  }
};
