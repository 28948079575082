const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
const EXPIRY_TIME_KEY = 'EXPIRY_TIME';

interface USERDATA {
  user_role: 2;
  user_functions: Array<string>;
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const deleteAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (token: string) => {
  return localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const deleteRefreshToken = () => {
  return localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getExpiryTime = () => {
  return localStorage.getItem(EXPIRY_TIME_KEY);
};

export const setExpiryTime = (token: string) => {
  return localStorage.setItem(EXPIRY_TIME_KEY, token);
};

export const deleteExpiryTime = () => {
  return localStorage.removeItem(EXPIRY_TIME_KEY);
};
