<template>
  <div class="position-relative">
    <div class="btn-groups position-absolute top-60 end-0">
      <button
        class=" btn btn-outline-primary rounded-pill  py-2 px-3 ms-3"
        data-bs-toggle="modal"
        data-bs-target="#delete_multiple_analysis_history_modal"
        :disabled="payload.features.length < 1"
      >
        選択したデータを削除
      </button>
    </div>
    <div class="table-responsive">
      <viz-table>
        <p class="text-danger">{{ errorMessage }}</p>
        <template #headings>
          <th scope="col">
            <input
              class="form-check-input select-all"
              type="checkbox"
              id="selectAllCheckboxId"
              @click="event => handleSelect(event)"
            />
          </th>

          <th v-for="(heading, index) in headings" :key="index" scope="col">
            {{ $t(analysisHeadingsMap[heading]) }}
          </th>
          <th scope="col"></th>
        </template>

        <template #data>
          <tr v-if="!analysisHistory.data || analysisHistory.data.length < 1">
            <td colspan="4" class="text-center text-muted">
              利用可能なエントリはありません
            </td>
          </tr>
          <tr v-for="(item, index) in analysisHistory.data" :key="index">
            <td>
              <input
                class="form-check-input checkbox"
                type="checkbox"
                :name="item.feature"
                :value="item.task_id"
                @click="
                  event => setSelectedItems(item.task_id, item.feature, event)
                "
              />
            </td>
            <td>{{ moment(item.execution_date) }}</td>
            <td>{{ $t(`labels.${item.type_of_analysis}`) }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.name }}</td>

            <td>
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="() => showModal(item)"
                data-bs-toggle="modal"
                data-bs-target="#resultModal"
              >
                {{ $t('common.buttonlabels.viewresult') }}
              </button>
              <button
                class="btn btn-default p-0 ms-1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <figure class="three_dots">
                  <img :src="icons.learnmore" alt="" />
                </figure>
              </button>
              <ul class="dropdown-menu p-0">
                <li>
                  <viz-button
                    class="dropdown-item py-2"
                    type="button"
                    data-bs-toggle="modal"
                    @click="() => setSelectedEntry(item.task_id, item.feature)"
                    data-bs-target="#delete_single_analysis_history_modal"
                  >
                    {{ $t('labels.delete') }}
                  </viz-button>
                </li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item py-2"
                    data-bs-toggle="modal"
                    data-bs-target="#abort-modal"
                    @click="() => setSelectedEntry(item.task_id, item.feature)"
                    v-if="item.status === '分析中'"
                  >
                    分析中止
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </viz-table>
      <div
        class="modal fade"
        id="abort-modal"
        tabindex="-1"
        aria-labelledby="abort"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">この進行を中止してもよろしいですか？</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-primary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
              >
                キャンセル
              </button>

              <viz-button
                type="button"
                class="btn btn-secondary rounded-pill ms-3 btn-sm"
                data-bs-dismiss="modal"
                @click="handleAbort"
              >
                > 分析を中止する
              </viz-button>
            </div>
          </div>
        </div>
      </div>
      <viz-pagination
        @onPageChange="handlePageChange"
        :totalEntries="analysisHistory.totalEntries"
        :perPageCount="perPageCount"
        :currentPage="currentPage"
      />

      <viz-modal :isModalVisible="true">
        <template #title> {{ $t('pages.ih.modal.title') }} </template>
        <template #body>
          <div class="modal-body">
            <analysis-report
              v-if="currentReport.type == 'Contribution Analysis'"
              :taskId="currentReport.taskId"
            />
            <extraction-report
              v-if="currentReport.type == 'Target Extraction'"
              :taskId="currentReport.taskId"
            />
            <pattern-a-report
              v-if="
                currentReport.type == 'Measure result simulation(Pattern A)'
              "
              :taskId="Number(currentReport.taskId)"
            />

            <pattern-b-report
              v-if="
                currentReport.type == 'Measure result simulation(Pattern B)'
              "
              :taskId="Number(currentReport.taskId)"
            />
          </div>
        </template>
      </viz-modal>
      <!-- delete single history modal -->
      <div
        class="modal fade"
        id="delete_single_analysis_history_modal"
        tabindex="-1"
        aria-labelledby="deleteAnalysisHistoryModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title">
                データの削除
              </h5>
              <viz-button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></viz-button>
            </div>
            <div class="modal-body">
              <p>
                {{ $t('pages.ih.tab2.delete-modal.subtitle') }}
              </p>
              <p class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="modal-footer justify-content-end border-top-0">
              <button
                type="button"
                ref="dismissModalDeleteSingle"
                class="btn btn-default text-muted"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <viz-button
                type="button"
                class="btn btn-secondary text-white rounded-pill"
                @click="handleSingleDelete"
                :loading="isDeletionPending"
              >
                {{ $t('labels.delete') }}
              </viz-button>
            </div>
          </div>
        </div>
      </div>

      <!-- delete multiple history modal -->
      <div
        class="modal fade"
        id="delete_multiple_analysis_history_modal"
        tabindex="-1"
        aria-labelledby="deleteAnalysisHistoryModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title">
                データの削除
              </h5>
              <viz-button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></viz-button>
            </div>
            <div class="modal-body">
              <p>
                削除したデータは復元できません。{{
                  payload.features.length
                }}件のデータを削除しますか?
              </p>
              <p class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="modal-footer justify-content-end border-top-0">
              <button
                type="button"
                ref="dismissModalDeleteMultiple"
                class="btn btn-default text-muted"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <viz-button
                type="button"
                class="btn btn-secondary text-white rounded-pill"
                @click="handleMultipleDelete"
                :loading="isDeletionPending"
              >
                {{ $t('labels.delete') }}
              </viz-button>
            </div>
          </div>
        </div>
      </div>
      <!-- tree-structure-modal -->
      <div
        class="modal fade"
        id="tree-structure-modal"
        tabindex="-1"
        aria-labelledby="tree-structure-modal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-fullscreen modal-dialog-centered h-auto">
          <div class="modal-content container">
            <div class="modal-header">
              <h5
                class="modal-title text-primary"
                id="tree-structure-modal-Label"
              >
                特徴量ごとの重要度を木構造で表示​
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body image-viewer-modal">
              <ImageViewer />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- display toast when user delete table data successfully -->
    <!-- <viz-toast message="データを削除しました" /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import VizTable from '@/components/ui/VizTable.vue';
import VizModal from '@/components/ui/VizModal.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import VizButton from '@/components/ui/VizButton.vue';

import AnalysisReport from '@/components/contribution-analysis/AnalysisResultReport.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
import PatternAReport from '@/components/measure-simulation/PatternAReport.vue';
import PatternBReport from '@/components/measure-simulation/PatternBReport.vue';

import { AnalysisHistory } from '@/store/modules/history';
import learnmoreIcon from '@/assets/icons/more.svg';

import ImageViewer from '@/components/target-extraction/ImageViewer.vue';

interface AnalysisHeadingsMap {
  execution_date: string;
  type_of_analysis: string;
  analysis_status: string;
  name: string;
  username: string;
}

export default defineComponent({
  name: 'AnalysisHistory',
  components: {
    VizTable,
    VizModal,
    VizButton,
    VizPagination,
    AnalysisReport,
    ExtractionReport,
    PatternAReport,
    PatternBReport,
    ImageViewer
  },

  data() {
    const payload = {
      task_ids: [] as number[],
      features: [] as string[]
    };
    const icons = markRaw({
      learnmore: learnmoreIcon
    });
    return {
      isModalVisible: false,
      analysisHeadingsMap: {
        execution_date: 'pages.ih.tab2.th1',
        type_of_analysis: 'pages.ih.tab2.th2',
        analysis_status: '分析ステータス',
        username: 'pages.ih.tab2.th3',
        name: 'pages.ih.tab2.th4'
      } as AnalysisHeadingsMap,
      currentReport: {
        type: '',
        taskId: 0
      },
      errorMessage: '',
      icons,
      perPageCount: 10,
      currentPage: 1,
      isDeletionPending: false,
      selectedEntry: {
        taskId: 0,
        feature: ''
      },
      payload,
      isMultipleDelete: false
    };
  },
  methods: {
    ...mapActions([
      'getAnalysisHistory',
      'getImportHistory',
      'deleteAnalysisHistory',
      'deleteMultipleAnalysisHistory',
      'addToast',
      'getStorageUsage',
      'abortTask'
    ]),

    handlePageChange(page: number, perPage: number) {
      this.perPageCount = perPage;
      this.currentPage = page;
      this.getAnalysisHistory({ page, perPage })
        .then(() => {
          this.errorMessage = '';
        })
        .catch(err => {
          if (err?.errors && err.errors[0].error) {
            this.errorMessage = err.errors[0].error;
          }
        })
        .finally(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            // eslint-disable-next-line
            // @ts-ignore
            checkbox.checked = false;
          }

          this.payload.features = [];
          this.payload.task_ids = [];
          this.isMultipleDelete = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    closeSingleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteSingle as any;
      dismissRef?.click();
    },
    closeMultipleDeleteModal() {
      const dismissRef = this.$refs.dismissModalDeleteMultiple as any;
      dismissRef?.click();
    },
    setSelectedEntry(taskId: number, feature: string) {
      this.isMultipleDelete = false;
      this.selectedEntry = { taskId, feature };
    },
    handleSelect(e: any) {
      e.target.checked ? this.selectAll() : this.deSelectAll();
    },
    selectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        if (items[i].type == 'checkbox') {
          // eslint-disable-next-line
          // @ts-ignore
          items[i].checked = true;
          if (
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features[i] !== items[i].name &&
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids[i] !== items[i].value
          ) {
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.features.push(items[i].name);
            // eslint-disable-next-line
            // @ts-ignore
            this.payload.task_ids.push(Number(items[i].value));
          }
        }
      }
    },
    deSelectAll() {
      this.isMultipleDelete = true;
      const items = document.getElementsByClassName(
        'checkbox'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        // @ts-ignore
        items[i].checked = false;
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.features, items[i].name);
        // eslint-disable-next-line
        // @ts-ignore
        this.removeItem(this.payload.task_ids, Number(items[i].value));
      }
    },
    setSelectedItems(taskId: any, feature: any, event: any) {
      const selectAllCheckboxId = document.getElementById(
        'selectAllCheckboxId'
      );
      if (selectAllCheckboxId) {
        // eslint-disable-next-line
        // @ts-ignore
        selectAllCheckboxId.checked = false;
      }
      this.isMultipleDelete = true;
      if (event.target.checked) {
        this.payload.features.push(feature);
        this.payload.task_ids.push(Number(taskId));
      } else {
        this.removeItem(this.payload.features, feature);
        this.removeItem(this.payload.task_ids, Number(taskId));
      }
    },
    removeItem(array: Array<any>, itemToRemove: any) {
      const index = array.indexOf(itemToRemove);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    handleMultipleDelete() {
      this.isDeletionPending = true;

      this.deleteMultipleAnalysisHistory(this.payload)
        .then(() => {
          const checkboxes = document.getElementsByClassName(
            'checkbox'
          ) as HTMLCollectionOf<HTMLElement>;
          for (const checkbox of checkboxes) {
            if (checkboxes !== null) {
              // eslint-disable-next-line
              // @ts-ignore
              checkbox.checked = false;
            }
          }
          if (
            this.perPageCount >= this.payload.features.length &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeMultipleDeleteModal();
          this.addToast({ message: 'データを削除しました。' });
        })
        .catch((err: any) => {
          const errs = err?.errors;

          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    handleSingleDelete() {
      const { taskId, feature } = this.selectedEntry;
      if (!taskId) {
        return;
      }
      this.isDeletionPending = true;
      this.deleteAnalysisHistory({ taskId, feature })
        .then(() => {
          if (
            this.perPageCount >= this.analysisHistory.totalEntries - 1 &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeSingleDeleteModal();
          this.addToast({ message: 'messages.analysis-history-deleted' });
          this.isDeletionPending = false;
        })
        .catch(err => {
          this.isDeletionPending = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    handleAbort() {
      const { taskId, feature } = this.selectedEntry;
      if (!taskId) {
        return;
      }
      this.isDeletionPending = true;
      this.abortTask({ taskId, feature })
        .then(() => {
          if (
            this.perPageCount >= this.analysisHistory.totalEntries - 1 &&
            this.currentPage > 1
          ) {
            this.currentPage = this.currentPage - 1;
            this.handlePageChange(this.currentPage, this.perPageCount);
          }
          this.handlePageChange(this.currentPage, this.perPageCount);
          this.getStorageUsage(this.userGroup?.id);
          this.closeSingleDeleteModal();
          // this.addToast({ message: 'messages.analysis-history-deleted' });
          this.isDeletionPending = false;
        })
        .catch(err => {
          this.isDeletionPending = false;
          const errs = err?.errors;
          if (Array.isArray(errs)) {
            errs.forEach(item => {
              this.errorMessage = item.error ? item.error : '';
              this.errorMessage = this.errorMessage.concat(
                item.task_id ? `Task: ${String(item.task_id)}` : ''
              );
              this.errorMessage = this.errorMessage.concat(
                item.feature ? `Feature: ${String(item.feature)}` : ''
              );
            });
          }
        })
        .finally(() => {
          this.payload.features = [];
          this.payload.task_ids = [];
          this.isDeletionPending = false;
          const selectAllCheckbox = document.querySelector('.select-all');
          // eslint-disable-next-line
          // @ts-ignore
          selectAllCheckbox.checked = false;
        });
    },
    showModal(item: AnalysisHistory) {
      this.isModalVisible = true;
      this.currentReport.type = item.type_of_analysis;
      this.currentReport.taskId = item.task_id;
    },
    hideModal() {
      this.isModalVisible = false;
      this.currentReport = {
        type: '',
        taskId: 0
      };
    },
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss');
    }
  },
  computed: {
    ...mapGetters(['analysisHistory', 'userGroup']),

    headings(): string[] {
      return Object.keys(this.analysisHeadingsMap);
    }
  },
  watch: {
    userGroup(newVal) {
      if (newVal) {
        this.handlePageChange(1, 10);
      }
    }
  }
});
</script>

<style scoped lang="scss">
table.table tr td:last-child {
  white-space: nowrap;
}
.three_dots {
  width: 20px;
  img {
    transition: all 0.3s ease;
  }
  &:hover {
    img {
      filter: brightness(0);
      transform: scale(1.1);
    }
  }
}
</style>
