<template>
  <!-- groups and members tab -->
  <div>
    <!-- form of group name -->
    <group-name-edit-form :id="state.id" :groupName="state.groupName" />

    <!-- table  -->
    <div class="table-responsive">
      <div class="sub_title">
        <h4>
          {{
            $t('pages.settings.groups.ms-table.title', {
              group: state.groupName,
              people: groupDetails.totalMembers,
              quota: groupDetails.quota
            })
          }}
        </h4>
      </div>
      <p className="text-danger m-2">{{ state.errorMessage }}</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ $t('pages.settings.groups.ms-table.th1') }}
            </th>
            <!-- <th scope="col">
              {{ $t('pages.settings.groups.ms-table.th2') }}
            </th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in groupDetails.members" :key="member.email">
            <!-- <td>山田太郎</td> -->
            <td>{{ member }}</td>
            <td class="text-right">
              <div class="btn-group dropstart">
                <button
                  type="button"
                  class="btn btn-default py-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <figure>
                    <img :src="icons.learnmore" alt="" />
                  </figure>
                </button>
                <ul class="dropdown-menu p-0">
                  <li>
                    <button
                      class="dropdown-item py-3"
                      type="button"
                      @click="() => selectMemberToDelete(member)"
                      data-bs-target="#delete_member_modal"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      {{ $t('labels.delete') }}
                    </button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <viz-pagination
        @onPageChange="handlePageChange"
        :totalEntries="groupDetails ? groupDetails.totalMembers : 0"
        :currentPage="state.currentPage"
        :perPageCount="state.perPageCount"
      />
    </div>

    <button
      type="button"
      class="btn btn-primary text-white"
      data-bs-toggle="modal"
      @click="openMemberInputModal"
      :data-bs-target="
        groupDetails.totalInvitations >= groupDetails.quota
          ? '#add_member_modal'
          : '#add_member_model_input'
      "
    >
      {{ $t('pages.settings.groups.add-member.add') }}
    </button>

    <!-- Add member Confirm Modal -->
    <div
      class="modal fade"
      id="add_member_modal"
      tabindex="-1"
      aria-labelledby="addMemberModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="addMemberModal">
              {{ $t('pages.settings.groups.add-member.title') }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {{ $t('pages.settings.groups.add-member.subtitle') }}
          </div>
          <div class="modal-footer justify-content-around border-top-0">
            <viz-button
              type="button"
              class="btn btn-default text-muted"
              data-bs-dismiss="modal"
            >
              {{ $t('labels.cancel') }}
            </viz-button>

            <button
              type="button"
              @click="handleQuotaExpansion"
              class="btn btn-primary text-white"
              data-bs-toggle="modal"
              data-bs-target="#add_member_model_input"
              data-bs-dismiss="modal"
            >
              {{ $t('pages.settings.groups.add-member.proceed') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Member Input Modal -->
    <div
      class="modal fade"
      id="add_member_model_input"
      tabindex="-1"
      aria-labelledby="addMemberModelInput"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <button
          type="button"
          class="d-none"
          data-bs-dismiss="modal"
          ref="addMemberDismissRef"
        ></button>
        <add-members-form
          v-if="state.isMemberInputModalOpen"
          :id="state.id"
          :groupName="state.groupName"
          :users="groupDetails.members"
          @close="closeMemberInputModal"
        />
      </div>
    </div>
    <!-- Delete Member Modal -->
    <delete-members-form
      :member="state.selectedMember"
      :groupId="state.id"
      @refreshGroup="
        loadGroupData(
          this.state.perPageCount >= this.groupDetails.totalMembers - 1 &&
            this.state.currentPage > 1
            ? state.currentPage - 1
            : state.currentPage,
          state.perPageCount
        )
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import { mapActions, mapGetters } from 'vuex';
import GroupNameEditForm from './GroupNameEditForm.vue';
import AddMembersForm from './AddMembersForm.vue';
import DeleteMembersForm from './DeleteMembersForm.vue';
import learnmoreIcon from '@/assets/icons/more.svg';
import { UserGroup } from '@/store/modules/auth';

export default defineComponent({
  components: {
    VizButton,
    GroupNameEditForm,
    AddMembersForm,
    DeleteMembersForm,
    VizPagination
  },

  beforeMount() {
    this.state.errorMessage = '';
    this.loadGroupData(this.state.currentPage, this.state.perPageCount);
  },
  methods: {
    ...mapActions(['loadGroupAndMembers', 'increaseGroupQuota']),
    loadGroupData(page: number, perPage: number) {
      const groupId = this.userGroup?.id;
      this.loadGroupAndMembers({ page, perPage, groupId })
        .then(data => {
          const { id, groupName } = data;
          this.state.id = id;
          this.state.groupName = groupName;
        })
        .catch(err => {
          this.state.errorMessage = err.errors ? err.errors[0].error : '';
          this.state.id = '';
          this.state.groupName = '';
        });
    },
    handleQuotaExpansion() {
      this.increaseGroupQuota(this.userGroup?.id)
        .then(() => {
          this.openMemberInputModal();
        })
        .catch(() => {
          const addMemberDismissRef = this.$refs.addMemberDismissRef as any;
          addMemberDismissRef?.click();
        });
    },
    handlePageChange(page: number, perPage: number) {
      this.state.currentPage = page;
      this.state.perPageCount = perPage;
      this.loadGroupAndMembers({ page, perPage, groupId: this.userGroup?.id });
    }
  },
  computed: {
    ...mapGetters(['userGroup', 'groupDetails']),
    totalMembers(): number {
      return Array.isArray(this.groupDetails.members)
        ? this.groupDetails.members.length
        : 0;
    }
  },
  setup() {
    const icons = markRaw({
      learnmore: learnmoreIcon
    });
    const state = reactive({
      id: '',
      groupName: '',
      errorMessage: '',
      isMemberInputModalOpen: false,
      isMemberModalOpen: false,
      selectedMember: '',
      currentPage: 1,
      perPageCount: 10
    });

    const openMemberInputModal = () => {
      state.isMemberInputModalOpen = true;
    };

    const closeMemberInputModal = () => {
      state.isMemberInputModalOpen = false;
    };
    const selectMemberToDelete = (member: string) => {
      if (!state.id) return;
      state.selectedMember = member;
    };

    return {
      state,
      openMemberInputModal,
      closeMemberInputModal,

      selectMemberToDelete,
      icons
    };
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal) {
        this.loadGroupData(1, 10);
      }
    }
  }
});
</script>

<style scoped></style>
