
import { defineComponent, reactive } from 'vue';
import VizTable from '@/components/ui/VizTable.vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizSelect from '@/components/ui/VizSelect.vue';
import VizTooltip from '@/components/ui/VizTooltip.vue';
import VizSwitchbox from '@/components/ui/VizSwitchbox.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import validationSchema from '@/validator/contribution-analysis/kpi-input';
import { useForm, useField } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
const { mapMutations, mapGetters } = createNamespacedHelpers('ca');

interface ErrorList {
  error?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'MeasureData',
  components: {
    VizTable,
    VizButton,
    VizSelect,
    VizSwitchbox,
    VizTooltip
  },
  beforeMount() {
    const { taskId } = this.$route.params;
    if (!taskId) {
      this.$router.replace({ path: '/analysis' });
    }
    this.setExitAllowed(false);
  },
  unmounted() {
    this.setExitAllowed(true);
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      errorMessage: '',
      isLoading: false,
      targetOptions: [{ text: '', value: '' }],
      lagListOptions: [{ text: '', value: '' }]
    });

    const initialFormValues = {
      modelName: '',
      target: '',
      date: '',
      method: 'linear-regression',
      considerLag: false,
      laglist: [],
      seasonEffects: false,
      mediaName: ''
    };

    const {
      errors,
      setFieldValue,
      handleSubmit,
      meta,
      values,
      setValues,
      setFieldError
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });

    const { value: modelName } = useField('modelName');
    const { value: target } = useField('target');
    const { value: date } = useField('date');
    const { value: method } = useField('method');
    const { value: considerLag } = useField('considerLag');
    const { value: laglist } = useField('laglist');
    const { value: seasonEffects } = useField('seasonEffects');
    const { value: mediaName } = useField('mediaName');

    const handleLagListChange = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      let tempLaglist = values.laglist ? values.laglist : [];

      if (target.value) {
        if (tempLaglist.includes(target.value)) {
          tempLaglist = tempLaglist.filter(item => item !== target.value);
        } else {
          tempLaglist.push(target.value);
        }
        setFieldValue('laglist', tempLaglist);
      }
    };
    const handleSeasonEffectChange = () => {
      if (!seasonEffects.value) {
        setFieldValue('mediaName', '');
      }
    };
    const handleConsiderLagChange = () => {
      if (!considerLag.value) {
        setFieldValue('laglist', []);
      }
    };
    const scrollToErrorView = () => {
      document.getElementById('error-container')?.scrollIntoView();
    };
    const onSubmit = handleSubmit(values => {
      if (
        values.considerLag &&
        Array.isArray(values.laglist) &&
        values.laglist.length < 1
      ) {
        setFieldError('laglist', 'errors.required-field');
        return;
      }

      state.isLoading = true;
      store
        .dispatch('ca/uploadKPIData', {
          ...values,
          taskId: Number(route.params.taskId)
        })
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';
          router.push({
            name: 'ContributionAnalysisObserve',
            params: { taskId: route.params.taskId }
          });
        })
        .catch((err: ApiErrorInterface) => {
          state.isLoading = false;
          type FieldOptions = {
            [key: string]: string;
          };
          const fieldMaps: FieldOptions = {
            season_effect: 'seasonEffects',
            model_name: 'modelName',
            method: 'method',
            lag: 'lag',
            lag_list: 'laglist'
          };
          if (Array.isArray(err?.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.errors.forEach((error: any) => {
              Object.keys(fieldMaps).forEach((fieldname: string) => {
                if (error[fieldname]) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const errorField: any = fieldMaps[fieldname];
                  setFieldError(errorField, error[fieldname]);
                }
              });
            });

            scrollToErrorView();
          }
          if (err.errors[0]?.error) {
            state.errorMessage = err.errors[0].error;
          }
        });
    });

    return {
      modelName,
      target,
      date,
      method,
      considerLag,
      laglist,
      seasonEffects,
      mediaName,
      errors,
      meta,
      state,
      handleLagListChange,
      handleSeasonEffectChange,
      handleConsiderLagChange,
      onSubmit,
      setValues
    };
  },
  computed: {
    ...mapGetters(['costIndicators']),
    seasonEffectOptions: function(): Array<any> {
      const costIndicators = this.costIndicators as { headers: Array<string> };
      if (costIndicators) {
        return costIndicators?.headers.map((item: string) => {
          return { text: item, value: item };
        });
      }
      return [];
    },
    dateOptions: function(): Array<any> {
      return this.seasonEffectOptions;
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    handleGoBack() {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.$router.push({
          name: 'ContributionEnterData'
        });
      }
    }
  }
});
