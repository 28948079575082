<template>
  <!-- contract tab -->
  <div>
    <p class="text-danger">{{ state.errorMessage }}</p>
    <div class="mb-3">
      <div class="sub-title">
        <h4>{{ $t('pages.settings.contract.title') }}</h4>
      </div>
      <div class="text-muted">
        {{ state.contract.contractNumber ? state.contract.contractNumber : '' }}
      </div>
    </div>
    <div class="mb-3">
      <div class="sub-title">
        <h4>{{ $t('labels.contract-date') }}</h4>
      </div>
      <div class="text-muted">
        {{
          state.contract.contractDate ? moment(state.contract.contractDate) : ''
        }}
      </div>
    </div>
    <div class="basic__information">
      <form
        class="basic__information__form"
        @submit.prevent="onSubmit"
        novalidate
      >
        <legend>{{ $t('labels.basic-info') }}</legend>
        <div class="row">
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                id="company_name"
                v-model="company"
                :readonly="!state.isEditable"
              />
              <label for="company_name">{{ $t('labels.company-name') }}</label>
              <p class="text-danger">
                {{ errors && errors.company ? $t(errors.company) : '' }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                required
                v-model="friganaCompany"
                :readonly="!state.isEditable"
              />
              <label for="frigana_company-name">
                {{ $t('pages.settings.contract.frigana-company.label') }}</label
              >
              <small class="text-muted">
                {{ $t('labels.do-not-use-space') }}
              </small>
              <p class="text-danger">
                {{
                  errors && errors.friganaCompany
                    ? $t(errors.friganaCompany)
                    : ''
                }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="org"
                required
                :readonly="!state.isEditable"
              />
              <label for="group_department">
                {{ $t('pages.settings.contract.org.label') }}
              </label>
              <p class="text-danger">
                {{ errors && errors.org ? $t(errors.org) : '' }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                id="person-incharge"
                v-model="person"
                required
                :readonly="!state.isEditable"
              />
              <label for="person-incharge">
                {{ $t('pages.settings.contract.personin.label') }}
              </label>
              <p class="text-danger">
                {{ errors && errors.person ? $t(errors.person) : '' }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="friganaPerson"
                id="frigana_person-incharge"
                :readonly="!state.isEditable"
                required
              />
              <label for="frigana_person-incharge">
                {{ $t('pages.settings.contract.frigana-person.label') }}
              </label>
              <small class="text-muted">
                {{ $t('labels.do-not-use-space') }}
              </small>
              <p class="text-danger">
                {{
                  errors && errors.friganaPerson ? $t(errors.friganaPerson) : ''
                }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="phone"
                id="call_number"
                :readonly="!state.isEditable"
                maxlength="15"
                required
              />
              <label for="call_number">
                {{ $t('pages.settings.contract.phone.label') }}
              </label>
              <p class="text-danger">
                {{ errors && errors.phone ? $t(errors.phone) : '' }}
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                id="Address"
                v-model="address"
                :readonly="!state.isEditable"
                required
              />
              <label for="Address">
                {{ $t('pages.settings.contract.address.label') }}
              </label>
              <p class="text-danger">
                {{ errors && errors.address ? $t(errors.address) : '' }}
              </p>
            </div>
          </div>

          <div class="col-md-8">
            <legend>
              {{ $t('pages.settings.contract.bottom-section.title') }}
            </legend>
            <div class="d-flex align-item-center text-muted mb-3">
              <div
                class="me-3"
                v-for="item in state.contract.contractFunction"
                :key="item"
              >
                <!-- {{ $t('pages.settings.contract.bottom-section.label1') }} -->
                {{ item }}
              </div>
            </div>
            <div class="text-muted mb-3">
              {{ $t('pages.settings.contract.bottom-section.info-first') }}

              <a href="#" class="text-primary"> info@datacurrent.com</a>
              {{ $t('pages.settings.contract.bottom-section.info-last') }}
            </div>
          </div>
        </div>
        <transition name="fade" v-if="!state.isEditable">
          <viz-button
            type="button"
            class="btn btn-primary text-white"
            @click="handleEdit"
          >
            {{ $t('labels.edit') }}
          </viz-button>
        </transition>
        <transition name="fade" v-if="state.isEditable">
          <div class="modal-footer justify-content-start border-top-0 px-0">
            <viz-button
              @click="handleDiscard"
              type="button"
              class="btn btn-default text-muted"
              data-bs-dismiss="modal"
            >
              {{ $t('labels.discard-edits') }}
            </viz-button>
            <viz-button
              type="submit"
              :loading="state.isLoading"
              class="btn btn-primary text-white rounded-pill"
            >
              {{ $t('labels.save-edits') }}
            </viz-button>
          </div>
        </transition>
      </form>
      <viz-toast v-if="state.toastMessage" :message="$t(state.toastMessage)" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizToast from '@/components/ui/VizToast.vue';
import validationSchema from '@/validator/settings/contract-management';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { ContractDataInterface } from '@/api/manager';
import moment from 'moment';

export default defineComponent({
  components: {
    VizButton,
    VizToast
  },
  beforeMount() {
    this.state.contract = {};
    this.populateContract();
  },

  setup() {
    const store = useStore();

    const state = reactive({
      isEditable: false,
      errorMessage: '',
      contract: {} as ContractDataInterface | {},
      isLoading: false,
      toastMessage: ''
    });

    const initialFormValues = {
      company: '',
      friganaCompany: '',
      org: '',
      person: '',
      friganaPerson: '',
      phone: '',
      address: ''
    };

    const { errors, handleSubmit, meta, setFieldError, setValues } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });
    const { value: company } = useField('company');
    const { value: friganaCompany } = useField('friganaCompany');
    const { value: org } = useField('org');
    const { value: person } = useField('person');
    const { value: friganaPerson } = useField('friganaPerson');
    const { value: phone } = useField('phone');
    const { value: address } = useField('address');

    const populateContract = () => {
      store
        .dispatch('loadContract')
        .then((contract: ContractDataInterface) => {
          state.contract = contract;
          setValues({ ...contract });
          state.errorMessage = '';
        })
        .catch(err => {
          state.contract = {};
          state.errorMessage =
            err?.errors && err?.errors[0].error ? err.errors[0].error : '';
        });
    };

    const onSubmit = handleSubmit(values => {
      state.isLoading = true;
      store
        .dispatch('updateContract', { ...state.contract, ...values })
        .then(() => {
          populateContract();
          state.isEditable = false;
          state.isLoading = false;
          state.toastMessage = 'messages.edits-saved';
          setTimeout(() => {
            state.toastMessage = '';
          }, 5000);
        })
        .catch(err => {
          state.isLoading = false;
          const errors = err?.errors;
          if (Array.isArray(errors)) {
            errors.forEach(item => {
              if (item.error) state.errorMessage = item.error;
              if (item.company_name)
                setFieldError('company', item.company_name);
              if (item.frigana_company_name)
                setFieldError('friganaCompany', item.frigana_company_name);
              if (item.organization_department)
                setFieldError('org', item.organization_department);
              if (item.person_incharge)
                setFieldError('person', item.person_incharge);
              if (item.frigana_person_incharge)
                setFieldError('friganaPerson', item.frigana_person_incharge);
              if (item.phone_number) setFieldError('phone', item.phone_number);
              if (item.street_address)
                setFieldError('address', item.street_address);
            });
          }
        });
    });

    return {
      company,
      friganaCompany,
      org,
      person,
      friganaPerson,
      phone,
      address,
      onSubmit,
      state,
      errors,
      meta,
      setValues,
      populateContract
    };
  },
  methods: {
    handleEdit() {
      this.state.isEditable = true;
    },
    handleDiscard() {
      this.state.isEditable = false;
      this.setValues({ ...this.state.contract });
    },
    moment: function(date: string) {
      return moment(date).format('YYYY/MM/DD');
    }
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
