/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import { RootState } from '../index';
import authAPI, {
  AccountDetailsInterface,
  ResetPasswordCredentials
} from '../../api/auth';
import {
  deleteAccessToken,
  deleteExpiryTime,
  deleteRefreshToken,
  getAccessToken,
  getExpiryTime,
  getRefreshToken,
  setAccessToken,
  setExpiryTime,
  setRefreshToken
} from '@/utils/local-storage';

interface LoginSuccessData {
  accessToken: string | null;
  refreshToken: string | null;
  expiresIn: number;

  user: {
    user_role: number;
    user_functions: Array<string>;
  } | null;
}

export interface UserGroup {
  id: number;
  name: string;
}

export interface RoleContractFunctions {
  role: string;
  contract_functions: Array<string>;
}

export interface StorageUsage {
  used: number;
  total: number;
}
export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  expiresIn: number;
  userGroup: UserGroup | null;
  user: {
    user_role: number;
    user_functions: Array<string>;
  } | null;
  status: string | null;
  storageUsage: StorageUsage;
  groups: Array<UserGroup> | null;
  accountDetails: AccountDetailsInterface | {};
}

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  accessToken: getAccessToken() || null,
  refreshToken: getRefreshToken() || null,
  userGroup: null,
  groups: [],
  user: null,
  expiresIn: Number(getExpiryTime()) || 0,
  status: null,
  storageUsage: { used: 0, total: 0 },
  accountDetails: {}
};

export default {
  state: initialState,
  mutations: {
    statusPending(state: AuthState) {
      state.status = STATUS.LOADING;
    },
    statusSuccess(state: AuthState) {
      state.status = STATUS.SUCCESS;
    },
    statusError(state: AuthState) {
      state.status = STATUS.ERROR;
    },
    setAccountDetails(state: AuthState, details: AccountDetailsInterface | {}) {
      state.accountDetails = details;
    },
    setUserGroup(state: AuthState, group: UserGroup | null) {
      state.userGroup = group;
    },
    setGroups(state: AuthState, groups: Array<UserGroup>) {
      state.groups = groups;
    },
    setStorageUsage(state: AuthState, storageUsage: StorageUsage) {
      state.storageUsage = storageUsage;
    },
    setUser(
      state: AuthState,
      user: { user_role: number; user_functions: Array<string> }
    ) {
      state.user = user;
    },
    setTokens(
      state: AuthState,
      {
        accessToken,
        refreshToken,
        expiresIn
      }: { accessToken: string; refreshToken: string; expiresIn: number }
    ) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expiresIn = expiresIn;
    },
    loginSuccess(
      state: AuthState,
      { accessToken, refreshToken, expiresIn, user }: LoginSuccessData
    ) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expiresIn = expiresIn;
      state.user = user;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetState(state: AuthState) {
      state = initialState;
    },
    logoutSuccess(state: AuthState) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.userGroup = null;
      state.groups = null;
    },
    loginFailure(state: AuthState) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
    }
  },
  actions: {
    resetAuthState(context: ActionContext<AuthState, RootState>) {
      return new Promise(resolve => {
        deleteAccessToken();
        deleteRefreshToken();
        context.commit('resetState');
        resolve('');
      });
    },
    getStorageUsage(
      context: ActionContext<AuthState, RootState>,
      groupId: number
    ) {
      return new Promise((resolve, reject) => {
        authAPI
          .getStorageUsage(groupId)
          .then(res => {
            const { bytes_used, total_bytes } = res?.data;
            const used = Number(bytes_used / 1073741824);
            const total = Number(total_bytes / 1073741824).toFixed(0);
            context.commit('setStorageUsage', { used, total });
            resolve({ used, total });
          })
          .catch(err => {
            reject(err);
            context.commit('setStorageUsage', { used: 0, total: 0 });
          });
      });
    },
    changeUserGroup(
      context: ActionContext<AuthState, RootState>,
      group: UserGroup
    ) {
      return new Promise((resolve, reject) => {
        context
          .dispatch('fetchContractFunctions', group.id)
          .then(res => {
            context.commit('setUserGroup', group);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchUserGroups(context: ActionContext<AuthState, RootState>) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        context.commit('setGroups', []);

        authAPI
          .fetchUserGroups()
          .then(res => {
            context.commit('statusSuccess');
            const groups = res.data?.groups;
            context.commit('setGroups', groups);
            resolve(groups);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    fetchContractFunctions(
      context: ActionContext<AuthState, RootState>,
      groupId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!groupId) {
          return reject('');
        }
        authAPI
          .fetchContractFunctions(groupId)
          .then(res => {
            const {
              role,
              contract_functions
            } = res.data as RoleContractFunctions;
            const data = {
              user_role: role,
              user_functions: contract_functions
            };
            context.commit('setUser', data);
            resolve(res.data);
          })
          .catch(err => {
            context.commit('setUser', null);
            reject(err);
          });
      });
    },
    login(context: ActionContext<AuthState, RootState>, credentials: any) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        authAPI
          .login(credentials)
          .then((res: any) => {
            const {
              refresh_token: refreshToken,
              access_token: accessToken,
              expires_in: expiresIn
            } = res.data;

            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            setExpiryTime(expiresIn);

            context.commit('statusSuccess');
            context.commit('loginSuccess', {
              refreshToken,
              accessToken,
              expiresIn
            });
            resolve(res);
          })
          .catch(err => {
            context.commit('loginFailure');
            context.commit('statusError');

            reject(err);
          });
      });
    },
    executeRefreshToken(_context: ActionContext<AuthState, RootState>) {
      return new Promise((resolve, reject) => {
        const refToken = String(getRefreshToken());

        authAPI
          .refreshToken(refToken)
          .then(res => {
            const data = res?.data;
            if (data?.is_expired) {
              reject('Refresh Token Expired');
            } else {
              _context.commit('setTokens', {
                accessToken: data?.access_token,
                refreshToken: data?.refresh_token,
                expiresIn: data?.expires_in
              });
              setAccessToken(data?.access_token);
              setRefreshToken(data?.refresh_token);
              setExpiryTime(data?.expires_in);
              resolve(res);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    logout(context: ActionContext<AuthState, RootState>) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');

        authAPI
          .logout()
          .then(res => {
            deleteAccessToken();
            deleteRefreshToken();
            deleteExpiryTime();
            context.commit('logoutSuccess');
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            if (err.status === 401) {
              deleteAccessToken();
              deleteRefreshToken();
              deleteExpiryTime();
              context.commit('statusSuccess');
              resolve({});
            }
            context.commit('statusError');
            reject(err);
          });
      });
    },
    register(context: ActionContext<AuthState, RootState>, credentials: any) {
      context.commit('statusPending');
      return new Promise((resolve, reject) => {
        authAPI
          .register(credentials)
          .then((res: any) => {
            if (res.errors) {
              reject(res.errors);
            }
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch((err: any) => {
            context.commit('statusError');
            reject(err);
          });
      });
    },

    resetPassword(
      context: ActionContext<AuthState, RootState>,
      { oldPassword, newPassword, confirmNewPassword }: ResetPasswordCredentials
    ) {
      return new Promise((resolve, reject) => {
        authAPI
          .resetPassword({ oldPassword, newPassword, confirmNewPassword })
          .then(_res => {
            deleteAccessToken();
            context.commit('logoutSuccess');
            resolve(_res);
          })
          .catch(_err => {
            reject(_err);
          });
      });
    },
    forgotPassword(
      context: ActionContext<AuthState, RootState>,
      email: string
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        authAPI
          .forgotPassword(email)
          .then(_res => {
            context.commit('statusSuccess');
            resolve(_res);
          })
          .catch(_err => {
            context.commit('statusError');

            reject(_err);
          });
      });
    },
    updateAccountDetails(
      context: ActionContext<AuthState, RootState>,
      data: AccountDetailsInterface
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');

        authAPI
          .updateAccountDetails(data)
          .then(res => {
            const { email: email } = res?.data;
            context.commit('setAccountDetails', { email });
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    loadUserAccountDetails(context: ActionContext<AuthState, RootState>) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        context.commit('setAccountDetails', {});

        authAPI
          .loadUserAccountDetails()
          .then(res => {
            context.commit('statusSuccess');
            const { email: email } = res?.data;
            context.commit('setAccountDetails', { email });

            resolve(res);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    preloadEmail(
      context: ActionContext<AuthState, RootState>,
      { token, role }: { token: string; role: number }
    ) {
      context.commit('statusPending');
      return new Promise((resolve, reject) => {
        authAPI
          .preloadEmail({ token, role })
          .then(res => {
            context.commit('statusSuccess');
            resolve(res);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    }
  },

  getters: {
    isLoggedIn: (state: AuthState) => !!state.accessToken,
    authStatus: (state: AuthState) => state.status,
    tokens: (state: AuthState) => ({
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      expiresIn: state.expiresIn
    }),
    user: (state: AuthState) => state.user,
    userGroup: (state: AuthState) => state.userGroup,
    storageUsage: (state: AuthState) => state.storageUsage,
    isStorageAvailable: ({ storageUsage }: AuthState) =>
      (storageUsage && storageUsage.total * 1024 - storageUsage.used * 1024) >
      100,
    groups: (state: AuthState) => state.groups,
    accountDetails: (state: AuthState) => state.accountDetails
  }
};
