
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('te');
interface UserScore {
  score: number;
  user_id: string;
  prediction: number;
}
export default defineComponent({
  name: 'KpiEffectResult',
  props: ['taskId'],

  beforeMount() {
    this.getUserScore(this.taskId)
      .then(data => {
        this.data = data;
        this.errorMessage = '';
      })
      .catch(err => {
        this.data = [];
        this.errorMessage = err?.errors ? err.errors[0].error : '';
      });
  },

  data() {
    return { data: [] as Array<UserScore>, errorMessage: '' };
  },
  computed: {
    maxBarValue: function(): number {
      let maxValue = 1;

      this.data.forEach((item: UserScore) => {
        const yaxisValue = Math.abs(Number(item.score));
        if (yaxisValue > maxValue) {
          maxValue = yaxisValue;
        }
      });
      return maxValue;
    }
  },
  methods: {
    ...mapActions(['getUserScore']),
    getBarPercentage(val: number) {
      return (Number(val) / 10) * 100;
    }
  },
  watch: {
    taskId(newtaskId) {
      if (newtaskId) {
        this.getUserScore(newtaskId)
          .then(data => {
            this.data = data;
          })
          .catch(() => (this.data = []));
      }
    }
  }
});
