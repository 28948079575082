
import { defineComponent, reactive } from 'vue';
import { mapActions } from 'vuex';
import VizButton from '@/components/ui/VizButton.vue';
import VizPagination from '@/components/ui/VizPagination.vue';
import moment from 'moment';

export default defineComponent({
  beforeMount() {
    this.state.data = { ...this.state.data, invoices: [], totalEntries: 0 };
    this.getInvoiceList({ page: 1, perPage: 10 })
      .then(data => {
        this.state.data = data;
        this.state.errorMessage = '';
      })
      .catch(err => {
        this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
      });
  },
  mounted() {
    const { tab, id } = this.$route.params;
    if (tab == 'billing') {
      if (id) {
        this.handleDownload(Number(id));
      }
    }
  },
  components: {
    VizButton,
    VizPagination
  },
  setup() {
    const state = reactive({
      data: { invoices: [], totalEntries: 0, currentPage: 1, perPageCount: 10 },
      errorMessage: ''
    });
    return { state };
  },
  methods: {
    ...mapActions(['getInvoiceList', 'downloadInvoice']),
    moment: function(date: string) {
      return date ? moment(date).format('YYYY/MM/DD') : '';
    },
    handleDownload(invoiceId: number) {
      this.downloadInvoice(invoiceId)
        .then(() => {
          this.state.errorMessage = '';
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
        });
    },
    handlePageChange(page: number, perPage: number) {
      this.getInvoiceList({ page, perPage })
        .then(data => {
          this.state.data = data;
          this.state.errorMessage = '';
          this.state.data = {
            ...this.state.data,
            currentPage: page,
            perPageCount: perPage
          };
        })
        .catch(err => {
          this.state.errorMessage = err?.errors ? err.errors[0]?.error : '';
        });
    }
  }
});
