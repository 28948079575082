<template>
  <section class=" step steps ">
    <ul
      :class="
        `d-flex align-items-center ${
          justifyStart ? 'justify-content-start' : 'justify-content-center'
        }`
      "
    >
      <li v-for="step in steps" v-bind:key="step.number">
        <div
          v-bind:class="
            ` d-flex align-items-center ${
              currentStep === step.number ? 'active' : ''
            }`
          "
        >
          <div class="number">
            {{ step.number }}
          </div>
          <div class="text">
            {{ $t(step.title) }}
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StepsTrack',
  props: {
    currentStep: Number,
    steps: Array,
    justifyStart: Boolean
  }
});
</script>
