
import { defineComponent } from 'vue';

export default defineComponent({
  beforeMount() {
    if (!this.$route.params?.errorMessage) {
      this.$router.push({ name: 'Login' });
    }
  },
  setup() {
    return {};
  }
});
