
import { defineComponent } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import ExecutionHistory from '@/components/common/ExecutionHistory.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'ContributionAnalysis',
  beforeMount() {
    this.resetKpiState();
  },
  components: {
    StepsTrack,
    ExecutionHistory,
  },
  mounted() {
    this.info = 'labels.requesting-taskid';
    this.getTaskId()
      .then(() => {
        this.errorMessage = '';
        this.info = '';
      })
      .catch((err) => {
        this.info = '';

        if (err && Array.isArray(err?.errors)) {
          this.errorMessage = err?.errors[0]?.error;
        } else {
          this.errorMessage =
            'Network Error while loading task.Reload and try again';
        }
      });

    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.fileUploadStatus('aborted');
        this.resetKpiState();
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      errorMessage: '',
      info: '',
      steps: [
        {
          title: 'pages.ca.steps.1',

          number: 1,
        },
        {
          title: 'pages.ca.steps.2',
          number: 2,
        },
        { title: 'pages.ca.steps.3', number: 3 },
      ],
    };
  },

  methods: {
    ...mapActions(['resetKpiState', 'getTaskId', 'fileUploadStatus']),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';
      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
  },
  computed: {
    ...mapGetters(['isExitAllowed']),
    currentStep: function (): number {
      return this.$route.meta.step || 1;
    },
  },
});
