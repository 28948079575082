
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import ExtractionReport from '@/components/target-extraction/ExtractionReport.vue';
const { mapGetters, mapActions } = createNamespacedHelpers('te');

export default defineComponent({
  name: 'TargetAnalysisReport',
  props: {
    isAnalysed: Boolean,
    steps: Array,
    currentStep: Number
  },
  emits: ['goBack', 'saveAnalysis'],
  computed: {
    ...mapGetters(['taskId'])
  },
  components: {
    StepsTrack,
    ExtractionReport
  },
  methods: {
    ...mapActions(['resetTargetState']),
    handleGoBack() {
      this.resetTargetState();
      this.$emit('goBack');
    }
  }
});
