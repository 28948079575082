
import { defineComponent } from 'vue';
import { confirmChangeEmail } from '@/api/auth';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  mounted() {
    const token = this.$route.query?.token;
    this.resetAuthState();
    if (!token) {
      this.$router.push({ name: 'Login' });
    }
    confirmChangeEmail(String(token))
      .then(res => {
        const { email } = res?.data;
        this.$router.push({ name: 'Login', params: { email } });
      })
      .catch(err => {
        let error = '';
        if (err?.errors) {
          err.errors.forEach((item: any) => {
            if (item.error) {
              error = item.error;
            }
            if (item.token) {
              error = item.token;
            }
          });
        }
        this.$router.push({ name: 'Login', params: { error } });
      });
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['resetAuthState'])
  },
  setup() {
    return {};
  }
});
