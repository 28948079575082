<template>
  <div class="main px-60">
    <div class="setting_page">
      <viz-tabs
        :currentTab="state.tab"
        :tabs="tabs"
        @onTabChange="handleTabChange"
      >
        <template #contract>
          <contract-account />
        </template>
        <template #billing>
          <billing />
        </template>
        <template #groups>
          <group-and-members />
        </template>
      </viz-tabs>
      <div
        v-if="
          state.tab &&
            state.tab.tabKey == 'groups' &&
            groups &&
            groups.length > 1
        "
        class="mb-4 text-right d-block"
      >
        <button
          type="button"
          class="btn btn-default text-primary p-0 my-0 mb-4"
          data-bs-target="#delete_group_modal"
          @click="openDeleteModal"
          data-bs-toggle="modal"
          data-bs-dismiss="modal"
        >
          {{ $t('pages.settings.groups.delete-group') }}
        </button>
      </div>
      <!-- delete group modal -->
      <div class="modal fade" id="delete_group_modal">
        <div class="modal-dialog modal-dialog-centered">
          <!-- delete group form -->
          <div class="modal-content" v-if="!state.isLoading">
            <div class="modal-header border-0">
              <h5 class="modal-title" id="deleteGroupModal">
                {{ $t('pages.settings.groups.delete-modal.title') }}
              </h5>
              <viz-button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="handleCancel"
              ></viz-button>
            </div>
            <div class="modal-body">
              <p>
                {{ $t('pages.settings.groups.delete-modal.subtitle') }}
              </p>
              <form action="POST">
                <div class="input-groups mt-3 mb-0">
                  <input
                    type="text"
                    class="form-control"
                    id="delete-input-group__name"
                    v-model="state.groupName"
                    required
                  />
                  <label for="delete-input-group__name">
                    {{ $t('pages.settings.groups.group-field.label') }}
                  </label>
                </div>
                <p class="text-danger">{{ $t(state.errorMessage) }}</p>
              </form>
            </div>
            <div class="modal-footer justify-content-end border-top-0">
              <button
                type="button"
                class="btn btn-default text-muted"
                @click="handleCancel"
                data-bs-dismiss="modal"
              >
                {{ $t('labels.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary text-white rounded-pill"
                @click="handleGroupDelete"
              >
                {{ $t('labels.delete') }}
              </button>
            </div>
          </div>
          <!-- delete group loader -->
          <div class="modal-content" v-if="state.isLoading">
            <div class="modal-header border-0 text-center">
              <h5 class="modal-title text-center w-100" id="deleteSpinnerModal">
                {{ $t('pages.settings.groups.delete-modal.deleted-title') }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="d-inline-block text-center w-100">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, markRaw } from 'vue';
import VizTabs from '@/components/ui/VizTabs.vue';
import VizButton from '@/components/ui/VizButton.vue';

import Billing from './Invoice/index.vue';
import ContractAccount from './ContractManagement/index.vue';
import GroupAndMembers from './GroupAndMembers/index.vue';
import { useStore, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Settings',
  components: {
    VizButton,
    VizTabs,
    Billing,
    ContractAccount,
    GroupAndMembers
  },
  beforeMount() {
    const { tab } = this.$route.params;
    if (tab == 'billing') {
      this.state.tab = this.tabs[1];
    }
  },
  computed: {
    ...mapGetters(['groups'])
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const tabs = markRaw([
      {
        tabKey: 'contract',
        title: 'Import History',
        tsKey: 'pages.settings.contract.title'
      },
      {
        tabKey: 'billing',
        title: 'Billing',
        tsKey: 'pages.settings.billing.title'
      },
      {
        tabKey: 'groups',
        title: 'Group and Members',
        tsKey: 'pages.settings.groups.title'
      }
    ]);

    const state = reactive({
      tab: tabs[0],
      isLoading: false,
      groupName: '',
      errorMessage: ''
    });

    const openDeleteModal = () => {
      // state.groupName = store.state.auth.userGroup?.name;
      state.groupName = '';
    };

    const handleTabChange = (tab: any) => {
      state.tab = tab;
    };

    const handleCancel = () => {
      state.errorMessage = '';
    };

    const handleGroupDelete = () => {
      const group = store.state.auth.userGroup;
      if (state.groupName !== group?.name) {
        state.errorMessage = 'errors.group-not-match';
        return;
      }

      state.isLoading = true;

      store
        .dispatch('deleteGroup', group?.id)
        .then(() => {
          state.isLoading = false;
          state.errorMessage = '';

          document.getElementById('delete_group_modal')?.click();

          store
            .dispatch('fetchUserGroups')
            .then(groups => {
              store
                .dispatch(
                  'changeUserGroup',
                  groups.length > 0 ? groups[0] : null
                )
                .then(() => {
                  return router.push({ name: 'History' });
                });
            })
            .catch(() => {
              state.errorMessage = 'errors.automatic-reload-failed';
            });
        })
        .catch(() => {
          state.errorMessage = 'errors.group-deletion-failed';
          state.isLoading = false;
        });
    };
    return {
      tabs,
      handleTabChange,
      handleGroupDelete,
      handleCancel,
      state,
      openDeleteModal
    };
  }
});
</script>
