<template>
  <div class="data-type">
    <div class="data-type__req">
      <div class="sub_title mb-0">
        <h4>
          {{ $t('pages.ca.enterdata.section2.h1') }}
        </h4>
        <!-- <div class="text-primary">
            {{ $t('pages.ca.enterdata.section2.h2') }}
          </div> -->
      </div>
      <div class="d-flex align-items-center mb-3 flex-wrap">
        <data-import-option
          v-for="option in dataImportOptions"
          :key="option.key"
          @click="setDataImportOption(option.key)"
          :label="$t(option.label)"
          :icon="option.icon"
          :class="option.key === state.importFrom ? 'active' : ''"
        />
      </div>
    </div>
    <div :class="`${state.importFrom === 'ilocal' ? 'd-block' : 'd-none'}`">
      <form
        class="data-type__form row py-0"
        @submit.prevent="onSubmit"
        novalidate
      >
        <p class="text-danger pb-4">{{ state.errorMessage }}</p>

        <div class="col-lg-8 col-md-12">
          <viz-file-input
            :label="$t('pages.ca.enterdata.section2.fileselect')"
            name="s3InputFile1"
            v-model="s3InputFile1"
            @change="uploadFile"
            :uploadState="state.isUploadLoading"
            :uploadProgress="
              kpiFileInputForm.progress
                ? kpiFileInputForm.progress.s3InputFile1
                : 0
            "
            @terminate="terminate"
            ref="s3input1"
            accept=".csv,.xls"
          />
          <span class="text-danger">{{
            errors.s3InputFile1 ? $t(errors.s3InputFile1) : ''
          }}</span>
        </div>

        <!-- method end -->
        <div class="d-block mt-3 button">
          <viz-button
            :disabled="state.isUploadLoading"
            :loading="state.isSubmissionLoading"
            type="submit"
            class="btn btn-primary text-white fw-bold"
          >
            {{ $t('common.buttonlabels.nextstep') }}
          </viz-button>
        </div>
      </form>
    </div>
    <div
      :class="
        `${
          state.importFrom === 'aws' || state.importFrom === 'gcs'
            ? 'd-block'
            : 'd-none'
        }`
      "
    >
      <form
        class="data-type__form row py-0"
        @submit.prevent="onSubmitAws"
        novalidate
      >
        <p class="text-danger pb-4">{{ state.errorMessage }}</p>
        <div class="col-lg-8 col-md-12  ">
          <div class="aws-form">
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="state.aws_access_key_id"
                id="aws_key"
                placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxx"
                autocomplete="off"
                required
              />
              <label for="aws_key">
                {{ state.importFrom === 'aws' ? 'AWS' : 'Google' }} Access Key
                ID
              </label>
              <p class="text-danger">
                {{
                  state.awsError.aws_access_key_id
                    ? $t(state.awsError.aws_access_key_id)
                    : ''
                }}
              </p>
            </div>
            <div class="input-groups">
              <input
                type="password"
                class="form-control"
                v-model="state.aws_secret_access_key"
                id="aws_secret"
                placeholder="********************"
                autocomplete="off"
                required
              />
              <label for="aws_secret">
                {{ state.importFrom === 'aws' ? 'AWS' : 'Google' }} Secret
                Access Key
              </label>
              <p class="text-danger">
                {{
                  state.awsError.aws_secret_access_key
                    ? $t(state.awsError.aws_secret_access_key)
                    : ''
                }}
              </p>
            </div>
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="state.aws_region"
                id="aws_region"
                placeholder="ap-northeast-1"
                autocomplete="off"
                required
              />
              <label for="aws_region">
                リージョン
              </label>
              <p class="text-danger">
                {{
                  state.awsError.aws_region ? $t(state.awsError.aws_region) : ''
                }}
              </p>
            </div>
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="state.bucket"
                id="aws_bucket"
                placeholder="bucket"
                autocomplete="off"
                required
              />
              <label for="aws_bucket">
                バケット
              </label>
              <p class="text-danger">
                {{ state.awsError.bucket ? $t(state.awsError.bucket) : '' }}
              </p>
            </div>
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="state.path"
                id="aws_path"
                placeholder="example"
                autocomplete="off"
                required
              />
              <label for="aws_path">
                ファイルパス(ファイル名を含まないバケット配下のパス)
              </label>
              <p class="text-danger">
                {{ state.awsError.path ? $t(state.awsError.path) : '' }}
              </p>
            </div>
            <div class="input-groups">
              <input
                type="text"
                class="form-control"
                v-model="state.filename"
                id="aws_filename"
                placeholder="202010_インプットKPI.csv"
                required
              />
              <label for="aws_filename">
                ファイル名を入力
              </label>
              <p class="text-danger">
                {{ state.awsError.filename ? $t(state.awsError.filename) : '' }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12  " v-if="state.isUploadLoading">
          <div class="d-flex align-items-center">
            <div class="progress" style="height: 10px; width: 90%">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="`width: ${state.awsprogress}%`"
                :aria-valuenow="state.awsprogress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            <div class="progress-percent">{{ state.awsprogress }}%</div>
            <button
              type="button"
              class="btn btn-secondary rounded-pill ms-3 btn-sm btn-abort"
              data-bs-toggle="modal"
              data-bs-target="#abort-modal"
            >
              インポートの中止
            </button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="abort-modal"
              tabindex="-1"
              aria-labelledby="abort"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    この進行を中止してもよろしいですか？
                  </div>
                  <div class="modal-footer justify-content-center">
                    <button
                      type="button"
                      class="btn btn-primary rounded-pill ms-3 btn-sm"
                      data-bs-dismiss="modal"
                    >
                      {{ $t('labels.cancel') }}
                    </button>
                    <viz-button
                      type="button"
                      class="btn btn-secondary rounded-pill ms-3 btn-sm"
                      data-bs-dismiss="modal"
                      @click="terminateUpload"
                    >
                      インポートの中止
                    </viz-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-block mt-3 button">
          <viz-button
            :disabled="state.isUploadLoading"
            :loading="state.isSubmissionLoading"
            type="submit"
            class="btn btn-primary text-white fw-bold"
          >
            {{ $t('common.buttonlabels.nextstep') }}
          </viz-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizFileInput from '@/components/ui/VizFileInput.vue';

// import VizInput from '@/components/ui/VizInput.vue';
import DataImportOption from '@/components/contribution-analysis/DataImportOption.vue';
import {
  attachFileIcon,
  cloudUploadIcon,
  awsIcon,
  googleCloudIcon
} from '@/utils/icons';
import { useForm, useField } from 'vee-validate';
import {
  useStore,
  createNamespacedHelpers,
  mapGetters as mapRootGetters
} from 'vuex';
import validationSchema from '@/validator/contribution-analysis/kpi-file-input';
import awsValidationSchema from '@/validator/contribution-analysis/kpi-aws';
import { useRouter } from 'vue-router';
import { UserGroup } from '@/store/modules/auth';

const { mapGetters, mapActions } = createNamespacedHelpers('ca');

interface ErrorList {
  error?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}
export default defineComponent({
  name: 'EnterData',
  beforeMount() {
    const kpiState = this.kpiFileInputForm.formState;
    if (kpiState) {
      this.setValues({ ...kpiState });
      this.state.files = {
        s3file1: kpiState?.s3file1
      };
    }
  },

  components: {
    VizFileInput,
    VizButton,
    DataImportOption
    // VizInput
  },

  setup() {
    const dataImportOptions = markRaw([
      // {
      //   label: 'pages.ca.enterdata.section2.rds',
      //   key: 'rds',
      //   icon: StorageIcon
      // },
      // {
      //   label: 'pages.ca.enterdata.section2.iweb',
      //   key: 'iweb',
      //   icon: linkIcon
      // },
      {
        label: 'pages.ca.enterdata.section2.ilocal',
        key: 'ilocal',
        icon: cloudUploadIcon
      },
      { label: 'pages.ca.enterdata.section2.aws', key: 'aws', icon: awsIcon },
      // {
      //   label: 'pages.ca.enterdata.section2.azure',
      //   key: 'azure',
      //   icon: azureIcon
      // },
      {
        label: 'pages.ca.enterdata.section2.google',
        key: 'gcs',
        icon: googleCloudIcon
      }
    ]);

    const initialFormValues = {
      s3InputFile1: null
    };

    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setValues,
      resetForm
    } = useForm({
      validationSchema,
      initialValues: initialFormValues
    });

    const state = reactive({
      importFrom: 'ilocal',
      errorMessage: '',
      isUploadLoading: false,
      isSubmissionLoading: false,
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_region: '',
      bucket: '',
      filename: '',
      path: '',
      checkFileUploadStatus: 0,
      awsprogress: 0,
      files: {
        s3file1: ''
      },
      awsError: {
        aws_access_key_id: '',
        aws_secret_access_key: '',
        aws_region: '',
        bucket: '',
        filename: '',
        path: ''
      }
    });

    const store = useStore();
    const router = useRouter();

    const setDataImportOption = (option: string) => {
      state.importFrom = option;
      state.aws_access_key_id = '';
      state.aws_secret_access_key = '';
      state.bucket = '';
      state.aws_region = '';
      state.path = '';
      state.filename = '';
      state.errorMessage = '';
    };

    const { value: s3InputFile1 } = useField('s3InputFile1');
    //  const { value: aws_key } = useField('aws_key');
    //   const { value: aws_secret } = useField('aws_secret');
    //    const { value: aws_region } = useField('aws_region');
    //     const { value: aws_bucket } = useField('aws_bucket');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadFile = async (ev: Event | any) => {
      ev.preventDefault();

      if (!store.getters.isStorageAvailable) {
        return setFieldError('s3InputFile1', 'messages.storage-full');
      }
      const files = ev.target.files;

      if (files && files[0]) {
        const file = files[0];
        const { used, total } = store.getters.storageUsage;
        const availableSize = total * 1073741824 - used * 1073741824;

        if (availableSize <= file.size) {
          return setFieldError('s3InputFile1', 'messages.file-limit-exceed');
        }

        state.isUploadLoading = true;

        store
          .dispatch('ca/localFileUpload', {
            file: files[0],
            fieldname: ev.target.name
          })
          .then(_res => {
            //set filename returned from signed url
            store.dispatch('getStorageUsage', store.getters.userGroup?.id);

            setFieldError(ev.target.name, undefined);
            if (ev.target.name === 's3InputFile1') {
              state.files.s3file1 = _res.filename;
            }

            state.isUploadLoading = false;
            store.dispatch('ca/fileUploadStatus', 'completed');
          })
          .catch(err => {
            if (err.status === 403) {
              setFieldError(ev.target.name, 'errors.invalid-file');
            } else if (err && Array.isArray(err?.errors)) {
              setFieldError(ev.target.name, err.errors[0].error);
            } else if (err.status === 408) {
              setFieldError(ev.target.name, 'errors.request-timeout');
            } else if (err.status === '503') {
              setFieldError(ev.target.name, 'errors.service-unavailable');
            }

            // else {
            //   setFieldError(ev.target.name, 'errors.network-error');
            // }
            state.isUploadLoading = false;
            store.dispatch('ca/fileUploadStatus', 'aborted');
          });
      }
    };

    const onSubmitAws = () => {
      const {
        aws_access_key_id,
        aws_secret_access_key,
        aws_region,
        bucket,
        filename,
        path
      } = state;

      if (!aws_access_key_id) {
        state.awsError.aws_access_key_id = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_access_key_id = '';
      }

      if (!aws_secret_access_key) {
        state.awsError.aws_secret_access_key = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_secret_access_key = '';
      }

      if (!aws_region) {
        state.awsError.aws_region = 'errors.required-field';
        return;
      } else {
        state.awsError.aws_region = '';
      }

      if (!bucket) {
        state.awsError.bucket = 'errors.required-field';
        return;
      } else {
        state.awsError.bucket = '';
      }

      if (!path) {
        state.awsError.path = 'errors.required-field';
        return;
      } else {
        state.awsError.path = '';
      }

      if (!filename) {
        state.awsError.filename = 'errors.required-field';
        return;
      } else {
        state.awsError.filename = '';
      }

      state.isUploadLoading = true;

      store
        .dispatch('ca/awsFileUpload', {
          aws_access_key_id,
          aws_secret_access_key,
          aws_region,
          bucket,
          analysis_type: 'contribution_analysis',
          filename,
          path,
          provider: state.importFrom
        })
        .then(_res => {
          state.checkFileUploadStatus = setInterval(() => {
            store
              .dispatch('ca/getAwsProgress')
              .then(d => {
                if (d.status === 'aborted') {
                  state.isUploadLoading = false;
                  state.errorMessage = d?.message;
                  clearInterval(state.checkFileUploadStatus);
                } else if (d.status === 'pending') {
                  state.awsprogress = d.completed;
                } else if (d.status === 'complete') {
                  state.awsprogress = d.completed;
                  state.isUploadLoading = false;
                  clearInterval(state.checkFileUploadStatus);

                  setTimeout(() => {
                    store
                      .dispatch(
                        'ca/fetchMeasureDataAws',
                        store.getters['ca/taskId']
                      )
                      .then(() => {
                        state.errorMessage = '';
                        state.isSubmissionLoading = false;
                        state.isUploadLoading = false;

                        router.push({
                          name: 'ContributionMeasureData',
                          path: '/analysis/measure',
                          params: { taskId: store.getters['ca/taskId'] }
                        });
                      })
                      .catch(err => {
                        debugger;
                        state.isSubmissionLoading = false;
                        state.errorMessage = err?.errors
                          ? err?.errors[0].error
                          : '';
                      });
                  }, 1000);
                }
              })
              .catch(err => {
                debugger;
                state.isUploadLoading = false;
                clearInterval(state.checkFileUploadStatus);
                state.isSubmissionLoading = false;
                state.errorMessage = err?.errors ? err?.errors[0].error : '';
              });
          }, 2000);
        });
    };

    const onSubmit = handleSubmit(values => {
      if (!state.files.s3file1) {
        setFieldError('s3InputFile1', 'errors.upload-not-complete');
        return;
      }
      state.isSubmissionLoading = true;
      store
        .dispatch('ca/updateKPIFileInputData', {
          ...values,
          ...state.files
        })
        .then(() => {
          store
            .dispatch('ca/fetchMeasureData', store.getters['ca/taskId'])
            .then(() => {
              state.errorMessage = '';
              state.isSubmissionLoading = false;

              router.push({
                name: 'ContributionMeasureData',
                path: '/analysis/measure',
                params: { taskId: store.getters['ca/taskId'] }
              });
            })
            .catch(err => {
              state.isSubmissionLoading = false;
              state.errorMessage = err?.errors ? err?.errors[0].error : '';
            });
        })
        .catch(err => {
          // state.isSubmissionLoading = false;
          // if (err.errors[0]?.error) {
          //   state.errorMessage = err.errors[0].error;
          // }
        });
    });

    return {
      s3InputFile1,
      // aws_key,
      // aws_secret,
      // aws_region,
      // aws_bucket,
      state,
      dataImportOptions,
      setDataImportOption,
      onSubmit,
      onSubmitAws,
      errors,
      meta,
      attachFileIcon,
      uploadFile,
      setValues,
      resetForm
    };
  },
  methods: {
    ...mapActions([
      'resetKPIFileInputFormState',
      'fileUploadStatus',
      'terminateUploadAws'
    ]),

    terminate() {
      this.resetForm();
      // this.fileUploadStatus('aborted');
      this.resetKPIFileInputFormState();
    },

    terminateUpload() {
      this.terminateUploadAws();
    }
  },
  computed: {
    ...mapGetters(['kpiFileInputForm', 'isExitAllowed', 'taskId']),
    ...mapRootGetters(['userGroup'])
  },
  watch: {
    userGroup(newVal: UserGroup) {
      if (newVal?.id) {
        this.resetForm();
        this.resetKPIFileInputFormState();
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.btn-abort {
  white-space: nowrap;
}
input::placeholder {
  color: transparent;
  transition: all 0.3s ease;
}
input:focus::placeholder {
  color: inherit;
}
</style>
