/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import { RootState } from '../index';
import simulationAPI, {
  OptimizeModelRequest,
  SimulateModelRequest
} from '@/api/measure-simulation';

export interface SimulationStateInterface {
  states: Array<string>;
  logs: Array<Array<string>>;
}
interface PatternAState {
  model: string;
}
interface PatternBState {
  model: string;
}

export interface MeasureSimulationState {
  status: string | null;
  taskId: number;
  modelOptions: Array<string>;
  simulationState: SimulationStateInterface;

  patternA: PatternAState | null;

  patternB: PatternBState | null;

  isExitAllowed: boolean;
  report: {
    patternA: any;
    patternB: any;
  };
}

const STATUS = { LOADING: 'loading', SUCCESS: 'success', ERROR: 'error' };

const initialState = {
  status: null,
  taskId: null,
  patternA: null,
  patternB: null,
  modelOptions: [],
  isExitAllowed: true,
  simulationState: {
    states: [],
    logs: null
  },
  report: {
    patternA: {
      targetSales: 0,
      targetValues: {},
      volumePrediction: [],
      kpiForecast: []
    },
    patternB: {
      targetSales: 0,
      targetValues: {},
      volumePrediction: [],
      kpiForecast: []
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setModelOptions(
      state: MeasureSimulationState,
      modelOptions: Array<string>
    ) {
      state.modelOptions = modelOptions;
    },
    setPatternAReportState(state: MeasureSimulationState, data: any) {
      state.patternA = { ...state.patternA, ...data };
    },
    setTaskId(state: MeasureSimulationState, taskID: number) {
      state.taskId = taskID;
    },

    setExitAllowed(state: MeasureSimulationState, isExitAllowed: boolean) {
      state.isExitAllowed = isExitAllowed;
    },

    setSimulationState(
      state: MeasureSimulationState,
      analysisState: SimulationStateInterface
    ) {
      state.simulationState = analysisState;
    },
    statusPending(state: MeasureSimulationState) {
      state.status = STATUS.LOADING;
    },

    statusSuccess(state: MeasureSimulationState) {
      state.status = STATUS.SUCCESS;
    },

    statusError(state: MeasureSimulationState) {
      state.status = STATUS.ERROR;
    }
  },
  actions: {
    getModelOptions(context: ActionContext<MeasureSimulationState, RootState>) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        context.commit('statusPending');
        simulationAPI
          .getModelOptions(groupId)
          .then(({ data }) => {
            context.commit('setModelOptions', data?.models);
            context.commit('statusSuccess');
            resolve(data);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    getMeasureOptions(
      context: ActionContext<MeasureSimulationState, RootState>,
      modelName: string
    ) {
      return new Promise((resolve, reject) => {
        context.commit('statusPending');
        const groupId = Number(context.rootState.auth.userGroup?.id);

        simulationAPI
          .getTargetMeasureOptions({ groupId, model: modelName })
          .then(({ data }) => {
            context.commit('statusSuccess');
            resolve(data?.target_values);
          })
          .catch(err => {
            context.commit('statusError');
            reject(err);
          });
      });
    },
    runSimulateModel(
      context: ActionContext<MeasureSimulationState, RootState>,
      requestData: SimulateModelRequest
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);
        context.commit('statusPending');

        simulationAPI
          .runSimulateModel({ ...requestData, groupId })
          .then(({ data }) => {
            context.commit('statusSuccess');
            const taskId = data?.task_id;
            context.commit('setTaskId', taskId);
            resolve(taskId);
          })
          .catch(err => {
            context.commit('statusError');
            context.commit('setTaskId', 0);
            reject(err);
          });
      });
    },
    runOptimizeModel(
      context: ActionContext<MeasureSimulationState, RootState>,
      requestData: OptimizeModelRequest
    ) {
      return new Promise((resolve, reject) => {
        const groupId = Number(context.rootState.auth.userGroup?.id);

        context.commit('statusPending');

        simulationAPI
          .runOptimizeModel({ ...requestData, groupId })
          .then(({ data }) => {
            context.commit('statusSuccess');
            const taskId = data?.task_id;
            context.commit('setTaskId', taskId);
            resolve(taskId);
          })
          .catch(err => {
            context.commit('statusError');
            context.commit('setTaskId', 0);
            reject(err);
          });
      });
    },
    getSimulationState(
      context: ActionContext<MeasureSimulationState, RootState>,
      { pattern, taskId }: { pattern: 'patternA' | 'patternB'; taskId: number }
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId || !pattern) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        const currentRun =
          pattern == 'patternA'
            ? simulationAPI.getSimulationState(taskId)
            : simulationAPI.getOptimizationState(taskId);
        currentRun
          .then(res => res.data)
          .then((data: SimulationStateInterface) => {
            context.commit('setSimulationState', data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    resetState(context: ActionContext<MeasureSimulationState, RootState>) {
      context.commit('setTaskId', null);
      context.commit('setExitAllowed', true);
    },
    getSimulateTotalSales(
      context: ActionContext<MeasureSimulationState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        simulationAPI
          .getSimulateTotalSalesReport(taskId)
          .then(res => {
            const targetSales = res?.data?.results
              ? res.data?.results[0]?.target_value
              : 0;
            context.commit('setPatternAReportState', targetSales);
            resolve(targetSales);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getSimulateMediaVolume(
      context: ActionContext<MeasureSimulationState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        simulationAPI
          .getSimulateMediaVolumeReport(taskId)
          .then(res => {
            const results = res.data?.results;
            let tempTarget = {};

            if (Array.isArray(results)) {
              results.forEach(item => {
                tempTarget = { ...tempTarget, [item.val]: item.budget };
              });
            }
            context.commit('setPatternAReportState', {
              targetValues: tempTarget
            });
            resolve(tempTarget);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    exportSimulationReport(
      _context: ActionContext<MeasureSimulationState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        simulationAPI
          .exportSimulationReport(taskId)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${taskId}.zip`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    exportOptimizationReport(
      _context: ActionContext<MeasureSimulationState, RootState>,
      taskId: number
    ) {
      return new Promise((resolve, reject) => {
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }
        simulationAPI
          .exportOptimizationReport(taskId)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${taskId}.zip`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    termiateProcess(
      context: ActionContext<MeasureSimulationState, RootState>,
    ) {
      return new Promise((resolve, reject) => {
        const taskId = context.state.taskId;
        if (!taskId) {
          const error = {
            errors: [
              {
                error:
                  'タスクIDが必要です。再ロード、再起動を試してみてください。'
              }
            ]
          };
          return reject(error);
        }

        simulationAPI
          .termiateProcess(Number(taskId),)
          .then(res => res.data.results)
          .then((results: Array<any>) => {
            resolve(results);
          })
          .catch(err => {
            reject(err);
          });
      });

    },
  },

  getters: {
    modelOptions: (state: MeasureSimulationState) => state.modelOptions,
    simulationState: (state: MeasureSimulationState) => state.simulationState,
    isExitAllowed: (state: MeasureSimulationState) => state.isExitAllowed,
    taskId: (state: MeasureSimulationState) => state.taskId,
    patternAReport: (state: MeasureSimulationState) => state.patternA
  }
};
