import { object, string, ref } from 'yup';
const signupSchema = object({
  email: string()
    .required(() => 'errors.required-email')
    .email(() => 'errors.invalid-email'),
  password: string()
    .required(() => 'errors.required-password')
    .min(8, () => 'errors.password-short'),
  confirmPassword: string()
    .required(() => 'errors.required-password')
    .oneOf([ref('password'), null], () => 'errors.passwords-not-match')
});
export default signupSchema;
