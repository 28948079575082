
import { defineComponent } from "vue";
import ExecutionHistory from "@/components/common/ExecutionHistory.vue";

export default defineComponent({
  name: "MeasureSimulation",
  components: {
    ExecutionHistory
  },
  data() {
    return {
      steps: [
        { title: "pages.ms.steps.1", number: 1 },
        { title: "pages.ms.steps.2", number: 2 }
      ]
    };
  }
});
