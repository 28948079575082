<template>
  <div class="main px-60">
    <main>
      <div>
        <steps-track
          v-bind:currentStep="currentStep"
          v-bind:steps="steps"
          justifyStart
        >
        </steps-track>
        <p class="text-danger">{{ errorMessage }}</p>
        <p class="text-info" v-if="info">{{ $t(info) }}</p>
        <router-view></router-view>
      </div>
    </main>

    <transition name="fade">
      <execution-history></execution-history>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import ExecutionHistory from '@/components/common/ExecutionHistory.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'ContributionAnalysis',
  beforeMount() {
    this.resetKpiState();
  },
  components: {
    StepsTrack,
    ExecutionHistory,
  },
  mounted() {
    this.info = 'labels.requesting-taskid';
    this.getTaskId()
      .then(() => {
        this.errorMessage = '';
        this.info = '';
      })
      .catch((err) => {
        this.info = '';

        if (err && Array.isArray(err?.errors)) {
          this.errorMessage = err?.errors[0]?.error;
        } else {
          this.errorMessage =
            'Network Error while loading task.Reload and try again';
        }
      });

    window.addEventListener('beforeunload', this.handleClose);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.handleClose);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isExitAllowed) {
      if (window.confirm('戻ってもよろしいですか？')) {
        this.fileUploadStatus('aborted');
        this.resetKpiState();
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      errorMessage: '',
      info: '',
      steps: [
        {
          title: 'pages.ca.steps.1',

          number: 1,
        },
        {
          title: 'pages.ca.steps.2',
          number: 2,
        },
        { title: 'pages.ca.steps.3', number: 3 },
      ],
    };
  },

  methods: {
    ...mapActions(['resetKpiState', 'getTaskId', 'fileUploadStatus']),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose(e: Event | any) {
      // eslint-disable-next-line no-useless-escape
      const confirmationMessage = 'o/';
      if (!this.isExitAllowed) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
  },
  computed: {
    ...mapGetters(['isExitAllowed']),
    currentStep: function (): number {
      return this.$route.meta.step || 1;
    },
  },
});
</script>
