
import { defineComponent, reactive, markRaw } from 'vue';
import VizButton from '@/components/ui/VizButton.vue';
import VizMultipleEmailInput from '@/components/ui/VizMultipleEmailInput.vue';
import { logo } from '@/utils/icons';
import validationSchema from '@/validator/add-group';
import { useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    VizButton,
    VizMultipleEmailInput
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {
      errors,
      handleSubmit,
      meta,
      setFieldError,
      setValues,
      values
    } = useForm({
      validationSchema
    });

    const { value: name } = useField('name');
    const { value: receiver } = useField('receiver');

    const state = reactive({
      errorMessage: '',
      isLoading: false
    });
    const icons = markRaw({
      logo
    });
    const handleChange = (data: Array<string>) => {
      setValues({ ...values, receiver: data });
    };
    const onSubmit = handleSubmit(data => {
      if (Array.isArray(data.receiver) && data.receiver.length > 10) {
        setFieldError('receiver', 'errors.max-invitation-limit');
        return;
      }

      state.isLoading = true;
      store
        .dispatch('addGroup', data)
        .then(() => {
          state.isLoading = false;
          store.dispatch('fetchUserGroups').then(groups => {
            const currentGroup = groups.filter(
              (group: { name: string; id: number }) => group.name == data?.name
            )?.[0];
            store.dispatch('changeUserGroup', currentGroup);
          });

          return router.push({ name: 'History' }).then(() => {
            store.dispatch('addToast', {
              message: 'messages.group-added',
              data: {
                groupName: data?.name,
                people: data?.receiver?.length
              }
            });
          });
        })
        .catch(err => {
          state.isLoading = false;

          const errs = err?.errors;
          if (errs) {
            errs.forEach((item: any) => {
              if (item.error) {
                state.errorMessage = item.error;
              }
              if (item.name) {
                setFieldError('name', item.name);
              }
              if (item.receiver) {
                setFieldError('receiver', item.receiver);
              }
            });
          }
        });
    });
    return {
      state,
      onSubmit,
      handleChange,
      errors,
      meta,
      icons,
      receiver,
      name
    };
  }
});
