<template>
  <main>
    <div>
      <steps-track v-bind:steps="steps" :currentStep="2"></steps-track>
      <div class="analyzing mt-5">
        <div class="row">
          <p class="text-danger pb-4">{{ $t(state.errorMessage) }}</p>

          <div class="col-md-4 mb-3">
            <div class="sub_title">
              <h4>{{ $t('pages.te.loader.title') }}・・・</h4>
            </div>
            <ul class="process">
              <li class="mt-4">
                <div
                  class="
                    progress-data
                    mb-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <!-- <div class="time text-primary">
                    {{ $t('pages.te.loader.secondsleft', { seconds: 32 }) }}
                  </div> -->
                </div>
                <div class="position-relative">
                  <div class="progress" style="height: 5px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="analysisPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="`width: ${analysisPercentage}%`"
                    ></div>
                  </div>
                </div>
              </li>
              <li
                :class="
                  `mt-4 ${isStateComplete(states.LOADING) &&
                    'sucess'}  ${hasStateError(states.LOADING) && 'error'}`
                "
              >
                {{ $t('pages.te.loader.dr') }}
              </li>
              <li
                :class="
                  `mt-4 ${isStateComplete(states.PREPROCESSING) &&
                    'sucess'}  ${hasStateError(states.PREPROCESSING) &&
                    'error'}`
                "
              >
                {{ $t('pages.te.loader.dp') }}
              </li>
              <li
                :class="
                  `mt-4 ${isStateComplete(states.TRAINING_AND_PREDICT) &&
                    'sucess'}  ${hasStateError(states.TRAINING_AND_PREDICT) &&
                    'error'}`
                "
              >
                {{ $t('pages.te.loader.ml') }}
              </li>
              <li
                :class="
                  `mt-4 ${isStateComplete(states.EVALUATING) &&
                    'sucess'} ${hasStateError(states.EVALUATING) && 'error'}`
                "
              >
                {{ $t('pages.te.loader.ie') }}
              </li>
              <li
                :class="
                  `mt-4 ${isStateComplete(states.FINISH_ESTIMATING) &&
                    'sucess'} ${hasStateError(states.FINISH_ESTIMATING) &&
                    'error'}`
                "
              >
                {{ $t('pages.te.loader.pca') }}
              </li>
            </ul>
          </div>
          <div class="col-md-8 mb-3">
            <div class="position-relative terminal">
              <div class="text">
                <p v-for="log in logs" :key="log">{{ log }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="button d-block">
          <!-- <viz-button
            type="button"
            :disabled="true"
            class="btn btn-default text-muted"
            @click="$emit('goBack')"
          >
            {{ $t('common.buttonlabels.goback') }}
          </viz-button> -->

          <viz-button
            type="button"
            :disabled="!isProcessComplete"
            @click="$emit('saveAnalysis')"
            class="btn btn-primary text-white fw-bold"
          >
            {{ $t('common.buttonlabels.nextstep') }}
          </viz-button>
          <viz-button
            type="button"
            class="btn btn-secondary rounded-pill ms-3 btn-abort"
            data-bs-toggle="modal"
            data-bs-target="#abort-modal"
            v-if="abortActive"
          >
            分析を中止する
          </viz-button>
          <!-- Modal -->
          <div
            class="modal fade"
            id="abort-modal"
            tabindex="-1"
            aria-labelledby="abort"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-sm modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  この進行を中止してもよろしいですか？
                </div>
                <div class="modal-footer justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary rounded-pill ms-3 btn-sm"
                    data-bs-dismiss="modal"
                  >
                    キャンセル
                  </button>
                  <viz-button
                    type="button"
                    class="btn btn-secondary rounded-pill ms-3 btn-sm"
                    data-bs-dismiss="modal"
                    @click="terminateProces"
                    v-if="abortActive"
                  >
                    分析を中止する
                  </viz-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw, reactive } from 'vue';
import StepsTrack from '@/components/ui/StepsTrack.vue';
import VizButton from '@/components/ui/VizButton.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('te');
import store from '@/store';

export default defineComponent({
  name: 'TargetAnalysisLoader',
  props: {
    isAnalysed: Boolean,
    steps: Array,
    currentStep: Number
  },

  components: {
    StepsTrack,
    VizButton
  },

  emits: ['saveAnalysis', 'goBack'],
  setup() {
    const states = markRaw({
      LOADING: 'loading_data',
      PREPROCESSING: 'preprocessing',
      TRAINING_AND_PREDICT: 'training_and_predicting',
      EVALUATING: 'evaluating',
      CALCULATE_IMPORTANCE: 'calculating importances',
      FINISH_ESTIMATING: 'finish_process'
    });
    const state = reactive({
      analysisPoll: 0,
      errorMessage: ''
    });
    return { state, states, abortActive: true };
  },
  mounted() {
    this.pullData();
    this.setExitAllowed(false);
  },

  unmounted() {
    this.setExitAllowed(true);
  },
  beforeUnmount() {
    store.dispatch('getStorageUsage', store.getters.userGroup?.id);

    clearInterval(this.state.analysisPoll);
  },
  computed: {
    ...mapGetters(['extractionStatus']),

    logs: function() {
      let logs: Array<string> = [];
      if (!Array.isArray(this.extractionStatus?.logs)) {
        return logs;
      }
      this.extractionStatus.logs.forEach((item: Array<string>) => {
        logs = [...logs, ...item];
      });
      return logs;
    },
    analysisError: function(): {
      status: boolean;
      failedSteps: Array<string>;
    } {
      const receivedStates = this.extractionStatus.states as Array<string>;
      const allStates = Object.values(this.states);
      if (!Array.isArray(receivedStates)) {
        return { status: false, failedSteps: [] };
      }
      const failedStepIndex = receivedStates.indexOf('error');

      return {
        status: receivedStates.includes('error'),
        failedSteps:
          failedStepIndex >= 0 ? allStates.slice(failedStepIndex) : []
      };
    },
    completedSteps: function(): Array<string> {
      const allStatesArray = Object.values(this.states);
      const statesReceived = this.extractionStatus?.states;
      let maxIndex = -1;
      if (!statesReceived) {
        return [];
      }
      statesReceived.forEach((stateName: string) => {
        const currentIndex = allStatesArray.indexOf(stateName);
        if (currentIndex > maxIndex) {
          maxIndex = currentIndex;
        }
      });

      return maxIndex >= 0 ? allStatesArray.slice(0, maxIndex + 1) : [];
    },
    analysisPercentage: function() {
      let percentComplete = 0;

      if (this.completedSteps) {
        const stepsCompleted = this.completedSteps.length;
        const TOTAL_STEPS = 5;
        percentComplete = (stepsCompleted / TOTAL_STEPS) * 100;
      }
      return percentComplete;
    },
    isProcessComplete: function(): boolean {
      return this.isStateComplete(this.states.FINISH_ESTIMATING);
    }
  },
  methods: {
    ...mapMutations(['setExitAllowed']),
    ...mapActions([
      'getExtractionStatus',
      'terminateUpload',
      'resetTargetState'
    ]),
    pullData() {
      this.state.analysisPoll = setInterval(() => {
        if (this.isStateComplete(this.states.FINISH_ESTIMATING)) {
          this.abortActive = false;
          clearInterval(this.state.analysisPoll);
        }
        this.getExtractionStatus()
          .then(() => {
            this.state.errorMessage = '';

            if (this.analysisError.status) {
              this.abortActive = false;
              this.state.errorMessage = 'errors.analysis-failed';
            }
          })
          .catch(err => {
            if (err && Array.isArray(err.errors)) {
              this.abortActive = false;
              this.state.errorMessage = err.errors[0].error;
            }
          });
      }, 2000);
    },
    hasStateError(state: string) {
      return this.analysisError.failedSteps?.includes(state) ? true : false;
    },
    isStateComplete(state: string): boolean {
      return (
        Array.isArray(this.completedSteps) &&
        this.completedSteps.includes(state)
      );
    },
    terminateProces() {
      this.terminateUpload();
      this.resetTargetState();
      this.$emit('goBack');
    }
  },
  watch: {
    analysisError(newVal) {
      if (newVal.status) {
        this.abortActive = false;
        this.state.errorMessage = 'errors.analysis-failed';
        clearInterval(this.state.analysisPoll);
      }
    }
  }
});
</script>

<style scoped lang="scss">
/* // css for the terminal design */
.terminal {
  max-width: 540px;
  min-height: 400px;
  max-height: 400px;
  background-color: #333333;
  padding: 10px 20px;

  overflow-y: auto;
  border-radius: 7px;
  .text {
    color: #fff;
    font-family: Courier;
    font-weight: 700;
  }
}
</style>
