<template>
  <div class="login max__width--form bg-white p-4">
    <form action="post" class="login__form">
      <figure class="mb-3">
        <img :src="icons.logo" alt="" style="padding: 0 10px;height: 110px;" />
      </figure>
      <legend>{{ $t('pages.signupc.title') }}</legend>

      <div class="d-block mt-3 button">
        <router-link
          to="/login"
          type="button"
          class="btn btn-primary text-white px-4"
        >
          {{ $t('pages.signupc.btnTitle') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { logo } from '@/utils/icons';

export default defineComponent({
  setup() {
    const icons = markRaw({
      logo: logo
    });
    return { icons };
  }
});
</script>

<style scoped></style>
