<template>
  <div class="analyzing mt-5">
    <analysis-report :taskId="taskId"></analysis-report>
    <div class="button d-block">
      <viz-button
        type="button"
        @click="handleGoBack"
        class="btn btn-default text-muted"
      >
        {{ $t('common.buttonlabels.goback') }}
      </viz-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import AnalysisReport from '@/components/contribution-analysis/AnalysisResultReport.vue';
import VizButton from '@/components/ui/VizButton.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('ca');

export default defineComponent({
  name: 'AnalysisResult',
  components: {
    AnalysisReport,
    VizButton
  },
  beforeMount() {
    const { taskId } = this.$route.params;
    if (!taskId) {
      this.$router.replace({ path: '/analysis' });
    }
  },
  data() {
    const state = reactive({
      isAnalysed: false
    });
    return { state };
  },
  beforeRouteLeave(to, from, next) {
    this.resetKpiState();
    this.getTaskId();
    next();
  },
  computed: {
    ...mapGetters(['taskId'])
  },
  props: {
    currentStep: Number
  },
  emits: ['changeStep'],

  methods: {
    ...mapActions(['resetKpiState', 'getTaskId']),

    handleAnalysisCompletion() {
      this.state.isAnalysed = true;
    },
    async handleGoBack() {
      this.$router.push({ name: 'ContributionEnterData' });
    }
  }
});
</script>
